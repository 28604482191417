import { SolutionByProductMindeskOverview } from '../../../Queries';
import useFetch from '../../../hooks/useFetch';
import { Link } from 'react-router-dom';
import ListCard from './ListCard';
import Loader from '../../common/Loader';
import FeatureCard from './FeatureCard';
import ByProductLayout from './ByProductLayout';
import bgImage from '../../../images/products/3dframe/banner-bg.png';
import mindeskLogo from '../../../images/mindesk-logo.png';
import Image from '../../common/Image';
import Banner from './Banner';
import SEO from '../../common/SEO';

export default function MindeskOverviewPage() {
  const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/product-mindesk-overview?' + SolutionByProductMindeskOverview);
  if (loading) {
    return <Loader />;
  }
  const { data } = response ? response : [];
  const { attributes } = data ? data : [];
  const { BenefitList } = attributes ? attributes : [];
  const { ExperienceList } = attributes ? attributes : [];
  const { HowToList } = attributes ? attributes : [];
  const seoMeta = (attributes.seo) ? attributes.seo : null
  return (
    <ByProductLayout menuHeading="Products" backgroundImage={bgImage} logo={attributes.Logo} >
      <SEO meta={seoMeta}></SEO>
      <Banner title={attributes.Title} description={attributes.Description} video={attributes.Video} link="https://mindeskvr.com/store/" productName="mindesk" />

      <section className="entry-structure">
        <div className="spacer-xs-8 spacer-xl-12"></div>
        <div className="row">
          <div className="col-sm-12">
            <div className="entry-display-2">
              <h2>{attributes && attributes.BenefitTitle ? attributes.BenefitTitle : ''}</h2>
              <p>{attributes && attributes.BenefitDescription ? attributes.BenefitDescription : ''}</p>
            </div>
          </div>
        </div>
        <div className="spacer-xs-6 spacer-xl-8"></div>
        {BenefitList.map((item, index) => {
          return <FeatureCard key={'feature-card-2-' + index} lastItem={index === (BenefitList.length - 1)} itemIndex={index} item={item} />;
        })}
        {GetStarted("big")}
        <div className="spacer-xs-8 spacer-xl-12"></div>
      </section>
      <section className="entry-structure-light">
        <div className="spacer-xs-8 spacer-xl-10"></div>
        <div className="row">
          <div className="col-sm-12">
            <div className="entry-display-2">
              <div className="entry-thumb img-overlay">
                <Image src={attributes && attributes.Image.data.attributes.url ? attributes.Image.data.attributes.url : ''} className="img-fluid" />
              </div>
              <div className="spacer-xs-6 spacer-xl-10"></div>
              <h2>{attributes && attributes.ExperienceTitle ? attributes.ExperienceTitle : ''}</h2>
              <p>{attributes && attributes.ExperienceDescription ? attributes.ExperienceDescription : ''}</p>
            </div>
          </div>
        </div>
        <div className="spacer-xs-6 spacer-xl-8"></div>
        {ExperienceList.map((itemEx, indexEx) => {
          return <FeatureCard key={'feature-card-1-' + indexEx} lastItem={indexEx === (ExperienceList.length - 1)} itemIndex={indexEx} item={itemEx} />;
        })}
      </section>
      <section className="entry-structure product-list-3">
        <div className="spacer-xs-8 spacer-xl-12"></div>
        <div className="row">
          <div className="col-sm-12">
            <div className="entry-display-3 text-center">
              <h3>{attributes && attributes.HowToTitle ? attributes.HowToTitle : ''}</h3>
              <div className="spacer-xs-4 spacer-xl-6"></div>
              <div className="row flex-wrap gx-4">
                {HowToList.map((itemList, indexList) => {
                  return <ListCard item={itemList} index={indexList} />;
                })}
              </div>
              <div className="spacer-xs-4 spacer-xl-6"></div>
              {GetStarted("big")}
            </div>
          </div>
        </div>
        <div className="spacer-xs-8 spacer-lg-12"></div>
      </section>
    </ByProductLayout>
  );
}

function GetStarted(size) {
  let btncClass = size == "big" ? "btn btn-primary btn-lg text-white icon-btn" : "btn btn-primary btn-sm text-white icon-btn px-5 py-3";
  return (
    <div className="row">
      <div className="col-sm-12 d-flex justify-content-center">
        <a href="https://mindeskvr.com/store/" target="_blank" className={btncClass}>Get Started<i className="icon-comman icon-arrow-right ms-3 fs-lg-22"></i></a>
        {/* <Link reloadDocument={true} to="#" className="btn btn-primary btn-lg text-white icon-btn">
          Get Started<i className="icon-comman icon-arrow-right ms-3 fs-lg-22"></i>
        </Link> */}
      </div>
    </div>
  );
}
