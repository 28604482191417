import { Link } from "react-router-dom"

export default function ServiceList({ serviceList }){
    return ( serviceList.map((service , index ) => {
        return (
            <div className="col-sm-6 mb-4" key={index}>
            <div className="card mb-3 bg-transparent border-0">
            <div className="row g-0">
            <div className="col-md-auto  d-flex justify-content-center">
                <div className={"entry-icon  mb-4 " +(service.IconColor)}>
                    <span><i className={"icon-services " + (service.IconSlug)}></i></span> 
            </div>
          </div>
          <div className="col-md-10">
             <div className="entry-display-5 ps-0 ps-md-6 text-center text-md-start">
                <h5>{service.Title}<i className="icon-comman icon-right"></i></h5>
                <p>{service.Description}</p>
                <Link reloadDocument={true} to={service.Link} className="stretched-link"></Link>
             </div>
          </div>
        </div>
        </div>
        </div>
        )
    }))
}