import React, { useEffect, useState, useRef } from 'react';
import { stockApi } from '../../services/stockApi';

export default function StockChart() {
  const [chartLoaded, setChartLoaded] = useState(false);
  const [objFinance, setObjFinance] = useState({ price: null, priceChange: null, volume: null, code: null, capitalization: null, negativeChange: null });
  const [count, setCount] = useState(365);
  const priceChartRef = useRef(null);
  let filters = { '1 month': 30, '3 months': 90, '6 months': 180, '1 year': 365, '2 years': 730, '5 years': 1825 };
  let priceChart;
  let volumeChart;

  useEffect(() => {
    stockApi.financialSummary(
      response => {
        const { data } = response;
        let negativeChange = data.change_in_percent.indexOf('-') >= 0 ? true : false;
        let capital = (data.market_cap / 1000000).toFixed(2);
        let volume = (data.volume / 1000000).toFixed(2);
        setObjFinance({
          price: data.last_price,
          negativeChange: negativeChange,
          priceChange: negativeChange ? data.change_in_percent : '+' + data.change_in_percent + ' ',
          volume: volume + 'M ',
          code: data.code,
          capitalization: capital + 'M '
        });
      },
      error => {
        console.log('error:', error);
      }
    );
    getData(count);
  }, []);

  const getData = count => {
    setChartLoaded(false);
    stockApi.chart(
      count,
      response => {
        if (response) {
          const { labels, data } = response;
          if (typeof data !== undefined) {
            createChart(data, labels);
          }
        }
      },
      error => {
        console.log('error:', error);
      }
    );
  };

  const createChart = (data, labels) => {
    const priceConfig = {
      type: 'line',
      data: {
        labels: [labels, labels],
        datasets: [
          {
            label: 'Close Price',
            borderColor: '#46adfa',
            data: data,
            fill: 'none',
            borderWidth: 1.5
          },
          {
            label: 'High Price',
            borderColor: '#ff4949',
            data: data,
            fill: 'none',
            borderWidth: 1.5
          }
        ]
      },
      options: {
        interaction: {
          mode: 'index',
          intersect: false
        },
        elements: {
          line: {
            tension: 0
          },
          point: {
            radius: 0
          }
        },
        parsing: {
          xAxisKey: 'date',
          yAxisKey: ['close', 'highPrice']
        },
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            bodySpacing: 4,
            padding: 10,
            cornerRadius: 2,
            displayColors: false,
            callbacks: {
              title: function (context) {
                var title = 'VR1';
                return title;
              },
              // label: function (context) {
              //   var label = 'Close Price: ' + context.dataset.data[context.dataIndex].close || '';
              //   return label;
              // },
              // afterLabel: function (context) {
              //   let temp = context.dataset.data[context.dataIndex].volume;
              //   var afterLabel = 'Volume: ' + temp * 1000000 || '';
              //   return afterLabel;
              // },
              labelTextColor: function (context) {
                return '#fff';
              }
            }
          }
        },
        scales: {
          y: {
            title: { display: false },
            beginAtZero: true,
            grid: {
              drawBorder: false
            },
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value, index, ticks) {
                return '$' + value + '  ';
              }
            }
          },
          x: {
            title: { display: false },
            grid: {
              display: false,
              drawBorder: false,
              drawOnChartArea: false
            },
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value, index, ticks) {
                // console.log('value, index, ticks:', value, index, ticks);
                return ' ';
              }
            }
          }
        }
      }
    };
    const volumeConfig = {
      type: 'bar',
      data: {
        labels: [labels],
        datasets: [
          {
            label: 'Close Price',
            borderColor: '#46adfa',
            data: data,
            fill: '#46adfa',
            borderWidth: 1.5,
            backgroundColor: '#46adfa'
          }
        ]
      },
      options: {
        interaction: {
          mode: 'index',
          intersect: false
        },
        elements: {
          line: {
            tension: 0
          },
          point: {
            radius: 0
          }
        },
        parsing: {
          xAxisKey: 'date',
          yAxisKey: ['volume']
        },
        responsive: true,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            bodySpacing: 4,
            padding: 10,
            cornerRadius: 2,
            displayColors: false,
            callbacks: {
              title: function (context) {
                var title = 'VR1';
                return title;
              },
              label: function (context) {
                var label = 'Close Price: ' + context.dataset.data[context.dataIndex].close || '';
                return label;
              },
              afterLabel: function (context) {
                let temp = context.dataset.data[context.dataIndex].volume;
                var afterLabel = 'Volume: ' + temp * 1000000 || '';
                return afterLabel;
              },
              labelTextColor: function (context) {
                return '#fff';
              }
            }
          }
        },
        scales: {
          y: {
            title: { display: false },
            beginAtZero: true,
            grid: {
              drawBorder: false
            },
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value, index, ticks) {
                return value + 'M  ';
              }
            }
          },
          x: {
            offset: true, // <-- This right here
            title: { display: false },
            min: 10,
            grid: {
              display: false,
              drawBorder: false,
              drawOnChartArea: false
            },
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value, index, ticks) {
                return '';
              }
            }
          }
        }
      }
    };
    try {
      setChartLoaded(true);
      const ctx = document.getElementById('priceChart').getContext('2d');
      const volumeCtx = document.getElementById('volumeChart').getContext('2d');
      priceChart = new window.Chart(ctx, priceConfig);
      volumeChart = new window.Chart(volumeCtx, volumeConfig);
    } catch (error) {
      // console.log('CHART ....... error:', error);
    }
  };

  const handleRangeChange = option => {
    if (option !== count) {
      var totalHeight = document.getElementById('priceChart').height + document.getElementById('volumeChart').height;

      var chartParentDiv = document.getElementById('chartParent');
      chartParentDiv.style.minHeight = totalHeight + 'px';

      var chartsDiv = document.getElementById('charts');
      document.getElementById('priceChart').remove();
      document.getElementById('volumeChart').remove();
      var priceChartCanvas = document.createElement('canvas');
      priceChartCanvas.id = 'priceChart';
      priceChartCanvas.width = 130;
      priceChartCanvas.height = 45;

      chartsDiv.appendChild(priceChartCanvas);
      var volumeChartCanvas = document.createElement('canvas');
      volumeChartCanvas.id = 'volumeChart';
      volumeChartCanvas.width = 70;
      volumeChartCanvas.height = 12;
      chartsDiv.appendChild(volumeChartCanvas);
      setCount(option);
      getData(option);
    }
  };

  return (
    <React.Fragment>
      <div className="col-sm-2 text-center  justify-content-center stock-chart-info-wrapper">
        <div className="element">
          <label>Price</label>
          <div className="d-inline">
            <p className="title">
              ${objFinance ? objFinance.price : ''} <sub>AUD</sub>
            </p>
          </div>
        </div>
        <hr />
        <div className="element">
          <label>Change</label>
          <div className="d-inline">
            <p className={'title ' + (objFinance.negativeChange ? 'stock-negative-price-change' : 'stock-positive-price-change')}>{objFinance.priceChange}</p>
          </div>
        </div>
        <hr />
        <div className="element">
          <label>Volume</label>
          <div className="d-inline">
            <p className="title">{objFinance ? objFinance.volume : ''}</p>
          </div>
        </div>
        <hr />
        <div className="element">
          <label>Vection Technologies Ltd</label>
          <div className="d-inline">
            <p className="title">ASX:{objFinance ? objFinance.code : ''}</p>
          </div>
        </div>
        <hr />
        <div className="element">
          <label>Capitalization</label>
          <div className="d-inline">
            <p className="title">
              ${objFinance ? objFinance.capitalization : ''}
              <sub>AUD</sub>
            </p>
          </div>
        </div>
      </div>
      <div className="col-sm-10">
        <div className="entry-thumb">
          <div id="chartParent">
            {/* <main className="d-flex justify-content-md-center align-items-center">
              <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </main> */}
            {!chartLoaded ? (
              <main className="d-flex justify-content-md-center align-items-center">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </main>
            ) : (
              <></>
            )}
            <div id="charts">
              <canvas id="priceChart" width="130" height="45" ref={priceChartRef}></canvas>
              <canvas id="volumeChart" width="70" height="12"></canvas>
            </div>
          </div>
          <div id="errorContainer"></div>
          <div className="market-announcement">
            <div className="row justify-content-center">
              <div className="col-12">
                <div className="d-flex align-items-center  flex-column flex-lg-row">
                  <p className="list-title">
                    <b>Range:</b>
                  </p>
                  <ul className="nav nav-pills mt-3 mt-lg-0 d-flex justify-content-center" id="pills-tab" role="tablist">
                    {Object.keys(filters).map(index => {
                      let daysCount = filters[index];
                      return (
                        <li className="nav-item" role="presentation" key={'nav-item-' + index}>
                          <button
                            className={'nav-link ' + (daysCount === count ? 'active' : '')}
                            id={'pills-' + index + '-tab'}
                            data-bs-toggle="pill"
                            data-bs-target={'#pills-' + index}
                            type="button"
                            role="tab"
                            aria-controls={'pills-' + index}
                            aria-selected="true"
                            onClick={() => {
                              handleRangeChange(daysCount);
                            }}
                          >
                            {index}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
            {/* <div className="row pt-3">
              <div className="col-12">
                <div className="d-flex align-items-center  flex-column flex-lg-row mt-3">
                  <p className="list-title">
                    <b>Compare against:&nbsp;</b>
                  </p>
                  <ul className="nav nav-pills  d-flex justify-content-center ml-5" id="pills-tab" role="tablist">
                    <li className="nav-item" role="presentation" key={'nav-item-compare'}>
                      <button className={'nav-link active'} type="button" role="tab" aria-selected="true">
                        ASX 200 (XJO)
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
