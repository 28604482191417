import { useState } from 'react';
import LearnMore from '../common/LearnMore';
import useFetch from '../../hooks/useFetch';
import Layout from '../Layout';
import Loader from '../common/Loader';
import Breadcrumb from '../common/Breadcrumb';
import Links from './Links';
import icBgImg from '../../images/ic-bg.png';
import SEO from './../common/SEO';

export default function FaqPage() {
  const [showAccordion, setShowAccordion] = useState({ state: true, index: 0 });
  const { loading, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/investor-faq?populate=*');
  if (loading) {
    return <Loader />;
  }
  const { attributes } = response ? response.data : [];
  const seoMeta = (attributes.seo) ? attributes.seo : null

  let breadcrumbLinks = [
    { 'link': '/', 'displayName': 'Home' },
    { 'link': '/investor-center/overview', 'displayName': 'Investment Center' },
    { 'link': '/investor-center/faq', 'displayName': 'FAQ' }
  ];
  return (
    <Layout>
      <SEO meta={seoMeta}></SEO>
      <main className="page-wrapper">
        <section className="banner-display-4 text-center text-lg-start" style={{ backgroundImage: `url(${icBgImg})` }}>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="entry-display-1">
                  <h1>{attributes.Title}</h1>
                  <div className="spacer-xs-1"></div>
                  <div aria-label="breadcrumb">
                    <Breadcrumb breadcrumbLinks={breadcrumbLinks} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <aside className="drawer-mobile sidebar-content d-flex d-xl-none">
          <div className="offcanvas offcanvas-start" tabIndex="-1" id="openCanvas" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-body handler-menu">
              <div className="spacer-xs-3"></div>
              <div className="sidebartitle">
                <h2>Investor Center</h2>
              </div>
              <Links />
            </div>
          </div>
        </aside>
        <section className="handler-menu d-none d-xl-block">
          <div className="spacer-xs-3"></div>
          <Links />
          <div className="spacer-xs-3"></div>
        </section>
        <section className="investor-faq">
          <div className="container">
            <div className="spacer-xs-8 spacer-lg-10"></div>
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <div className="entry-display-3 text-center">
                  <h3>{attributes.Title}</h3>
                </div>
                <div className="spacer-lg-4"></div>
                <div className="accordion entry-accordian" id="accordionOne">
                  {attributes.FaqList.map((faq, index) => {
                    return (
                      <div className="accordion-item" key={index}>
                        <h2 className="accordion-header" id="headingOne">
                          <button
                            type="button"
                            className={'accordion-button ' + (showAccordion.state && showAccordion.index == index ? '' : 'collapsed')}
                            onClick={() => setShowAccordion({ state: true, index: index })}
                          >
                            {faq.Title}
                          </button>
                        </h2>
                        <div className={'accordion-collapse collapse ' + (showAccordion.state && showAccordion.index == index ? 'show' : '')}>
                          <div className="accordion-body">{faq.Description}</div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className="spacer-xs-8 spacer-lg-12"></div>
          </div>
        </section>
        <LearnMore />
      </main>
    </Layout>
  );
}
