import React from 'react';
import { Link } from 'react-router-dom';
export default function ServiceCard(props) {
    return (
        <>
            <div key={props.index} className="col-sm-6 mb-4">
                <div className="card mb-3 bg-transparent border-0">
                    <div className="row g-0">
                        <div className="col-md-auto  d-flex justify-content-center">
                            <div className={props.item && props.item.IconColor ? "entry-icon mb-4 " + props.item.IconColor : "entry-icon color-1 mb-4"}>
                                <span><i className={props.item && props.item.IconSlug ? props.iconFor + " " + props.item.IconSlug : props.iconFor + "icon-3d-modeling-renderings"}></i></span>
                            </div>
                        </div>
                        <div className="col-md-10">
                            <div className="entry-display-5 ps-0 ps-md-6 text-center text-md-start">
                                <h5>{props.item && props.item.Title ? props.item.Title : ""}<i className="icon-comman icon-right"></i></h5>
                                <p>{props.item && props.item.Description ? props.item.Description : ""}</p>
                                <Link reloadDocument={true} to={props.item && props.item.Link ? props.item.Link : '#'} className="stretched-link"></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
