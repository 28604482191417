import ReactMarkdown from 'react-markdown';
import Image from '../../common/Image';

export default function FeatureCard(props) {
  const { itemIndex, lastItem, page } = props;
  const direction = (itemIndex + 1) % 2 === 0 ? 'left' : 'right';
  const imageClass = direction === 'left' ? 'order-lg-1 order-1' : 'order-lg-2 order-1';
  const descriptionClass = direction === 'left' ? 'order-lg-2 order-2' : 'order-lg-1 order-2';
  const cardClass = page == 'virtual-reality' || page == 'mixed-reality' || page == 'extended-reality' ? 'block-card-sm' : 'block-card-md';
  return (
    <>
      <div key={itemIndex} className={"row gx-6 block-card " + cardClass}>
        <div className={'col-lg-6 ' + descriptionClass}>
          <div className="entry-display-3">
            <h3>{props && props.item.Title ? props.item.Title : ''}</h3>
            <div className="spacer-xs-1"></div>
            <ReactMarkdown className="reactMarkdown" children={props.item.Description} />
            {/* <p>
            {props && props.item.Description ? props.item.Description : '' }
            </p> */}
          </div>
        </div>
        <div className={'col-lg-6 mb-4 mb-xl-0 ' + imageClass}>
          <div className="entry-thumb img-overlay">
            <Image src={props && props.item.Image.data.attributes.url ? props.item.Image.data.attributes.url : ''} className="img-fluid" alt="" />
          </div>
        </div>
      </div>
      {console.log(lastItem)}
      {lastItem ? <div className="spacer-xs-8 spacer-xl-10"></div> : <div className="spacer-xs-8 spacer-xl-12"></div>}
    </>
  );
}
