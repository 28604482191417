import { useEffect } from "react";
import $ from "jquery";
import useFetch from "../../../hooks/useFetch";
import Loader from '../../common/Loader';
import TechnologyLayout from "./TechnologyLayout";
import DemoImage from "../../../images/demo-image.png";
import InfographyImage from "../../../images/infography.svg";
import InfographyMobileImage from "../../../images/infography-mobile.svg";
import Image from '../../common/Image';
import { TechnologyIntegratedXrQuery } from '../../../Queries';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import ServiceCard from './ServiceCard';
import SEO from '../../common/SEO';

export default function IntegratedXrPage() {
    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/technology-integrated-xr?' + TechnologyIntegratedXrQuery);

    if (loading) {
        return <Loader />;
    }
    const { data } = response ? response : [];
    const { attributes } = data ? data : [];
    const { RealityList } = attributes ? attributes : [];
    const { ServiceList } = attributes ? attributes : [];
    const { RealityProcessList } = attributes ? attributes : [];
    const { ProductList } = attributes ? attributes : [];
    const { ServiceDetailList } = attributes ? attributes : [];
    const { IndustyList } = attributes ? attributes : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null
    return (
        <TechnologyLayout>
            <SEO meta={seoMeta}></SEO>
            <section className="banner banner-display-2">
                <div className="row">
                    <div className="spacer-xs-8 spacer-xl-12"></div>
                    <div className="entry-display-1 width-xxl-50 width-xxxl-68 order-2 order-xl-1 text-xl-start text-center">
                        <h1>{attributes && attributes.Title ? attributes.Title : ''}</h1>
                        <div className="spacer-xs-1"></div>
                        <ReactMarkdown className="reactMarkdownDiv" children={attributes && attributes.Description ? attributes.Description : ''} />
                    </div>
                    <div className="entry-thumb d-flex flex-column flex-xl-row justify-content-center justify-content-xl-end align-items-center position-absolute order-1 order-xl-2">
                        <div className="circle-bg d-none d-xl-block"></div>
                        <div className="spacer-xs-5 order-1 d-flex d-xl-none"></div>
                        <div className="entry-image img-overlay">
                            <Image src={attributes && attributes.CircleImage.data.attributes.url ? attributes.CircleImage.data.attributes.url : ''} alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure">
                <div className="infographic-block list">
                    <div className="row gx-0">
                        <div className="width-lg-33">
                            <ul className="card list-unstyled h-100 mb-0">
                                {RealityList &&
                                    RealityList.map((itemRL, indexRL) => {
                                        return (
                                            <li key={indexRL}>{itemRL && itemRL.Title ? itemRL.Title : ''}</li>
                                        )
                                    })}
                            </ul>
                        </div>
                        <div className="width-lg-34">
                            <div className="entry-image h-100 align-items-center d-flex justify-content-center">
                                <Image src={InfographyImage} className="img-fluid d-none d-lg-flex" alt="" />
                                <Image src={InfographyMobileImage} className="img-fluid d-flex d-lg-none" alt="" />
                            </div>
                        </div>
                        <div className="width-lg-33">
                            <ul className="card list-unstyled h-100 mb-0">
                                {ServiceList &&
                                    ServiceList.map((itemSL, indexSL) => {
                                        return (<li key={indexSL}>{itemSL && itemSL.Title ? itemSL.Title : ''}</li>)
                                    })}
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure">
                <div className="spacer-xs-2"></div>
                <div className="row technology-buttons">
                    <div className="col-sm-12">
                        <div className="entry-display-2 text-md-start text-center">
                            <h2>{attributes && attributes.LearnmoreTitle ? attributes.LearnmoreTitle : ''}</h2>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes && attributes.LearnmoreDescription ? attributes.LearnmoreDescription : ''}</p>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <div className="row gx-3">
                                {RealityProcessList &&
                                    RealityProcessList.map((itemRPL, indexRPL) => {
                                        return (
                                            <div key={indexRPL} className="col-sm-12 col-md-6 col-lg-4">
                                                <Link reloadDocument={true} to={itemRPL && itemRPL.Link ? itemRPL.Link : '#'} className="btn btn-outline-primary border-2 px-1 py-3 w-100 mb-3">{itemRPL && itemRPL.Title ? itemRPL.Title : ''}</Link>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3 text-md-start text-center">
                            <h3>{attributes && attributes.ProductTitle ? attributes.ProductTitle : ''}</h3>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-4 spacer-lg-6"></div>
                <div className="row gx-6 product product--theme-lite">
                    {ProductList &&
                        ProductList.map((itemPL, indexPL) => {
                            return (
                                <div key={indexPL} className="col-sm-4 d-flex">
                                    <div className="card border-0 bg-transparent py-4">
                                        <div className={itemPL && itemPL.IconColor ? "entry-icon " + itemPL.IconColor : "entry-icon color-7"}>
                                            <span>
                                                <Image src={itemPL && itemPL.IconSlug ? "/images/home/solutions/logo/" + itemPL.IconSlug : ''} />
                                            </span>
                                        </div>
                                        <div className="spacer-xs-3 spacer-lg-4"></div>
                                        <div className="entry-display-4 text-center flex-grow-1 d-flex flex-column">
                                            <h4>{itemPL && itemPL.Title ? itemPL.Title : ''}</h4>
                                            <p>{itemPL && itemPL.Description ? itemPL.Description : ''}</p>
                                        </div>
                                        <Link reloadDocument={true} to={itemPL && itemPL.Link ? itemPL.Link : '#'} className="read-more-arrow d-flex justify-content-center mt-auto stretched-link"><i className="icon-comman icon-arrow-right text-ultra-light fs-xs-18"></i></Link>
                                    </div>
                                </div>
                            )
                        })}
                </div>
            </section>
            <div className="spacer-xs-8 spacer-xl-10"></div>
            <section className="entry-structure-light theme-lite">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3 text-md-start text-center">
                            <h3>{attributes && attributes.ServiceTitle ? attributes.ServiceTitle : ''}</h3>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-4 spacer-xl-6"></div>
                <div className="row gx-6 justify-content-start">
                    {ServiceDetailList &&
                        ServiceDetailList.map((itemSDL, indexSDL) => {
                            return <ServiceCard index={indexSDL} item={itemSDL} iconFor="icon-services" />;
                        })}
                </div>
                <div className="spacer-xs-8 spacer-xl-10"></div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure theme-lite">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3 text-md-start text-center">
                            <h3>{attributes && attributes.IndustryTitle ? attributes.IndustryTitle : ''}</h3>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                        </div>
                    </div>
                </div>
                <div className="row gx-6 justify-content-start">
                    {IndustyList &&
                        IndustyList.map((itemIL, indexIL) => {
                            return <ServiceCard index={indexIL} item={itemIL} iconFor="icon-industries" />;
                        })}
                </div>
            </section>
            <div className="spacer-xs-4 spacer-xl-6"></div>
        </TechnologyLayout>
    )
}