export default function SiteMapList() {
  return [
    {
      'heading': 'Company',
      'link': '/company/vection-technologies',
      'subMenu': [
        {
          'name': 'What is Vection Technologies',
          'link': '/company/vection-technologies'
        },
        {
          'name': 'Our Story',
          'link': '/company/our-story'
        },
        {
          'name': 'Brands that Support Our Vision',
          'link': '/company/our-vision'
        },
        {
          'name': 'Global Offices',
          'link': '/company/global-offices'
        },
        {
          'name': 'Sitemap',
          'link': '/sitemap'
        }
      ]
    },
    {
      'heading': 'Needs',
      'link': '#',
      'subMenu': [
        { 'name': 'Design Review', 'link': '/solutions/needs/design-review' },
        { 'name': 'Training', 'link': '/solutions/needs/training' },
        { 'name': 'Remote Presentations', 'link': '/solutions/needs/remote-presentations' },
        { 'name': 'Retail 3D Experience', 'link': '/solutions/needs/retail-experience' },
        { 'name': '3D E-commerce', 'link': '/solutions/needs/ecommerce' }
      ]
    },
    {
      'heading': 'Products',
      'link': '#',
      'subMenu': [
        { 'name': 'EnWorks', 'link': '/solutions/products/enwork/overview' },
        { 'name': '3DFrame', 'link': '/solutions/products/3d-frame/overview' },
        { 'name': 'Mindesk', 'link': '/solutions/products/mindesk/overview' },
        { 'name': 'Xrkiosk', 'link': '/solutions/products/xrkiosk/overview' },
        { 'name': 'Configurator', 'link': '/solutions/products/configurator/overview' }
      ]
    },
    {
      'heading': 'Industry',
      'link': '#',
      'subMenu': [
        { 'name': 'Machinery Manufacturing', 'link': '#' },
        { 'name': 'Architecture, Engineering & Construction', 'link': '#' },
        { 'name': 'Automotive', 'link': '#' },
        { 'name': 'Naval', 'link': '#' },
        { 'name': 'Furniture', 'link': '#' },
        { 'name': 'Fashion', 'link': '#' },
        { 'name': 'Public Sector', 'link': '#' },
        { 'name': 'Education', 'link': '#' },
        { 'name': 'Healthcare & Pharma', 'link': '#' },
        { 'name': 'Sports', 'link': '#' },
        { 'name': 'Betting', 'link': '#' },
        { 'name': 'Media & Communications', 'link': '#' },
        { 'name': 'Defence, Military & Law Enforcement', 'link': '#' },
        { 'name': 'Aerospace', 'link': '#' }
      ]
    },

    //
    {
      'heading': 'Benefits',
      'link': '#',
      'subMenu': [
        { 'name': 'Faster Time to Market', 'link': '#' },
        { 'name': 'Effective Training', 'link': '#' },
        { 'name': 'Improve Remote Sales', 'link': '#' },
        { 'name': 'Augmented Shopping Experience', 'link': '#' },
        { 'name': 'Increase Conversion Rates', 'link': '#' }
      ]
    },
    {
      'heading': 'Investor Centre',
      'link': '/investor-center/overview',
      'subMenu': [
        { 'name': 'Overview', 'link': '/investor-center/overview' },
        { 'name': 'Stock Price', 'link': '/investor-center/stock-price' },
        { 'name': 'Market Announcements', 'link': '/investor-center/market-announcement' },
        { 'name': 'Media', 'link': '/investor-center/media' },
        { 'name': 'FAQ', 'link': '/investor-center/faq' },
        { 'name': 'Leadership & Governance', 'link': '/investor-center/leadership' }
      ]
    },
    {
      'heading': 'Support',
      'link': '#',
      'subMenu': [
        { 'name': 'Contact Us', 'link': '/contact-us' },
        { 'name': 'Services', 'link': '/support/services' },
        { 'name': 'Blog', 'link': '/blog' },
        { 'name': 'Case Studies', 'link': '/case-studies' },
        { 'name': 'Success Stories', 'link': '/success-stories' },
        { 'name': 'What is INTEGRATEDXR', 'link': '/support/technology/what-is-integratedxr' },
        { 'name': 'What is Virtual Reality', 'link': '/support/technology/what-is-virtual-reality' },
        { 'name': 'What is Augmented Reality', 'link': '/support/technology/What-is-augmented-reality' },
        { 'name': 'What is Mixed Reality', 'link': '/support/technology/What-is-mixed-reality' },
        { 'name': 'What is Extended Reality', 'link': '/support/technology/What-is-extended-reality' },
        { 'name': 'What is the Metaverse', 'link': '/support/technology/What-is-the-metaverse' }
      ]
    }
  ];
}
