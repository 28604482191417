import useFetch from '../../hooks/useFetch';
import Layout from '../Layout';
import { ProfessionServicesQuery } from '../../Queries';
import Loader from '../common/Loader';
import Image from '../common/Image';
import LearnMore from '../common/LearnMore';
import { Link } from 'react-router-dom';
import bgImage from "../../images/circle-bg.png";
import SEO from './../common/SEO';

export default function ProfessionalServices(props) {
  const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/support-overview?' + ProfessionServicesQuery);
  const { data } = response ? response : [];
  const { attributes } = data ? data : [];
  const { ServiceList } = attributes ? attributes : [];
  if (loading) {
    return <Loader />;
  }
  const seoMeta = (attributes.seo) ? attributes.seo : null
  return (
    <Layout>
      <SEO meta={seoMeta}></SEO>
      <main className="page-wrapper">
        <section className="banner banner-display-1 bg-grey-dark">
          <div className="container">
            <div className="spacer-xs-5 spacer-xl-13"></div>
            <div className="row align-items-center">
              <div className="entry-display-1 width-xl-55 order-2 order-xl-1 text-center text-xl-start">
                <span className="sub-heading text-primary fw-semibold">PROFESSIONAL SERVICES</span>
                <div className="spacer-xs-2"></div>
                <h1 className="text-white">{attributes && attributes.Title ? attributes.Title : ''}</h1>
                <div className="spacer-xs-1"></div>
                <p className="text-white">{attributes && attributes.Description ? attributes.Description : ''}</p>
              </div>
              <div className="entry-thumb width-xl-45 d-flex flex-column flex-xl-row justify-content-center justify-content-xl-end align-items-center order-xl-2 order-1">
                <div className="circle-bg d-none d-xl-block" style={{ backgroundImage: `url(${bgImage})` }}></div>
                <div className="spacer-xs-5 order-1 d-flex d-xl-none"></div>
                <div className="entry-image dark img-overlay">
                  <Image src={attributes && attributes.Image.data.attributes.url ? attributes.Image.data.attributes.url : ''} />
                </div>
              </div>
            </div>
            <div className="spacer-xs-6 spacer-xl-13"></div>
          </div>
        </section>
        <section className="entry-structure-light theme-lite">
          <div className="spacer-xs-8 spacer-xl-12"></div>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="entry-display-3 text-md-left text-center">
                  <h3>Services</h3>
                  <div className="spacer-xs-4 spacer-xl-6"></div>
                </div>
              </div>
            </div>
            <div className="row gx-6 justify-content-start">
              {ServiceList && ServiceList.map((item, index) => {
                return (
                  <div key={index} className="col-sm-6 mb-4">
                    <div className="card mb-3 bg-transparent border-0">
                      <div className="row g-0">
                        <div className="col-md-auto d-flex justify-content-center">
                          <div className={item && item.IconColor ? "entry-icon mb-4 " + item.IconColor : "entry-icon color-1 mb-4"}>
                            <span><i className={item && item.IconSlug ? "icon-services " + item.IconSlug : "icon-services icon-3d-modeling-renderings"}></i></span>
                          </div>
                        </div>
                        <div className="col-md-10">
                          <div className="entry-display-5 ps-0 ps-md-6 text-center text-md-start">
                            <h5>{item && item.Title ? item.Title : ''}<i className="icon-comman icon-right"></i></h5>
                            <p>{item && item.Description ? item.Description : ''}</p>
                            <Link reloadDocument={true} to={item && item.Link ? item.Link : ''} className="stretched-link"></Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className="spacer-xs-6 spacer-xl-8"></div>
        </section>
        <LearnMore />
      </main>
    </Layout>
  );
}
