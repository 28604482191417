import { useEffect } from "react";
import $ from "jquery";
import useFetch from "../../../hooks/useFetch"
import Loader from '../../common/Loader';
import IndustryLayout from "./IndustryLayout";
import Image from "../../common/Image";
import { SolutionByIndustryEducation } from '../../../Queries';
import ReactMarkdown from 'react-markdown';
import SEO from './../../common/SEO';

export default function IndustryEducation() {
    useEffect(() => {
        var windowWidth = $(window).width();
        var container = $('.container').width();

        var calcWidth = windowWidth - container;

        $('.art').css('right', - (calcWidth / 2));
    }, [])

    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/industry-education?' + SolutionByIndustryEducation);

    if (loading) {
        return <Loader />;
    }
    const { attributes } = response ? response.data : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null
    return (<IndustryLayout>
            <SEO meta={seoMeta}></SEO>
        <section className="entry-section">
            <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-1">
                        <p className="sub-heading text-primary fw-semibold">EDUCATION</p>
                        <h1>{attributes && attributes.Title ? attributes.Title : ''}</h1>
                        <div className="spacer-xs-4 spacer-xl-6"></div>
                        <div className="entry-thumb img-overlay">
                            <Image src={attributes && attributes.Image ? attributes.Image.data.attributes.url : ''} className="img-fluid" />
                        </div>
                        <div className="spacer-xs-4 spacer-xl-6"></div>
                        <p>{attributes && attributes.Description ? attributes.Description : ''}</p>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-8 spacer-xl-10"></div>
        </section>
        <section className="entry-structure-light">
            <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-3">
                        <h3>{attributes && attributes.VRTitle ? attributes.VRTitle : ''}</h3>
                        <div className="spacer-xs-1"></div>
                        <ReactMarkdown className="reactMarkdown" children={attributes.VRDescription} components={{
                            a: ({ children, href }) => <a href={href} className='sub-link' target='_blank'>{children[0]}</a>
                        }} />
                        <div className="spacer-xs-4 spacer-xl-6"></div>
                        <div className="entry-thumb img-overlay">
                          <Image src={attributes && attributes.VRImage ? attributes.VRImage.data.attributes.url : ''} className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-8 spacer-xl-10"></div>
        </section>
        <div className="spacer-xs-8 spacer-xl-12"></div>
        <section className="entry-structure">
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-2">
                        <h2>{attributes && attributes.WhatVRTitle ? attributes.WhatVRTitle : ''}</h2>
                        <div className="spacer-xs-1"></div>
                        <ReactMarkdown children={attributes.WhatVRDescription ? attributes.WhatVRDescription : ''} />
                    </div>
                </div>
            </div>
            <div className="spacer-xs-6 spacer-xl-8"></div>
            <div className="row gx-6 block-card block-card-md">
                <div className="col-lg-6 order-lg-1 order-2">
                    <div className="entry-display-3">
                        <h3>{attributes && attributes.RoleVRTitle ? attributes.RoleVRTitle : ''}</h3>
                        <div className="spacer-xs-1"></div>
                        <p>{attributes && attributes.RoleVRDescription ? attributes.RoleVRDescription : ''}</p>
                    </div>
                </div>
                <div className="col-lg-6 order-lg-2 order-1 mb-4 mb-xl-0">
                    <div className="entry-thumb img-overlay">
                         <Image src={attributes && attributes.RoleVRImage ? attributes.RoleVRImage.data.attributes.url : ''} className="img-fluid" />
                    </div>
                </div>
            </div>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-3">
                        <div className="entry-thumb img-overlay">
                         <Image src={attributes && attributes.RoleVRSubImage ? attributes.RoleVRSubImage.data.attributes.url : ''} className="img-fluid" />
                        </div>
                        <div className="spacer-xs-6 spacer-xl-8"></div>
                        <h3>{attributes && attributes.BenefitsVRTitle ? attributes.BenefitsVRTitle : ''}</h3>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-4"></div>
            <div className="row check-list">
                <div className="col-sm-12">
                    <ul>
                        {attributes.BefefitsList && attributes.BefefitsList.map((item,index) => {
                            return (
                            <li key={index}>
                            <h5>{item.Title}</h5>
                            <p>{item.Description}</p>
                           </li>)
                        })}
                    </ul>
                </div>
            </div>
        </section>
        <div className="spacer-xs-8 spacer-xl-12"></div>
        <section className="entry-structure-light">
            <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-3">
                        <h3>{attributes.ARTitle ? attributes.ARTitle : ''}</h3>
                        <div className="spacer-xs-1"></div>
                        <p>{attributes.ARDescription ? attributes.ARDescription : ''}</p>
                        <div className="spacer-xs-4 spacer-xl-6"></div>
                        <div className="entry-thumb img-overlay">
                        <Image src={attributes && attributes.ARImage ? attributes.ARImage.data.attributes.url : ''} className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-8 spacer-xl-10"></div>
        </section>
        <div className="spacer-xs-8 spacer-xl-12"></div>
        <section className="entry-structure">
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-3">
                        <h3>{attributes.WhatARTitle ? attributes.WhatARTitle : ''}</h3>
                        <div className="spacer-xs-1"></div>
                        <ReactMarkdown children={attributes.WhatARDescription ? attributes.WhatARDescription : ''} />
                    </div>
                </div>
            </div>
        </section>
        <div className="spacer-xs-8 spacer-xl-12"></div>
        <section className="entry-structure-light">
            <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-3">
                        <div className="entry-thumb img-overlay">
                        <Image src={attributes && attributes.HowARImage ? attributes.HowARImage.data.attributes.url : ''} className="img-fluid" />
                        </div>
                        <div className="spacer-xs-6 spacer-xl-8"></div>
                        <h3>{attributes.HowARTitle ? attributes.HowARTitle : ''}</h3>
                        <div className="spacer-xs-3"></div>
                    </div>
                </div>
            </div>
            <div className="row check-list">
                <div className="col-sm-12">
                    <ul>
                        {attributes.HowARList && attributes.HowARList.map((item,index) => {
                            return (
                                <li key={index}>
                                <h5>{item.Title}</h5>
                                <p>{item.Description}</p>
                              </li> )
                        })}
                        </ul>
                </div>
            </div>
            <div className="spacer-xs-8 spacer-xl-10"></div>
        </section>
        <section className="entry-structure">
            <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-3">
                        <h3>{attributes.BenefitsARTitle ? attributes.BenefitsARTitle : ''}</h3>
                        <div className="spacer-xs-1"></div>
                        <ReactMarkdown children={attributes.BenefitsARDescription ? attributes.BenefitsARDescription : ''}/>
                        <div className="spacer-xs-4 spacer-xl-6"></div>
                        <div className="entry-thumb img-overlay">
                        <Image src={attributes && attributes.BenefitsARImage ? attributes.BenefitsARImage.data.attributes.url : ''} className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-8 spacer-xl-10"></div>
        </section>
        <section className="entry-structure-light report-card">
            <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-3">
                        <h3>{attributes.TakeawayTitle ? attributes.TakeawayTitle : ''}</h3>
                        <div className="spacer-xs-1"></div>
                        <ReactMarkdown children={attributes.TakeawayDescription ? attributes.TakeawayDescription : ''}/>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-8 spacer-xl-10"></div>
        </section>
    </IndustryLayout>)
}