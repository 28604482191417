import Banner from './Banner';
import Business from './Business';
import FaqAccordian from '../common/FaqAccordian';
import Features from './Features';
import Industry from './Industry';
import Solutions from './Solutions';
import Support from './Support';
import useFetch from '../../hooks/useFetch';
import Layout from '../Layout';
import { HomeQuery } from '../../Queries';
import LearnMore from '../common/LearnMore';
import Loader from '../common/Loader';
import SEO from './../common/SEO';

export default function Home(props) {
  const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/Home?' + HomeQuery);
  const { data } = response ? response : [];
  const { attributes } = data ? data : [];
  
  if (loading) {
    return <Loader />;
  }
  const seoMeta = (attributes.seo) ? attributes.seo : null

  return (
    <Layout>
      <SEO meta={seoMeta}></SEO>
      <main className="page-wrapper home-page">
        <section className="hero">
          <div className="container">
            <div className="spacer-xs-4 spacer-md-6 spacer-lg-10 spacer-xxl-10"></div>
            <Banner {...attributes} />
            <div className="spacer-xs-8 spacer-md-12 spacer-lg-12 spacer-xxl-18"></div>
            <Business {...attributes} />
            <div className="spacer-xs-4 spacer-md-6 spacer-lg-6 spacer-xxl-6"></div>
          </div>
        </section>
        <Features {...attributes} />
        <Solutions {...attributes} />
        <Support {...attributes} />
        <Industry {...attributes} />
        <FaqAccordian {...attributes} />
        <LearnMore {...attributes} />
      </main>
    </Layout>
  );
}
