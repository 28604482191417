export const form = {
  contactUs(formData, cb, errorCb) {
    fetch(process.env.REACT_APP_AWS_API_URL + '/contact-us', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ...formData })
    })
      .then(response => {
        return response.json();
      })
      .then(
        result => {
          if (result.success === true) {
            cb(result);
          } else {
            errorCb(result.errors ? result.errors : { message: 'Could not submit the form.Try again!' });
          }
        },
        error => {
          if (error.status === 403) {
            localStorage.removeItem('token');
            window.location = './signin';
          }
          errorCb(error.errors ? error.errors : { message: 'Could not submit the form.Try again!' });
        }
      )
      .catch(error => {
        errorCb({ message: 'Could not submit the form.Try again!' });
      });
  },
  enquirySubmit(formData, cb, errorCb) {
    let apiUrl = process.env.REACT_APP_AWS_API_URL + '/product-enquiry';

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ...formData })
    })
      .then(response => {
        return response.json();
      })
      .then(
        result => {
          if (result.success === true) {
            cb(result);
          } else {
            errorCb(result.errors ? result.errors : { message: 'Could not submit the form.Try again!' });
          }
        },
        error => {
          errorCb(error.errors ? error.errors : { message: 'Could not submit the form.Try again!' });
        }
      )
      .catch(error => {
        errorCb({ message: 'Could not submit the form.Try again!' });
      });
  },
  openPositionsSubmit(formData, cb, errorCb) {
    let apiUrl = process.env.REACT_APP_AWS_API_URL + '/company-open-positions';

    fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ ...formData })
    })
      .then(response => {
        return response.json();
      })
      .then(
        result => {
          if (result.success === true) {
            cb(result);
          } else {
            errorCb(result.errors ? result.errors : { message: 'Could not submit the form.Try again!1' });
          }
        },
        error => {
          errorCb(error.errors ? error.errors : { message: 'Could not submit the form.Try again!2' });
        }
      )
      .catch(error => {
        errorCb({ message: 'Could not submit the form.Try again!3' });
      });
  }
};
