import React from 'react';
import Loader from '../../common/Loader';
import useFetch from '../../../hooks/useFetch';
import { ProfessionServicesQuery } from '../../../Queries';
import { Link } from 'react-router-dom';

export default function Sidemenu(props) {
  const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/support-overview?' + ProfessionServicesQuery);
  const { data } = response ? response : [];
  const { attributes } = data ? data : [];
  const { ServiceList } = attributes ? attributes : [];
  if (loading) {
    return <Loader />;
  }
  let currentPath = window.location.pathname;
  return (
    <>
      <div className="sidebartitle">
        <h2>Professional Service</h2>
      </div>
      <div className="sidebarmenu custom-scroller">
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link reloadDocument={true} className={currentPath == 'professional-services' ? 'nav-link active' : 'nav-link'} aria-current="page" to="/support/services">
              Overview
            </Link>
          </li>
          {ServiceList &&
            ServiceList.map((item, index) => {
              return (
                <li key={index} className="nav-item">
                  <Link reloadDocument={true} className={currentPath.includes(item.Link) ? 'nav-link active' : 'nav-link'} aria-current="page" to={item && item.Link ? item.Link : '#'}>
                    {item && item.Title ? item.Title : ''}
                  </Link>
                </li>
              );
            })}
        </ul>
        <Link reloadDocument={true} to="/contact-us" className="btn btn-primary btn-sm text-white icon-btn ml-xs-4 mr-xs-4 mt-5 d-flex justify-content-center">
          <i className="icon-comman icon-envelope-fill me-2"></i>
          Contact Us
        </Link>
      </div>
    </>
  );
}
