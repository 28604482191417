import { useEffect, useState } from 'react';
import noImageIcon from '../../images/no-image-icon-15.png';

export default function Video({src, ...rest}) {
  const [url, setUrl] = useState(src);

  useEffect(() => {
    setUrl(src ? src : '');
  });


  return (

    <video controls {...rest}>
      <source
       onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = noImageIcon;
      }}
       src={url}></source>
    </video>
  );
}
