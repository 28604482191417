import React, { useEffect } from 'react';
import $ from 'jquery';
import Image from '../../common/Image';
import LearnMore from '../../common/LearnMore';
import SideMenu from '../../common/SideMenu';
import Layout from '../../Layout';
import { Link, useLocation } from 'react-router-dom';

export default function ByProductLayout(props) {
  const { pathname } = useLocation();
  useEffect(() => {
    var windowWidth = $(window).width();
    var container = $('.container').width();

    var calcWidth = windowWidth - container;

    $('.art').css('right', -(calcWidth / 2));
  });

  const menuItems = [
    // {
    //   name: 'What is Vection Technologies',
    //   link: '/solutions/products-mindesk-overview'
    // },
    {
      name: 'Enworks',
      link: '#',
      subMenu: [
        {
          name: 'Overview',
          link: '/solutions/products/enwork/overview'
        },
        {
          name: 'Get Started',
          link: '/solutions/products/enwork/get-started'
        }
      ]
    },
    {
      name: 'Mindesk',
      link: '#',
      subMenu: [
        {
          name: 'Overview',
          link: '/solutions/products/mindesk/overview'
        },
        // {
        //   name: 'Pricing',
        //   link: '/solutions/products-mindesk-pricing'
        // },
        {
          name: 'Get Started',
          link: 'https://mindeskvr.com/store/'
        },
        {
          name: 'Download',
          link: 'https://mindeskvr.com/download/'
        }
      ]
    },
    {
      name: '3DFrame',
      link: '#',
      subMenu: [
        {
          name: 'Overview',
          link: '/solutions/products/3d-frame/overview'
        },
        {
          name: 'For Webex',
          link: '/solutions/products/3d-frame/webex'
        },
        {
          name: 'Pricing',
          link: '/solutions/products/3d-frame/pricing'
        },
        {
          name: 'Get Started',
          link: 'https://app.3dframe.vection-technologies.com/signup'
        },
        {
          name: 'Go To Tutorial',
          link: 'https://www.youtube.com/playlist?list=PLP1eKeWNTKK-8ZZ4YGaOTPO92dxyKznes'
        },
        // {
        //   name: 'Download',
        //   link: '#'
        // }
      ]
    },
    {
      name: ' 3D Configurator',
      link: '#',
      subMenu: [
        {
          name: 'Overview',
          link: '/solutions/products/configurator/overview'
        },
        {
          name: 'Get Started',
          link: '/solutions/products/configurator/get-started'
        }
      ]
    },
    {
      name: 'XRKiosk',
      link: '#',
      subMenu: [
        {
          name: 'Overview',
          link: '/solutions/products/xrkiosk/overview'
        },
        {
          name: 'Get Started',
          link: '/solutions/products/xrkiosk/get-started'
        }
      ]
    }
  ];

  const { menuHeading, backgroundImage, logo } = props;
  return (
    <Layout>
      <main className="page-wrapper">
        <section className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-content">
                <SideMenu menuHeading={menuHeading} menuItems={menuItems} />
                <section className="main-content">
                  <section className="banner-display-3">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="entry-display-1">
                          <div className={pathname == "/solutions/products/3d-frame/webex" ? "hero-thumb-2" : "hero-thumb"}>
                            <Image src={props && props.logo.data.attributes.url ? props.logo.data.attributes.url : ''} className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="art position-absolute d-none d-xl-flex">
                      <Image src={backgroundImage} alt="" className="img-fluid" />
                    </div>
                  </section>

                  {props.children}
                </section>
              </div>
            </div>
          </div>
        </section>
        {props.page && props.page == "overview" ? 
         <section className="entry-structure-bg system-requirement">
         <div className="container">
            <div className="spacer-xs-8 spacer-xl-15"></div>
            <div className="row justify-content-start align-items-center">
               <div className="col-xl-5 col-sm-12">
                  <div className="entry-display-3">
                     <h3 className="text-white">System Requirements</h3>
                     <div className="spacer-xs-2"></div>
                     <ul>
                        <li>3DFrame works in 3D on modern PCs and on Mac.</li>
                        <li>Virtual Reality (VR) mode requires a VR-ready pc.</li>
                        <li>Virtual Reality (VR) not supported on Mac.</li>
                        <li>3D formats supported: .fbx and obj.</li>
                     </ul>
                  </div>
               </div>
            </div>
            <div className="spacer-xs-8 spacer-xl-15"></div>
         </div>
         </section> 
        :<LearnMore />}
      </main>
    </Layout>
  );
}
