import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../images/vection-logo.svg';
import FooterMenuList from '../../utitls/FooterMenuList';
import Image from './Image';

export default function StaticFooterMenu() {
  const footerMenuList = FooterMenuList();
  return (
    <>
        <div className="row">
          <div className="col-sm-12">
            <div className="footer-logo text-center text-md-start">
              <Image src={logo}  className="img-fluid"/>
            </div>
          </div>
        </div>
        <div className="spacer-xs-5"></div>
        <div className="row gx-5">
          {footerMenuList &&
            footerMenuList.map((menu, index) => {
              return (
                <div className="col-sm-12 col-md-4 col-lg text-center text-md-start mb-4" key={'footer-menu-' + index}>
                  <h4 className="footer-title">{menu.heading}</h4>
                  <ul className="unstyled p-0 m-0">
                    {menu.subMenu &&
                      menu.subMenu.map((page, pageIndex) => {
                        return (
                          <li key={'pageIndex-' + pageIndex}>
                            <Link reloadDocument={true} to={page.link}>{page.name}</Link>
                          </li>
                        );
                      })}
                  </ul>
                </div>
              );
            })}
        </div>
    </>
  );
}
