import React,{ useEffect } from "react";
import $ from "jquery";
import useFetch from "../../../hooks/useFetch"
import Loader from '../../common/Loader';
import Image from "../../common/Image";
import IndustryLayout from "./IndustryLayout"
import { IndustriesBettingQuery } from "../../../Queries";
import ReactMarkdown from "react-markdown";
import SEO from './../../common/SEO';

export default function IndustryBetting() {
    useEffect(() => {
        var windowWidth = $(window).width();
        var container = $('.container').width();

        var calcWidth = windowWidth - container;

        $('.art').css('right', - (calcWidth / 2));
    },[])
    
    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/industries-betting?' + IndustriesBettingQuery);
    
    if (loading) {
        return <Loader />;
    }
    const { attributes } = response ? response.data : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null
    return (<IndustryLayout>
            <SEO meta={seoMeta}></SEO>
     <section className="entry-section">
        <div className="spacer-xs-8 spacer-xl-10"></div>
        <div className="row">
            <div className="col-sm-12">
                <div className="entry-display-1">
                    <p className="sub-heading text-primary fw-semibold">BETTING</p>
                <h1>{attributes.Title ? attributes.Title : ''}</h1>
                <div className="spacer-xs-4 spacer-xl-6"></div>
                <div className="entry-thumb img-overlay">
                <Image src={attributes && attributes.Image ? attributes.Image.data.attributes.url : ''} className="img-fluid" />
                </div>
                <div className="spacer-xs-4 spacer-xl-6"></div>
                <p>{attributes.Description ? attributes.Description : ''} 
                </p>
                </div>
            </div>
        </div>
        <div className="spacer-xs-8 spacer-xl-12"></div>
    </section>
    <section className="entry-structure-light">
        <div className="spacer-xs-8 spacer-xl-10"></div>
        <div className="row">
            <div className="col-sm-12">
                <div className="entry-display-3">
                <h3>{attributes.NeedToTitle ? attributes.NeedToTitle : ''}</h3>
                <div className="spacer-xs-1"></div>
                <p>{attributes.NeedToDescription ? attributes.NeedToDescription : ''}</p>
                <div className="spacer-xs-4 spacer-xl-6"></div>
                <div className="entry-thumb img-overlay">
                <Image src={attributes && attributes.NeedToImage ? attributes.NeedToImage.data.attributes.url : ''} className="img-fluid" />
                </div>
                <div className="spacer-xs-4 spacer-xl-6"></div>
                <div className="accordion entry-accordian" id={'accordion0'}>
                {attributes.BettingRealityList && attributes.BettingRealityList.map((item , index) => {
                        return (
                        <div className="accordion-item" key={index}>
                        <h2 className="accordion-header" id={'heading' + index}>
                            <button
                            className={"accordion-button " + (index == 0 ? "" : "collapsed")}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={'#collapse' + index}
                            aria-expanded={true}
                            aria-controls={'collapse' + index}
                            >
                            {item.Title}
                            </button>
                        </h2>
                        <div id={'collapse' + index}  className={"accordion-collapse collapse " + (index == 0 ? "show" : "")} aria-labelledby={'heading' + index} data-bs-parent={'#accordion0'}>
                            <div className="accordion-body">
                            <ReactMarkdown className="reactMarkdown" children={item.Description} components={{
                              p : ({children}) => <div>{children}</div>,
                              a: ({ children, href }) => <a href={href} className='sub-link' target='_blank'>{children[0]}</a>
                             }} />
                            </div>
                        </div>
                        </div>
                        )
                    })}
                </div>
                </div>
            </div>
        </div>
        <div className="spacer-xs-8 spacer-xl-10"></div>
    </section>
    <div className="spacer-xs-8 spacer-xl-12"></div>
    <section className="entry-structure">
        <div className="row">
            <div className="col-sm-12">
                <div className="entry-display-2">
                <h2>{attributes.RoleTitle ? attributes.RoleTitle : ''}</h2>
                </div>
            </div>
        </div>
        <div className="spacer-xs-4"></div>
        <div className="row gx-6">
            <div className="col-sm-12 order-lg-1 order-2">
                <div className="entry-display-5">
                    <h5 className="fw-bold">{attributes.RoleSubTitle ? attributes.RoleSubTitle : ''}</h5>
                    <div className="spacer-xs-1"></div>
                    <p>{attributes.RoleDescription ? attributes.RoleDescription : ''}</p>
                </div>
            </div>
        </div>
        <div className="spacer-xs-6 spacer-xl-8"></div>
         {attributes.RoleList && attributes.RoleList.map((role,index) => {
             return (
                 <React.Fragment key={index}>
                         <div className="row gx-6 block-card block-card-sm">
                        <div className="col-lg-6 order-lg-2 order-2">
                            <div className="entry-display-3">
                                <p>{role.Description}</p>
                            </div>
                        </div>
                        <div className="col-lg-6 order-lg-1 order-1 mb-4 mb-xl-0">
                            <div className="entry-thumb img-overlay">
                            <Image src={role && role.Image ? role.Image.data.attributes.url : ''} className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div className="spacer-xs-8 spacer-xl-10"></div>
                 </React.Fragment>
             )
         })}
    </section>
    {/* <div className="spacer-xs-8 spacer-xl-12"></div> */}
    <section className="entry-structure-light report-card">
        <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-3">
                        <h3>{attributes.OnlineCasinosTitle ? attributes.OnlineCasinosTitle : ''}</h3>
                        <div className="spacer-xs-1"></div>
                        <p>{attributes.OnlineCasinosDescription ? attributes.OnlineCasinosDescription :''}</p>
                    </div>
                </div>
            </div>
        <div className="spacer-xs-8 spacer-xl-10"></div>
    </section>
    </IndustryLayout>)
}