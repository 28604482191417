import { useEffect } from "react";
import $ from "jquery";
import useFetch from '../../hooks/useFetch';
import Loader from '../common/Loader';
import Breadcrumb from '../common/Breadcrumb';
import companyBannerImg from "../../images/company/company-banner-bg.svg";
import demoImg from "../../images/demo-image-lg.png";
import ServiceList from "./ServiceList";
import IndustryList from "./IndustryList";
import RobustSolutions from "./RobustSolutions";
import CompanyLayout from "./CompanyLayout";
import BusinessIcons from "./BusinessIcons";
import { Link } from "react-router-dom";
import SEO from './../common/SEO';
import Image from '../common/Image';

export default function VectionTechnologies() {
   useEffect(() => {
      var windowWidth = $(window).width();
      var container = $('.container').width();

      var calcWidth = windowWidth - container;

      $('.art').css('right', - (calcWidth / 2));
   })

   const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/company-index?populate=*');

   if (loading) {
      return <Loader />;
   }
   const { attributes } = response ? response.data : {};
   const seoMeta = (attributes.seo) ? attributes.seo : null
   let breadcrumbLinks = [
      { 'link': '/', 'displayName': 'Home' },
      { 'link': '/company/vection-technologies', 'displayName': 'Company' },
      { 'link': '/company/vection-technologies', 'displayName': 'What is Vection Technologies' }
   ];
   const menuItems = [
      {
         name: ' What is Vection Technologies',
         link: '/company/vection-technologies',
      },
      {
         name: 'Our Story',
         link: '/company/our-story',
      },
      {
         name: 'Brands that support our vision',
         link: '/company/our-vision',
      },
      {
         name: 'Global Offices',
         link: '/company/global-offices',
      },
      {
         name: 'Open Positions',
         link: '/company/open-positions',
      }
   ];
   return (<CompanyLayout>
      <SEO meta={seoMeta}></SEO>
      <section className="banner banner-display-2">
         <div className="row">
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <div className="entry-display-1 width-xxl-50 width-xxxl-68 order-2 order-xl-1 text-xl-start text-center">
               <p className="sub-heading text-primary fw-semibold text-uppercase">What is Vection Technologies</p>
               <h1>{attributes.Title}</h1>
               <div className="spacer-xs-1"></div>
               <p className="fw-medium">{attributes.Description}</p>
               <div className="spacer-xs-4"></div>
            </div>
            <div className="entry-thumb d-flex flex-column flex-xl-row justify-content-center justify-content-xl-end align-items-center position-absolute order-1 order-xl-2">
               <div className="circle-bg d-none d-xl-block"></div>
               <div className="spacer-xs-5 order-1 d-flex d-xl-none"></div>
               <div className="entry-image img-overlay">
                  <Image src={attributes.Image.data.attributes.url} alt="" className="img-fluid" />
               </div>
            </div>
         </div>
      </section>
      <section className="entry-section">
         <div className="spacer-xs-8 spacer-lg-12"></div>
         <BusinessIcons {...attributes} />
         <div className="spacer-xs-6 spacer-lg-10"></div>
      </section>
      {/* <section className="banner-display-3 text-center text-lg-start">
                           <div className="row w-100">
                              <div className="width-lg-70">
                                 <div className="entry-display-1">
                                    <h1>What is Vection Technologies</h1>
                                    <nav aria-label="breadcrumb">
                                    <Breadcrumb breadcrumbLinks={breadcrumbLinks} />
                                    </nav>
                                 </div>
                              </div>
                           </div>
                           <div className="art position-absolute d-none d-lg-flex">
                              <img src={companyBannerImg} alt=""/>
                           </div>
                        </section> */}
      {/* <section className="entry-section">
         <div className="spacer-xs-8 spacer-lg-12"></div>
         <div className="row">
                              <div className="col-sm-12">
                                 <div className="entry-display-1">
                                    <h1>{attributes.Title}</h1>
                                    <div className="spacer-xs-4 spacer-lg-6"></div>
                                    <div className="entry-thumb">
                                       <img src={demoImg} alt="" className="img-fluid"/>
                                    </div>
                                    <div className="spacer-xs-4 spacer-lg-6"></div>
                                    <p>{attributes.Description}</p>
                                 </div>
                              </div>
                           </div>
      </section> */}
      <section className="entry-structure-light technology-buttons">
         <div className="spacer-xs-8 spacer-lg-10"></div>
         <div className="row">
            <div className="col-sm-12">
               <div className="entry-display-3 text-center text-lg-start">
                  <h3>{attributes.TechnologiesTitle}</h3>
                  <div className="spacer-xs-4 spacer-lg-6"></div>
                  <div className="row gx-3">
                     {attributes.TechnologyList.map((tech, index) => {
                        return (
                           <div className="col-sm-12 col-md-6 col-lg-4" key={index}>
                              <Link reloadDocument={true} to={tech.Link} className="btn btn-outline-primary border-2 px-1 py-3 w-100 mb-3">{tech.Title}</Link>
                           </div>)
                     })}
                  </div>
               </div>
            </div>
         </div>
         <div className="spacer-xs-6 spacer-lg-8"></div>
      </section>
      <section className="product product--theme-lite">
         <div className="spacer-xs-8 spacer-lg-12"></div>
         <div className="row">
            <div className="col-sm-12">
               <div className="entry-display-3 text-center text-lg-start">
                  <h3>{attributes.SolutionTitle}</h3>
               </div>
            </div>
         </div>
         <div className="spacer-xs-4 spacer-lg-6"></div>
         <div className="row gx-6">
            <RobustSolutions solutionList={attributes.SolutionList} />
         </div>
         <div className="spacer-xs-6 spacer-xl-8"></div>
      </section>
      <section className="entry-structure-light">
         <div className="spacer-xs-8 spacer-xl-10"></div>
         <div className="row">
            <div className="col-sm-12">
               <div className="entry-display-3">
                  <h3>{attributes.ServiceTitle}</h3>
                  <div className="spacer-xs-4 spacer-xl-6"></div>
               </div>
            </div>
         </div>
         <div className="row gx-4 justify-content-start theme-lite">
            <ServiceList serviceList={attributes.ServiceList} />
         </div>
         <div className="spacer-lg-10"></div>
      </section>
      <section className="entry-structure theme-lite">
         <div className="spacer-xs-8 spacer-lg-12"></div>
         <div className="row">
            <div className="col-sm-12">
               <div className="entry-display-3">
                  <h3>{attributes.IndustryTitle}</h3>
                  <div className="spacer-xs-4 spacer-lg-6"></div>
                  <div className="row gx-6 justify-content-start">
                     <IndustryList industryList={attributes.IndustryList} />
                  </div>
               </div>
            </div>
         </div>
         <div className="spacer-xs-8 spacer-lg-10"></div>
      </section>
   </CompanyLayout>
   )
}