import React, { useEffect } from "react";
import $ from "jquery";
import useFetch from "../../../hooks/useFetch"
import Loader from '../../common/Loader';
import IndustryLayout from "./IndustryLayout"
import { SolutionByIndustryFashion } from '../../../Queries';
import ReactMarkdown from 'react-markdown';
import SEO from './../../common/SEO';

export default function IndustryFashion() {
    useEffect(() => {
        var windowWidth = $(window).width();
        var container = $('.container').width();

        var calcWidth = windowWidth - container;

        $('.art').css('right', - (calcWidth / 2));
    }, [])

    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/industry-fashion?' + SolutionByIndustryFashion);

    if (loading) {
        return <Loader />;
    }
    const { attributes } = response ? response.data : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null
    // console.log(attributes)
    return (<IndustryLayout>
            <SEO meta={seoMeta}></SEO>
        <section className="entry-section">
            <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-1">
                        <p className="sub-heading text-primary fw-semibold">FASHION</p>
                        <h1>{attributes && attributes.Title ? attributes.Title : ''}</h1>
                        <div className="spacer-xs-4 spacer-xl-6"></div>
                        <div className="entry-thumb img-overlay">
                            <img src={attributes && attributes.Image ? attributes.Image.data.attributes.url : ''} alt="" className="img-fluid" />
                        </div>
                        <div className="spacer-xs-4 spacer-xl-6"></div>
                        <p>{attributes && attributes.Description ? attributes.Description : ''}</p>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-8 spacer-xl-12"></div>
        </section>
        <section className="entry-structure-light">
            <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-3">
                        <h3>{attributes && attributes.SubTitle ? attributes.SubTitle : ''}</h3>
                        <div className="spacer-xs-1"></div>
                        <p>{attributes && attributes.SubDescription ? attributes.SubDescription : ''}</p>
                        <div className="spacer-xs-4 spacer-xl-6"></div>
                        <div className="entry-thumb img-overlay">
                            <img src={attributes && attributes.SubImage ? attributes.SubImage.data.attributes.url : ''} alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-8 spacer-xl-10"></div>
        </section>
        <div className="spacer-xs-8 spacer-xl-12"></div>
        <section className="entry-structure">
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-2">
                        <h2>{attributes && attributes.InnovationTitle ? attributes.InnovationTitle : ''}</h2>
                        <p>{attributes && attributes.InnovationDescription ? attributes.InnovationDescription : ''} </p>
                    </div>
                </div>
            </div>
            {attributes.Innovations.map((item, index) => {
                if (index % 2 === 0) {
                    return (
                        <React.Fragment key={`Innovation-${index}`}>
                            <div className="spacer-xs-6 spacer-xl-8"></div>
                            <div className="row gx-6 block-card block-card-sm">
                                <div className="col-lg-6 order-lg-1 order-2">
                                    <div className="entry-display-3">
                                        <h3>{item.Title}</h3>
                                        <div className="spacer-xs-1"></div>
                                        {/* <p>Through VR, people may attend virtual fashion events. For instance, in 2021, <a href="https://www.vogue.co.uk/news/gallery/balenciaga-autumn-winter-2020" target="_blank">Balenciaga</a> released its autumn/winter collection in an unprecedented virtual event. Attendees used Oculus glasses to contemplate the entire collection in a virtual environment. </p> */}
                                        <ReactMarkdown className="reactMarkdown" children={item.Description} components={{
                                            img: ({ node, ...props }) => <div className="entry-thumb img-overlay"><img className="img-fluid" {...props} /></div>,
                                            a: ({ children, href }) => <a href={href} className='sub-link' target='_blank'>{children[0]}</a>
                                        }} />
                                    </div>
                                </div>
                                <div className="col-lg-6 order-lg-2 order-1 mb-4 mb-xl-0">
                                    <div className="entry-thumb img-overlay">
                                        <img src={item && item.Image ? item.Image.data.attributes.url : ''} className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                } else {
                    return (
                        <React.Fragment key={`Innovation-${index}`}>
                            <div className="spacer-xs-8 spacer-xl-12"></div>
                            <div className="row gx-6 block-card block-card-sm">
                                <div className="col-lg-6 order-lg-2 order-2">
                                    <div className="entry-display-3">
                                        <h3>{item.Title}</h3>
                                        <div className="spacer-xs-1"></div>
                                        {/* <p>Through VR, customers can access everything they usually find in a physical store, such as clothing and dressing rooms. Globally recognised brands have begun testing VR applications in their stores to create interactive and immersive experiences for customers.</p> */}
                                        <ReactMarkdown className="reactMarkdown" children={item.Description} components={{
                                            img: ({ node, ...props }) => <div className="entry-thumb img-overlay"><img className="img-fluid" {...props} /></div>,
                                            a: ({ children, href }) => <a href={href} className='sub-link' target='_blank'>{children[0]}</a>
                                        }} />
                                    </div>
                                </div>
                                <div className="col-lg-6 order-xs-1 order-1 mb-4 mb-xl-0">
                                    <div className="entry-thumb img-overlay">
                                        <img src={item && item.Image ? item.Image.data.attributes.url : ''} className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                        </React.Fragment>
                    )
                }
            })}

        </section>
        <div className="spacer-xs-8 spacer-xl-12"></div>
        <section className="entry-structure-light">
            <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-2">
                        <h2>{attributes && attributes.BenefitTitle ? attributes.BenefitTitle : ''}</h2>
                        <p>{attributes && attributes.BenefitDescription ? attributes.BenefitDescription : ''}</p>
                        <div className="spacer-xs-4 spacer-xl-6"></div>
                        <div className="row gx-6 theme-lite">
                            {attributes.Benefits.map((item, index) => {
                                return (
                                    <div key={`Benefit-${index}`} className="col-sm-12 mb-4">
                                        <div className="card mb-3 bg-transparent border-0">
                                            <div className="row g-0">
                                                <div className="col-md-auto d-flex justify-content-center">
                                                    <div className={`entry-icon ${item.IconColor} mb-4`}>
                                                        <span><i className={`icon-industries ${item.IconSlug}`}></i></span>
                                                    </div>
                                                </div>
                                                <div className="col-md-10">
                                                    <div className="entry-display-5 ps-0 ps-md-6 text-center text-md-start">
                                                        <h5>{item.Title}</h5>
                                                        <p>{item.Description}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-4"></div>
        </section>
        <div className="spacer-xs-8 spacer-xl-12"></div>
        <section className="entry-structure entry-structure">
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-3">
                        <h3>{attributes && attributes.ARTitle ? attributes.ARTitle : ''}</h3>
                        <div className="spacer-xs-1"></div>
                        {/* <p>Augmented reality has been used in the fashion industry to boost customer engagement with virtual try-ons. For example, <a href="https://www.burberryplc.com/en/news/corporate/2020/burberry-brings-products-to-google-search-through-augmented-real.html" target="_blank">Burberry</a> has applied AR in retail to encourage customers to purchase clothes through digital devices to see their outfits. Augmented reality is an interactive 3D experience that simultaneously merges real world views with computer-generated elements. For example, users may use smartphones to combine immediate real-world surroundings with computer graphics. </p> */}
                        <ReactMarkdown className="reactMarkdown" children={attributes.ARDescription} components={{
                            img: ({ node, ...props }) => <div className="entry-thumb img-overlay"><img className="img-fluid" {...props} /></div>,
                            a: ({ children, href }) => <a href={href} className='sub-link' target='_blank'>{children[0]}</a>
                        }} />
                        <div className="spacer-xs-4 spacer-xl-6"></div>
                        <div className="entry-thumb img-overlay">
                            <img src={attributes && attributes.ARImage ? attributes.ARImage.data.attributes.url : ''} alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="spacer-xs-8 spacer-xl-12"></div>
        <section className="entry-structure-light">
            <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-2">
                        <h2>{attributes && attributes.FashionTitle ? attributes.FashionTitle : ''}</h2>
                        <div className="spacer-xs-1"></div>
                        <p>{attributes && attributes.FashionDescription ? attributes.FashionDescription : ''}</p>
                    </div>
                </div>
            </div>
            {attributes.FashionList.map((item, index) => {
                return (
                    <React.Fragment key={`FashionList-${index}`}>
                        <div className="spacer-xs-6 spacer-xl-8"></div>
                        <div className="row gx-6 block-card block-card-sm">
                            <div className="col-lg-6 order-lg-2 order-2">
                                <div className="entry-display-3">
                                    <h3>{item.Title}</h3>
                                    <div className="spacer-xs-1"></div>
                                    <p>{item.Description}</p>
                                </div>
                            </div>
                            <div className="col-lg-6 order-lg-1 order-1 mb-4 mb-xl-0">
                                <div className="entry-thumb img-overlay">
                                    <img src={item && item.Image ? item.Image.data.attributes.url : ''} className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </React.Fragment>
                )
            })}
            
            <div className="spacer-xs-6 spacer-xl-8"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-3">
                        <h3>{attributes && attributes.TakeawayTitle ? attributes.TakeawayTitle : ''}</h3>
                        <div className="spacer-xs-1"></div>
                        <p>{attributes && attributes.TakeawayDescription ? attributes.TakeawayDescription : ''}</p>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-8 spacer-xl-12"></div>
        </section>
    </IndustryLayout>)
}