import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import useFetch from '../hooks/useFetch';
import { SiteMapQuery } from '../Queries';
import SiteMapList from '../utitls/SiteMapList';
import LearnMore from './common/LearnMore';
import Business from './common/Business';
import bannerBg from '../images/products/3dframe/banner-bg.png';
import Layout from './Layout';
import Loader from './common/Loader';
import $ from 'jquery';
import SEO from './common/SEO';

export default function SiteMapPage() {
  const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/site-map?' + SiteMapQuery);
  const { data } = response ? response : [];
  const { attributes } = data ? data : [];
  
  const siteMapList = SiteMapList();
  
  useEffect(() => {
    var windowWidth = $(window).width();
    var container = $('.container').width();
    
    var calcWidth = windowWidth - container;
    
    $('.art').css('right', -(calcWidth / 2));
  });
  
  if (loading) {
    return <Loader />;
  }
  const seoMeta = (attributes.seo) ? attributes.seo : null
  return (
    <Layout>
      <SEO meta={seoMeta}></SEO>
      <main className="page-wrapper">
        <section className="container">
          <section className="banner-display-3">
            <div className="row">
              <div className="col-sm-12">
                <div className="entry-display-1">
                  <h1>Sitemap</h1>
                </div>
              </div>
            </div>
            <div className="art position-absolute d-none d-xl-flex">
              <img src={bannerBg} alt="" className="img-fluid" />
            </div>
          </section>
          <section className="entry-structure sitemap">
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <div className="entry-display-3">
                  <h3>{attributes.Title}</h3>
                </div>
              </div>
            </div>
            <div className="spacer-xs-6 spacer-xl-8"></div>
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <div className="entry-display-4">
                  <div className="row">
                    {siteMapList &&
                      siteMapList.map((menu, index) => {
                        return (
                          <div className={'col-sm-4 ' + (siteMapList.length !== index + 1 ? 'mb-5' : '')} key={'footer-menu-' + index}>
                            <h4 className="footer-title">{menu.heading}</h4>
                            <div className="spacer-xs-2"></div>
                            <ul>
                              {menu.subMenu &&
                                menu.subMenu.map((page, pageIndex) => {
                                  return (
                                    <li className="mb-3" key={'pageIndex-' + pageIndex}>
                                      <Link className="text-content fs-xs-18 text-decoration-underline" reloadDocument={true} to={page.link}>
                                        {page.name}
                                      </Link>
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
            <div className="spacer-xs-6 spacer-xl-12"></div>
          </section>
          <section className="entry-structure">
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <Business {...attributes} titleClass="" />
              </div>
            </div>
            <div className="spacer-xs-8 spacer-xl-12"></div>
          </section>
        </section>
        <LearnMore />
      </main>
    </Layout>
  );
}
