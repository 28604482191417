import Layout from '../../Layout';
import LearnMore from '../../common/LearnMore';
import SideMenu from "../../common/SideMenu";

export default function IndustryLayout(props) {
    const menuItems = [
        {
            name: ' Overview',
            link: '/solutions/industries/overview',
        },
        {
            name: 'Machinery Manufacturing',
            link: '/solutions/industries/machinery-manufacturing',
        },
        {
            name: 'Architecture, Engineering & Construction',
            link: '/solutions/industries/architecture-engineering-and-construction',
        },
        {
            name: 'Automotive',
            link: '/solutions/industries/automotive',
        },
        {
            name: 'Naval',
            link: '/solutions/industries/naval-and-shipbuilding',
        },
        {
            name: 'Furniture and Home Decor',
            link: '/solutions/industries/furniture-and-home-decor',
        },
        {
            name: 'Fashion',
            link: '/solutions/industries/fashion',
        },
        {
            name: 'Public Sector',
            link: '/solutions/industries/public-sector',
        },
        {
            name: 'Education',
            link: '/solutions/industries/education',
        },
        {
            name: 'Sports',
            link: '/solutions/industries/sports',
        },
        {
            name: 'Betting',
            link: '/solutions/industries/betting',
        },
        {
            name: 'Media & Communications',
            link: '/solutions/industries/media-and-communications',
        },
        {
            name: 'Defence, Military & Law Enforcement',
            link: '/solutions/industries/defence-military-and-law-enforcement',
        },
        {
            name: 'Healthcare & Pharma',
            link: '/solutions/industries/healthcare-and-pharma',
        },
        {
            name: 'Retail',
            link: '/solutions/industries/retail',
        },
        {
            name: 'Aerospace',
            link: '/solutions/industries/aerospace',
        },
        ];
        return (
        <Layout>
        <main className="page-wrapper">
        <div className="container">
            <div className="row">
                <div className="col-sm-12">
                <div className="page-content">
                <SideMenu menuHeading="Industries" menuItems={menuItems} />
                    <div className="main-content">
                        {props.children}
                    </div>
                </div>
                </div>
            </div>
        </div>
        <LearnMore/> 
        </main>
        </Layout>
    )
}