import React, { useEffect, useState } from 'react';
import Image from '../common/Image';
import BenefitLayout from './BenefitLayout';
import FeatureCard from './FeatureCard';
import { Link, useParams } from 'react-router-dom';
import Loader from '../common/Loader';
import ReactMarkdown from 'react-markdown';
import { apiBenefit } from '../../services/apiBenefit';

export default function BenefitPage(props) {
    const { slug } = useParams();
    const [isLoading, setIsLoading] = useState(true);
    const [attributes, setAttributes] = useState([]);
    const [BenefitList, setBenefitList] = useState([]);
    const [IncreaseAnalysisList, setIncreaseAnalysisList] = useState([]);

    useEffect(() => {
        setIsLoading(true);
        apiBenefit.needs(
            slug,
          response => {
            const { attributes, BenefitList, IncreaseAnalysisList } = response ? response : [];
            setAttributes(attributes);
            setBenefitList(BenefitList);
            setIncreaseAnalysisList(IncreaseAnalysisList);
            setIsLoading(false);
          },
          error => {
            console.log('error', error);
          }
        );
      }, [slug]);
    if (isLoading) {
        return <Loader />;
    }

    function footerGetStartedLink(FooterGetStartedLink) {
        return (
            <div className="row">
                <div className="col-sm-12 d-flex justify-content-center">
                    <Link to={FooterGetStartedLink ? FooterGetStartedLink : '#'} className="btn btn-primary btn-lg text-white icon-btn">Get Started<i className="icon-comman icon-arrow-right ms-3 fs-lg-22"></i></Link>
                </div>
            </div>
        )
    }

    function increaseConversionRate(attributes, IncreaseAnalysisList) {
        return (
            <>
                <section className="entry-structure-light">
                    <div className="spacer-xs-8 spacer-xl-10"></div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="entry-display-2">
                                <h2>{attributes && attributes.IncreaseAnalysisTiitle ? attributes.IncreaseAnalysisTiitle : ''} </h2>
                                <div className="spacer-xs-1"></div>
                                <ReactMarkdown className="reactMarkdown" children={attributes.IncreaseAnalysisDescription} components={{
                a: ({ children, href }) => <a href={href} className='sub-link' target='_blank'>{children[0]}</a>
              }} />
                                <div className="spacer-xs-4 spacer-xl-6"></div>
                                <div className="entry-thumb img-overlay">
                                    <Image src={attributes && attributes.IncreaseAnalysisImage ? attributes.IncreaseAnalysisImage.data.attributes.url : ''} className="img-fluid" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="spacer-xs-8 spacer-xl-10"></div>
                </section>
                <div className="spacer-xs-8 spacer-xl-12"></div>
                <section className="entry-structure">
                    {IncreaseAnalysisList.map((item, index) => {
                        return <FeatureCard key={'feature-card-2-' + index} lastItem={index === 3} itemIndex={index} item={item} />;
                    })}
                    <div className="row">
                        <div className="col-sm-12 d-flex justify-content-center">
                            <Link to={attributes && attributes.FooterGetStartedLink ? attributes.FooterGetStartedLink : '#'} className="btn btn-primary btn-lg text-white icon-btn">Get Started<i className="icon-comman icon-arrow-right ms-3 fs-lg-22"></i></Link>
                        </div>
                    </div>
                </section>
                <div className="spacer-xs-8 spacer-xl-12"></div>
            </>
        )
    }
    return (
        <BenefitLayout>
            <section className="banner banner-display-2">
                <div className="row">
                    <div className="spacer-xs-8 spacer-xl-12"></div>
                    <div className="entry-display-1 width-xxl-50 width-xxxl-68 order-2 order-xl-1 text-xl-start text-center">
                        <h1>{attributes && attributes.Title ? attributes.Title : ''}</h1>
                        <div className="spacer-xs-1"></div>
                        <p className="fw-medium">{attributes && attributes.Description ? attributes.Description : ''}</p>
                        <div className="spacer-xs-4"></div>
                        <Link to={attributes && attributes.GetStartedLink ? attributes.GetStartedLink : '#'} className="btn btn-primary btn-sm text-white icon-btn px-5 py-3">Get Started<i className="icon-comman icon-arrow-right ms-3 fs-lg-18"></i></Link>
                    </div>
                    <div className="entry-thumb d-flex flex-column flex-xl-row justify-content-center justify-content-xl-end align-items-center position-absolute order-1 order-xl-2">
                        <div className="circle-bg d-none d-xl-block"></div>
                        <div className="spacer-xs-5 order-1 d-flex d-xl-none"></div>
                        <div className="entry-image img-overlay">
                            <Image src={attributes && attributes.CircleImage ? attributes.CircleImage.data.attributes.url : ''} alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3 text-xl-start text-center">
                            <h3>{attributes && attributes.SubDescriptionBold ? attributes.SubDescriptionBold : ''}</h3>
                        </div>
                    </div>
                </div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure-light">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-2">
                            <h2>{attributes && attributes.SubTitle ? attributes.SubTitle : ''}</h2>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes && attributes.SubDescription ? attributes.SubDescription : ''}</p>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <div className="entry-thumb img-overlay">
                                <Image src={attributes && attributes.SubImage ? attributes.SubImage.data.attributes.url : ''} className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-10"></div>
            </section>
            <section className="entry-structure">
                {BenefitList.map((itemSolution, indexSolution) => {
                    return <FeatureCard key={'feature-card-2-' + indexSolution} lastItem={indexSolution === 3} itemIndex={indexSolution} item={itemSolution} />;
                })}
                {slug != "increase-conversion-rate" && footerGetStartedLink(attributes.FooterGetStartedLink)}
            </section>
            {slug != "increase-conversion-rate" && <div className="spacer-xs-8 spacer-xl-12"></div>}
             {slug == "increase-conversion-rate" && increaseConversionRate(attributes, IncreaseAnalysisList)}   
        </BenefitLayout>
    );
}
