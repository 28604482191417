import React from 'react';
import { SolutionByNeedQuery } from '../../../Queries';
import useFetch from '../../../hooks/useFetch';
import Loader from '../../common/Loader';
import ByNeedLayout from './ByNeedLayout';
import SEO from '../../common/SEO';

export default function EcommercePage() {
  const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/solution-by-need-e-commerce?' + SolutionByNeedQuery);

  if (loading) {
    return <Loader />;
  }
  const { data } = response ? response : [];
  const { attributes } = data ? data : [];
  const { Challenges } = attributes ? attributes : [];
  const seoMeta = (attributes.seo) ? attributes.seo : null
  const breadcrumbLinks = [
    { 'link': '/', 'displayName': 'Home' },
    { 'link': '/solutions', 'displayName': 'Solution' },
    { 'link': '#', 'displayName': 'Needs' },
    { 'link': '/solutions/needs-ecommerce', 'displayName': 'Ecommerce' }
  ];

  return (
    <ByNeedLayout
      banner={attributes.Banner}
      heading={attributes.heading}
      description={attributes.description}
      challenges={Challenges}
      breadcrumbLinks={breadcrumbLinks}
      faq={attributes.FAQ}
    >
      <SEO meta={seoMeta}></SEO>
    </ByNeedLayout>
  );
}
