import { Link } from 'react-router-dom';
import Layout from '../../Layout';
import Breadcrumb from '../../common/Breadcrumb';
import blogImg from '../../../images/blog.jpg';
import LearnMore from '../../common/LearnMore';
import Loader from '../../common/Loader';
import { useState, useEffect } from 'react';
import SEOPage from '../../common/SEOPage';

export default function CaseStudies() {
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const perPage = 2;

  const getData = async url => {
    try {
      const res = await fetch(url, { headers: { Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}` } });
      const json = await res.json();
      setData(json.data);
      setTotalPages(json.meta.pagination.pageCount);
      setActivePage(json.meta.pagination.page);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const url = process.env.REACT_APP_CMS_URL + `/api/case-studies?populate=*&sort[0]=updatedAt:DESC&pagination[page]=1&pagination[pageSize]=${perPage}`;
    getData(url);
  }, []);

  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const getDateFormat = dates => {
    let date = new Date(dates);
    return date.getDate() + ' ' + monthNames[date.getMonth()] + ', ' + date.getFullYear('YYYY');
  };

  let breadcrumbLinks = [
    { 'link': '/', 'displayName': 'Home' },
    { 'link': '/blog', 'displayName': 'Knowledge Center' },
    { 'link': '/blog', 'displayName': 'Case Studies' }
  ];

  const handlePage = (page) => {
    const url = process.env.REACT_APP_CMS_URL + `/api/case-studies?populate=*&sort[0]=updatedAt:DESC&pagination[page]=${page}&pagination[pageSize]=${perPage}`;
    getData(url);
  };


  if (loading) {
    return <Loader />;
  }
  const getSubMenu = () => {
    return (
      <ul>
        <li>
          <Link reloadDocument={true} to="/blog">Blog</Link>
        </li>
        <li>
          <Link reloadDocument={true} to="/case-studies" className="active">
            Case Studies
          </Link>
        </li>
        <li>
          <Link reloadDocument={true} to="/success-stories">Success Stories</Link>
        </li>
      </ul>
    );
  };

  const url = process.env.REACT_APP_CMS_URL + '/api/case-studies-overview?populate=*';

  return (
    <Layout>
      <SEOPage url={url}></SEOPage>
      <main className="page-wrapper investor-center">
        <section className="banner-display-4 text-center text-lg-start" style={{ backgroundImage: `url(${blogImg})` }}>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="entry-display-1">
                  <h1>Case Studies</h1>
                  <Breadcrumb breadcrumbLinks={breadcrumbLinks} />
                </div>
              </div>
            </div>
          </div>
        </section>
        <aside className="drawer-mobile sidebar-content d-flex d-xl-none">
          <div className="offcanvas offcanvas-start" tabIndex="-1" id="openCanvas" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-body handler-menu">
              <div className="spacer-xs-3"></div>
              <div className="sidebartitle">
                <h2>Knowledge Center</h2>
              </div>
              {getSubMenu()}
            </div>
          </div>
        </aside>
        <section className="handler-menu d-none d-xl-block">
          <div className="spacer-xs-3"></div>
          {getSubMenu()}
          <div className="spacer-xs-3"></div>
        </section>
        <section className="post">
          <div className="container">
            <div className="row">
              <div className="spacer-xs-8 spacer-lg-10"></div>
              {data.map((item, index) => {
                var url = '/case-studies/' + item.attributes.Slug;
                return (
                  <article key={index} className="col-sm-4 mb-5">
                    <div className="card">
                      {item.attributes.Image.data !== null && (
                        <div className="post-thumb img-overlay">
                          <img src={item.attributes.Image.data.attributes.formats.small.url} className="img-fluid" alt={item.attributes.Image.data.attributes.alternativeText} />
                        </div>
                      )}
                      <div className="post-content">
                        <h3>{item.attributes.Title}</h3>
                        <p className="date">{getDateFormat(item.attributes.publishedAt)}</p>
                        <a href={url} className="read-more mt-auto text-primary stretched-link d-flex align-items-center">
                          Read More<i className="icon-comman icon-right ms-2"></i>
                        </a>
                      </div>
                    </div>
                  </article>
                );
              })}
            </div>
            <div className="spacer-lg-5 spacer-xs-3"></div>
            {totalPages > 1 && <div className="row">
              <div className="col-sm-12">
                <div className="pagination">
                  <ul className="d-flex justify-content-center list-unstyled">
                    {activePage > 1 && (
                      <li className="page-item">
                         <span className="page-link cursor-pointer" onClick={e => handlePage(activePage - 1)}>
                          Prev
                        </span>
                      </li>
                    )}
                    {[...Array(totalPages)].map((value, index) => {
                      return <li className={'page-item ' + (activePage === index + 1 ? 'active' : '')} key={'pagination-' + index}>
                         <span className="page-link cursor-pointer" onClick={(e) => { handlePage(index + 1) }}>{index + 1}</span>
                      </li>
                    })}
                    {activePage < totalPages && (
                      <li className="page-item">
                        <span className="page-link cursor-pointer" onClick={e => handlePage(activePage + 1)}>
                          Next
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>}
            {totalPages > 1 && <div className="spacer-xs-8 spacer-lg-12"></div>}
          </div>
        </section>
        <LearnMore />
      </main>
    </Layout>
  );
}
