import $ from 'jquery';

export const stockApi = {
  chart(count, cb, errorCb) {
    let labels = [];
    let data = [];
    var ajaxurl = 'https://www.asx.com.au/asx/1/share/VR1/prices?interval=daily&count=' + count;
    $.ajax({
      dataType: 'jsonp',
      url: ajaxurl
    })
      .done(function (response) {
        let dataIndex = response.data.length - 1;
        response &&
          response.data.map((element, index) => {
            var mydate = new Date(element.close_date.replace('+1000', ''));
            var day = mydate.getDate();
            var month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][mydate.getMonth()];
            var sDate = day + ' ' + month + " '" + mydate.getFullYear().toString().substr(-2);

            labels.push(sDate);
            data[dataIndex--] = {
              date: sDate,
              close: element.close_price,
              highPrice: element.day_high_price,
              volume: element.volume / 1000000
            };
          });
        cb({ data, labels });
      })
      .fail(function () {
        cb({ error: 'No data found', data: [] });
      });
  },
  technologyIndexChart(count, cb, errorCb) {
    var ajaxurl = 'https://www.asx.com.au/asx/1/share/VR1/prices?interval=daily&count=' + count;
    $.ajax({
      dataType: 'jsonp',
      url: ajaxurl
    })
      .done(function (response) {
        let labels = [];
        let data = [];
        let dataIndex = response.data.length - 1;
        response &&
          response.data.map((element, index) => {
            var mydate = new Date(element.close_date.replace('+1000', ''));
            var day = mydate.getDate();
            var month = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'][mydate.getMonth()];
            var sDate = day + ' ' + month + " '" + mydate.getFullYear().toString().substr(-2);

            labels.push(sDate);
            data[dataIndex--] = {
              date: sDate,
              close: element.close_price,
              highPrice: element.day_high_price,
              volume: element.volume / 1000000
            };
          });
        cb({ data, labels });
      })
      .fail(function () {
        cb({ error: 'No data found', data: [] });
      });
  },
  announcements(count, cb, errorCb) {
    var ajaxurl = 'https://www.asx.com.au/asx/1/company/VR1/announcements?count=' + count;
    $.ajax({
      dataType: 'jsonp',
      url: ajaxurl
    })
      .done(function (response) {
        cb({ announcementList: response.data });
      })
      .fail(function () {
        cb({ announcementList: [], error: 'No data found' });
      });
  },
  financialSummary(cb, errorCb) {
    var ajaxurl = 'https://www.asx.com.au/asx/1/company/VR1?fields=primary_share';
    $.ajax({
      dataType: 'jsonp',
      url: ajaxurl
    })
      .done(function (response) {
        cb({ data: response.primary_share ? response.primary_share : [] });
      })
      .fail(function () {
        cb({ data: [], error: 'No data found' });
      });
  }
};
