import React, { useRef } from 'react';
import { Autoplay, Controller } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default function Business(props) {
  const { BusinessTitle, BusinessIcons, titleClass, spaceBetween } = props;
  const swiper1Ref = useRef();

  return (
    <div className="company-logo overflow-hidden">
      <h2 className={titleClass}>{BusinessTitle}</h2>
      <div className="spacer-xs-2 spacer-lg-4"></div>
      <Swiper
        className="swiper swiper-company-logo d-flex justify-content-center swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events"
        modules={[Autoplay, Controller]}
        onSwiper={swiper => {
          swiper1Ref.current = swiper;
        }}
        loop={true}
        speed={5000}
        slidesPerView="auto"
        spaceBetween={spaceBetween ? spaceBetween : 0}
        autoplay={{
          delay: 1
          // disableOnInteraction: true,
        }}
        preventInteractionOnTransition={true}
        navigation={{
          nextEl: '.swiper-button-next-unique',
          prevEl: '.swiper-button-prev-unique'
        }}
        keyboard={{ enabled: true, onlyInViewport: false }}
        pagination={{
          el: '.swiper-pagination',
          clickable: true
        }}
        breakpoints={{
          340: {
            slidesPerView: 'auto',
            spaceBetween: 80,
            allowTouchMove: false
          },
          640: {
            slidesPerView: 'auto',
            spaceBetween: 0,
            allowTouchMove: false
          },
          768: {
            slidesPerView: 'auto',
            spaceBetween: 0,
            allowTouchMove: false
          },
          1024: {}
        }}
      >
        <div className="swiper-wrapper row flex-nowrap">
          {BusinessIcons.data.map((item, index) => {
            return (
              <SwiperSlide className="swiper-slide width-xs-20 d-flex justify-content-center align-items-center" key={'swiper-slide-' + index}>
                <img src={item.attributes.url} className="img-fluid" alt="" />
              </SwiperSlide>
            );
          })}
        </div>
      </Swiper>
    </div>
  );
}
