import React,{ useEffect } from "react";
import $ from "jquery";
import useFetch from "../../../hooks/useFetch"
import Loader from '../../common/Loader';
import Image from "../../common/Image";
import IndustryLayout from "./IndustryLayout"
import { IndustryAerospaceQuery } from "../../../Queries";
import ReactMarkdown from "react-markdown";
import SEO from './../../common/SEO';

export default function IndustryAerospace() {
    useEffect(() => {
        var windowWidth = $(window).width();
        var container = $('.container').width();

        var calcWidth = windowWidth - container;

        $('.art').css('right', - (calcWidth / 2));
    },[])
    
    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/industries-aerospace?' + IndustryAerospaceQuery);
    
    if (loading) {
        return <Loader />;
    }
    const { attributes } = response ? response.data : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null
    return (<IndustryLayout>
            <SEO meta={seoMeta}></SEO>
        <section className="entry-section">
        <div className="spacer-xs-8 spacer-xl-10"></div>
        <div className="row">
            <div className="col-sm-12">
                <div className="entry-display-1">
                <p className="sub-heading text-primary fw-semibold">AEROSPACE</p>
                <h1>{attributes.Title ? attributes.Title : ''} </h1>
                <p>{attributes.Description ? attributes.Description : ''}
                </p>
                <div className="spacer-xs-4 spacer-xl-6"></div>
                <div className="entry-thumb">
                  <Image src={attributes && attributes.Image ? attributes.Image.data.attributes.url : ''} className="img-fluid" />
                </div>
                <div className="spacer-xs-4 spacer-xl-6"></div>
                <p>{attributes.SubDescription ? attributes.SubDescription : ''} 
                </p>
                </div>
            </div>
        </div>
        <div className="spacer-xs-8 spacer-xl-12"></div>
    </section>
    <section className="entry-structure-light">
        <div className="spacer-xs-8 spacer-xl-10"></div>
        <div className="row">
            <div className="col-sm-12">
                <div className="entry-display-3">
                <h3>{attributes.AerospaceIndustryTitle ? attributes.AerospaceIndustryTitle : ''}</h3>
                <div className="spacer-xs-1"></div>
                <p>{attributes.AerospaceIndustryDescription ? attributes.AerospaceIndustryDescription : ''}</p>
                <div className="spacer-xs-4 spacer-xl-6"></div>
                <div className="entry-thumb">
                <Image src={attributes && attributes.AerospaceIndustryImage ? attributes.AerospaceIndustryImage.data.attributes.url : ''} className="img-fluid" />
                </div>
                </div>
            </div>
        </div>
        <div className="spacer-xs-8 spacer-xl-10"></div>
    </section>
        <div className="spacer-xs-8 spacer-xl-12"></div>
    <section className="entry-structure">
        <div className="row">
            <div className="col-sm-12">
                <div className="entry-display-2">
                <h2>{attributes.GlanceTitle ? attributes.GlanceTitle : ''}</h2>
                    <div className="spacer-xs-1"></div>
                <p>{attributes.GlanceDescription ? attributes.GlanceDescription : ''}</p>
                </div>
            </div>
        </div>
        <div className="spacer-xs-6 spacer-xl-8"></div>
         {attributes.GlanceList && attributes.GlanceList.map((glance , index) => {
             return <React.Fragment key={index}>
                 <div className="row gx-6 block-card block-card-sm">
              <div className={(index % 2) == 0 ? "col-lg-6 order-lg-1 order-2" : "col-lg-6 order-lg-2 order-1" }>
                 <div className="entry-display-3">
                     <h3>{glance.Title}</h3>
                     <div className="spacer-xs-1"></div>
                     <ReactMarkdown className="reactMarkdown" children={glance.Description ? glance.Description : ''} components={{
                        a: ({ children, href }) => <a href={href} className='sub-link' target='_blank'>{children[0]}</a>
                        }} />
                 </div>
             </div>
             <div className={(index % 2) == 0 ? "col-lg-6 order-lg-2 order-1 mb-4 mb-xl-0" : "col-lg-6 order-lg-1 order-2 mb-4 mb-xl-0"}>
                 <div className="entry-thumb">
                 <Image src={glance && glance.Image ? glance.Image.data.attributes.url : ''} className="img-fluid" />
                 </div>
             </div>
             </div>
             <div className="spacer-xs-8 spacer-xl-12"></div>
             </React.Fragment>
         })}
    </section>
    <div className="spacer-xs-8 spacer-xl-12"></div>
    <section className="entry-structure-light">
        <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-2">
                        <h2>{attributes.RoleTitle ? attributes.RoleTitle : ''}</h2>
                            <div className="spacer-xs-1"></div>
                        <p>{attributes.RoleDescription ? attributes.RoleDescription : ''}</p>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-4"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-3">
                        <h3>{attributes.VrGlanceTitle ? attributes.VrGlanceTitle : ''}</h3> 
                        <div className="spacer-xs-1"></div>
                        <p>{attributes.VrGlanceDescription ? attributes.VrGlanceDescription : ''}</p>
                    </div>
                </div>
            </div>
        <div className="spacer-xs-8 spacer-xl-10"></div>
     </section>
    <div className="spacer-xs-8 spacer-xl-12"></div>
    <section className="entry-structure">
        <div className="row">
            <div className="col-sm-12">
                <div className="entry-display-2">
                <h2>{attributes.ApplicationsTitle ? attributes.ApplicationsTitle : ''}</h2>
                </div>
            </div>
        </div>
        <div className="spacer-xs-6 spacer-xl-8"></div>
        {attributes.ApplicationsList && attributes.ApplicationsList.map((item,index) => {
            return <React.Fragment key={index}>
            <div className="row gx-6 block-card block-card-sm">
            <div className={(index % 2) == 0 ? "col-lg-6 order-lg-1 order-2" : "col-lg-6 order-lg-2 order-1" }>
                <div className="entry-display-3">
                    <h3>{item.Title}</h3>
                    <div className="spacer-xs-1"></div>
                    <p>{item.Description}</p>
                </div>
            </div>
            <div className={(index % 2) == 0 ? "col-lg-6  mb-4 mb-xl-0  order-lg-2 order-1" : "col-lg-6  mb-4 mb-xl-0  order-lg-1 order-2"}>
                <div className="entry-thumb">
                <Image src={item && item.Image ? item.Image.data.attributes.url : ''} className="img-fluid" />
                </div>
            </div>
           </div>
            <div className="spacer-xs-8 spacer-xl-12"></div> 
            </React.Fragment>
        })}
        <div className="row">
            <div className="col-sm-12">
                <div className="entry-display-3">
                    <h3>{attributes.TakeawayTitle ? attributes.TakeawayTitle : ''}</h3>
                    <div className="spacer-xs-1"></div>
                    <p>{attributes.TakeawayDescription ? attributes.TakeawayDescription : ''}</p>
                </div>
            </div>
        </div>
    </section>
    <div className="spacer-xs-8 spacer-xl-12"></div>
    </IndustryLayout>)
}