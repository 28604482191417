import qs from 'qs';

function common(params, sortBy = '') {
  return (
    qs.stringify(
      {
        populate: params
      },
      {
        encodeValuesOnly: true
      }
    ) + sortBy
  );
}

export const MenuQuery = common(['*', 'SubMenu.SubMenuItem.*', 'SubMenu.SubMenuItem.icon'], '&sort=id:ASC');

export const HomeQuery = common([
  '*',
  'BannerList',
  'BannerList.Image',
  'BusinessIcons',
  'FeatureList',
  'FeatureList.Image',
  'SolutionList',
  'SupportList',
  'IndustryList',
  'FaqList',
  'seo.metaImage'
]);

export const SolutionQuery = common(['*', 'CircleImage.image', 'ByNeed', 'ByProducts', 'ByIndustry', 'ByIntegration', 'seo.metaImage'], '&sort=id:ASC');
export const SolutionByNeedQuery = common(['*', 'Image', 'ChallengeList', 'FaqList', 'ChallengeList.Image', 'FAQ', 'seo.metaImage'], '&sort=id:ASC');
export const ProfessionServicesQuery = common(['*', 'Image', 'ServiceList', 'seo.metaImage'], '&sort=id:ASC');
export const ThreeDModelServicesQuery = common(
  ['*', 'CircleImage', 'BannerImage', 'UsecaseImage.image', 'ExampleList.Image', 'FeatureList.Image', 'RenderImage', 'seo.metaImage'],
  '&sort=id:ASC'
);
export const XrCustomizationQuery = common(
  [
    '*',
    'CircleImage',
    'BannerImage',
    'SubImage',
    'ExtraImage',
    'FeatureList.Image',
    'FeatureList.LinkList',
    'UsecaseList',
    'VrFeatureList.Image',
    'VrFeatureList.LinkList',
    'seo.metaImage'
  ],
  '&sort=id:ASC'
);
export const MetaverseQuery = common(['*', 'image1', 'CircleImage', 'BannerImage', 'FeatureList.Image', 'FeatureList.LinkList', 'seo.metaImage'], '&sort=id:ASC');
export const IctInfrastructureQuery = common(['*', 'CircleImage', 'BannerImage', 'FeatureList.Image', 'seo.metaImage'], '&sort=id:ASC');
export const KioskQuery = common(['*', 'CircleImage', 'BannerImage', 'CustomKioskList', 'ProductList.Image', 'seo.metaImage'], '&sort=id:ASC');
export const InvestorCenterAsxTechnology = common(['*', 'Video', 'BenefitList', 'seo.metaImage'], '&sort=id:ASC');
export const InvestorCenterLeadership = common(
  ['*', 'DirectorList.Avatar', 'SecretaryList.Avatar', 'AdvisorList.Avatar', 'seo.metaImage', 'AnualReportFile', 'AppendixFile'],
  '&sort=id:ASC'
);
export const StockPriceQuery = common(['*', 'seo.metaImage']);
export const MediaQuery = common(['*', 'Medias.image']);
export const SolutionByProductEnworkOverview = common(['*', 'BenefitList', 'Image', 'Logo', 'Video', 'SolutionList.Image', 'seo.metaImage']);
export const SolutionByProductConfiguratorOverview = common(['*', 'BenefitList.Image', 'Image', 'Logo', 'Video', 'FeatureList.Image', 'seo.metaImage']);
export const SolutionByProductMindeskOverview = common(['*', 'BenefitList.Image', 'Image', 'Logo', 'Video', 'ExperienceList.Image', 'HowToList.Image', 'seo.metaImage']);
export const SolutionByProductMindeskPricing = common(['*', 'seo.metaImage']);
export const SolutionByProductThreeDFrameOverview = common([
  '*',
  'VideoCover',
  'Logo',
  'EnterpriseList',
  'NeedList',
  'NeedList.Image',
  'BuildStepList',
  'BuildStepList.Image',
  'VideoMp4',
  'VideoWebm',
  'VideoOgg',
  'seo.metaImage'
]);
export const SolutionByProductThreeDFrameGetStarted = common(['*', 'Logo', 'BusinessIcons', 'seo.metaImage']);
export const SolutionByProductThreeDFramePricing = common(['*', 'Logo', 'PricingSwitcherList', 'MonthlyList', 'AnnuallyList', 'seo.metaImage']);
export const SolutionByProductXrkioskOverview = common(['*', 'BenefitList.Image', 'Image', 'Logo', 'Video', 'FeatureList.Image', 'seo.metaImage']);
export const SolutionByProductEnworkGetStarted = common(['*', 'Logo', 'BusinessIcons', 'seo.metaImage']);
export const SolutionByProductConfiguratorGetStarted = common(['*', 'Logo', 'BusinessIcons', 'seo.metaImage']);
export const SolutionByProductXrkiosGetStarted = common(['*', 'Logo', 'BusinessIcons', 'seo.metaImage']);
export const CompanyOurStoryQuery = common(['*', 'Image', 'TimelineList.Image', 'seo.metaImage']);
export const SolutionByProductOverview = common(['*', 'Image', 'ProductList', 'seo.metaImage']);
export const SolutionByNeedOverview = common(['*', 'Image', 'NeedList', 'seo.metaImage']);
export const TechnologyExtendedRealityQuery = common(['*', 'BannerImage', 'SubImage', 'TypesOfExtendedReality.Image', 'PopularUseCases.Image', 'seo.metaImage']);
export const TechnologyMixedRealityQuery = common(['*', 'BannerImage', 'Reality.Image', 'RealityUseCases.Image', 'seo.metaImage']);
export const TechnologyMetaverseQuery = common(['*', 'OverviewImage', 'ActivitiesList', 'ActivitiesList.Image', 'Image', 'AreasOfInterestImage', 'seo.metaImage']);
export const AugmentedRealityQuery = common([
  '*',
  'RealityImage',
  'AugmentedRealityImage',
  'DevicesList.Image',
  'Image',
  'RealityWorkImage',
  'UseCasesList.Image',
  'seo.metaImage'
]);
export const CompanyGlobalOffice = common(['*', 'CompanyLocations.Image', 'seo.metaImage'], '&sort=id:ASC');
export const ComppanyLocationQuery = common(['*', 'seo.metaImage']);
export const TechnologyIntegratedXrQuery = common([
  '*',
  'CircleImage',
  'RealityList',
  'ServiceList',
  'RealityProcessList',
  'ProductList',
  'ServiceDetailList',
  'IndustyList',
  'seo.metaImage'
]);
export const TechnologyVirtualRealityQuery = common([
  '*',
  'CircleImage',
  'BannerImage',
  'VRTypeList.Image',
  'VRUsecaseList.Image',
  'HealthcareList',
  'HealthcareTypesList.Image',
  'RealityImage',
  'RealityFAQList',
  'HealthcareImage',
  'seo.metaImage'
]);
export const SolutionByIndustryMedia = common([
  '*',
  'Image',
  'TechnologyImage',
  'UseApplicationImage',
  'VrEventImage',
  'ArAdvertisingImage',
  'VrFilmImage',
  'MetaverseImage',
  'RealityList', 
  'seo.metaImage'
]);
export const SolutionByIndustryNaval = common(['*', 'Image', 'TechnologyImage', 'ApplicationList.Image', 'MetaverseImage', 'RealityList', 'seo.metaImage']);
export const SolutionByIndustryPublicSector = common(['*', 'Image', 'ArServiceImage', 'ArApplicationList.Image', 'VrApplicationList.Image', 'seo.metaImage']);
export const SolutionByIndustryRetail = common(['*', 'Image', 'ApplicationList.Image', 'RealityList', 'TechnologyImage', 'seo.metaImage']);
export const SolutionByIndustrySports = common(['*', 'Image', 'SubImage', 'VrList.Image', 'ArSubImage', 'seo.metaImage']);
export const SolutionByProductThreeDFrameWebex = common(['*', 'Logo', 'FeaturesList', 'FeaturesList.Image', 'VideoCover', 'VideoMp4', 'VideoWebm', 'VideoOgg', 'seo.metaImage']);
export const BenefitsQuery = common(['*', 'CircleImage', 'SubImage', 'BenefitList.Image', 'IncreaseAnalysisList.Image', 'IncreaseAnalysisImage', 'seo.metaImage']);
export const SiteMapQuery = common(['*', 'BusinessIcons.Image', 'seo.metaImage']);
export const IndustryAerospaceQuery = common(['*', 'Image', 'GlanceList', 'GlanceList.Image', 'ApplicationsList', 'ApplicationsList.Image', 'AerospaceIndustryImage', 'seo.metaImage']);
export const IndustriesArchitectureQuery = common(['*', 'Image', 'RealityListArchitecture', 'VisualizationList', 'VisualizationList.Image', 'ToolsList', 'ToolsList.Image', 'seo.metaImage']);
export const IndustriesAutomotiveQuery = common(['*', 'Image', 'ImmersiveTechImage', 'RealityList', 'BenefitsOfImmersiveImage', 'VrImage', 'DesignImage', 'TrainingImage', 'seo.metaImage']);
export const IndustriesBettingQuery = common(['*', 'Image', 'BettingRealityList', 'RoleList', 'RoleList.Image', 'NeedToImage', 'seo.metaImage']);
export const IndustryDefenceQuery = common(['*', 'Image', 'DefenceRealityList', 'ExplainedImage', 'ApplicationsImage', 'DefenceApplicationsList', 'DefenceApplicationsList.Image', 'seo.metaImage']);
export const IndustryFurnitureQuery = common(['*', 'Image', 'ImmersiveImage', 'FurnitureRealityList', 'UsesImage', 'UsesList', 'UsesList.Image', 'seo.metaImage']);
export const IndustryMachineryQuery = common(['*', 'Image', 'RealityImage', 'MachineryRealityList', 'UsesCasesList', 'UsesCasesList.Image', 'seo.metaImage']);
export const IndustryHealthcareQuery = common([
  '*',
  'Image',
  'ImmersiveImage',
  'HealthcareApplicationsARList',
  'HealthcareApplicationsARList.Image',
  'VRExplainedImage',
  'HealthcareRealityList',
  'HealthcareApplicationsVRList',
  'HealthcareApplicationsVRList.Image', 
  'seo.metaImage'
]);
export const ContactUsQuery = common(['*', 'BusinessIcons.Image', 'seo.metaImage']);
export const BlogInnerQuery = common(['*', 'Image', 'ChallengesFeaturesBenefitsList', 'ContentWebexList', 'seo.metaImage']);
export const CaseStudyInnerQuery = common(['*', 'Image', 'ChallengesFeaturesBenefitsList', 'ContentWebexList', 'seo.metaImage'], '&sort[0]=updatedAt:DESC');
export const SuccessStoryInnerQuery = common(['*', 'Image', 'ChallengesFeaturesBenefitsList', 'ContentWebexList', 'seo.metaImage'], '&sort[0]=updatedAt:DESC');

export const SolutionByIndustryFashion = common(['*', 'Image', 'SubImage', 'Innovations.Image', 'Benefits', 'ARImage', 'FashionList.Image', 'seo.metaImage']);
export const SolutionByIndustryEducation = common([
  '*',
  'Image',
  'VRImage',
  'RoleVRImage',
  'RoleVRSubImage',
  'ARImage',
  'HowARImage',
  'BenefitsARImage',
  'BefefitsList',
  'HowARList',
  'seo.metaImage'
]);
export const InvestorCenterADR = common([
    '*',
    'Image',
    'InvestorGlanceList',
    'ContactsForInvestorsList',
    'ContactsForRegisteredList',
    'AdrFaqList',
    'seo.metaImage'
  ]);
