import React,{ useEffect } from "react";
import $ from "jquery";
import useFetch from "../../../hooks/useFetch"
import Loader from '../../common/Loader';
import Image from "../../common/Image";
import IndustryLayout from "./IndustryLayout"
import { IndustryMachineryQuery } from "../../../Queries";
import ReactMarkdown from "react-markdown";
import SEO from './../../common/SEO';

export default function IndustryMachineryManufacturing() {
    useEffect(() => {
        var windowWidth = $(window).width();
        var container = $('.container').width();

        var calcWidth = windowWidth - container;

        $('.art').css('right', - (calcWidth / 2));
    },[])
    
    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/industry-machinery-manufacturing?' + IndustryMachineryQuery);
    
    if (loading) {
        return <Loader />;
    }
    const { attributes } = response ? response.data : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null
    return (<IndustryLayout>
            <SEO meta={seoMeta}></SEO>
         <section className="entry-section">
        <div className="row">
            <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="col-sm-12">
                <div className="entry-display-1">
                    <p className="sub-heading text-primary fw-semibold">MACHINERY MANUFACTURING</p>
                <h1>{ attributes.Title ? attributes.Title : ''}</h1>
                <div className="spacer-xs-4 spacer-xl-6"></div>
                <div className="entry-thumb img-overlay">
                <Image src={attributes && attributes.Image ? attributes.Image.data.attributes.url : ''} className="img-fluid" />
                </div>
                <div className="spacer-xs-4 spacer-xl-6"></div>
                 <ReactMarkdown children={attributes.Description ? attributes.Description : ''}/>
                </div>
            </div>
        </div>
        <div className="spacer-xs-8 spacer-xl-12"></div>
    </section>
    <section className="entry-structure-light">
        <div className="spacer-xs-8 spacer-xl-10"></div>
        <div className="row">
            <div className="col-sm-12">
                <div className="entry-display-3">
                <h3>{attributes.RealityTitle ? attributes.RealityTitle : ''}</h3>
                <div className="spacer-xs-1"></div>
                <p>{attributes.RealityDescription ? attributes.RealityDescription : ''}</p>
                <div className="spacer-xs-4 spacer-xl-6"></div>
                <div className="entry-thumb img-overlay">
                <Image src={attributes && attributes.RealityImage ? attributes.RealityImage.data.attributes.url : ''} className="img-fluid" />
                </div>
                <div className="spacer-xs-4 spacer-xl-6"></div>
                <div className="accordion entry-accordian" id={'accordion0'}>
                    {attributes.MachineryRealityList && attributes.MachineryRealityList.map((item,index) => {
                           return (
                            <div className="accordion-item" key={index}>
                            <h2 className="accordion-header" id={'heading' + index}>
                            <button
                            className={"accordion-button " + (index == 0 ? "" : "collapsed")}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={'#collapse' + index}
                                aria-expanded={true}
                                aria-controls={'collapse' + index}
                            >
                                {item.Title}
                            </button>
                            </h2>
                            <div id={'collapse' + index}  className={"accordion-collapse collapse " + (index == 0 ? "show" : "")} aria-labelledby={'heading' + index} data-bs-parent={'#accordion0'}>
                            <div className="accordion-body">{item.Description}</div>
                            </div>
                        </div>
                        )
                    })}
                </div>
                </div>
            </div>
        </div>
        <div className="spacer-xs-8 spacer-xl-10"></div>
    </section>
    <section className="entry-structure">
        <div className="spacer-xs-8 spacer-xl-12"></div>
        <div className="row">
            <div className="col-sm-12">
                <div className="entry-display-2">
                <h2>{attributes.UsesCasesTitle ?attributes.UsesCasesTitle : ''}</h2>
                <div className="spacer-xs-1"></div>
                <p>{attributes.UsesCasesDescription ? attributes.UsesCasesDescription : ''}</p>
                </div>
            </div>
        </div>
        <div className="spacer-xs-6 spacer-xl-8"></div>
        {attributes.UsesCasesList && attributes.UsesCasesList.map((item,index) => {
            return (
                <React.Fragment key={index}>
                <div className="row gx-6 block-card block-card-md">
                    <div className={(index % 2) == 0 ? "col-lg-6 order-lg-1 order-2" : "col-lg-6 order-lg-2 order-1"}>
                        <div className="entry-display-3">
                            <h3>{item.Title}</h3>
                                <div className="spacer-xs-1"></div>
                                <ReactMarkdown className="reactMarkdown" children={item.Description} components={{
                                    a: ({ children, href }) => <a href={href} className='sub-link' target='_blank'>{children[0]}</a>
                                }} />
                                </div>
                            </div>
                            <div className={(index % 2) == 0 ? "col-lg-6 order-lg-2 order-1" : "col-lg-6 order-lg-1 order-2"}>
                                <div className="entry-thumb img-overlay">
                                <Image src={item && item.Image ? item.Image.data.attributes.url : ''} className="img-fluid" />
                                </div>
                            </div>
                        </div>
                        <div className="spacer-xs-8 spacer-xl-12"></div>
                </React.Fragment>
            )
        })}
    </section>
    <section className="entry-structure-light report-card">
        <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
                <div className="col-md-12">
                    <div className="entry-display-3">
                        <h3>{attributes.TakeawayTitle ? attributes.TakeawayTitle : ''}</h3>
                        <div className="spacer-xs-1"></div>
                        <ReactMarkdown children={attributes.TakeawayDescription ? attributes.TakeawayDescription : ''}/>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-8 spacer-xl-10"></div>
    </section>
    </IndustryLayout>)
}