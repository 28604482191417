import { Link } from 'react-router-dom';
import CustomLink from '../common/CustomLink';
import Image from '../common/Image';

export default function ByNeedCard(props) {
    const { Heading, ListItem } = props;
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-2 text-center text-md-start">
                            <h2>{Heading}</h2>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                        </div>
                    </div>
                </div>
                <div className="row gx-6">
                    {ListItem.map((item, index) => {
                        return (
                            <div key={index} className="col-sm-6 mb-4">
                                <div className="card mb-3 bg-transparent border-0">
                                    <div className="row g-0">
                                        <div className="col-md-auto d-flex justify-content-center">
                                            <div className={item && item.IconSlug ? "entry-icon mb-4 " + item.IconColor : "entry-icon color-1 mb-4"}>
                                                <span><i className={item && item.IconSlug ? "icon-need " + item.IconSlug : "icon-need icon-design-review"}></i></span>
                                            </div>
                                        </div>
                                        <div className="col-md-10">
                                            <div className="entry-display-5 ps-0 ps-md-6 text-center text-md-start">
                                                <h5>{item && item.Title ? item.Title : ''}<i className="icon-comman icon-right"></i></h5>
                                                <p>{item && item.Description ? item.Description : ''}</p>
                                                <Link reloadDocument={true} to={item && item.Link ? item.Link : '#'} className="stretched-link"></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>
        </>
    );
}
