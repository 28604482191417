import LearnMore from '../common/LearnMore';
import { StockPriceQuery } from '../../Queries';
import useFetch from '../../hooks/useFetch';
import Layout from '../Layout';
import Loader from '../common/Loader';
import Breadcrumb from '../common/Breadcrumb';
import Links from './Links';
import StockChart from './StockChart';
import icBgImg from '../../images/ic-bg.png';
import moment from 'moment';
import SEO from './../common/SEO';

export default function StockPricePage() {
  const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/investor-stock-price?' + StockPriceQuery);
  if (loading) {
    return <Loader />;
  }

  const { data } = response ? response : [];
  const { attributes } = data ? data : [];
  const seoMeta = (attributes.seo) ? attributes.seo : null

  let breadcrumbLinks = [
    { 'link': '/', 'displayName': 'Home' },
    { 'link': '/investor-center', 'displayName': 'Investment Center' },
    { 'link': '/investor-center/stock-price', 'displayName': 'Stock Price' }
  ];

  return (
    <Layout>
      <SEO meta={seoMeta}></SEO>
      <main className="page-wrapper">
        <section className="banner-display-4 text-center text-lg-start" style={{ backgroundImage: `url(${icBgImg})` }}>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="entry-display-1">
                  <h1>{attributes.Title}</h1>
                  <div className="spacer-xs-1"></div>
                  <div aria-label="breadcrumb">
                    <Breadcrumb breadcrumbLinks={breadcrumbLinks} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <aside className="drawer-mobile sidebar-content d-flex d-xl-none">
          <div className="offcanvas offcanvas-start" tabIndex="-1" id="openCanvas" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-body handler-menu">
              <div className="spacer-xs-3"></div>
              <div className="sidebartitle">
                <h2>Investor Center</h2>
              </div>
              <Links />
            </div>
          </div>
        </aside>
        <section className="handler-menu d-none d-xl-block">
          <div className="spacer-xs-3"></div>
          <Links />
          <div className="spacer-xs-3"></div>
        </section>
        <section className="stock-price">
          <div className="container">
            <div className="spacer-xs-8 spacer-lg-10"></div>
            <div className="row justify-content-center stock-chart-container">
              <div className="col-sm-12">
                <div className="entry-display-2 text-center">
                  <h2>{attributes.Title}</h2>
                  <p>{attributes.Description}</p>
                </div>
                <div className="spacer-xs-6 spacer-lg-10"></div>
              </div>

              <StockChart />
            </div>
          </div>
        </section>
        <LearnMore />
      </main>
    </Layout>
  );
}
