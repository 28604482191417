import { useEffect } from "react";
import $ from "jquery";
import useFetch from "../../../hooks/useFetch"
import Loader from '../../common/Loader';
import IndustryLayout from "./IndustryLayout";
import { SolutionByIndustryRetail } from "../../../Queries"
import Image from '../../common/Image';
import RealityCard from './RealityCard';
import ReactMarkdown from 'react-markdown';
import ApplicationCard from './ApplicationCard';
import SEO from './../../common/SEO';

export default function IndustryRetail() {
    useEffect(() => {
        var windowWidth = $(window).width();
        var container = $('.container').width();

        var calcWidth = windowWidth - container;

        $('.art').css('right', - (calcWidth / 2));
    }, [])

    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/industries-retail?' + SolutionByIndustryRetail);

    if (loading) {
        return <Loader />;
    }
    const { attributes } = response ? response.data : [];
    const { RealityList } = attributes ? attributes : [];
    const { ApplicationList } = attributes ? attributes : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null
    return (
        <IndustryLayout>
            <SEO meta={seoMeta}></SEO>
            <section className="entry-section">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-1">
                            <p className="sub-heading text-primary fw-semibold">RETAIL</p>
                            <h1>{attributes && attributes.Title ? attributes.Title : ''}</h1>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes && attributes.Description ? attributes.Description : ''}</p>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <div className="entry-thumb">
                                <Image src={attributes && attributes.Image ? attributes.Image.data.attributes.url : ''} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-12"></div>
            </section>
            <section className="entry-structure-light">
                <div className="spacer-xs-4 spacer-xl-6"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3">
                            <div className="accordion entry-accordian" id="retialaccordion">
                                {RealityList.map((item, index) => {
                                    return <RealityCard itemIndex={index} item={item} />;
                                })}
                            </div>
                            <div className="spacer-xs-6 spacer-xl-8"></div>
                            <div className="entry-thumb">
                                <Image src={attributes && attributes.TechnologyImage ? attributes.TechnologyImage.data.attributes.url : ''} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-10"></div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-2">
                            <h2>{attributes && attributes.ApplicationTitle ? attributes.ApplicationTitle : ''}</h2>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes && attributes.ApplicationDescription ? attributes.ApplicationDescription : ''}</p>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-6 spacer-xl-8"></div>
                {ApplicationList.map((item, index) => {
                    return <ApplicationCard key={'feature-card-2-' + index} lastItem={index === 4} itemIndex={index} item={item} page="public-sector" />;
                })}
            </section>
            <section className="entry-structure-light">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3">
                            <h3>{attributes && attributes.TakeAwayTitle ? attributes.TakeAwayTitle : ''} </h3>
                            <ReactMarkdown className="reactMarkdown" children={attributes.TakeAwayDescription} />
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-10"></div>
            </section>
        </IndustryLayout>
    )
}