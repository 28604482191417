import useFetch from '../../../hooks/useFetch';
import Layout from '../../Layout';
import { ThreeDModelServicesQuery } from '../../../Queries';
import Image from '../../common/Image';
import LearnMore from '../../common/LearnMore';
import SideMenu from '../ProfessionalServices/sidemenu';
import ServiceFeatureCard from './ServiceFeatureCard';
import { Link } from 'react-router-dom';
import Loader from '../../common/Loader';
import { Pagination, EffectFade, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import ReactMarkdown from 'react-markdown';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import React from 'react';
import SEO from '../../common/SEO';

export default function ProfessionalServicesModeling(props) {
    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/support-3dmodeling?' + ThreeDModelServicesQuery);
    if (loading) {
        return <Loader />;
    }
    const { data } = response ? response : [];
    const { attributes } = data ? data : [];
    const { ExampleList } = attributes ? attributes : [];
    const { FeatureList } = attributes ? attributes : [];
    const { RenderImage } = attributes ? attributes : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null
    return (
        <Layout>
            <SEO meta={seoMeta}></SEO>
            <main className="page-wrapper">
                <section className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="page-content">

                                <aside className="sidebar sidebar-content sticky-top d-none d-xl-flex">
                                    <SideMenu />
                                </aside>
                                <aside className="drawer-mobile sidebar-content d-flex d-xl-none">
                                    <div className="offcanvas offcanvas-start" tabIndex="-1" id="openCanvas" aria-labelledby="offcanvasExampleLabel">
                                        <div className="offcanvas-body">
                                            <div className="spacer-xs-3"></div>
                                            <SideMenu />
                                        </div>
                                    </div>
                                </aside>
                                <section className="main-content">
                                    <section className="banner banner-display-2">
                                        <div className="row">
                                            <div className="spacer-xs-8 spacer-xl-12"></div>
                                            <div className="entry-display-1 width-xxl-50 width-xxxl-68 order-2 order-xl-1 text-xl-start text-center">
                                                <h1>{attributes && attributes.Title ? attributes.Title : ''}</h1>
                                                <div className="spacer-xs-1"></div>
                                                <p>{attributes && attributes.Description ? attributes.Description : ''}</p>
                                            </div>
                                            <div className="entry-thumb d-flex flex-column flex-xl-row justify-content-center justify-content-xl-end align-items-center position-absolute order-1 order-xl-2">
                                                <div className="circle-bg d-none d-xl-block"></div>
                                                <div className="spacer-xs-5 order-1 d-flex d-xl-none"></div>
                                                <div className="entry-image img-overlay">
                                                    <Image src={attributes && attributes.CircleImage.data.attributes.url ? attributes.CircleImage.data.attributes.url : ''} className="img-fluid" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="spacer-xs-8 spacer-xl-12"></div>
                                    <section className="entry-structure">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="entry-display-1">
                                                    <div className="entry-thumb img-overlay">
                                                        <Image src={attributes && attributes.BannerImage.data.attributes.url ? attributes.BannerImage.data.attributes.url : ''} className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="spacer-xs-8 spacer-xl-12"></div>
                                    </section>
                                    <section className="entry-structure-light">
                                        <div className="spacer-xs-8 spacer-xl-10"></div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="entry-display-2">
                                                    <h2>{attributes && attributes.SubTitle ? attributes.SubTitle : ''}</h2>
                                                    <div className="spacer-xs-1"></div>
                                                    <p>{attributes && attributes.SubDescription ? attributes.SubDescription : ''}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="spacer-xs-8 spacer-xl-10"></div>
                                    </section>
                                    <section className="entry-structure">
                                        <div className="spacer-xs-8 spacer-xl-12"></div>
                                        <div className="row gx-6 block-card block-card-md">
                                            <div className="width-lg-54 order-lg-1 order-2">
                                                <div className="entry-display-3">
                                                    <h3>{attributes && attributes.UsecaseTitle ? attributes.UsecaseTitle : ''}</h3>
                                                    <div className="spacer-xs-1"></div>
                                                    <ReactMarkdown className="reactMarkdown" children={attributes.UsecaseDescription} />
                                                    <div className="spacer-xs-3"></div>
                                                </div>
                                            </div>
                                            <div className="width-lg-46 order-lg-2 order-1 mb-4 mb-xl-0">
                                                <div className="entry-thumb img-overlay">
                                                    <Image src={attributes && attributes.UsecaseImage.data.attributes.url ? attributes.UsecaseImage.data.attributes.url : ''} className="img-fluid" />
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="spacer-xs-4 spacer-xl-6"></div> */}
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="entry-display-4 bg-primary px-4 py-3 rounded-2 d-flex align-items-center flex-lg-row flex-column">
                                                    <h4 className="text-white fw-regular fs-lg-22 flex-1 pe-lg-4 mb-3 mb-lg-0 text-center text-lg-start">
                                                        {attributes && attributes.UsecaseQuote ? attributes.UsecaseQuote : ''}
                                                    </h4>
                                                    <Link reloadDocument={true} to={attributes && attributes.UsecaseQuoteLink ? attributes.UsecaseQuoteLink : '#'} className="btn btn-white btn-sm text-primary px-5 text-nowrap">Learn More</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="spacer-xs-8 spacer-xl-12"></div>
                                    <section className="entry-structure-light">
                                        <div className="spacer-xs-8 spacer-xl-10"></div>
                                        {ExampleList &&
                                            ExampleList.map((itemModelEx, indexModelEx) => {
                                                return (
                                                    <React.Fragment key={indexModelEx}>
                                                        <div className="row" key={indexModelEx}>
                                                            <div key={"ex-" + indexModelEx} className="col-sm-12">
                                                                <div className="entry-display-3">
                                                                    <h3>{itemModelEx && itemModelEx.Title ? itemModelEx.Title : ''} </h3>
                                                                    <div className="spacer-xs-1"></div>
                                                                    <p>{itemModelEx && itemModelEx.Description ? itemModelEx.Description : ''}</p>
                                                                    <div className="spacer-xs-4 spacer-xl-6"></div>
                                                                    <div className="entry-thumb img-overlay">
                                                                        <Image src={itemModelEx && itemModelEx.Image.data.attributes.url ? itemModelEx.Image.data.attributes.url : ''} className="img-fluid" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {indexModelEx < ExampleList.length-1 ? <div className="spacer-xs-8 spacer-xl-12"></div> : <div className="spacer-xs-8 spacer-xl-10"></div>}
                                                    </React.Fragment>
                                                )
                                            })}
                                    </section>
                                    <section className="entry-structure">
                                        <div className="spacer-xs-8 spacer-xl-12"></div>
                                        {FeatureList.map((itemSolution, indexSolution) => {
                                            return <ServiceFeatureCard key={'feature-card-2-' + indexSolution} lastItem={indexSolution === (FeatureList.length - 1)} itemIndex={indexSolution} item={itemSolution} />;
                                        })}
                                    </section>
                                    <section className="entry-structure-light">
                                        <div className="spacer-xs-8 spacer-xl-12"></div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="entry-display-3">
                                                    <h3>{attributes && attributes.RenderTitle ? attributes.RenderTitle : ''}</h3>
                                                    <div className="spacer-xs-1"></div>
                                                    <p>{attributes && attributes.RenderDescription ? attributes.RenderDescription : ''}</p>
                                                    <div className="spacer-xs-4 spacer-xl-6"></div>
                                                    <Swiper
                                                        spaceBetween={30}
                                                        slidesPerView={1}
                                                        effect="fade"
                                                        speed={5000}
                                                        loop={true}
                                                        onSlideChange={() => console.log('slide change')}
                                                        onSwiper={(swiper) => console.log(swiper)}
                                                        modules={[Pagination, EffectFade, Autoplay]}
                                                        pagination={{
                                                            el: '.swiper-pagination',
                                                            clickable: true
                                                        }}
                                                        autoplay={{
                                                            delay: 1,
                                                        }}
                                                        fadeEffect={{
                                                            crossFade: true,
                                                        }}
                                                    >
                                                        {RenderImage &&
                                                            RenderImage.data.map((itemImg, indexImg) => {
                                                                return (
                                                                    <SwiperSlide key={indexImg}>
                                                                        <div className="swiper-slide">
                                                                            <div className="entry-thumb img-overlay">
                                                                                <Image src={itemImg && itemImg.attributes.url ? itemImg.attributes.url : ''} className="img-fluid rounded-2" />
                                                                            </div>
                                                                        </div>
                                                                    </SwiperSlide>
                                                                )
                                                            })}
                                                        <div className="swiper-pagination"></div>
                                                    </Swiper>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="spacer-xs-8 spacer-xl-12"></div>
                                    </section>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
                <LearnMore />
            </main>
        </Layout>
    );
}
