import { BenefitsQuery } from '../Queries';

export const apiBenefit = {
  needs(slug, cb, errorCb) {
    let url = process.env.REACT_APP_CMS_URL + '/api/benefits/?filters[slug][$eq]=' + slug + '&' + BenefitsQuery;

    fetch(url, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}`
      }
    })
      .then(response => {
        return response.json();
      })
      .then(
        result => {
          if (result) {
          
            const { data } = result ? result : [];
            const { attributes } = data[0] ? data[0] : [];
            const { BenefitList } = attributes ? attributes : [];
            const { IncreaseAnalysisList } = attributes ? attributes : [];
            cb({ attributes, BenefitList, IncreaseAnalysisList });
          } else {
            errorCb(result.errors ? result.errors : { message: 'Could not fetch data.Try again!' });
          }
        },
        error => {
          console.log('error:', error);
          errorCb(error.errors ? error.errors : { message: 'Could not fetch data.Try again!' });
        }
      )
      .catch(error => {
        console.log('error:', error);
        errorCb({ message: 'Could not fetch data.Try again!' });
      });
  }
};
