export default function IndustryList({ industryList }){
    return(
        industryList.map((industry , index) => {
            return(
                <div className="col-sm-6 mb-4" key={index}>
                <div className="card mb-3 bg-transparent border-0">
                    <div className="row g-0">
                    <div className="col-md-auto d-flex justify-content-center">
                        <div className={"entry-icon mb-4 " + industry.IconColor}>
                            <span><i className={"icon-industries "+ industry.IconSlug}></i></span> 
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="entry-display-5 ps-0 ps-md-6 text-center text-md-start">
                            <h5>{industry.Title}<i className="icon-comman icon-right text-white"></i></h5>
                            <p>{industry.Description}</p>
                            <a className="stretched-link"></a>
                            <div className="spacer-lg-1"></div>
                            <div className="divider-bottom d-none d-lg-flex"></div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            )
        })
    )
}