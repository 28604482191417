import React from 'react';
import logo from '../../images/vection-logo.svg';
import { Link, useLocation,useParams } from 'react-router-dom';
import Image from './Image';
import HeaderCoreMenu from './HeaderCoreMenu';
import HeaderMobileMenu from './HeaderMobileMenu';

export default function StaticHeader() {
  const { pathname } = useLocation();
  let { id } = useParams();
  const pageWithSideBar =  ["/solutions/products/", "/solutions/Industry/", "/support/services/", "/success-stories" , "/blog", "/case-studies", "/support/technology/", "/company/vection-technologies","/company/our-story","/company/our-vision","/company/global-offices" , "/investor-center/"];

  let hasSideBar = pageWithSideBar.filter((item) => {
    if(pathname == `/blog/${id}`){
        return false;
    }
    return pathname.indexOf(item) >= 0 ? true : false;
  });
  
  return (
    <>
      <div className="main-header">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="top-bar d-none d-xl-flex">
                <Link reloadDocument={true} to="/blog" className="link blog">
                  <i className="icon-comman icon-chat me-2"></i> Blog
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container px-0 px-xl-2">
          <nav className="navbar navbar-expand-xl navbar-light align-self-stretch">
            <div className="nav-left d-flex align-items-center align-self-stretch">
              { hasSideBar.length ? (
                <a
                href="#openCanvas"
                data-bs-toggle="offcanvas"
                role="button"
                aria-controls="offcanvasExample"
                className="sidebar-menu-icon d-flex align-items-center justify-content-center bg-grey-dark align-self-stretch d-block d-xl-none"
              >
                <i className="icon-comman icon-arrow-right px-4 fs-lg-18 text-white"></i>
              </a>
              ) : (
                <></>
              )}
              <a className="navbar-brand ms-3 ms-xl-0" href="/">
                <Image src={logo} className="img-fuild" />
              </a>
            </div>

            <HeaderCoreMenu />

            <Link reloadDocument={true} to="/contact-us" className="btn btn-primary btn-sm text-white icon-btn d-none d-xl-flex">
              <i className="icon-comman icon-envelope-fill me-2"></i>Contact Us
            </Link>
          </nav>
        </div>
      </div>

      <HeaderMobileMenu />
    </>
  );
}
