import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Breadcrumb from '../../common/Breadcrumb';
import Image from '../../common/Image';
import LearnMore from '../../common/LearnMore';
import Layout from '../../Layout';
import ChallengeCard from './ChallengeCard';
import FaqAccordian from './FaqAccordian';
import { SolutionByNeedQuery } from '../../../Queries';
import useFetch from '../../../hooks/useFetch';
import Loader from '../../common/Loader';
import { api } from '../../../services/api';

export default function ByNeedLayoutPage(props) {
  const { need } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [attributes, setAttributes] = useState([]);
  const [ChallengeList, setChallengeList] = useState([]);
  const [FaqList, setFaqList] = useState([]);
  const [prevNeedSlug, setPrevNeedSlug] = useState(need);

  useEffect(() => {
    setIsLoading(true);
    api.needs(
      need,
      response => {
        const { attributes, ChallengeList, FaqList } = response ? response : [];
        setAttributes(attributes);
        setChallengeList(ChallengeList);
        setFaqList(FaqList);
        setIsLoading(false);
      },
      error => {
        console.log('error', error);
      }
    );
  }, [need]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Layout>
      <main className="page-wrapper">
        <section className="banner-display-1">
          <div className="container">
            <div className="spacer-xs-5 spacer-xl-13"></div>
            <div className="row align-items-center">
              <div className="entry-display-1 width-xl-55 order-xl-1 order-2 text-center text-xl-start">
                <h1>{attributes && attributes.Title ? attributes.Title : ''}</h1>
                <div className="spacer-xs-1"></div>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb d-flex justify-content-center justify-content-xl-start mb-0">
                    <li className="breadcrumb-item">
                      <Link reloadDocument={true} to="#">Home</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link reloadDocument={true} to="/solutions">Solutions</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link reloadDocument={true} to="/solutions-need-overview">Needs</Link>
                    </li>
                    {need == 'design-review' && (
                      <li className="breadcrumb-item active" aria-current="page">
                        Design Review
                      </li>
                    )}
                    {need == 'training' && (
                      <li className="breadcrumb-item active" aria-current="page">
                        Training
                      </li>
                    )}
                    {need == 'remote-presentations' && (
                      <li className="breadcrumb-item active" aria-current="page">
                        Remote Presentations
                      </li>
                    )}
                    {need == 'retail-experience' && (
                      <li className="breadcrumb-item active" aria-current="page">
                        Retail 3D Experience
                      </li>
                    )}
                    {need == 'ecommerce' && (
                      <li className="breadcrumb-item active" aria-current="page">
                        3D E-commerce
                      </li>
                    )}
                  </ol>
                </nav>
                <div className="spacer-xs-6 spacer-lg-0"></div>
              </div>
              <div className="entry-thumb width-xl-45 d-flex flex-column flex-xl-row justify-content-center justify-content-xl-end align-items-center order-xl-2 order-1">
                <div className="circle-bg d-none d-xl-block"></div>
                <div className="spacer-xs-5 order-1 d-flex d-xl-none"></div>
                <div className="entry-image img-overlay">
                  <Image src={attributes && attributes.Image.data.attributes.url ? attributes.Image.data.attributes.url : ''} className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <div className="spacer-xs-2 spacer-lg-4 spacer-xl-13"></div>
        </section>
        <section className="element-background">
          <div className="container">
            <div className="spacer-xs-8 spacer-lg-12"></div>
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="entry-display-1 text-center">
                  <h1 className="text-white">{attributes && attributes.SubTitle ? attributes.SubTitle : ''}</h1>
                  <div className="spacer-xs-1"></div>
                  <p className="text-ultra-light">{attributes && attributes.Description ? attributes.Description : ''}</p>
                </div>
              </div>
            </div>
            <div className="spacer-xs-8 spacer-lg-12"></div>
          </div>
        </section>
        <section className="entry-structure">
          <div className="container">
            <div className="spacer-xs-8 spacer-lg-12"></div>
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="entry-display-1 text-center">
                  <h1>{attributes && attributes.ChallengeTitle ? attributes.ChallengeTitle : ''}</h1>
                  <div className="spacer-xs-4 spacer-lg-12"></div>
                </div>
              </div>
            </div>
            {ChallengeList &&
              ChallengeList.map((item, index) => {
                return <ChallengeCard key={'challenge-item-' + index} {...item} itemIndex={index} />;
              })}
          </div>
        </section>
        <section className="faq">
          <div className="container">
            <div className="spacer-xs-4"></div>
            <div className="row">
              <div className="col-sm-12">
                <div className="entry-display-3">
                  <h3 className="text-center">{attributes && attributes.FaqTitle ? attributes.FaqTitle : ''}</h3>
                  <div className="spacer-xs-6 spacer-lg-10"></div>
                  <FaqAccordian faq={FaqList} />
                </div>
              </div>
            </div>
            <div className="spacer-xs-8 spacer-lg-12"></div>
          </div>
        </section>
        <LearnMore />
      </main>
    </Layout>
  );
}
