import LearnMore from '../common/LearnMore';
import useFetch from '../../hooks/useFetch';
import Layout from '../Layout';
import Loader from '../common/Loader';
import Breadcrumb from '../common/Breadcrumb';
import Links from './Links';
import investorImg from '../../images/investor-center-overview.svg';
import icBgImg from '../../images/ic-banner.jpg';
import { Link } from 'react-router-dom';
import SEO from './../common/SEO';

export default function OverviewPage() {
  const { loading, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/investor-overview?populate=*');
  if (loading) {
    return <Loader />;
  }
  const { attributes } = response ? response.data : [];
  const seoMeta = (attributes.seo) ? attributes.seo : null
  let breadcrumbLinks = [
    { 'link': '/', 'displayName': 'Home' },
    { 'link': '/investor-center/overview', 'displayName': 'Investment Center' },
    { 'link': '/investor-center/overview', 'displayName': 'Overview' }
  ];
  return (
    <Layout>
      <SEO meta={seoMeta}></SEO>
      <main className="page-wrapper">
        <section className="banner-display-4 text-center text-lg-start" style={{ backgroundImage: `url(${icBgImg})` }}>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="entry-display-1">
                  <h1>Overview</h1>
                  <div className="spacer-xs-1"></div>
                  <div aria-label="breadcrumb">
                    <Breadcrumb breadcrumbLinks={breadcrumbLinks} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <aside className="drawer-mobile sidebar-content d-flex d-xl-none">
          <div className="offcanvas offcanvas-start" tabIndex="-1" id="openCanvas" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-body handler-menu">
              <div className="spacer-xs-3"></div>
              <div className="sidebartitle">
                <h2>Investor Center</h2>
              </div>
              <Links />
            </div>
          </div>
        </aside>
        <section className="handler-menu d-none d-xl-block">
          <div className="spacer-xs-3"></div>
          <Links />
          <div className="spacer-xs-3"></div>
        </section>

        <section className="entry-structure">
          <div className="container">
            <div className="spacer-xs-8 spacer-lg-12"></div>
            <div className="row gx-6 block-card block-card-lg">
              <div className="col-sm-6 order-lg-1 order-2">
                <div className="entry-display-1">
                  <h1>{attributes.Title}</h1>
                  <div className="spacer-xs-1"></div>
                  <p>{attributes.Description}</p>
                </div>
              </div>
              <div className="col-sm-6 order-lg-2 order-1">
                <div className="entry-thumb mb-4 mb-lg-0 img-overlay">
                  <img src={attributes.Image.data.attributes.url} className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="spacer-xs-8 spacer-lg-12"></div>

        <section className="about-vection element-background">
          <div className="spacer-xs-12 spacer-lg-20"></div>
          <div className="container">
            <div className="row">
              <div className="width-lg-100">
                <div className="entry-display-2">
                  <h2 className="text-white text-center">{attributes.StockTitle}</h2>
                </div>
              </div>
            </div>
          </div>
          <div className="spacer-xs-12 spacer-lg-20"></div>
        </section>
        <div className="spacer-xs-8 spacer-lg-12"></div>
        <section className="investor-glance theme-lite">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="entry-display-2">
                  <h2 className="text-center">{attributes.GlanceTitle}</h2>
                </div>
                <div className="spacer-xs-4 spacer-lg-6"></div>
                <div className="row align-items-start justify-content-center">
                  {attributes.GlanceList.map((glance, index) => {
                    return (
                      <div className="col-sm-3 mb-5 mb-lg-0" key={index}>
                        <div className={'entry-icon d-flex justify-content-center ' + glance.IconColor}>
                          <span>
                            <i className={'icon-investor ' + glance.IconSlug}></i>
                          </span>
                        </div>
                        <div className="spacer-xs-3"></div>
                        <div className="entry-display-5 text-center">
                          <p className="fw-bold">{glance.SubTitle}</p>
                          <h5 className="fw-regular">{glance.Title}</h5>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="spacer-xs-8 spacer-lg-12"></div>
        <section className=" technology-buttons">
          <div className="container">
            <div className="row justify-content-center">
              <div className="width-lg-78">
                <div className="entry-display-3 text-center">
                  <h3>{attributes.TechnologiesTitle}</h3>
                  <div className="spacer-xs-4 spacer-lg-6"></div>
                  <div className="row gx-3">
                    {attributes.TechnologyList.map((tech, index) => {
                      return (
                        <div className="col-sm-4" key={index}>
                          <Link reloadDocument={true} to={tech.Link} className="btn btn-outline-primary border-2 px-1 py-3 w-100 mb-3">
                            {tech.Title}
                          </Link>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="spacer-xs-8 spacer-lg-12"></div>
        <section className="disclaimer bg-lighten-grey">
          <div className="spacer-xs-8 spacer-lg-10"></div>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="entry-display-5">
                  <h5>{attributes.DisclaimerTitle}</h5>
                  <p className="fs-lg-14 mb-0">{attributes.DisclaimerDescription}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="spacer-xs-8 spacer-lg-10"></div>
        </section>
        <LearnMore />
      </main>
    </Layout>
  );
}
