import { Link, useLocation } from 'react-router-dom';

export default function Links(props) {
  const { pathname } = useLocation();

  return (
      <ul>
        <li>
          <Link reloadDocument={true} className={pathname === '/investor-center/overview' || pathname === '/investor-center' ? ' active' : ''} aria-current="page" to={'/investor-center/overview'}>
            Overview
          </Link>
        </li>
        <li>
          <Link reloadDocument={true} className={pathname === '/investor-center/stock-price' ? ' active' : ''} to={'/investor-center/stock-price'}>
            Stock Price
          </Link>
        </li>
        <li>
          <Link reloadDocument={true} className={pathname === '/investor-center/market-announcement' ? ' active' : ''} to={'/investor-center/market-announcement'}>
            Market Announcements
          </Link>
        </li>
        {/* <li>
          <Link reloadDocument={true} className={pathname === '/investor-center/asx-technology' ? ' active' : ''} to={'/investor-center/asx-technology'}>
            S&P ASX All Technology Index
          </Link>
        </li> */}
        <li>
          <Link reloadDocument={true} className={pathname === '/investor-center/adr-program' ? ' active' : ''} to={'/investor-center/adr-program'}>
            ADR Program
          </Link>
        </li>
        <li>
          <Link reloadDocument={true} className={pathname === '/investor-center/media' ? ' active' : ''} to={'/investor-center/media'}>
            Media
          </Link>
        </li>
        <li>
          <Link reloadDocument={true} className={pathname === '/investor-center/faq' ? ' active' : ''} to={'/investor-center/faq'}>
            FAQ
          </Link>
        </li>
        <li>
          <Link reloadDocument={true} className={pathname === '/investor-center/leadership' ? ' active' : ''} to={'/investor-center/leadership'}>
            Leadership & governance
          </Link>
        </li>
      </ul>
  );
}
