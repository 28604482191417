import React from 'react';
import { Link } from 'react-router-dom';

export default function Support(props) {
  const { SupportTitle, SupportDescription, SupportList } = props;

  return (
    <section className="entry-structure theme-lite">
      <div className="container">
        <div className="spacer-xs-8 spacer-md-10 spacer-lg-12"></div>
        <div className="row">
          <div className="col-sm-12">
            <div className="entry-display-1 text-center">
              <h1>{SupportTitle}</h1>
              <p className="text-center">{SupportDescription}</p>
              <div className="spacer-xs-6 spacer-lg-10"></div>
            </div>
          </div>
        </div>

        <div className="row gx-6 justify-content-start">
          {SupportList.map((item, index) => {
            return (
                <div className="col-sm-6 mb-4" key={index}>
                <div className="card mb-3 bg-transparent border-0">
                   <div className="row g-0">
                      <div className="col-md-auto d-flex justify-content-center">
                         <div className={"entry-icon mb-4 " + item.IconColor}>
                            <span><i className={"icon-services " + item.IconSlug}></i></span> 
                         </div>
                      </div>
                      <div className="col-md-10">
                         <div className="entry-display-5 ps-0 ps-md-6 text-center text-md-start">
                            <h5>{item.Title}<i className="icon-comman icon-right"></i></h5>
                            <p>{item.Description}</p>
                            <Link reloadDocument={true} to={item.Link ? item.Link : "#"} className="stretched-link">
                           </Link>
                         </div>
                      </div>
                   </div>
                </div>
             </div>
            );
          })}
        </div>
        <div className="spacer-xs-4 spacer-md-6 spacer-lg-10"></div>
      </div>
    </section>
  );
}
