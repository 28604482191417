import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import HeaderMenuList from '../../utitls/HeaderMenuList';

const hideMenu = () => {
  if (document.body.classList.contains('mobile-menu-open')) {
    document.body.className = document.body.className.replace('mobile-menu-open', '');
  }
};

function MenuLink(props) {
  const { children, ...rest } = props;
  return (
    <Link
      {...rest}
      onClick={() => {
        hideMenu();
      }}
    >
      {children}
    </Link>
  );
}

export default function HeaderMobileMenu() {
  const { pathname } = useLocation();
  const headerMenuList = HeaderMenuList();
  const [swipeClass, setSwipeClass] = useState('');
  const [activeSubMenu, setActiveSubMenu] = useState('');

  const handleSwipe = (e, menuName) => {
    setActiveSubMenu(menuName);
    setSwipeClass('swipe');
  };

  const handleBack = e => {
    setSwipeClass('');
  };

  return (
    <div className={'mobile-menu ' + swipeClass}>
      <nav className="main-navigation d-flex flex-column">
        <ul>
          {headerMenuList &&
            headerMenuList.map((menu, index) => {
              let allLinks = [];
              allLinks.push(menu.link);
              if (menu.subMenu) {
                menu.subMenu.forEach(data => {
                  allLinks.push(data.link);
                  if (data.list) {
                    data.list.forEach(data2 => {
                        if(data2.list){
                            data2.list.forEach(data3 => {
                              allLinks.push(data3.link);
                            });
                        }
                      allLinks.push(data2.link);
                    });
                  }
                  return true;
                });
              }
              if(menu.subSection){
                menu.subSection.forEach(data => {
                    allLinks.push(data.link)
                    if(data.list){
                        data.list.forEach((data2) => {
                            allLinks.push(data2.link)
                        })
                    }
                })
              }
              return (
                <li key={'mobile-main-menu-list-' + menu.name} className="d-flex">
                  {(menu.name === "Solutions" || menu.name === "Support")?
                 <a
                  
                 className={allLinks.includes(pathname) ? 'cursor-pointer active' : 'cursor-pointer'}
                 onClick={(e) => {
                     handleSwipe(e, menu.name)
                   
                 }}
               >
                 <h5 className="mb-0">{menu.name}</h5>
               </a>: <MenuLink
                    to={menu.link}
                    className={allLinks.includes(pathname) ? ' active' : ''}
                    onClick={(e) => {
                   
                        if (!menu.subMenu) hideMenu();
                  
                    }}
                  >
                    <h5 className="mb-0">{menu.name}</h5>
                  </MenuLink>
                  
                  }
                  
                  {menu.subMenu ? <i className="icon-comman icon-right ms-auto text-center m-auto custom-clickable" onClick={e => handleSwipe(e, menu.name)}></i> : <></>}
                </li>
              );
            })}
        </ul>
        <MenuLink to="/contact-us" className="btn btn-primary btn-sm text-white icon-btn justify-content-center mt-auto">
          <i className="icon-comman icon-envelope-fill me-2"></i>
          Contact Us
        </MenuLink>
      </nav>
      <div className="mobile-inner-menu">
        <div className="icon-menu-back">
          <span
            className="custom-clickable "
            onClick={e => {
              handleBack(e);
            }}
          >
            <div className="d-flex align-items-center">
              <i className="icon-comman icon-arrow-right me-2 fs-xs-16 text-primary"></i>
              <p className="mb-0 fw-bold text-primary">Back</p>
            </div>
          </span>
        </div>
        {/*  */}
        {headerMenuList &&
          headerMenuList.map((menu, index) => {
            return (
              <React.Fragment key={'mobile-parent1-' + index + menu.name}>
                {menu.subMenu ? (
                  <div className={'menu-item ' + (activeSubMenu === menu.name ? 'active' : '')} data-category={menu.name} key={'menu-name-' + menu.name + '-' + index}>
                    <div className="spacer-xs-3"></div>
                    <div className="entry-display-4 d-flex align-items-center">
                      <h4 className="mb-0">{menu.name}</h4>
                    </div>

                    {menu.subMenu.map((subMenuItem, subMenuIndex) => {
                      return (
                        <React.Fragment key={'mobile-parent2-' + index + subMenuItem.heading}>
                          <div className="spacer-xs-4"></div>
                          <div className="entry-display-5 d-flex align-items-center">
                            <h5 className="mb-0">{subMenuItem.heading}</h5>
                            {subMenuItem.hideSeeAll === true ? (
                              <></>
                            ) : (
                              <MenuLink to={subMenuItem.link} className="text-primary text-decoration-none ms-auto fs-xs-16 fw-semibold">
                                See All
                              </MenuLink>
                            )}
                          </div>
                          <div className="spacer-xs-1"></div>
                          <nav>
                            <ul>
                              {subMenuItem.list &&
                                subMenuItem.list.map((page, pageIndex) => {
                                  let links = [];
                                  links.push(page.link);
                                  if(page.list){
                                      page.list.forEach((innerMenu) => {
                                        links.push(innerMenu.link)
                                      })
                                  } 
                                  return (
                                    <li key={'sub-menu-list-' + page.name + (subMenuIndex + index)}>
                                      <MenuLink to={page.link} className={(links.includes(pathname)) ? 'active' : ''}>
                                        <p className="mb-0">{page.name}</p>
                                      </MenuLink>
                                    </li>
                                  );
                                })}
                            </ul>
                          </nav>
                        </React.Fragment>
                      );
                    })}
                  </div>
                ) : (
                  <></>
                )}
              </React.Fragment>
            );
          })}
      </div>
    </div>
  );
}
