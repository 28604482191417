export default function ByProductSidebar(props) {
  return (
    <>
      <div className="spacer-xs-3"></div>
      <div className="sidebartitle">
        <h2>Products</h2>
      </div>
      <div className="sidebarmenu custom-scroller">
        <div className="accordion" id="accordionOne">
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingTwo">
              <button
                className={
                  props.activeMenu == 'products-enwork-overview' || props.activeMenu == 'products-enwork-get-started'
                    ? 'accordion-button collapsed active'
                    : 'accordion-button collapsed'
                }
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseTwo"
                aria-expanded="false"
                aria-controls="collapseTwo"
              >
                Enworks
              </button>
            </h2>
            <div id="collapseTwo" 
            className={
                props.activeMenu == 'products-enwork-overview' || props.activeMenu == 'products-enwork-get-started'
                  ? 'accordion-collapse collapse show'
                  : 'accordion-collapse collapse'
              }
            aria-labelledby="headingTwo" data-bs-parent="#accordionOne">
              <div className="accordion-body">
                <ul>
                  <li>
                    <a href="products-enwork-overview" className={props.activeMenu == 'products-enwork-overview' ? 'active' : ''}>
                      <i className="icon-comman icon-right-fill"></i>
                      <h3>Overview</h3>
                    </a>
                  </li>
                  <li>
                    <a href="products-enwork-get-started" className={props.activeMenu == 'products-enwork-get-started' ? 'active' : ''}>
                      <i className="icon-comman icon-right-fill"></i>
                      <h3>Get Started</h3>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingOne">
              <button
                className={
                  props.activeMenu == 'products-mindesk-overview' || props.activeMenu == 'products-mindesk-pricing'
                    ? 'accordion-button collapsed active'
                    : 'accordion-button collapsed'
                }
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseOne"
                aria-expanded="false"
                aria-controls="collapseOne"
              >
                Mindesk
              </button>
            </h2>
            <div id="collapseOne" 
            className={
                props.activeMenu == 'products-mindesk-overview' || props.activeMenu == 'products-mindesk-pricing'
                  ? 'accordion-collapse collapse show'
                  : 'accordion-collapse collapse'
              }
            aria-labelledby="headingOne" data-bs-parent="#accordionOne">
              <div className="accordion-body">
                <ul>
                  <li>
                    <a href="products-mindesk-overview" className={props.activeMenu == 'products-mindesk-overview' ? 'active' : ''}>
                      <i className="icon-comman icon-right-fill"></i>
                      <h3>Overview</h3>
                    </a>
                  </li>
                  <li>
                    <a href="products-mindesk-pricing" className={props.activeMenu == 'products-mindesk-pricing' ? 'active' : ''}>
                      <i className="icon-comman icon-right-fill"></i>
                      <h3>Pricing</h3>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="icon-comman icon-right-fill"></i>
                      <h3>Get Started</h3>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="icon-comman icon-right-fill"></i>
                      <h3>Download</h3>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="headingThree">
              <button
                className={
                  props.activeMenu == 'products-3d-frame-overview' || props.activeMenu == 'products-3d-frame-webex' || props.activeMenu == 'products-3d-frame-pricing'
                    ? 'accordion-button collapsed active'
                    : 'accordion-button collapsed'
                }
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseThree"
                aria-expanded="false"
                aria-controls="collapseThree"
              >
                3DFrame
              </button>
            </h2>
            <div id="collapseThree"
            className={
                props.activeMenu == 'products-3d-frame-overview' || props.activeMenu == 'products-3d-frame-webex' || props.activeMenu == 'products-3d-frame-pricing'
                  ? 'accordion-collapse collapse show'
                  : 'accordion-collapse collapse'
              }
            aria-labelledby="headingThree" data-bs-parent="#accordionOne">
              <div className="accordion-body">
                <ul>
                  <li>
                    <a href="products-3d-frame-overview" className={props.activeMenu == 'products-3d-frame-overview' ? 'active' : ''}>
                      <i className="icon-comman icon-right-fill"></i>
                      <h3>Overview</h3>
                    </a>
                  </li>
                  <li>
                    <a href="products-3d-frame-webex" className={props.activeMenu == 'products-3d-frame-webex' ? 'active' : ''}>
                      <i className="icon-comman icon-right-fill"></i>
                      <h3>For Webex</h3>
                    </a>
                  </li>
                  <li>
                    <a href="products-3d-frame-pricing" className={props.activeMenu == 'products-3d-frame-pricing' ? 'active' : ''}>
                      <i className="icon-comman icon-right-fill"></i>
                      <h3>Pricing</h3>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="icon-comman icon-right-fill"></i>
                      <h3>Get Started</h3>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="icon-comman icon-right-fill"></i>
                      <h3>Download</h3>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFour">
              <button
                className={
                  props.activeMenu == 'products-configurator-overview' || props.activeMenu == 'products-configurator-get-started'
                    ? 'accordion-button collapsed active'
                    : 'accordion-button collapsed'
                }
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFour"
                aria-expanded="false"
                aria-controls="collapseFour"
              >
                3D Configurator
              </button>
            </h2>
            <div id="collapseFour" 
            className={
                props.activeMenu == 'products-configurator-overview' || props.activeMenu == 'products-configurator-get-started'
                  ? 'accordion-collapse collapse show'
                  : 'accordion-collapse collapse'
              }
            aria-labelledby="headingFour" data-bs-parent="#accordionOne">
              <div className="accordion-body">
                <ul>
                  <li>
                    <a href="products-configurator-overview" className={props.activeMenu == 'products-configurator-overview' ? 'active' : ''}>
                      <i className="icon-comman icon-right-fill"></i>
                      <h3>Overview</h3>
                    </a>
                  </li>
                  <li>
                    <a href="products-configurator-get-started" className={props.activeMenu == 'products-configurator-get-started' ? 'active' : ''}>
                      <i className="icon-comman icon-right-fill"></i>
                      <h3>Get Started</h3>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="headingFive">
              <button
                className={
                  props.activeMenu == 'products-xrkiosk-overview' || props.activeMenu == 'products-xrkiosk-get-started'
                    ? 'accordion-button collapsed active'
                    : 'accordion-button collapsed'
                }
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseFive"
                aria-expanded="false"
                aria-controls="collapseFive"
              >
                XRKiosk
              </button>
            </h2>
            <div id="collapseFive"
            className={
                props.activeMenu == 'products-xrkiosk-overview' || props.activeMenu == 'products-xrkiosk-get-started'
                  ? 'accordion-collapse collapse show'
                  : 'accordion-collapse collapse'
              }
            aria-labelledby="headingFive" data-bs-parent="#accordionOne">
              <div className="accordion-body">
                <ul>
                  <li>
                    <a href="products-xrkiosk-overview" className={props.activeMenu == 'products-xrkiosk-overview' ? 'active' : ''}>
                      <i className="icon-comman icon-right-fill"></i>
                      <h3>Overview</h3>
                    </a>
                  </li>
                  <li>
                    <a href="products-xrkiosk-get-started" className={props.activeMenu == 'products-xrkiosk-get-started' ? 'active' : ''}>
                      <i className="icon-comman icon-right-fill"></i>
                      <h3>Get Started</h3>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <a href="contact-us" className="btn btn-primary btn-sm text-white icon-btn ml-xs-4 mr-xs-4 mt-5 d-flex justify-content-center">
          <i className="icon-comman icon-envelope-fill me-2"></i>
          Contact Us
        </a>
      </div>
    </>
  );
}
