import useFetch from '../../hooks/useFetch';
import { Link } from 'react-router-dom';
import CustomIcon from '../common/CustomIcon';

export default function LearnMore() {
  // const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/learn-more');
  // const { data } = response ? response : [];
  // const { title, description, contactLink } = data && data.attributes ? data.attributes : { title: '', description: '', contactLink: '' };

  return (
    <section className="learn-more entry-structure-bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-sm-12">
            <div className="entry-wrapper">
              <div className="entry-display-1">
                <h1 className="text-white">Want to Learn More?</h1>
                <p className="text-ultra-light">Ask about Vection Technologies’ solutions, professional services or anything else. We are ready to help.</p>
                <div className="spacer-xs-4 spacer-lg-4"></div>
                <Link reloadDocument={true} to={'/contact-us'} className="btn btn-primary btn-sm text-white icon-btn">
                  <i className="icon-comman icon-envelope-fill me-2" />
                  Contact Us
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
