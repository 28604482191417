export default function RobustSolutions({ solutionList }){
    return(
        solutionList.map((solution ,index) => {
            return <div className="col-sm-4 d-flex" key={index}>
            <div className="card border-0 bg-transparent py-4">
               <div className={"entry-icon " + solution.IconColor}>
                  <span>
                     <img src={solution.IconSlug}/>
                  </span>
               </div>
               <div className="spacer-xs-3 spacer-lg-4"></div>
               <div className="entry-display-4 text-center flex-grow-1 d-flex flex-column">
                  <h4>{solution.Title}</h4>
                  <p>{solution.Description}</p>
                  <a href={solution.Link} className="d-flex justify-content-center mt-auto stretched-link"><i class="icon-comman icon-arrow-right text-ultra-light fs-xs-18"></i></a>
               </div>
            </div>
         </div>
        })
    )
}