import Layout from "../../Layout";
import LearnMore from "../../common/LearnMore";
import SideMenu from "../../common/SideMenu";

export default function TechnologyLayout(props) {
    const menuItems = [
        {
            name: ' What is Integrated XR?',
            link: '/support/technology/what-is-integratedxr',
        },
        {
            name: 'What is Virtual Reality?',
            link: '/support/technology/what-is-virtual-reality',
        },
        {
            name: 'What is Augmented Reality?',
            link: '/support/technology/What-is-augmented-reality',
        },
        {
            name: 'What is Mixed Reality?',
            link: '/support/technology/What-is-mixed-reality',
        },
        {
            name: 'What is Extended Reality?',
            link: '/support/technology/What-is-extended-reality',
        },
        {
            name: 'What is the Metaverse?',
            link: '/support/technology/What-is-the-metaverse',
        }
    ];
    return (
        <Layout>
            <main className="page-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="page-content">
                                <SideMenu menuHeading="Technologies" menuItems={menuItems} />
                                <section className="main-content">
                                    {props.children}
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <LearnMore />
            </main>
        </Layout>
    )
}