import { useEffect } from "react";
import $ from "jquery";
import useFetch from "../../../hooks/useFetch"
import Loader from '../../common/Loader';
import IndustryLayout from "./IndustryLayout"
import DemoImage from "../../../images/demo-image.png";
import SEO from './../../common/SEO';

export default function IndustryOverview() {
    useEffect(() => {
        var windowWidth = $(window).width();
        var container = $('.container').width();

        var calcWidth = windowWidth - container;

        $('.art').css('right', - (calcWidth / 2));
    },[])
    
    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/industry-overview?populate=*');
    
    if (loading) {
        return <Loader />;
    }
    const { attributes } = response ? response.data : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null
    // console.log(attributes)
    return (<IndustryLayout>
            <SEO meta={seoMeta}></SEO>
        <section className="banner-display-2">
            <div className="row">
                <div className="spacer-xs-6 spacer-xl-12"></div>
                <div className="entry-display-1 width-xxl-50 width-xxxl-68 order-2 order-xl-1 text-xl-start text-center">
                    <p className="sub-heading text-primary fw-semibold">OVERVIEW</p>
                    <h1>{attributes.Title}</h1>
                    <div className="spacer-xs-2"></div>
                    <p>{attributes.Description}</p>
                </div>
                <div className="entry-thumb d-flex flex-column flex-xl-row justify-content-center justify-content-xl-end align-items-center position-absolute order-1 order-xl-2">
                    <div className="circle-bg d-none d-lg-block"></div>
                    <div className="spacer-xs-5 order-1 d-flex d-xl-none"></div>
                    <div className="entry-image img-overlay">
                        <img src={DemoImage} alt="" className="img-fluid" />
                    </div>
                </div>
            </div>
        </section>
        <div className="spacer-xs-8 spacer-xl-12"></div>
        <section className="entry-structure p-0 theme-lite">
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-3 text-md-start text-center">
                        <h3>{attributes.IndustryTitle}</h3>
                        <div className="spacer-xs-4 spacer-xl-6"></div>
                        <div className="row gx-6 justify-content-start">
                        {attributes.IndustryList.map((industry, index) => {
                            return (
                                <div className="col-sm-6 mb-4" key={index}>
                                    <div className="card mb-3 bg-transparent border-0">
                                        <div className="row g-0">
                                            <div className="col-md-auto d-flex justify-content-center">
                                                <div className={"entry-icon  mb-4 " + (industry.IconColor)}>
                                                    <span><i className={"icon-industries "+ (industry.IconSlug)}></i></span>
                                                </div>
                                            </div>
                                            <div className="col-md-10">
                                                <div className="entry-display-5 ps-0 ps-md-6 text-center text-md-start">
                                                    <h5>{industry.Title}<i className="icon-comman icon-right"></i></h5>
                                                    <p>{industry.Description}</p>
                                                    <a href={industry.Link} className="stretched-link"></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-4 spacer-xl-6"></div>
        </section>
        <section className="entry-structure-light technology-buttons">
            <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-3 text-md-start text-center">
                        <h3>{attributes.TechnologyTitle}</h3>
                        <div className="spacer-xs-4 spacer-xl-6"></div>
                        <div className="row gx-3">
                            {attributes.TechnologyList.map((tech , index) => {
                            return(
                                <div className="col-sm-12 col-md-6 col-lg-4">
                                    <a href={tech.Link} className="btn btn-outline-primary border-2 px-1 py-3 w-100 mb-3">{tech.Title}</a>
                                </div>)
                            })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-6 spacer-xl-8"></div>
        </section>
        <section className="product product--theme-lite">
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-3 text-md-start text-center">
                        <h3>{attributes.SolutionTitle} </h3>
                        <div className="spacer-xs-4 spacer-xl-6"></div>
                        <div className="row gx-6">
                        {attributes.SolutionList.map((Solution , index) => {
                            return (
                                <div className="col-sm-4">
                                    <div className="card border-0 bg-transparent py-4">
                                        <div className={"entry-icon " + (Solution.IconColor)}>
                                            <span>
                                                <img src={`/images/home/solutions/logo/${Solution.IconSlug}`} />
                                            </span>
                                        </div>
                                        <div className="spacer-xs-3 spacer-lg-4"></div>
                                        <div className="entry-display-4 text-center">
                                            <h4>{Solution.Title}</h4>
                                            <p>{Solution.Description}</p>
                                            <a href={Solution.Link} className="read-more-arrow d-flex justify-content-center"></a>
                                        </div>
                                    </div>
                                </div>
                                )
                        })}
                        </div>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-6 spacer-xl-8"></div>
        </section>
        <section className="entry-structure-light">
            <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-3 text-md-start text-center">
                        <h3>{attributes.ServiceTitle}</h3>
                        <div className="spacer-xs-4 spacer-xl-6"></div>
                    </div>
                </div>
            </div>
            <div className="row gx-6 justify-content-start theme-lite">
                {attributes.ServiceList.map((service, index) => {
                    return(
                        <div className="col-sm-6 mb-4">
                            <div className="card mb-3 bg-transparent border-0">
                                <div className="row g-0">
                                    <div className="col-md-auto  d-flex justify-content-center">
                                        <div className={"entry-icon mb-4 " + (service.IconColor)}>
                                            <span><i className={"icon-services "+(service.IconSlug)}></i></span>
                                        </div>
                                    </div>
                                    <div className="col-md-10">
                                        <div className="entry-display-5 ps-0 ps-md-6 text-center text-md-start">
                                            <h5>{service.Title}<i className="icon-comman icon-right"></i></h5>
                                            <p>{service.Description}</p>
                                            <a href={service.Link} className="stretched-link"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
                
            </div>
            <div className="spacer-xs-8 spacer-xl-10"></div>
        </section>
    </IndustryLayout>)
}