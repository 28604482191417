import designReview from '../images/header/need/design-review.svg';
import training from '../images/header/need/training.svg';
import remote from '../images/header/need/remote.svg';
import marketing from '../images/header/need/marketing.svg';
import ecommerce from '../images/header/need/ecommerce.svg';
import enwork from '../images/header/product/new/enworks.svg';
import frame from '../images/header/product/new/3d-frame.svg';
import mindesk from '../images/header/product/new/mindesk.svg';
import xrikiosks from '../images/header/product/new/xr-kiosk.svg';
import configurator from '../images/header/product/new/configurator.svg';
import automotive from '../images/header/industry/automotive.svg';
import naval from '../images/header/industry/naval.svg';
import furniture from '../images/header/industry/furniture.svg';
import fashion from '../images/header/industry/fashion.svg';
import education from '../images/header/industry/education.svg';
import rightFill from '../images/svg/right-fill.svg';
import threeDModeling from '../images/header/support/3d-modeling.svg';
import xrCustomization from '../images/header/support/xr-customization.svg';
import metavers from '../images/header/support/metavers.svg';
import ictInfrastructure from '../images/header/support/ict-infrastructure.svg';
import kiosk from '../images/header/support/kiosk.svg';
import blog from '../images/header/support/blog.svg';
import caseStudies from '../images/header/support/case-studies.svg';
import successStories from '../images/header/support/success-stories.svg';

export default function HeaderMenuList() {
  return [
    {
      'name': 'Home',
      'link': '/home'
    },
    {
      'name': 'Solutions',
      'link': '/solutions',
      'subMenu': [
        {
          'heading': 'By Need',
          'link': '/solutions-need-overview',
          'class': 'col-sm-3',
          'list': [
            {
              'name': 'Design Review',
              'icon': designReview,
              'link': '/solutions/needs/design-review'
            },
            {
              'name': 'Training',
              'icon': training,
              'link': '/solutions/needs/training'
            },
            {
              'name': 'Remote Presentations',
              'icon': remote,
              'link': '/solutions/needs/remote-presentations'
            },
            {
              'name': 'Retail 3D Experience',
              'icon': marketing,
              'link': '/solutions/needs/retail-experience'
            },
            {
              'name': '3D E-commerce',
              'icon': ecommerce,
              'link': '/solutions/needs/ecommerce'
            }
          ]
        },
        {
          'heading': 'By Product',
          'link': '/solutions-products-overview',
          'class': 'col-sm-3',
          'list': [
            {
              'name': 'Mindesk',
              'icon': mindesk,
              'link': '/solutions/products/mindesk/overview'
            },
            {
              'name': 'EnWorks',
              'icon': enwork,
              'link': '/solutions/products/enwork/overview',
              'list' : [
                  {
                    'name' : 'EnWorks',
                    'link' : '/solutions/products/enwork/get-started'
                  }
              ]
            },
            {
              'name': '3DFrame',
              'icon': frame,
              'link': '/solutions/products/3d-frame/overview',
              'list' : [
                  {
                      'name' : '3DFrame',
                      'link' : '/solutions/products/3d-frame/webex'
                  },
                  {
                    'name' : '3DFrame',
                    'link' : '/solutions/products/3d-frame/pricing'
                  },
                  {
                    'name' : '3DFrame',
                    'link' : '/solutions/products/3d-frame/get-started'
                  }
              ]
            },
            {
              'name': 'Configurator',
              'icon': configurator,
              'link': '/solutions/products/configurator/overview',
              'list' : [
                {
                  'name' : 'Configurator',
                  'link' : '/solutions/products/configurator/get-started'
                }
             ]
            },
            {
              'name': 'Xrkiosk',
              'icon': xrikiosks,
              'link': '/solutions/products/xrkiosk/overview',
              'list' : [
                {
                  'name' : 'Xrkiosk',
                  'link' : '/solutions/products/xrkiosk/get-started'
                }
            ]
            }
          ]
        },
        {
          'heading': 'By Industry',
          'link': '/solutions/industries/overview',
          'class': 'col-sm-3',
          'list': [
            {
              'name': 'Automotive',
              'icon': automotive,
              'link': '/solutions/industries/automotive'
            },
            {
              'name': 'Naval',
              'icon': naval,
              'link': '/industries/naval'
            },
            {
              'name': 'Furniture',
              'icon': furniture,
              'link': '/industries/furniture'
            },
            {
              'name': 'Fashion',
              'icon': fashion,
              'link': '/solutions/industries/fashion'
            },
            {
              'name': 'Education',
              'icon': education,
              'link': '/solutions/industries/education'
            }
          ]
        },
        {
          'heading': 'By Integration',
          'link': '#',
          'class': 'col-sm-3 bg-lighten',
          'list': [
            {
              'name': 'Rhino',
              'icon': rightFill,
              'link': '#'
            },
            {
              'name': 'Autodesk Revit',
              'icon': rightFill,
              'link': '#'
            },
            {
              'name': 'Grasshopper',
              'icon': rightFill,
              'link': '#'
            },
            {
              'name': 'Dassault Solidworks',
              'icon': rightFill,
              'link': '#'
            },
            {
              'name': 'Unreal Engine',
              'icon': rightFill,
              'link': '#'
            }
            // {
            //   'name': 'Webex by Cisco',
            //   'icon': rightFill,
            //   'link': '#'
            // }
          ]
        }
      ]
    },
    {
      'name': 'Support',
      'link': '/support/services',
      'subMenu': [
        {
          'heading': 'Services',
          'link': '/support/services',
          'class': 'col-sm-4',
          'list': [
            {
              'name': '3D Modeling & Renderings',
              'icon': threeDModeling,
              'link': '/support/services/3d-modeling-rendering'
            },
            {
              'name': 'XR Customizations & White Labels',
              'icon': xrCustomization,
              'link': '/support/services/xr-customization-white-labels'
            },
            {
              'name': 'Metaverse & Web 3.0',
              'icon': metavers,
              'link': '/support/services/metaverse-web3.0'
            },
            {
              'name': 'ICT Infrastructure',
              'icon': ictInfrastructure,
              'link': '/support/services/ict-infrastructure'
            },
            {
              'name': 'Kiosks',
              'icon': kiosk,
              'link': '/support/services/kiosks'
            }
          ]
        },
        {
          'heading': 'Knowledge Center',
          'link': '/professional-services',
          'class': 'col-sm-4',
          'hideSeeAll': true,
          'list': [
            {
              'name': 'Blog',
              'icon': blog,
              'link': '/blog',
              'list': [
                {
                  'name': 'Bloh Inner',
                  'link': '/blog/introducing-metaverse-enabled-webex-meetings-the-future-of-online-presentations-1'
                }
              ]
            },
            {
              'name': 'Case Studies',
              'icon': caseStudies,
              'link': '/case-studies'
            },
            {
              'name': 'Success Stories',
              'icon': successStories,
              'link': '/success-stories'
            }
          ]
        },
        {
          'heading': 'Technology',
          'link': '#',
          'class': 'col-sm-4 bg-lighten',
          'hideSeeAll': true,
          'list': [
            {
              'name': 'What is INTEGRATEDXR?',
              'icon': rightFill,
              'link': '/support/technology/what-is-integratedxr'
            },
            {
              'name': 'What is Virtual Reality?',
              'icon': rightFill,
              'link': '/support/technology/what-is-virtual-reality'
            },
            {
              'name': 'What is Augmented Reality?',
              'icon': rightFill,
              'link': '/support/technology/What-is-augmented-reality'
            },
            {
              'name': 'What is Mixed Reality?',
              'icon': rightFill,
              'link': '/support/technology/What-is-mixed-reality'
            },
            {
              'name': 'What is Extended Reality?',
              'icon': rightFill,
              'link': '/support/technology/What-is-extended-reality'
            },
            {
              'name': 'What is the Metaverse?',
              'icon': rightFill,
              'link': '/support/technology/What-is-the-metaverse'
            }
          ]
        }
      ]
    },
    { 'name': 'Company', 'link': '/company/vection-technologies' ,
       'subSection' : [
          {
            'name': 'Our Story',
            'link': '/company/our-story'
          },
          {
            'name': 'Brands that support our vision',
            'link': '/company/our-vision'
          },
          {
            'name': 'Global Offices',
            'link': '/company/global-offices',
            'list': [
                {
                  'link': '/company/global-offices/perth'
                },
                {
                  'link': '/company/global-offices/sydney'
                },
                {
                  'link': '/company/global-offices/ahmedabad'
                },
                {
                  'link': '/company/global-offices/milano'
                },
                {
                  'link': '/company/global-offices/bologna'
                },
                {
                  'link': '/company/global-offices/rome'
                },
                {
                  'link': '/company/global-offices/bari'
                },
                {
                  'link': '/company/global-offices/abu-dhabi'
                },
                {
                  'link': '/company/global-offices/san-francisco'
                }
              ]
          },
          {
            'name': 'Open Positions',
            'link': '/company/open-positions'
          },
       ]
    },
    { 'name': 'Investor Center', 'link': '/investor-center/overview' ,
    'subSection': [
        {
          'name': 'Stock Price',
          'link': '/investor-center/stock-price'
        },
        {
          'name': 'Market Announcement',
          'link': '/investor-center/market-announcement'
        },
        {
            'name': 'ADR Program',
            'link': '/investor-center/adr-program'
        },
        {
          'name': 'Media',
          'link': '/investor-center/media'
        },
        {
          'name': 'FAQ',
          'link': '/investor-center/faq'
        },
        {
          'name': 'Leadership',
          'link': '/investor-center/leadership'
        }
      ]
    }
  ];
}