import React from 'react';
import { Navigation, Thumbs,FreeMode } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/css/free-mode";
import "swiper/css/thumbs";

export default function FeaturesList(props) {
    const { FeatureList ,setThumbsSwiper } = props;
    return  <Swiper
    className="swiper-container gallery-thumbs overflow-visible"
    spaceBetween="auto"
    slidesPerView={1}
    // freeMode={true}
    // watchSlidesProgress={true}
    modules={[FreeMode,Navigation, Thumbs]}
    onSwiper={setThumbsSwiper}
   
    breakpoints = {{
        300: {
            slidesPerView: 'auto',
            spaceBetween: 15,
        },
        640: {
            slidesPerView: 1.5,
            spaceBetween: 15,
        },
        768: {
            slidesPerView: 2.5,
            spaceBetween: 15,
        },
        1024: {
            slidesPerView: 3.5,
            spaceBetween: 15,
        },
        1200: {
            slidesPerView: 5,
            spaceBetween: 15,
            allowTouchMove: false,
        },
      }}
    >
    {FeatureList.map((item, itemIndex) => {
      return (
        <SwiperSlide 
        //  className = "width-xs-90 width-md-40 width-lg-30 width-xl-20"
         key={itemIndex}>
          <div className="card entry-card p-4 custom-clickable">
              <div className="entry-icon">
                  <i className={"icon-need "+ item.IconSlug}></i>
              </div>
            <div className="spacer-xs-1"></div>
            <div className="entry-display-5">
              <h5>{item.Title}</h5>
              <p className="mb-0 text-eclips">{item.ShortDescription}...</p>
            </div>
          </div>
        </SwiperSlide>
      );
    })}
  </Swiper>
}