import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import Image from '../common/Image';
import { Autoplay, Controller, Navigation, Pagination, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default function Banner(props) {
  const { BannerList } = props;

  const swiper1Ref = useRef();
  const swiper2Ref = useRef();

  // useLayoutEffect(() => {
  //   swiper1Ref.current.controller.control = swiper2Ref.current;
  //   swiper2Ref.current.controller.control = swiper1Ref.current;
  // }, []);

  return (
    <>
      <div className="row">
        <div className="col-lg-6 order-lg-1 order-2">
          {/* <Swiper
            className="swiper-container about-content-slider swiper-container-fade swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events"
            modules={[Controller, EffectFade, Thumbs]}
            onSwiper={swiper => {
              swiper1Ref.current = swiper;
            }}
            speed={1000}
            effect="fade"
            slidesPerView={1}
            spaceBetween={50}
            loop={true}
            fadeEffect={{
              crossFade: true
            }}
            preventInteractionOnTransition={true}
            navigation={{
              nextEl: '.swiper-button-next-unique',
              prevEl: '.swiper-button-prev-unique'
            }}
            keyboard={{ enabled: true, onlyInViewport: false }}
            pagination={{
              el: '.swiper-pagination',
              clickable: true
            }}
          >
            {BannerList.map((item, index) => {
              return (
                <SwiperSlide key={'swiper-slide-' + index}> 
                  <div className="entry-display-1">
                    <h1 className="text-white">{item.Title}</h1>
                    <div className="spacer-xs-1 spacer-lg-0"></div>
                    <p className="text-ultra-light">{item.Description}</p>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper> */}
          <div className="entry-display-1">
                    <h1 className="text-white">{BannerList[0].Title}</h1>
                    <div className="spacer-xs-1 spacer-lg-0"></div>
                    <p className="text-ultra-light">{BannerList[0].Description}</p>
                  </div>
        </div>
        <div className="col-lg-6 order-lg-2 order-1 text-center mb-4 mb-lg-0  ps-lg-5">
          <Swiper
            onSwiper={swiper => {
              swiper2Ref.current = swiper;
            }}
            className="swiper-container about-image-slider swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events"
            modules={[Pagination, Controller, EffectFade, Thumbs, Autoplay]}
            slidesPerView={1}
            spaceBetween={50}
            loop={true}
            navigation={{
              nextEl: '.swiper-button-next-unique',
              prevEl: '.swiper-button-prev-unique'
            }}
            keyboard={{ enabled: true, onlyInViewport: false }}
            pagination={{
              el: '.swiper-pagination',
              clickable: true
            }}
            effect="fade"
            speed={5000}
            autoplay={{
              delay: 1,
            }}
            fadeEffect={{
              crossFade: true,
            }}
          >
            {BannerList.map((item, index) => {
              return (
                <SwiperSlide key={'swiper-slide-' + index}>
                  <div className="hero-img img-overlay">
                    <Image src={item.Image.data.attributes.url} className="img-fluid" />
                    <div className="spacer-xs-4"></div>
                  </div>
                </SwiperSlide>
              );
            })}
            <div className="spacer-xs-5"></div>
            <div className="swiper-pagination swiper-pagination-bullets swiper-pagination-bullets-dynamic swiper-pagination-clickable"></div>
          </Swiper>
        </div>
      </div>
    </>
  );
}
