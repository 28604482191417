import { Link, useLocation } from 'react-router-dom';
import HeaderMenuList from '../../utitls/HeaderMenuList';

export default function HeaderCoreMenu() {
  const { pathname } = useLocation();
  const headerMenuList = HeaderMenuList();

  const toggelMobileMenu = () => {
    if (document.body.classList.contains('mobile-menu-open')) {
      document.body.className = document.body.className.replace('mobile-menu-open', '');
    } else {
      document.body.className = document.body.className + ' mobile-menu-open';
    }
  };

  return (
    <div className="nav-right me-3 me-xl-0">
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          {headerMenuList &&
            headerMenuList.map((menu, index) => {
              let allLinks = [];
              allLinks.push(menu.link);
              if(menu.list) {
                menu.list.forEach(dirctLi => {
                    allLinks.push(dirctLi.link);
                })
              }
              if (menu.subMenu) {
                menu.subMenu.forEach(data => {
                  allLinks.push(data.link);
                  if (data.list) {
                    data.list.forEach(data2 => {
                        if(data2.list){
                            data2.list.forEach(data3 => {
                              allLinks.push(data3.link);
                            });
                        }
                      allLinks.push(data2.link);
                    });
                  }
                  return true;
                });
              }
              if(menu.subSection){
                menu.subSection.forEach(data => {
                    allLinks.push(data.link)
                    if(data.list){
                        data.list.forEach((data2) => {
                            allLinks.push(data2.link)
                        })
                    }
                })
              }
             
              return (
                <li className={'nav-item ' + (menu.subMenu ? 'mega-dropdown' : '')} key={'main-menu-' + index}>
                  <Link reloadDocument={true} className={'nav-link ' + (allLinks.includes(pathname) || (pathname == "/" && menu.name == "Home")  ? ' active' : '')} to={menu.link}>
                    {menu.name}
                  </Link>
                  {menu.subMenu ? (
                    <div className="sub-menu">
                      <div className="container">
                        <div className="row">
                          {menu.subMenu.map((subMenuItem, subMenuIndex) => {
                            let isLastMenu = subMenuIndex === menu.subMenu.length - 1;
                            return (
                              <div className={subMenuItem.class} key={'subMenuIndex-' + subMenuIndex}>
                                <div className={'mega-list ' + (isLastMenu ? 'links' : '')}>
                                  <div className="list-heading">
                                    <h4>{subMenuItem.heading}</h4>
                                    {subMenuItem.hideSeeAll === true ? (
                                      <></>
                                    ) : (
                                      <Link reloadDocument={true} to={subMenuItem.link} className="">
                                        See All
                                      </Link>
                                    )}
                                  </div>
                                  <ul className="p-0 m-0">
                                    {subMenuItem.list &&
                                      subMenuItem.list.map((page, pageIndex) => {
                                        let links = [];
                                        links.push(page.link);
                                        if(page.list){
                                            page.list.forEach((innerMenu) => {
                                              links.push(innerMenu.link)
                                            })
                                        } 
                                        return (
                                          <li key={'sub-menu-list-' + page.name + (subMenuIndex + index)}>
                                            <Link reloadDocument={true} to={page.link} className="" id={page.link} onClick={ (event) =>  subMenuItem.heading === "By Integration" || subMenuItem.heading === "By Industry" ?  event.preventDefault() : undefined } className={(links.includes(pathname)) ? 'active' : ''}>
                                              <div className="entry-icon">
                                                <img src={page.icon} className="img-fluid" />
                                              </div>
                                              <div className="entry-content">
                                                <h3>{page.name}</h3>
                                                <i className="icon-comman icon-right"></i>
                                              </div>
                                            </Link>
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </li>
              );
            })}
        </ul>
      </div>
      <div className="aux-component">
        <span className="mobile-menu-btn" onClick={e => toggelMobileMenu(e)}>
          <div className="hamburger">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </span>
      </div>
    </div>
  );
}
