import { BrowserRouter, Routes, Route } from 'react-router-dom';
import React from 'react';
import Home from './components/Home';
import './scss/main.scss';
import Solutions from './components/Solutions';
import ProfessionalServices from './components/Support';
import ProfessionalServices3dmodeling from './components/Support/ProfessionalServices/3dmodeling';
import ProfessionalServicesXrCustomization from './components/Support/ProfessionalServices/xr-customization';
import ProfessionalServicesMetaverse from './components/Support/ProfessionalServices/metaverse';
import ProfessionalServicesIctInfrastructure from './components/Support/ProfessionalServices/ict-infrastructure';
import ProfessionalServicesKiosk from './components/Support/ProfessionalServices/kiosk';
import StockPricePage from './components/InvestorCenter/StockPricePage';
import MarketAnnouncementPage from './components/InvestorCenter/MarketAnnouncementPage';
import OverviewPage from './components/InvestorCenter/OverviewPage';
import MediaPage from './components/InvestorCenter/MediaPage';
import FaqPage from './components/InvestorCenter/FaqPage';
import AsxTechnologyPage from './components/InvestorCenter/AsxTechnologyPage';
import LeadershipPage from './components/InvestorCenter/LeadershipPage';
import Blog from './components/Support/KnowledgeCenter/Blog';
import BlogInner from './components/Support/KnowledgeCenter/BlogInner';
import CaseStudies from './components/Support/KnowledgeCenter/CaseStudies';
import CaseStudiesInner from './components/Support/KnowledgeCenter/CaseStudiesInner';
import SuccessStories from './components/Support/KnowledgeCenter/SuccessStories';
import SuccessStoriesInner from './components/Support/KnowledgeCenter/SuccessStoriesInner';
import VectionTechnologies from './components/Company/VectionTechnologies';
import OurStory from './components/Company/OurStory';
import DesignReviewPage from './components/Solutions/ByNeed/DesignReviewPage';
import EnworkOverviewPage from './components/Solutions/ByProduct/EnworkOverviewPage';
import EnworkGetStartedPage from './components/Solutions/ByProduct/EnworkGetStartedPage';
import ProductsOverview from './components/Solutions/ProductsOverview';
import ThreeDFrameWebex from './components/Solutions/ByProduct/ThreeDFrameWebex';
import ThreeDFramePricing from './components/Solutions/ByProduct/ThreeDFramePricing';
import MindeskPricing from './components/Solutions/ByProduct/MindeskPricing';
import XrkioskOverviewPage from './components/Solutions/ByProduct/XrkioskOverviewPage';
import XrkioskGetStartedPage from './components/Solutions/ByProduct/XrkioskGetStartedPage';
import ConfiguratorOverviewPage from './components/Solutions/ByProduct/ConfiguratorOverviewPage';
import ConfiguratorGetStartedPage from './components/Solutions/ByProduct/ConfiguratorGetStartedPage';
import TrainingPage from './components/Solutions/ByNeed/TrainingPage';
import RemotePresentationPage from './components/Solutions/ByNeed/RemotePresentationPage';
import MarketingPage from './components/Solutions/ByNeed/MarketingPage';
import EcommercePage from './components/Solutions/ByNeed/EcommercePage';
import OurVision from './components/Company/OurVision';
import GlobalOffices from './components/Company/GlobalOffices';
import CityLayoutPage from './components/Company/GlobalOffices/CityLayoutPage';
import OpenPositions from './components/Company/OpenPositions';
import MindeskOverviewPage from './components/Solutions/ByProduct/MindeskOverviewPage';
import ThreeDFrameOverviewPage from './components/Solutions/ByProduct/ThreeDFrameOverviewPage';
import IndustryOverview from './components/Solutions/ByIndustry/IndustryOverview';
import IndustryFashion from './components/Solutions/ByIndustry/IndustryFashion';
import IndustryEducation from './components/Solutions/ByIndustry/IndustryEducation';
import NoPage from './components/NoPage';
import ByNeedOverview from './components/Solutions/ByNeedOverview';
import ByNeedLayoutPage from './components/Solutions/ByNeed/ByNeedLayoutPage';
import MixedReality from './components/Support/Technology/MixedReality';
import ExtendedReality from './components/Support/Technology/ExtendedReality';
import Metaverse from './components/Support/Technology/Metaverse';
import AugmentedReality from './components/Support/Technology/AugmentedReality';
import IntegratedXrPage from './components/Support/Technology/IntegratedXrPage';
import VirtualRealityPage from './components/Support/Technology/VirtualRealityPage';
import ContactUsPage from './components/ContactUsPage';
import ThreeDFrameGetStartedPage from './components/Solutions/ByProduct/ThreeDFrameGetStartedPage';
import BenefitPage from './components/Benefits/BenefitPage';
import BlogInnerNew from './components/Support/KnowledgeCenter/BlogInnerNew';
import SiteMapPage from './components/SiteMapPage';
import { HelmetProvider } from 'react-helmet-async';
import { SEOContext } from './contexts';
import IndustryMedia from './components/Solutions/ByIndustry/IndustryMedia';
import IndustryNaval from './components/Solutions/ByIndustry/IndustryNaval';
import IndustryPublicSector from './components/Solutions/ByIndustry/IndustryPublicSector';
import IndustryRetail from './components/Solutions/ByIndustry/IndustryRetail';
import IndustrySports from './components/Solutions/ByIndustry/IndustrySports';
import IndustryAutomotive from './components/Solutions/ByIndustry/IndustryAutomotive';
import IndustryArchitecture from './components/Solutions/ByIndustry/IndustryArchitecture';
import IndustryAerospace from './components/Solutions/ByIndustry/IndustryAerospace';
import IndustryDefence from './components/Solutions/ByIndustry/IndustryDefence';
import IndustryBetting from './components/Solutions/ByIndustry/IndustryBetting';
import IndustryHealthcare from './components/Solutions/ByIndustry/IndustryHealthcare';
import IndustryMachineryManufacturing from './components/Solutions/ByIndustry/IndustryMachineryManufacturing';
import IndustryFurniture from './components/Solutions/ByIndustry/IndustryFurniture';
import Footer from './components/common/Footer';
import AdrProgramPage from './components/InvestorCenter/AdrProgramPage';

const defaultSEO = { title: 'Vection Technologies' };

function App() {
  return (
    <SEOContext.Provider value={defaultSEO}>
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route index path="/" exact element={<Home />} />
            <Route path="/home" element={<Home />} />
            <Route path="/solutions" exact element={<Solutions />} />
            <Route path="/solutions-need-overview" exact element={<ByNeedOverview />} />
            <Route path="/solutions/needs-design-review" element={<DesignReviewPage />} />
            <Route path="/solutions/needs-training" element={<TrainingPage />} />
            <Route path="/solutions/needs-remote-presentation" element={<RemotePresentationPage />} />
            <Route path="/solutions/needs-retail-experience" element={<MarketingPage />} />
            <Route path="/solutions/needs-ecommerce" element={<EcommercePage />} />
            <Route path="/support/services" element={<ProfessionalServices />} />
            <Route path="/support/services/3d-modeling-rendering" element={<ProfessionalServices3dmodeling />} />
            <Route path="/support/services/xr-customization-white-labels" element={<ProfessionalServicesXrCustomization />} />
            <Route path="/support/services/metaverse-web3.0" element={<ProfessionalServicesMetaverse />} />
            <Route path="/support/services/ict-infrastructure" element={<ProfessionalServicesIctInfrastructure />} />
            <Route path="/support/services/kiosks" element={<ProfessionalServicesKiosk />} />
            <Route path="/company/vection-technologies" element={<VectionTechnologies />} />
            <Route path="/company/our-story" element={<OurStory />} />
            <Route path="/company/our-vision" element={<OurVision />} />
            <Route path="/company/global-offices" element={<GlobalOffices />} />
            <Route path="/company/global-offices/:city" element={<CityLayoutPage />} />
            <Route path="/company/open-positions" element={<OpenPositions />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:id" element={<BlogInnerNew />} />
            <Route path="/case-studies" element={<CaseStudies />} />
            <Route path="/case-studies/:id" element={<CaseStudiesInner />} />
            <Route path="/success-stories" element={<SuccessStories />} />
            <Route path="/success-stories/:id" element={<SuccessStoriesInner />} />
            <Route path="/investor-center/overview" element={<OverviewPage />} />
            <Route path="/investor-center" element={<OverviewPage />} />
            <Route path="/investor-center/stock-price" element={<StockPricePage />} />
            <Route path="/investor-center/market-announcement" element={<MarketAnnouncementPage />} />
            <Route path="/investor-center/media" element={<MediaPage />} />
            <Route path="/investor-center/faq" element={<FaqPage />} />
            {/* <Route path="/investor-center/asx-technology" element={<AsxTechnologyPage />} /> */}
            <Route path="/investor-center/leadership" element={<LeadershipPage />} />
            <Route path="/solutions-products-overview" element={<ProductsOverview />} />
            <Route path="/solutions/products/enwork/overview" element={<EnworkOverviewPage />} />
            <Route path="/solutions/products/enwork/get-started" element={<EnworkGetStartedPage />} />
            <Route path="/solutions/products/3d-frame/overview" element={<ThreeDFrameOverviewPage />} />
            <Route path="/solutions/products/3d-frame/webex" element={<ThreeDFrameWebex />} />
            <Route path="/solutions/products/3d-frame/pricing" element={<ThreeDFramePricing />} />
            <Route path="/solutions/products/mindesk/overview" element={<MindeskOverviewPage />} />
            {/* <Route path="/solutions/products-mindesk-get-started" element={<MindeskGetStartedPage />} /> */}
            <Route path="/solutions/products-mindesk-pricing" element={<MindeskPricing />} />
            <Route path="/solutions/products/xrkiosk/overview" element={<XrkioskOverviewPage />} />
            <Route path="/solutions/products/xrkiosk/get-started" element={<XrkioskGetStartedPage />} />
            <Route path="/solutions/products/configurator/overview" element={<ConfiguratorOverviewPage />} />
            <Route path="/solutions/industries/overview" element={<IndustryOverview />} />
            <Route path="/solutions/industries/fashion" element={<IndustryFashion />} />
            <Route path="/solutions/industries/education" element={<IndustryEducation />} />
            <Route path="/solutions/products/configurator/get-started" element={<ConfiguratorGetStartedPage />} />
            <Route path="/solutions/needs/:need" element={<ByNeedLayoutPage />} />
            <Route path="/support/technology/What-is-mixed-reality" element={<MixedReality />} />
            <Route path="/support/technology/What-is-extended-reality" element={<ExtendedReality />} />
            <Route path="/support/technology/What-is-the-metaverse" element={<Metaverse />} />
            <Route path="/support/technology/What-is-augmented-reality" element={<AugmentedReality />} />
            <Route path="/contact-us" element={<ContactUsPage />} />
            <Route path="/support/technology/what-is-integratedxr" element={<IntegratedXrPage />} />
            <Route path="/support/technology/what-is-virtual-reality" element={<VirtualRealityPage />} />
            <Route path="/solutions/products/3d-frame/get-started" element={<ThreeDFrameGetStartedPage />} />
            <Route path="/benefits/:slug" element={<BenefitPage />} />
            <Route path="/sitemap" element={<SiteMapPage />} />
            <Route path="/solutions/industries/media-and-communications" element={<IndustryMedia />} />
            <Route path="/solutions/industries/naval-and-shipbuilding" element={<IndustryNaval />} />
            <Route path="/solutions/industries/public-sector" element={<IndustryPublicSector />} />
            <Route path="/solutions/industries/retail" element={<IndustryRetail />} />
            <Route path="/solutions/industries/sports" element={<IndustrySports />} />
            <Route path="/solutions/industries/automotive" element={<IndustryAutomotive />} />
            <Route path="/solutions/industries/architecture-engineering-and-construction" element={<IndustryArchitecture />} />
            <Route path="/solutions/industries/aerospace" element={<IndustryAerospace />} />
            <Route path="/solutions/industries/betting" element={<IndustryBetting />} />
            <Route path="/solutions/industries/defence-military-and-law-enforcement" element={<IndustryDefence />} />
            <Route path="/solutions/industries/healthcare-and-pharma" element={<IndustryHealthcare />} />
            <Route path="/solutions/industries/machinery-manufacturing" element={<IndustryMachineryManufacturing />} />
            <Route path="/solutions/industries/furniture-and-home-decor" element={<IndustryFurniture />} />
            <Route path="/investor-center/adr-program" element={<AdrProgramPage />} />
            <Route path="*" element={<NoPage />} />
          </Routes>
          {/* Footer */}
          <Footer />
          {/* Footer */}
        </BrowserRouter>
      </HelmetProvider>
    </SEOContext.Provider>
  );
}

export default App;
