import LearnMore from '../common/LearnMore';
import useFetch from '../../hooks/useFetch';
import Layout from '../Layout';
import Loader from '../common/Loader';
import Breadcrumb from '../common/Breadcrumb';
import Links from './Links';
import { InvestorCenterLeadership } from '../../Queries';
import icBgImg from "../../images/ic-bg.png"
import demoImg from "../../images/demo-image.png";
import Image from '../common/Image';
import ReactMarkdown from 'react-markdown';
import SEO from './../common/SEO';

export default function Leadership() {
    const { loading, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/investor-leadership?' + InvestorCenterLeadership);
    if (loading) {
        return <Loader />;
    }
    const { attributes } = response ? response.data : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null

    let breadcrumbLinks = [
        { 'link': '/', 'displayName': 'Home' },
        { 'link': '/investor-center/overview', 'displayName': 'Investment Center' },
        { 'link': '/investor-center/leadership', 'displayName': 'Leadership & governance' }
    ];
    const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    const getDateFormat = dates => {
        let date = new Date(dates);
        return monthNames[date.getMonth()] + ' ' + date.getFullYear('YYYY');
    };
    return (
        <Layout>
            <SEO meta={seoMeta}></SEO>
            <main className="page-wrapper">
                <section className="banner-display-4 text-center text-lg-start" style={{ backgroundImage: `url(${icBgImg})` }}>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="entry-display-1">
                                    <h1>{attributes && attributes.DirectorTitle ? attributes.DirectorTitle : ''} </h1>
                                    <div className="spacer-xs-1"></div>
                                    <div aria-label="breadcrumb">
                                        <Breadcrumb breadcrumbLinks={breadcrumbLinks} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <aside className="drawer-mobile sidebar-content d-flex d-xl-none">
                    <div className="offcanvas offcanvas-start" tabIndex="-1" id="openCanvas" aria-labelledby="offcanvasExampleLabel">
                        <div className="offcanvas-body handler-menu">
                            <div className="spacer-xs-3"></div>
                            <div className="sidebartitle">
                                <h2>Investor Center</h2>
                            </div>
                            <Links />
                        </div>
                    </div>
                </aside>
                <section className="handler-menu d-none d-xl-block">
                    <div className="spacer-xs-3"></div>
                    <Links />
                    <div className="spacer-xs-3"></div>
                </section>
                <section className="director-list">
                    <div className="container">
                        <div className="spacer-xs-8 spacer-lg-10"></div>
                        <div className="row justify-content-center">
                            <div className="col-sm-12 text-center text-md-start">
                                <div className="entry-display-3">
                                    <h3>{attributes.DirectorTitle}</h3>
                                </div>
                                <div className="spacer-xs-8 spacer-lg-8"></div>
                                <div className="row justify-content-center">
                                    <div className="col-sm-12">
                                        <ul className="entry-list p-0">
                                            {attributes.DirectorList.map((director, index) => {
                                                return (
                                                    <li key={index}>
                                                        <div className="entry-thumb align-self-center align-self-md-start">
                                                            <Image src={director.Avatar.data.attributes.url} alt="" />
                                                        </div>
                                                        <div className="entry-content">
                                                            <h3>{director.Name}</h3>
                                                            <p className="role">{director.Role}</p>
                                                            <p className="time">Director since {getDateFormat(director.Since)}</p>
                                                            <ReactMarkdown className="reactMarkdownDiv detail" children={director.About} />
                                                        </div>
                                                    </li>
                                                )
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="entry-display-3">
                                            <h3>{attributes.SecretaryTitle}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="spacer-xs-4 spacer-lg-6"></div>
                                <div className="row justify-content-center">
                                    <div className="col-sm-12">
                                        <ul className="entry-list p-0">
                                            {attributes.SecretaryList.map((secretary, index) => {
                                                return (<li key={index}>
                                                    <div className="entry-thumb align-self-center align-self-md-start">
                                                        <Image src={secretary.Avatar.data.attributes.url} />
                                                    </div>
                                                    <div className="entry-content">
                                                        <h3>{secretary.Name}</h3>
                                                        <p className="role">{secretary.Role}</p>
                                                        <ReactMarkdown className="reactMarkdownDiv detail" children={secretary.About} />
                                                    </div>
                                                </li>)
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="entry-display-3">
                                            <h3>{attributes.AdvisorTitle}</h3>
                                        </div>
                                    </div>
                                </div>
                                <div className="spacer-xs-4 spacer-lg-6"></div>
                                <div className="row justify-content-center">
                                    <div className="col-sm-12">
                                        <ul className="entry-list p-0 last">
                                            {attributes.AdvisorList.map((advisor, index) => {
                                                return (<li key={index}>
                                                    <div className="entry-thumb align-self-center align-self-md-start">
                                                        <Image src={advisor.Avatar.data.attributes.url} alt="" />
                                                    </div>
                                                    <div className="entry-content">
                                                        <h3>{advisor.Name}</h3>
                                                        <p className="role">{advisor.Role}</p>
                                                        <ReactMarkdown className="reactMarkdownDiv detail" children={advisor.About} />
                                                    </div>
                                                </li>)
                                            })}
                                        </ul>
                                    </div>
                                </div>
                                <div className="spacer-xs-6 spacer-lg-13"></div>
                                {console.log("attributes:",attributes)}
                                <div className="row">
                                    <div className="col-sm-12">
                                        <div className="accordion comman-accordion" id="accordionOne">
                                            <div className="row">
                                                <div className="col-sm-12 col-lg-6">
                                                    <div className="accordion-item mb-lg-0">
                                                        <h2 className="accordion-header" id="headingOne">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                {attributes.AppendixTitle}
                                                            </button>
                                                        </h2>
                                                        <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionOne">
                                                            <div className="accordion-body">
                                                                <a href={attributes.AppendixFile ? attributes.AppendixFile.data.attributes.url : ''} target="_blank" className="text-white text-decoration-underline">{attributes.AppendixDescription}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-lg-6">
                                                    <div className="accordion-item m-0">
                                                        <h2 className="accordion-header" id="headingTwo">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                                                {attributes.AnualReportTitle}
                                                            </button>
                                                        </h2>
                                                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionOne">
                                                            <div className="accordion-body">
                                                                <a href={attributes.AnualReportFile ? attributes.AnualReportFile.data.attributes.url : ''} target="_blank" className="text-white text-decoration-underline">{attributes.AnualReportDescription}</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="spacer-xs-8 spacer-lg-14"></div>
                    </div>
                </section>
                <LearnMore />
            </main>
        </Layout>
    );
}
