import React from 'react';
import Image from '../../common/Image';
import demoImage from '../../../images/demo-image.png';

export default function ListCard(props) {
  return (
    <div key={props.index} className="col-md-6 col-lg-4 mb-4">
      <div className="card border-0 bg-transparent py-3">
        <div className="entry-thumb">
          <Image src={props && props.item.Image.data.attributes.url ? props.item.Image.data.attributes.url : ''} className="img-fluid" alt="" />
        </div>
        <div className="entry-display-5 text-center text-xl-start">
          <h5>{props && props.item.Title ? props.item.Title : '' }</h5>
          <p>{props && props.item.Description ? props.item.Description : '' }</p>
        </div>
      </div>
    </div>
  );
}
