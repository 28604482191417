import { useEffect } from "react";
import $ from "jquery";
import useFetch from "../../../hooks/useFetch"
import Loader from '../../common/Loader';
import IndustryLayout from "./IndustryLayout";
import { SolutionByIndustryPublicSector } from "../../../Queries"
import Image from '../../common/Image';
import RealityCard from './RealityCard';
import ReactMarkdown from 'react-markdown';
import ApplicationCard from './ApplicationCard';
import SEO from './../../common/SEO';

export default function IndustryPublicSector() {
    useEffect(() => {
        var windowWidth = $(window).width();
        var container = $('.container').width();

        var calcWidth = windowWidth - container;

        $('.art').css('right', - (calcWidth / 2));
    }, [])

    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/industries-public-sector?' + SolutionByIndustryPublicSector);

    if (loading) {
        return <Loader />;
    }
    const { attributes } = response ? response.data : [];
    const { ArApplicationList } = attributes ? attributes : [];
    const { VrApplicationList } = attributes ? attributes : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null
    return (
        <IndustryLayout>
            <SEO meta={seoMeta}></SEO>
            <section className="entry-section">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-1">
                            <p className="sub-heading text-primary fw-semibold">GOVERNMENT AND PUBLIC SECTOR</p>
                            <h1>{attributes && attributes.Title ? attributes.Title : ''}</h1>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <div className="entry-thumb img-overlay">
                                <Image src={attributes && attributes.Image ? attributes.Image.data.attributes.url : ''} alt="" className="img-fluid" />
                            </div>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <p>{attributes && attributes.Description ? attributes.Description : ''}</p>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-12"></div>
            </section>
            <section className="entry-structure-light">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3">
                            <h3>{attributes && attributes.ArServiceTitle ? attributes.ArServiceTitle : ''}</h3>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes && attributes.ArServiceDescription ? attributes.ArServiceDescription : ''}</p>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <div className="entry-thumb img-overlay">
                                <Image src={attributes && attributes.ArServiceImage ? attributes.ArServiceImage.data.attributes.url : ''} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-10"></div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3">
                            <h3>{attributes && attributes.WhatIsArTitle ? attributes.WhatIsArTitle : ''}</h3>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes && attributes.WhatIsArDescription ? attributes.WhatIsArDescription : ''} </p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure-light">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-2">
                            <h2>{attributes && attributes.ArApplicationTitle ? attributes.ArApplicationTitle : ''}</h2>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes && attributes.ArApplicationDescription ? attributes.ArApplicationDescription : ''}</p>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-6 spacer-xl-8"></div>

                {ArApplicationList.map((item, index) => {
                    return <ApplicationCard key={'feature-card-2-' + index} lastItem={index === 6} itemIndex={index} item={item} page="public-sector" />;
                })}
            </section>
            <section className="entry-structure p-0">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-2">
                            <h2>{attributes && attributes.VrRoleTitle ? attributes.VrRoleTitle : ''}</h2>
                            <div className="spacer-xs-1"></div>
                            <ReactMarkdown className="reactMarkdown" children={attributes.VrRoleDescription} components={{
                                a: ({ children, href }) => <a href={href} className='sub-link' target='_blank'>{children[0]}</a>
                            }} />
                        </div>
                        <div className="spacer-xs-4 spacer-xl-6"></div>
                        <div className="entry-display-4">
                            <h4>{attributes && attributes.WhatIsVrTitle ? attributes.WhatIsVrTitle : ''}</h4>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes && attributes.WhatIsVrDescription ? attributes.WhatIsVrDescription : ''}</p>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-10"></div>
            </section>
            <section className="entry-structure-light">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-2">
                            <h2>{attributes && attributes.VrApplicationTitle ? attributes.VrApplicationTitle : ''}</h2>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes && attributes.VrApplicationDiscription ? attributes.VrApplicationDiscription : ''}</p>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-6 spacer-xl-8"></div>
                {VrApplicationList.map((item, index) => {
                    return <ApplicationCard key={'feature-card-2-' + index} lastItem={index === 4} itemIndex={index} item={item} page="public-sector" />;
                })}
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3">
                            <h3>{attributes && attributes.TechnologyTitle ? attributes.TechnologyTitle : ''}</h3>
                            <p>{attributes && attributes.TechnologyDescription ? attributes.TechnologyDescription : ''}</p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
        </IndustryLayout>
    )
}