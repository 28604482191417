import { useEffect } from "react";
import $ from "jquery";
import useFetch from "../../../hooks/useFetch"
import Loader from '../../common/Loader';
import IndustryLayout from "./IndustryLayout";
import { SolutionByIndustrySports } from "../../../Queries"
import Image from '../../common/Image';
import RealityCard from './RealityCard';
import ReactMarkdown from 'react-markdown';
import ApplicationCard from './ApplicationCard';
import mrImage from '../../../images/solutions/industries/sports/mixed-reality-sport.jpg';
import SEO from './../../common/SEO';

export default function IndustrySports() {
    useEffect(() => {
        var windowWidth = $(window).width();
        var container = $('.container').width();

        var calcWidth = windowWidth - container;

        $('.art').css('right', - (calcWidth / 2));
    }, [])

    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/industries-sport?' + SolutionByIndustrySports);

    if (loading) {
        return <Loader />;
    }
    const { attributes } = response ? response.data : [];
    const { VrList } = attributes ? attributes : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null
    return (
        <IndustryLayout>
            <SEO meta={seoMeta}></SEO>
            <section className="entry-section">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-1">
                            <p className="sub-heading text-primary fw-semibold">SPORTS</p>
                            <h1>{attributes && attributes.Title ? attributes.Title : ''}</h1>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <div className="entry-thumb img-overlay">
                            <Image src={attributes && attributes.Image ? attributes.Image.data.attributes.url : ''} alt="" className="img-fluid" />
                            </div>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <p>{attributes && attributes.Description ? attributes.Description : ''}</p>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-12"></div>
            </section>
            <section className="entry-structure-light">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3">
                            <p>{attributes && attributes.SubDescription ? attributes.SubDescription : ''}</p>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <div className="entry-thumb img-overlay">
                            <Image src={attributes && attributes.SubImage ? attributes.SubImage.data.attributes.url : ''} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-10"></div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-2">
                            <h2>{attributes && attributes.VrTitle ? attributes.VrTitle : ''} </h2>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes && attributes.VrDescription ? attributes.VrDescription : ''}</p>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-6 spacer-xl-8"></div>
                {VrList.map((item, index) => {
                    return <ApplicationCard key={'feature-card-2-' + index} lastItem={index === 2} itemIndex={index} item={item} page="public-sector" />;
                })}
            </section>
            <section className="entry-structure-light">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-2">
                            <h2>{attributes && attributes.ArTitle ? attributes.ArTitle : ''}</h2>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes && attributes.ArDescription ? attributes.ArDescription : ''}</p>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-6 spacer-xl-8"></div>
                <div className="row gx-6 block-card block-card-md">
                    <div className="col-lg-6 order-lg-2 order-2">
                        <div className="entry-display-3">
                            <p>{attributes && attributes.ArSubDescription ? attributes.ArSubDescription : ''}</p>
                        </div>
                    </div>
                    <div className="col-lg-6 order-lg-1 order-1 mb-4 mb-xl-0">
                        <div className="entry-thumb img-overlay">
                        <Image src={attributes && attributes.ArSubImage ? attributes.ArSubImage.data.attributes.url : ''} alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-10"></div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3">
                            <h3>{attributes && attributes.MrTitle ? attributes.MrTitle : ''}</h3>
                            <div className="spacer-xs-1"></div>
                            <p>Mixed Reality (MR) also manages to merge digital elements with physical reality in its own way. The tech’s being extensively used as an alternative to regular training, may it be in indoor or outdoor environments. </p>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <div className="entry-thumb img-overlay">
                                <Image src={mrImage} alt="" className="img-fluid" />
                            </div>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <p>With MR, you have the option of creating specific in-game situations for athletes to go through again and again. This type of training video also happens through the simulation of a player’s POV angle, adding an extra layer of realism to the experience.<br /><br />These simulations can include computer-generated obstacles or even players, which can be inserted in such a way that athletes must push their abilities to maximum capacity. Through repetition of the same exercise, players and their coaches can easily identify mistakes, and then explore new game strategies and techniques.<br /><br />Mixed Reality can also help teams prior to facing specific opponents, by allowing them to inspect the opponent’s stadium or arena without having to be physically present. <br /><br />On top of that, MR can also help scouts and agents to find new talent all over the world. Digital interfaces are being developed to allow the rating of young athletes through data collection based on their physical performance. </p>
                            {/* <ReactMarkdown className="reactMarkdown" children={attributes.MrDescription} /> */}
                        </div>
                    </div>
                </div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure-light report-card">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3">
                            <h3>{attributes && attributes.LieAhedTitle ? attributes.LieAhedTitle : ''}</h3>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes && attributes.LieAhedDescription ? attributes.LieAhedDescription : ''}</p>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-10"></div>
            </section>
        </IndustryLayout>
    )
}