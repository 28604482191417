import { useEffect, useState } from 'react';

const useFetch = url => {
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [reqSent, setReqSend] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetch(url, { headers: { Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}` } });
        const json = await res.json();
        setResponse(json);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    if (!reqSent) {
      setReqSend(true);
      fetchData();
    }
  }, [url]);

  return { loading, error, response };
};

export default useFetch;
