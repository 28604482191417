import { Link } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import Layout from '../../Layout';
import { IctInfrastructureQuery } from '../../../Queries';
import Image from '../../common/Image';
import LearnMore from '../../common/LearnMore';
import SideMenu from '../ProfessionalServices/sidemenu';
import Loader from '../../common/Loader';
import ServiceFeatureCard from './ServiceFeatureCard';
import SEO from '../../common/SEO';

export default function ProfessionalServicesIctInfrastructure(props) {
  const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/support-ict-infrastructure?' + IctInfrastructureQuery);
  const { data } = response ? response : [];
  const { attributes } = data ? data : [];
  const { FeatureList } = attributes ? attributes : [];

  if (loading) {
    return <Loader />;
  }
  const seoMeta = (attributes.seo) ? attributes.seo : null
  return (
    <Layout>
      <SEO meta={seoMeta}></SEO>
      <main className="page-wrapper">
        <section className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="page-content">
                <aside className="sidebar sidebar-content sticky-top d-none d-xl-flex">
                  <SideMenu />
                </aside>
                <aside className="drawer-mobile sidebar-content d-flex d-xl-none">
                  <div className="offcanvas offcanvas-start" tabIndex="-1" id="openCanvas" aria-labelledby="offcanvasExampleLabel">
                    <div className="offcanvas-body">
                      <div className="spacer-xs-3"></div>
                      <SideMenu />
                    </div>
                  </div>
                </aside>
                <section className="main-content">
                  <section className="banner banner-display-2">
                    <div className="row">
                      <div className="spacer-xs-8 spacer-xl-12"></div>
                      <div className="entry-display-1 width-xxl-50 width-xxxl-68 order-2 order-xl-1 text-xl-start text-center">
                        <h1>{attributes && attributes.Title ? attributes.Title : ''}</h1>
                        <div className="spacer-xs-1"></div>
                        <p>{attributes && attributes.Description ? attributes.Description : ''}</p>
                      </div>
                      <div className="entry-thumb d-flex flex-column flex-xl-row justify-content-center justify-content-xl-end align-items-center position-absolute order-1 order-xl-2">
                        <div className="circle-bg d-none d-xl-block"></div>
                        <div className="spacer-xs-5 order-1 d-flex d-xl-none"></div>
                        <div className="entry-image img-overlay">
                          <Image src={attributes && attributes.CircleImage.data.attributes.url ? attributes.CircleImage.data.attributes.url : ''} className="img-fluid" />
                        </div>
                      </div>
                    </div>
                  </section>
                  <div className="spacer-xs-8 spacer-xl-12"></div>
                  <section className="entry-structure">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="entry-display-1">
                          <div className="entry-thumb img-overlay">
                            <Image src={attributes && attributes.BannerImage.data.attributes.url ? attributes.BannerImage.data.attributes.url : ''} className="img-fluid" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <div className="spacer-xs-8 spacer-xl-12"></div>
                  <section className="entry-structure-light">
                    <div className="spacer-xs-8 spacer-xl-10"></div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="entry-display-2">
                          <h2>{attributes && attributes.SubTitle ? attributes.SubTitle : ''}</h2>
                          <div className="spacer-xs-1"></div>
                          <p>{attributes && attributes.SubDescription ? attributes.SubDescription : ''}</p>
                          <div className="spacer-xs-3"></div>
                          <Link reloadDocument={true} to={attributes && attributes.LearnMoreLink ? attributes.LearnMoreLink : '#'} className="btn btn-primary btn-sm text-white px-5">Learn More</Link>
                        </div>
                      </div>
                    </div>
                    <div className="spacer-xs-8 spacer-xl-10"></div>
                  </section>
                  <section className="entry-structure">
                    <div className="spacer-xs-8 spacer-xl-12"></div>
                    {FeatureList.map((itemSolution, indexSolution) => {
                      return <ServiceFeatureCard key={'feature-card-2-' + indexSolution} lastItem={indexSolution === 5} itemIndex={indexSolution} item={itemSolution} />;
                    })}
                  </section>
                </section>
              </div>
            </div>
          </div>
        </section>
        <LearnMore />
      </main>
    </Layout>
  );
}
