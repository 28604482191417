import Layout from '../Layout';
import { SolutionByNeedOverview } from '../../Queries';
import LearnMore from '../common/LearnMore';
import useFetch from '../../hooks/useFetch';
import Loader from '../common/Loader';
import Image from '../common/Image';
import bgImage from "../../images/circle-bg.png";
import ByNeedCard from './ByNeedCard';
import SEO from './../common/SEO';

export default function ByNeedOverview(props) {
    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/need-overview?' + SolutionByNeedOverview);
    if (loading) {
        return <Loader />;
    }
    const { data } = response ? response : [];
    const { attributes } = data ? data : [];
    const { NeedList } = attributes ? attributes : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null
    return (
        <Layout>
            <SEO meta={seoMeta}></SEO>
            <main className="page-wrapper">
                <section className="banner banner-display-1 bg-grey-dark">
                    <div className="container">
                        <div className="spacer-xs-5 spacer-xl-13"></div>
                        <div className="row align-items-center">
                            <div className="entry-display-1 width-xl-55 order-2 order-xl-1 text-center text-xl-start">
                                <span className="sub-heading text-primary fw-semibold">OVERVIEW</span>
                                <div className="spacer-xs-2"></div>
                                <h1 className="text-white">{attributes && attributes.Title ? attributes.Title : ''}</h1>
                                <div className="spacer-lg-1"></div>
                                <p className="text-white">{attributes && attributes.Description ? attributes.Description : ''}</p>
                            </div>
                            <div className="entry-thumb width-xl-45 d-flex flex-column flex-xl-row justify-content-center justify-content-xl-end align-items-center order-xl-2 order-1">
                                <div className="circle-bg d-none d-xl-block" style={{ backgroundImage: `url(${bgImage})` }}></div>
                                <div className="spacer-xs-5 order-1 d-flex d-xl-none"></div>
                                <div className="entry-image dark img-overlay">
                                    <Image src={attributes && attributes.Image.data.attributes.url ? attributes.Image.data.attributes.url : ''} />
                                </div>
                            </div>
                        </div>
                        <div className="spacer-xs-6 spacer-xl-13"></div>
                    </div>
                </section>
                <section className="theme-lite">
                    <div className="spacer-xs-8 spacer-xl-12"></div>
                    <ByNeedCard Heading="By Need" ListItem={NeedList} />
                </section>
                <div className="spacer-xs-6 spacer-xl-8"></div>
                <LearnMore />
            </main>
        </Layout>
    );
}
