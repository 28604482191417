import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { Autoplay, Controller, Lazy, Navigation, Pagination, Thumbs,FreeMode, EffectFade } from 'swiper';
import Image from '../common/Image';
import ReactMarkdown from 'react-markdown';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "swiper/css/free-mode";
import "swiper/css/thumbs";
import CustomIcon from '../common/CustomIcon';
import { Link } from 'react-router-dom';
import FeaturesList from './FeaturesList';

export default function Features(props) {
  const { FeatureTitle, FeatureDescription, FeatureList } = props;
  const swiper1 = useRef();
  const swiperThumbs = useRef();
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  useLayoutEffect(() => {
    // console.log('useLayoutEffect', swiper1, swiperThumbs);
    // swiper1Ref.current.controller.control = swiper2Ref.current;
    // swiper2Ref.current.controller.control = swiper1Ref.current;
  });
  const renderLink = (link) => {
        let setLink = '';
        switch (link) {
            case "design-review":
                setLink= "/solutions/products/mindesk/overview";
                break;
            case "training":
                setLink= "/solutions/products/enwork/overview";
                break;
            case "remote-presentations":
                setLink= "/solutions/products/3d-frame/overview";
                break;
            case "retail-experience":
                setLink= "/solutions/products/xrkiosk/overview";
                break;
            case "e-commerce":
                setLink= "/solutions/products/configurator/overview";
                break;
        }

        return <Link reloadDocument={true} to={setLink} className="btn btn-primary btn-sm text-white icon-btn"><i className="icon-comman icon-unlock me-2"></i>Solutions</Link>
  }
  const strongComp = (props) => {
      return (
          <>
            <p class="fw-bold">{props && props.children[0]}</p>
            <div class="spacer-xs-1"></div>
           </>
      )
  }
  return (
    <section className="block-slider technologies-silder">
      <div className="spacer-xs-8 spacer-md-10 spacer-lg-12"></div>
      <div className="container">
        <div className="row">
          <div className="col-sm-12 order-1 order-lg-1">
            <div className="entry-display-1 text-start text-md-center">
              <h1>{FeatureTitle}</h1>
              <p>{FeatureDescription}</p>
            </div>
          </div>
        </div>
        <div className="spacer-xs-6 spacer-lg-10"></div>
        <div className="row">
        <div className="col-sm-12 order-2 order-lg-1">
        <Swiper
          className="swiper-container gallery-top home-swiper"
          loop={false}
          spaceBetween={10}
          navigation={true}
          scrollEnabled = {false}
          modules={[FreeMode,Navigation, Thumbs, EffectFade]}
          thumbs={{ swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null }}
          ref={swiper1}
          breakpoints = {{
            300: {
              autoHeight: true,
              spaceBetween: 10,
            }
          }}
          effect="fade"
          fadeEffect={{
            crossFade: true,
        }}
          >
          {FeatureList.map((item, index) => {
            return (
              <SwiperSlide key={`slide_${index}`}>
                <div className="row align-items-center">
                  <div className="width-lg-55 d-flex align-items-center justify-content-center mb-5 mb-lg-0">
                    <div className="entry-thumb img-overlay">
                      <Image src={item.Image.data.attributes.url} />
                      <div className="py-5"></div>
                    </div>
                  </div>
                  <div className="width-lg-45">
                    <div className="entry-display-3">
                    <h3 className="d-flex"> <i className={"icon-need pe-3 " + item.IconSlug}></i>{item.Title}</h3>
                      <div className="spacer-xs-1"></div>
                      <ReactMarkdown className="reactMarkdownDiv" children={item.Description} 
                      components={{
                        strong : strongComp,
                       }}
                      />
                      <div className="spacer-xs-4"></div>
                      <div className="gap-2 d-flex justify-content-start">
                        {/* {renderLink(item.Link)} */}
                        <Link reloadDocument={true} to={item.ProductLink} className="btn btn-primary btn-sm text-white icon-btn">
                          <i className="icon-comman icon-unlock me-2"></i>Solutions
                          </Link>
                        <Link reloadDocument={true} to={item.Link} className="btn btn-link btn-sm text-primary icon-btn">
                          Read More<i className="icon-comman icon-right fs-xs-15 ms-2"></i>
                        </Link>                   
                    </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        </div>
          </div>
          <div className="spacer-xs-6 spacer-lg-8"></div>
          <div className="row">
          <div className="col-sm-12 order-1 order-lg-2">
        <FeaturesList FeatureList ={FeatureList} setThumbsSwiper={setThumbsSwiper} />
        </div>
        </div>
      </div>
      <div className="spacer-xs-8 spacer-md-10 spacer-lg-14"></div>
    </section>
  );
}
