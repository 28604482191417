import React from 'react';
import Breadcrumb from '../../common/Breadcrumb';
import Image from '../../common/Image';
import LearnMore from '../../common/LearnMore';
import Layout from '../../Layout';
import ChallengeCard from './ChallengeCard';
import FaqAccordian from './FaqAccordian';

export default function ByNeedLayout(props) {
  const { banner, heading, description, breadcrumbLinks, challenges, faq } = props;
  return (
    <Layout>
      <main className="page-wrapper">
        <section className="banner banner-display-1">
          <div className="container">
            <div className="spacer-xs-5 spacer-xl-13"></div>
            <div className="row align-items-center">
              <div className="entry-display-1 width-xl-55 order-xl-1 order-2 text-center text-xl-start">
                <h1>{banner.title}</h1>
                <div className="spacer-xs-1"></div>
                <nav aria-label="breadcrumb">
                  <Breadcrumb breadcrumbLinks={breadcrumbLinks} />
                </nav>
                <div className="spacer-xs-6 spacer-xl-0"></div>
              </div>
              <div className="entry-thumb width-xl-45 d-flex flex-column flex-xl-row justify-content-center justify-content-xl-end align-items-center order-xl-2 order-1">
                <div className="circle-bg d-none d-xl-block"></div>
                <div className="entry-image img-overlay">
                  <Image src={banner && banner.image && banner.image.data && banner.image.data.attributes.url} className="img-fluid" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div className="spacer-xs-2 spacer-lg-4 spacer-xl-13"></div>
        </section>
        <section className="element-background">
          <div className="container">
            <div className="spacer-lg-12"></div>
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="entry-display-1 text-center">
                  <h1 className="text-white">{heading}</h1>
                  <div className="spacer-xs-1"></div>
                  <p className="text-ultra-light">{description}</p>
                </div>
              </div>
            </div>
            <div className="spacer-xs-8 spacer-lg-12"></div>
          </div>
        </section>
        <section className="entry-structure">
          <div className="container">
            <div className="spacer-xs-8 spacer-lg-12"></div>
            <div className="row justify-content-center">
              <div className="col-lg-12">
                <div className="entry-display-1 text-center">
                  <h1>Challenges</h1>
                  <div className="spacer-xs-4 spacer-lg-12"></div>
                </div>
              </div>
            </div>
            {challenges &&
              challenges.map((item, index) => {
                return <ChallengeCard key={'challenge-item-' + index} {...item} itemIndex={index} />;
              })}
          </div>
        </section>
        <FaqAccordian faq={faq} />
        <LearnMore />
      </main>
    </Layout>
  );
}
