import React from "react";
import { SolutionByProductThreeDFrameWebex } from '../../../Queries';
import useFetch from '../../../hooks/useFetch';
import Loader from '../../common/Loader';
import ByProductLayout from './ByProductLayout';
import bgImage from '../../../images/products/3dframe/banner-bg.png';
import { Link } from "react-router-dom";
import SEO from '../../common/SEO';

export default function ThreeDFrameWebex() {
    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/three-d-frame-for-webex?' + SolutionByProductThreeDFrameWebex);
    //SolutionByProductEnworkOverview
    if (loading) {
        return <Loader />;
    }
    const { data } = response ? response : [];
    const { attributes } = data ? data : [];
    // console.log(attributes)
    const seoMeta = (attributes.seo) ? attributes.seo : null
    return (
        <ByProductLayout menuHeading="Products" backgroundImage={bgImage} logo={attributes.Logo}>
            <SEO meta={seoMeta}></SEO>
         <section className="entry-structure">
        <div className="spacer-xs-8 spacer-xl-12"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-1">
                        <h1>{attributes.Title}</h1>
                        <div className="spacer-xs-1"></div>
                        <p>{attributes.Description}</p>
                        <div className="spacer-xs-4"></div>
                        <a href="https://app.3dframe.vection-technologies.com/signup" target="_blank" className="btn btn-primary btn-sm text-white py-3 pl-xs-5 pr-xs-5 fs-xs-17">Bring 3DFrame into Webex</a>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-1">
                        <div className="entry-video-1">
                            <video id='video' controls="controls" preload='none' height="100%" width="100%" poster={attributes.VideoCover.data.attributes.url}>
                                <source id='mp4' src="images/solutions/products/3d-frame-video.mp4" type='video/mp4' />
                                <source id='webm' src={attributes.VideoWebm.data.attributes.url} type='video/webm' />
                                <source id='ogv' src={attributes.VideoOgg.data.attributes.url}  type='video/ogg' />
                                <p>Your user agent does not support the HTML5 Video element.</p>
                            </video>
                        </div>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-8 spacer-xl-12"></div>
        </section>
        <section className="entry-structure-light">
             {attributes.FeaturesList.map((feature, index) => {
                 return <React.Fragment key ={index}>
                 <div className="spacer-xs-8 spacer-xl-12"></div>
                 <div className="row gx-6 block-card block-card-sm">
                <div className={"col-lg-6 order-2 " + ((index % 2) == 0 ? "order-lg-1" : "order-lg-2")}>
                    <div className="entry-display-3">
                        <h3>{feature.Title}</h3>
                        <div className="spacer-xs-1"></div>
                        <p>{feature.Description}
                        </p>
                    </div>
                </div>
                <div className={"col-lg-6 order-1 " + ((index % 2) == 0 ? "order-lg-2" : "order-lg-1")}>
                    <div className="entry-thumb mb-4 mb-xl-0 img-overlay">
                        <img src={feature.Image.data.attributes.url} className="img-fluid"/>
                    </div>
                </div>
                 </div>
                 </React.Fragment>
             })}
            <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
                <div className="col-sm-12 d-flex justify-content-center">
                    <Link reloadDocument={true} to="/solutions/products/3d-frame/overview" className="btn btn-primary btn-lg text-white icon-btn">Learn all the features<i className="icon-comman icon-arrow-right ms-3 fs-lg-22"></i></Link>
                </div>
            </div>
            <div className="spacer-xs-8 spacer-lg-12"></div>
        </section>
        </ByProductLayout>
    );
}
