import { useState } from "react";
import { SolutionByProductThreeDFramePricing } from '../../../Queries';
import useFetch from '../../../hooks/useFetch';
import Loader from '../../common/Loader';
import ByProductLayout from './ByProductLayout';
import bgImage from '../../../images/products/3dframe/banner-bg.png';
import ReactMarkdown from 'react-markdown';
import { Link } from "react-router-dom";
import SEO from '../../common/SEO';

export default function ThreeDFramePricing() {
    const[pricingSwitcher,setPricingSwitcher] = useState("Monthly")
    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/three-d-frame-pricing?' + SolutionByProductThreeDFramePricing);
    //SolutionByProductEnworkOverview
    if (loading) {
        return <Loader />;
    }
    const { data } = response ? response : [];
    const { attributes } = data ? data : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null

    return (
        <ByProductLayout menuHeading="Products" backgroundImage={bgImage} logo={attributes.Logo}>
            <SEO meta={seoMeta}></SEO>
                <section className="entry-structure pricing-section p-0">
                <div className="spacer-xs-8 spacer-xl-12"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-1 text-center">
                            <h1>{attributes.Title}</h1>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-4 spacer-xl-6"></div>
                <div className="row justify-content-center">
                    <div className="width-lg-37">
                        <div className="pricing-switcher">
                            <ul className="nav nav-pills mb-3 d-flex" id="pills-tab" role="tablist">
                                {attributes.PricingSwitcherList.map((price , index) => {
                                    return (
                                        <li className="nav-item flex-grow-1" role="presentation" key={index}>
                                        <button onClick={() => {
                                            setPricingSwitcher(price.Title);
                                            }} className={price.Title == pricingSwitcher ? "nav-link w-100 active" : "nav-link w-100 " } id="pills-monthly-tab" data-bs-toggle="pill" data-bs-target="#pills-monthly" type="button" role="tab" aria-controls="pills-monthly" aria-selected="true">{price.Title}</button>
                                    </li>
                                    )
                                })}
                            </ul>
                            <div className="save"><span>{attributes.SaveTitle}</span></div>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-6 spacer-lg-9"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="tab-content" id="pills-tabContent">
                            {pricingSwitcher == "Monthly" && <div className="tab-pane fade show active" id="pills-monthly" role="tabpanel" aria-labelledby="pills-monthly-tab">
                                <div className="row">
                                    {attributes.MonthlyList.map((monthly , index) => {
                                        return (
                                            <div className="col-md-12 col-lg-4 mb-5 mb-lg-0" key={index}>
                                            <div className={monthly.Title == "Flat" ? "card pricing flat" : "card pricing"}>
                                                <h4>{monthly.Title}</h4>
                                                <div className="plan-price">
                                                {monthly.Title == "Flat" && <span className="badge rounded-pill bg-warning text-white">{monthly.PerSeat}</span>}
                                                    <p> 
                                                        <span className="amount">{monthly.Amount}</span>   
                                                        {monthly.Title == "Flat" && <span className="mo">{monthly.Mo}</span>}
                                                        {monthly.Title == "Enterprise" && <small>{monthly.Pricing}</small>}        
                                                    </p>
                                                    <div className="days">
                                                        {monthly.Days}
                                                    </div>
                                                </div>
                                                <a href="https://app.3dframe.vection-technologies.com/signup" target="_blank" className={monthly.Title == "Flat" ? "btn btn-primary btn-sm text-white d-flex justify-content-center" : "btn btn-primary-light" }>{monthly.LinkTitle}</a>
                                            </div>
                                            <div className="spacer-xs-4 spacer-xl-3"></div>
                                            <div className="plan-included">
                                                <h5>{monthly.IncludeTitle}</h5>
                                                <ul>
                                                <ReactMarkdown className="" children={monthly && monthly.IncludeList ? monthly.IncludeList : ''} />
                                                </ul>
                                            </div>
                                        </div>
                                        )
                                    })}
                                </div>
                            </div>}
                            {pricingSwitcher == "Annually" && 
                            <div className="tab-pane fade show active" id="pills-annualy" role="tabpanel" aria-labelledby="pills-annualy-tab" >
                            <div className="row">
                            {attributes.AnnuallyList.map((annual,index) => {
                               return (
                                <div className="col-md-12 col-lg-4 mb-5 mb-lg-0" key={index}>   
                                    <div className={annual.Title == "Flat" ? "card pricing flat" : "card pricing"}>
                                        <h4>{annual.Title}</h4>
                                        <div className="plan-price">
                                            {annual.Title == "Flat" && <span className="badge rounded-pill bg-warning text-white">{annual.PerSeat}</span>}
                                            <p> 
                                                <span className="amount">{annual.Amount}</span>  
                                                {annual.Title == "Enterprise" && <small>{annual.Pricing}</small>}         
                                            </p>
                                            <div className="days">
                                                {annual.Days}
                                            </div>
                                        </div>
                                        <a href="https://app.3dframe.vection-technologies.com/signup" target="_blank" className={annual.Title == "Flat" ? "btn btn-primary btn-sm text-white d-flex justify-content-center" : "btn btn-primary-light" }>{annual.LinkTitle}</a>
                                    </div>
                                    <div className="spacer-xs-4 spacer-xl-3"></div>
                                    <div className="plan-included">
                                        <h5>{annual.IncludeTitle}</h5>
                                        <ul>
                                        <ReactMarkdown className="reactMarkdownDiv" children={annual && annual.IncludeList ? annual.IncludeList : ''} />
                                        </ul>
                                    </div>
                                    </div>)
                                })}
                            </div>
                            </div>}
                        </div>
                    </div>
                </div>   
                <div className="spacer-lg-12"></div>                               
            </section>
        </ByProductLayout>
    );
}
