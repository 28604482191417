export default function FooterMenuList() {
  return [
    {
      'heading': 'Company',
      'link': '/company/vection-technologies',
      'subMenu': [
        { 'name': 'What is Vection Technologies', 'link': '/company/vection-technologies' },
        { 'name': 'Our Story', 'link': '/company/our-story' },
        { 'name': 'Brands that Support Our Vision', 'link': '/company/our-vision' },
        { 'name': 'Global Offices', 'link': '#' }
      ]
    },
    {
      'heading': 'Needs',
      'link': '#',
      'subMenu': [
        { 'name': 'Design Review', 'link': '/solutions/needs/design-review' },
        { 'name': 'Training', 'link': '/solutions/needs/training' },
        { 'name': 'Remote Presentations', 'link': '/solutions/needs/remote-presentations' },
        { 'name': 'Retail 3D Experience', 'link': '/solutions/needs/retail-experience' },
        { 'name': '3D E-commerce', 'link': '/solutions/needs/ecommerce' }
      ]
    },
    {
      'heading': 'Benefits',
      'link': '#',
      'subMenu': [
        { 'name': 'Faster Time to Market', 'link': '#' },
        { 'name': 'Effective Training', 'link': '#' },
        { 'name': 'Improve Remote Sales', 'link': '#' },
        { 'name': 'Augmented Shopping Experience', 'link': '#' },
        { 'name': 'Increase Conversion Rates', 'link': '#' }
      ]
    },
    {
      'heading': 'Support',
      'link': '#',
      'subMenu': [
        { 'name': 'Contact Us', 'link': '/contact-us' },
        { 'name': 'Services', 'link': '/support/services' },
        { 'name': 'Blog', 'link': '/blog' },
        { 'name': 'Case Studies', 'link': '/case-studies' },
        { 'name': 'Success Stories', 'link': '/success-stories' },
        { 'name': 'What is INTEGRATEDXR', 'link': '/support/technology/what-is-integratedxr' },
        { 'name': 'What is Virtual Reality', 'link': '/support/technology/what-is-virtual-reality' },
        { 'name': 'What is Augmented Reality', 'link': '/support/technology/What-is-augmented-reality' },
        { 'name': 'What is Mixed Reality', 'link': '/support/technology/What-is-mixed-reality' },
        { 'name': 'What is Extended Reality', 'link': '/support/technology/What-is-extended-reality' },
        { 'name': 'What is the Metaverse', 'link': '/support/technology/What-is-the-metaverse' },
        { 'name': 'Sitemap', 'link': '/sitemap' }
      ]
    },
    {
      'heading': 'Investor Centre',
      'link': '/investor-center/overview',
      'subMenu': [
        { 'name': 'Overview', 'link': '/investor-center/overview' },
        { 'name': 'Stock Price', 'link': '/investor-center/stock-price' },
        { 'name': 'Market Announcements', 'link': '/investor-center/market-announcement' },
        { 'name': 'ADR Program', 'link': '/investor-center/adr-program' },
        { 'name': 'Media', 'link': '/investor-center/media' },
        { 'name': 'FAQ', 'link': '/investor-center/faq' },
        { 'name': 'Leadership & Governance', 'link': '/investor-center/leadership' }
      ]
    }
  ];
}
