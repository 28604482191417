import React from 'react';
import CustomIcon from '../../common/CustomIcon';
import { Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';

export default function BenefitCard(props) {
  return (
    <div key={props.index} className="col-sm-6 mb-4">
      <div className="card mb-3 bg-transparent border-0">
        <div className="row g-0">
          <div className="col-md-auto d-flex justify-content-center">
            <div className={props.item && props.item.IconColor ? "entry-icon mb-4 " + props.item.IconColor : "entry-icon color-1 mb-4"}>
              <span>
                <i className={props.item && props.item.IconSlug ? "icon-product icon-" + props.item.IconSlug : 'icon-product icon-enhance-problem-solving'}
                  style={{ color: props.item.IconColor }}>
                </i>
              </span>
            </div>
          </div>
          <div className="col-md-10">
            <div className="entry-display-5 ps-0 ps-md-6 text-center text-md-start">
              <h5>{props.item && props.item.Title ? props.item.Title : ''}<i className="icon-comman icon-right"></i></h5>
              {/* <p>{props.item && props.item.Description ? props.item.Description : ''}</p> */}
              <ReactMarkdown className="reactMarkdown" children={props.item.RichDescription} components={{
                img: ({ node, ...props }) => <div className="entry-thumb img-overlay"><img className="img-fluid" {...props} /></div>,
                a: ({ children, href }) => <a href={href} className='sub-link' target='_blank'>{children[0]}</a>
              }} />
              <Link reloadDocument={true} to={props.item && props.item.Link ? props.item.Link : '#'} className="stretched-link" />
            </div>
          </div>
        </div>
      </div>
    </div>

  );
}
