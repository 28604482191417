import useFetch from '../../../hooks/useFetch';
import Layout from '../../Layout';
import { XrCustomizationQuery } from '../../../Queries';
import Image from '../../common/Image';
import LearnMore from '../../common/LearnMore';
import SideMenu from '../ProfessionalServices/sidemenu';
import Loader from '../../common/Loader';
import ServiceFeatureCard from './ServiceFeatureCard';
import { Link } from 'react-router-dom';
import SEO from '../../common/SEO';

export default function ProfessionalServicesXrCustomization(props) {
    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/support-xr-customization?' + XrCustomizationQuery);
    const { data } = response ? response : [];
    const { attributes } = data ? data : [];
    const { FeatureList } = attributes ? attributes : [];
    const { UsecaseList } = attributes ? attributes : [];
    const { VrFeatureList } = attributes ? attributes : [];
    if (loading) {
        return <Loader />;
    }
    const seoMeta = (attributes.seo) ? attributes.seo : null;
    return (
        <Layout>
            <SEO meta={seoMeta}></SEO>
            <main className="page-wrapper">
                <section className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="page-content">
                                <aside className="sidebar sidebar-content sticky-top d-none d-xl-flex">
                                    <SideMenu />
                                </aside>
                                <aside className="drawer-mobile sidebar-content d-flex d-xl-none">
                                    <div className="offcanvas offcanvas-start" tabIndex="-1" id="openCanvas" aria-labelledby="offcanvasExampleLabel">
                                        <div className="offcanvas-body">
                                            <div className="spacer-xs-3"></div>
                                            <SideMenu />
                                        </div>
                                    </div>
                                </aside>
                                <section className="main-content">
                                    <section className="banner banner-display-2">
                                        <div className="row">
                                            <div className="spacer-xs-8 spacer-xl-12"></div>
                                            <div className="entry-display-1 width-xxl-50 width-xxxl-68 order-2 order-xl-1 text-xl-start text-center">
                                                <h1>{attributes && attributes.Title ? attributes.Title : ''}</h1>
                                                <div className="spacer-xs-1"></div>
                                                <p>{attributes && attributes.Description ? attributes.Description : ''}</p>
                                            </div>
                                            <div className="entry-thumb d-flex flex-column flex-xl-row justify-content-center justify-content-xl-end align-items-center position-absolute order-1 order-xl-2">
                                                <div className="circle-bg d-none d-xl-block"></div>
                                                <div className="spacer-xs-5 order-1 d-flex d-xl-none"></div>
                                                <div className="entry-image img-overlay">
                                                    <Image src={attributes && attributes.CircleImage.data.attributes.url ? attributes.CircleImage.data.attributes.url : ''} className="img-fluid" />
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="spacer-xs-8 spacer-xl-12"></div>
                                    <section className="entry-structure">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="entry-display-1">
                                                    <div className="entry-thumb img-overlay">
                                                        <Image src={attributes && attributes.BannerImage.data.attributes.url ? attributes.BannerImage.data.attributes.url : ''} className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <div className="spacer-xs-8 spacer-xl-12"></div>
                                    <section className="entry-structure-light">
                                        <div className="spacer-xs-8 spacer-xl-10"></div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="entry-display-3">
                                                    <h3>{attributes && attributes.SubTitle ? attributes.SubTitle : ''}</h3>
                                                    <div className="spacer-xs-1"></div>
                                                    <p>{attributes && attributes.SubDescription ? attributes.SubDescription : ''}</p>
                                                    <div className="spacer-xs-4 spacer-xl-6"></div>
                                                    <div className="entry-thumb img-overlay">
                                                        <Image src={attributes && attributes.SubImage.data.attributes.url ? attributes.SubImage.data.attributes.url : ''} className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="spacer-xs-8 spacer-xl-10"></div>
                                    </section>
                                    <section className="entry-structure">
                                        <div className="spacer-xs-8 spacer-xl-12"></div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="entry-display-2">
                                                    <h2>{attributes && attributes.ArTitle ? attributes.ArTitle : ''}</h2>
                                                    <div className="spacer-xs-1"></div>
                                                    <p>{attributes && attributes.ArDescription ? attributes.ArDescription : ''}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="spacer-xs-4 spacer-xl-6"></div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="entry-display-4 bg-primary px-4 py-3 rounded-2 d-flex align-items-center flex-lg-row flex-column">
                                                    <h4 className="text-white fw-regular fs-lg-22 flex-1 pe-lg-4 mb-3 mb-lg-0 text-center text-lg-start">
                                                        {attributes && attributes.ArQuote ? attributes.ArQuote : ''}
                                                    </h4>
                                                    <Link reloadDocument={true} to={attributes && attributes.ArQuoteLink ? attributes.ArQuoteLink : '#'} className="btn btn-white btn-sm text-primary px-5 text-nowrap">Learn More</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="entry-structure">
                                        <div className="spacer-xs-8 spacer-xl-12"></div>
                                        {FeatureList.map((itemSolution, indexSolution) => {
                                            return <ServiceFeatureCard key={'feature-card-2-' + indexSolution} lastItem={indexSolution === 3} itemIndex={indexSolution} item={itemSolution} linkFor="xr-customization" />;
                                        })}
                                    </section>
                                    <section className="entry-structure">                                    
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="entry-display-2">
                                                <h2>{attributes && attributes.VrTitle ? attributes.VrTitle : ''}</h2>
                                                <div className="spacer-xs-1"></div>
                                                <p>{attributes && attributes.VrDescription ? attributes.VrDescription : ''}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="spacer-xs-4 spacer-xl-6"></div>
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="entry-display-4 bg-primary px-4 py-3 rounded-2 d-flex align-items-center flex-lg-row flex-column">
                                                <h4 className="text-white fw-regular fs-lg-22 flex-1 pe-lg-4 mb-3 mb-lg-0 text-center text-lg-start">
                                                {attributes && attributes.VrQuote ? attributes.VrQuote : ''}
                                                </h4>
                                                <Link reloadDocument={true} to={attributes && attributes.VrQuoteLink ? attributes.VrQuoteLink : '#'} className="btn btn-white btn-sm text-primary px-5 text-nowrap">Learn More</Link>
                                            </div> 
                                        </div>
                                    </div>
                                </section>
                                <section className="entry-structure">
                                    <div className="spacer-xs-8 spacer-xl-12"></div>
                                    {VrFeatureList.map((itemSolution, indexSolution) => {
                                            return <ServiceFeatureCard key={'feature-card-2-' + indexSolution} lastItem={indexSolution === 3} itemIndex={indexSolution} item={itemSolution} linkFor="xr-customization" />;
                                        })}
                                </section>
                                    <section className="entry-structure-light check-list">
                                        <div className="spacer-xs-8 spacer-xl-10"></div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="entry-display-4">
                                                    <h4>{attributes && attributes.UsecaseTitle ? attributes.UsecaseTitle : ''}</h4>
                                                </div>
                                                <div className="spacer-xs-3"></div>
                                                <ul>
                                                    {UsecaseList.map((itemUsecase, indexUsecase) => {
                                                        return <li key={indexUsecase}>{itemUsecase && itemUsecase.Text ? itemUsecase.Text : ''}</li>
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="spacer-xs-8 spacer-xl-10"></div>
                                    </section>
                                    <section className="entry-structure">
                                        <div className="spacer-xs-8 spacer-xl-10"></div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="entry-display-3">
                                                    <div className="entry-thumb img-overlay">
                                                        <Image src={attributes && attributes.ExtraImage.data.attributes.url ? attributes.ExtraImage.data.attributes.url : ''} className="img-fluid" />
                                                    </div>
                                                    <div className="spacer-xs-4 spacer-xl-6"></div>
                                                    <p>{attributes && attributes.ExtraDescription ? attributes.ExtraDescription : ''}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="spacer-xs-4 spacer-xl-6"></div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="entry-display-4 bg-primary px-4 py-3 rounded-2 d-flex align-items-center flex-lg-row flex-column">
                                                    <h4 className="text-white fw-regular fs-lg-22 flex-1 pe-lg-4 mb-3 mb-lg-0 text-center text-lg-start">
                                                        {attributes && attributes.ExtraQuote ? attributes.ExtraQuote : ''}
                                                    </h4>
                                                    <Link reloadDocument={true} to="/contact-us" className="btn btn-white btn-sm text-primary px-5 text-nowrap">Contact Us</Link>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="spacer-xs-8 spacer-xl-10"></div>
                                    </section>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
                <LearnMore />
            </main>
        </Layout>
    );
}
