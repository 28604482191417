import {  SolutionByProductThreeDFrameOverview} from '../../../Queries';
import useFetch from '../../../hooks/useFetch';
import { Link } from 'react-router-dom';
import Loader from '../../common/Loader';
import ByProductLayout from './ByProductLayout';
import bgImage from '../../../images/products/3dframe/banner-bg.png';
import SEO from '../../common/SEO';

export default function ThreeDFrameOverviewPage() {
  const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/three-d-frame-overview?' + SolutionByProductThreeDFrameOverview);
  //SolutionByProductEnworkOverview
  if (loading) {
      return <Loader />;
  }
  const { data } = response ? response : [];
  const { attributes } = data ? data : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null
    return (
    <ByProductLayout menuHeading="Products" backgroundImage={bgImage} logo={attributes.Logo} page="overview">
            <SEO meta={seoMeta}></SEO>
      <section className="entry-structure">
        <div className="spacer-xs-8 spacer-xl-12"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-1">
                        <h1>{attributes.Title}</h1>
                        <div className="spacer-xs-1"></div>
                        <p>{attributes.Description}</p>
                        <div className="spacer-xs-4"></div>
                        <a href="https://app.3dframe.vection-technologies.com/signup" target="_blank" className="btn btn-primary btn-sm text-white icon-btn px-5 py-3">Get Started<i className="icon-comman icon-arrow-right ms-3 fs-lg-18"></i></a>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-1">
                        <div className="entry-video-1">
                            <video id='video' controls="controls" preload='none' height="100%" width="100%" poster={attributes.VideoCover.data.attributes.url}>
                                <source id='mp4' src="images/solutions/products/3d-frame-video.mp4" type='video/mp4' />
                                <source id='webm' src={attributes.VideoWebm.data.attributes.url} type='video/webm' />
                                <source id='ogv' src={attributes.VideoOgg.data.attributes.url} type='video/ogg' />
                                <p>Your user agent does not support the HTML5 Video element.</p>
                            </video>
                        </div>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-8 spacer-xl-12"></div>
        </section>
        <section className="entry-structure-light theme-lite">
        <div className="spacer-xs-8 spacer-xl-12"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-3">
                        <h3>{attributes.EnterpriseTitle}</h3>
                        <div className="spacer-xs-4 spacer-xl-6"></div>
                    </div>
                </div>
            </div>
            <div className="row gx-6 justify-content-start">
                {attributes.EnterpriseList.map((enterprise,index) => {
                    return <div className="col-sm-6 mb-4"  key= {index}>
                    <div className="card mb-3 bg-transparent border-0">
                        <div className="row g-0">
                            <div className="col-md-auto d-flex justify-content-center">
                                <div className={"entry-icon mb-4 " + enterprise.IconColor}>
                                    <span><i className={"icon-product " + enterprise.IconSlug}></i></span> 
                                </div>
                            </div>
                            <div className="col-md-10">
                            <div className="entry-display-5 ps-0 ps-md-6 text-center text-md-start">
                                    <h5>{enterprise.Title}<i className="icon-comman icon-right"></i></h5>
                                    <p>{enterprise.Description}</p>
                                    <a href="#" className="stretched-link"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                })}
            </div>
            <div className="spacer-xs-4 spacer-xl-6"></div>
        </section>
        <section className="entry-structure product-list-2">
        <div className="spacer-xs-8 spacer-xl-12"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-3 text-center">
                        <h3>{attributes.NeedTitle}</h3>
                        <p>{attributes.NeedDescription}</p>
                        <div className="spacer-xs-4 spacer-xl-6"></div>
                        <div className="row flex-wrap gx-5">
                            {attributes.NeedList.map((need ,index) => {
                                return <div className="col-sm-6 col-lg-4 mb-4" key= {index}>
                                <div className="card border-0 bg-transparent py-3">
                                    <div className="entry-thumb img-overlay">
                                        <img src={need.Image.data.attributes.url}className="img-fluid"/>
                                    </div>
                                    <div className="entry-display-4 text-center">
                                        <h4>{need.Title}</h4>
                                        <p>{need.Description}</p>
                                    </div>
                                </div>
                            </div>
                            } )}
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section className="entry-structure product-list-3">
            <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-3 text-center">
                        <h3>{attributes.BuildTitle}</h3>
                        <p>{attributes.BuildDescription}</p>
                        <div className="spacer-xs-4 spacer-xl-6"></div>
                        <div className="row flex-wrap gx-4">
                            {attributes.BuildStepList.map((buildStep,index) => {
                                return (
                                <div className="col-md-6 col-lg-4 mb-4" key={index}>
                                <div className="card border-0 bg-transparent py-3">
                                    <div className="entry-thumb img-overlay">
                                        <img src={buildStep.Image.data.attributes.url} className="img-fluid"/>
                                    </div>
                                    <div className="entry-display-5 text-center text-md-start">
                                        <h5>{buildStep.Title}</h5>
                                        <p>{buildStep.Description}</p>
                                    </div>
                                </div>
                            </div> )})}
                        </div>
                        <div className="spacer-xs-4 spacer-xl-6"></div>
                        <div className="row">
                            <div className="col-sm-12 d-flex justify-content-center">
                            <a href="https://app.3dframe.vection-technologies.com/signup" target="_blank" className="btn btn-primary btn-lg text-white icon-btn">Get Started<i className="icon-comman icon-arrow-right ms-3 fs-lg-22"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-8 spacer-lg-12"></div>
        </section>
    </ByProductLayout>
  );
}

function GetStarted() {
  return (
    <Link reloadDocument={true} to="#" className="btn btn-primary btn-sm text-white icon-btn px-5 py-3">
      Get Started<i className="icon-comman icon-arrow-right ms-3 fs-lg-18"></i>
    </Link>
  );
}
