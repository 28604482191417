import { useEffect, useState } from 'react';
import useFetch from '../../hooks/useFetch';
import Loader from '../common/Loader';
import Breadcrumb from '../common/Breadcrumb';
import LearnMore from '../common/LearnMore';
import Layout from '../Layout';
import Links from './Links';
import moment from 'moment';
import icBgImg from '../../images/ic-bg.png';
import { stockApi } from '../../services/stockApi';
import SEO from './../common/SEO';

export default function MarketAnnouncementPage() {
  const [chuckedList, setChuckedList] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [activeYear, setActiveYear] = useState(moment().get('year'));
  const currentYear = moment().get('year');
  const [announcementList, setAnnouncementList] = useState(null);
  const [list, setList] = useState(null);

  const perPage = 10;
  const totalYears = 8;
  let years = [];
  for (var i = 0; i < totalYears; i++) {
    years.push(currentYear - i);
  }

  let breadcrumbLinks = [
    { 'link': '/', 'displayName': 'Home' },
    { 'link': '/investor-center', 'displayName': 'Investment Center' },
    { 'link': '/investor-center/market-announcement', 'displayName': 'Market Announcements' }
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = (count = currentYear) => {
    stockApi.announcements(
      count,
      response => {
        if (response) {
          const { announcementList } = response;
          setAnnouncementList(announcementList);
          getPaginatedData(announcementList);
        }
      },
      error => {
        console.log('error:', error);
      }
    );
  };

  const getPaginatedData = announcementData => {
    let chuckedList = sliceIntoChunks(announcementData, perPage);
    setChuckedList(chuckedList);
    if (chuckedList) {
      setList(chuckedList[activePage - 1]);
      setTotalPages(chuckedList.length);
    } else {
      setList([]);
      setTotalPages(0);
    }
  };

  const sliceIntoChunks = (arr, chunkSize) => {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
      const chunk = arr.slice(i, i + chunkSize);
      res.push(chunk);
    }
    return res;
  };

  const pagination = () => {
    let items = [];
    let i = 0;
    while (i < totalPages) {
      i++;
      items.push(
        <li
          className={'page-item ' + (activePage === i ? 'active' : '')}
          key={'pagination-' + i}
          onClick={e => {
            handlePageChange(e);
          }}
          data-page={i}
        >
          <a className="page-link" href="#">
            {i}
          </a>
        </li>
      );
    }
    return items;
  };

  const handleYearChange = year => {
    setList([]);
    setTimeout(() => {
      getData(year);
    }, 500);
  };

  const handlePageChange = e => {
    let page = e.target.firstChild.data;
    setActivePage(page);
    setList(chuckedList[page - 1]);
  };

  const handleNext = () => {
    if (activePage < totalPages) {
      let page = activePage + 1;
      if (chuckedList[page - 1]) {
        setActivePage(page);
        setList(chuckedList[page - 1]);
      }
    }
  };

  const handlePrev = () => {
    if (activePage > 0) {
      let page = activePage - 1;
      if (chuckedList[page - 1]) {
        setActivePage(page);
        setList(chuckedList[page - 1]);
      }
    }
  };

  const { loading, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/investor-market-announcement?populate=*');
  if (loading) {
    return <Loader />;
  }
  const { attributes } = response ? response.data : [];
  const seoMeta = (attributes.seo) ? attributes.seo : null

  return (
    <Layout>
      <SEO meta={seoMeta}></SEO>
      <main className="page-wrapper">
        <section className="banner-display-4 text-center text-lg-start" style={{ backgroundImage: `url(${icBgImg})` }}>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="entry-display-1">
                  <h1>{attributes.Title}</h1>
                  <div className="spacer-xs-1"></div>
                  <div aria-label="breadcrumb">
                    <Breadcrumb breadcrumbLinks={breadcrumbLinks} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <aside className="drawer-mobile sidebar-content d-flex d-xl-none">
          <div className="offcanvas offcanvas-start" tabIndex="-1" id="openCanvas" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-body handler-menu">
              <div className="spacer-xs-3"></div>
              <div className="sidebartitle">
                <h2>Investor Center</h2>
              </div>
              <Links />
            </div>
          </div>
        </aside>
        <section className="handler-menu d-none d-xl-block">
          <div className="spacer-xs-3"></div>
          <Links />
          <div className="spacer-xs-3"></div>
        </section>
        <section className="market-announcement">
          <div className="container">
            <div className="spacer-xs-8 spacer-lg-10"></div>
            <div className="row justify-content-center">
              <div className="col-sm-12">
                <div className="entry-display-2 text-center">
                  <h2>{attributes.Title}</h2>
                  <p>{attributes.Description}</p>
                </div>
              </div>
            </div>
            <div className="spacer-xs-4"></div>
            <div className="row justify-content-center">
              <div className="width-lg-80">
                {/* <div className="d-flex align-items-center justify-content-center flex-column flex-lg-row">
                  <p className="list-title">choose a Year:</p>
                  <ul className="nav nav-pills mt-3 mt-lg-0 d-flex justify-content-center" id="pills-tab" role="tablist">
                    {years.map((year, index) => {
                      return (
                        <li className="nav-item" role="presentation" key={'nav-item-' + index}>
                          <button
                            className={'nav-link ' + (activeYear === year ? 'active' : '')}
                            id={'pills-' + year + '-tab'}
                            data-bs-toggle="pill"
                            data-bs-target={'#pills-' + year}
                            type="button"
                            role="tab"
                            aria-controls={'pills-' + year}
                            aria-selected="true"
                            onClick={() => handleYearChange(year)}
                          >
                            {year}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div> */}
                {/* <div className="spacer-xs-4 spacer-lg-6"></div> */}
                <div className="tab-content" id="pills-tabContent">
                  <div className="tab-pane fade show active" id="pills-one" role="tabpanel" aria-labelledby="pills-one-tab">
                    {list !== null && (
                      <>
                        <ul className="list-info p-0 m-0">
                          {list && list.map((item, index) => {
                            return (
                              <li key={'announcement-' + index}>
                                <h5>{moment(item.document_date).format('DD MMM YYYY')}</h5>
                                <i className="icon-comman  icon-order"></i>
                                <a href={item.url} target="_blank" download>
                                  {item.header}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                        <div className="spacer-lg-9 spacer-xs-5"></div>
                        <div className="pagination">
                          <ul className="d-flex justify-content-center list-unstyled">
                            {activePage > 1 && (
                              <li className="page-item" onClick={e => handlePrev(e)}>
                                <a className="page-link" href="#" tabIndex="-1" aria-disabled="true">
                                  Prev
                                </a>
                              </li>
                            )}
                            {pagination()}
                            {activePage < totalPages && (
                              <li className="page-item" onClick={e => handleNext(e)}>
                                <a className="page-link" href="#">
                                  Next
                                </a>
                              </li>
                            )}
                          </ul>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="spacer-xs-8 spacer-lg-12"></div>
          </div>
        </section>
        <LearnMore />
      </main>
    </Layout>
  );
}
