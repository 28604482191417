import SEO from './SEO';
import Loader from './Loader';
import useFetch from '../../hooks/useFetch';

export default function SEOPage(props) {
    const { loading, error, response } = useFetch(props.url);
    if (loading) {
        return <Loader />;
    }
    const { data } = response ? response : [];
    if (data === null)
        return
    const { attributes } = data ? data : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null
    return (
        <SEO meta={seoMeta}></SEO>
    )

}
