import { useEffect } from 'react';
import $ from 'jquery';
import Breadcrumb from '../common/Breadcrumb';
import companyBannerImg from '../../images/company/company-banner-bg.svg';
import CompanyLayout from './CompanyLayout';
import { CompanyGlobalOffice } from '../../Queries';
import useFetch from '../../hooks/useFetch';
import Loader from '../common/Loader';
import imgParth from '../../images/company/global-offices/perth.svg';
import Image from '../common/Image';
import { Link } from 'react-router-dom';
import SEOPage from '../common/SEOPage';

export default function GlobalOffices() {
  useEffect(() => {
    var windowWidth = $(window).width();
    var container = $('.container').width();

    var calcWidth = windowWidth - container;

    $('.art').css('right', -(calcWidth / 2));
  });
  const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/company-regions?' + CompanyGlobalOffice);

  if (loading) {
    return <Loader />;
  }
  const { data } = response ? response : [];

  let breadcrumbLinks = [
    { 'link': '/', 'displayName': 'Home' },
    { 'link': '/company/vection-technologies', 'displayName': 'Company' },
    { 'link': '/company/global-offices', 'displayName': 'Global Offices' }
  ];
  const url = process.env.REACT_APP_CMS_URL + '/api/global-offices-overview?populate=*';
  return (
    <CompanyLayout>
      <SEOPage url={url}></SEOPage>
      <div className="spacer-xs-8 spacer-lg-12"></div>
      <section className="country-block">
        {data &&
          data.map((item, index) => {
            return (
              <div key={index} className="row gx-3">
                <div className="col-sm-12">
                  <div className="entry-display-3">
                    {index == 0 && <p className="sub-heading text-primary fw-semibold text-uppercase  mb-5">Global Offices</p>}
                    <h3>{item && item.attributes.Name ? item.attributes.Name : ''}</h3>
                  </div>
                </div>
                <div className="spacer-xs-3"></div>
                {item.attributes.CompanyLocations.data &&
                  item.attributes.CompanyLocations.data
                    .sort((a, b) => (a.id > b.id ? 1 : -1))
                    .map((itemCity, indexCity) => {
                      return (
                        <div key={indexCity} className="width-xs-50 width-md-30 width-lg-25">
                          <div className="card text-center p-4 mb-4">
                            <div className="entry-thumb mb-4">
                              <Image
                                src={itemCity && itemCity.attributes.Image.data.attributes.url ? itemCity.attributes.Image.data.attributes.url : ''}
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            <h4>{itemCity && itemCity.attributes.City ? itemCity.attributes.City : ''}</h4>
                            <Link reloadDocument={true} to={'/company/global-offices/' + itemCity.attributes.Slug} className="stretched-link"></Link>
                          </div>
                        </div>
                      );
                    })}
                    
                    {index < data.length-1 ? <div class="spacer-xs-6 spacer-lg-8"></div> : ''}
              </div>
            );
          })}
          <div className="spacer-xs-8 spacer-xl-10"></div>
      </section>
    </CompanyLayout>
  );
}
