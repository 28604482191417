import React, { useLayoutEffect, useRef } from 'react';
import Image from '../common/Image';
import { Autoplay, Controller, Navigation, Pagination, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default function Business(props) {
  const { BusinessTitle, BusinessIcons } = props;

  const swiper1Ref = useRef();
  const swiper2Ref = useRef();
  useLayoutEffect(() => {
    swiper1Ref.current.controller.control = swiper2Ref.current;
  }, []);

  return (
    <div className="row justify-content-center">
      <div className="col-lg-12">
        <div className="company-logo overflow-hidden">
          <h2 className="text-white fw-bold">{BusinessTitle}</h2>
          <div className="spacer-xs-2 spacer-lg-4"></div>
          <Swiper
            className="swiper swiper-company-logo d-flex justify-content-center swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events"
            modules={[Autoplay, Controller]}
            onSwiper={swiper => {
              swiper1Ref.current = swiper;
            }}
            loop={true}
            speed={5000}
            slidesPerView="auto"
            spaceBetween={0}
            autoplay={{
              delay: 1
              // disableOnInteraction: true,
            }}
            preventInteractionOnTransition={true}
            navigation={{
              nextEl: '.swiper-button-next-unique',
              prevEl: '.swiper-button-prev-unique'
            }}
            keyboard={{ enabled: true, onlyInViewport: false }}
            pagination={{
              el: '.swiper-pagination',
              clickable: true
            }}
            breakpoints={{
              340: {
                slidesPerView: 'auto',
                spaceBetween: 80,
                allowTouchMove: false
              },
              640: {
                slidesPerView: 'auto',
                spaceBetween: 0,
                allowTouchMove: false
              },
              768: {
                slidesPerView: 'auto',
                spaceBetween: 0,
                allowTouchMove: false
              },
              1024: {}
            }}
          >
            {BusinessIcons.data.map((item, index) => {
              return (
                <SwiperSlide className="swiper-slide width-xs-20 d-flex justify-content-center align-items-center" key={'swiper-slide-' + index}>
                  <img src={item.attributes.url} className="img-fluid" alt="" />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </div>
  );
}
