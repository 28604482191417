import LearnMore from '../../common/LearnMore';
import { SolutionByProductEnworkOverview } from '../../../Queries';
import useFetch from '../../../hooks/useFetch';
import Loader from '../../common/Loader';
import BannerVideoUrl from './BannerVideoUrl';
import ByProductLayout from './ByProductLayout';
import BenefitCard from './BenefitCard';
import FeatureCard from './FeatureCard';
import Image from '../../common/Image';
import bgImage from '../../../images/products/3dframe/banner-bg.png';
import { Link } from 'react-router-dom';
import SEO from '../../common/SEO';

export default function EnworkOverviewPage() {
    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/product-emworks-overview?' + SolutionByProductEnworkOverview);
    if (loading) {
        return <Loader />;
    }
    const { data } = response ? response : [];
    const { attributes } = data ? data : [];
    const { BenefitList } = attributes ? attributes : [];
    const { SolutionList } = attributes ? attributes : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null
    return (
        <ByProductLayout menuHeading="Products" backgroundImage={bgImage} logo={attributes.Logo} >
            <SEO meta={seoMeta}></SEO>
            <BannerVideoUrl title={attributes.Title} description={attributes.Description} video={attributes.Video} link="/solutions/products/enwork/get-started" />
            <section className="entry-structure-light theme-lite">
                <div className="spacer-xs-8 spacer-xl-12"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3 text-center text-xl-start">
                            <h3>{attributes && attributes.BenefitTitle ? attributes.BenefitTitle : ''} </h3>
                            <p>{attributes && attributes.BenefitDescription ? attributes.BenefitDescription : ''}</p>
                            <div className="spacer-xs-4 spacer-lg-6"></div>
                        </div>
                    </div>
                </div>
                <div className="row gx-6 justify-content-start">
                    {BenefitList.map((item, index) => {
                        return <BenefitCard index={index} item={item} />;
                    })}
                </div>
                <div className="spacer-xs-4 spacer-xl-6"></div>
            </section>
            <section className="entry-structure">
                <div className="spacer-xs-8 spacer-xl-12"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-2">
                            <div className="entry-thumb img-overlay">
                                <Image src={attributes && attributes.Image.data.attributes.url ? attributes.Image.data.attributes.url : ''} className="img-fluid" />
                            </div>
                            <div className="spacer-xs-6 spacer-xl-12"></div>
                            <h2>{attributes && attributes.SolutionTitle ? attributes.SolutionTitle : ''}</h2>
                            <p>{attributes && attributes.SolutionDescription ? attributes.SolutionDescription : ''}</p>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-6 spacer-xl-8"></div>
                {SolutionList.map((itemSolution, indexSolution) => {
                    return <FeatureCard key={'feature-card-2-' + indexSolution} lastItem={indexSolution === 2} itemIndex={indexSolution} item={itemSolution} />;
                })}
                <div className="row">
                    <div className="col-sm-12 d-flex justify-content-center">
                        <Link reloadDocument={true} to="/solutions/products/enwork/get-started" className="btn btn-primary btn-lg text-white icon-btn">Get Started<i className="icon-comman icon-arrow-right ms-3 fs-lg-22"></i></Link>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-12"></div>
            </section>
        </ByProductLayout>
    );
}
