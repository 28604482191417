import React,{ useEffect } from "react";
import $ from "jquery";
import useFetch from "../../../hooks/useFetch"
import Loader from '../../common/Loader';
import Image from "../../common/Image";
import IndustryLayout from "./IndustryLayout"
import { IndustryHealthcareQuery } from "../../../Queries";
import ReactMarkdown from "react-markdown";
import SEO from './../../common/SEO';

export default function IndustryHealthcare() {
    useEffect(() => {
        var windowWidth = $(window).width();
        var container = $('.container').width();

        var calcWidth = windowWidth - container;

        $('.art').css('right', - (calcWidth / 2));
    },[])
    
    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/industry-healthcare?' + IndustryHealthcareQuery);
    
    if (loading) {
        return <Loader />;
    }
    const { attributes } = response ? response.data : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null
    return (<IndustryLayout>
            <SEO meta={seoMeta}></SEO>
          <section className="entry-section">
            <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-1">
                        <p className="sub-heading text-primary fw-semibold">HEALTHCARE AND PHARMA</p>
                    <h1>{attributes.Title ? attributes.Title : ''}</h1>
                    <div className="spacer-xs-4 spacer-xl-6"></div>
                    <div className="entry-thumb">
                    <Image src={attributes && attributes.Image ? attributes.Image.data.attributes.url : ''} className="img-fluid" />
                    </div>
                    <div className="spacer-xs-4 spacer-xl-6"></div>
                    <p>{attributes.Description ? attributes.Description : ''}
                    </p>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-8 spacer-xl-12"></div>
        </section>
        <section className="entry-structure-light">
            <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-3">
                    <h3>{attributes.ImmersiveTitle ? attributes.ImmersiveTitle : ''}</h3>
                    <div className="spacer-xs-1"></div>
                    <ReactMarkdown className="reactMarkdown" children={attributes.ImmersiveDescription ? attributes.ImmersiveDescription : ''} components={{
                        a: ({ children, href }) => <a href={href} className='sub-link' target='_blank'>{children[0]}</a>
                        }} />
                    <p></p>
                    <div className="spacer-xs-4 spacer-xl-6"></div>
                    <div className="entry-thumb">
                    <Image src={attributes && attributes.ImmersiveImage ? attributes.ImmersiveImage.data.attributes.url : ''} className="img-fluid" />
                    </div>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-8 spacer-xl-10"></div>
        </section>
        <div className="spacer-xs-8 spacer-xl-12"></div>
        <section className="entry-structure">
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-2">
                    <h2>{attributes.AugmentedTitle ? attributes.AugmentedTitle : ''}</h2>
                    <div className="spacer-xs-1"></div>
                     <ReactMarkdown children={attributes.AugmentedDescription ? attributes.AugmentedDescription : ''}/>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-4"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-3">
                    <h3>{attributes.WhatisARTitle ?attributes.WhatisARTitle : ''}</h3>
                    <div className="spacer-xs-1"></div>
                    <p>{attributes.WhatisARDescription ? attributes.WhatisARDescription : ''}</p>
                    </div>
                </div>
            </div>
        </section>
        <div className="spacer-xs-8 spacer-xl-12"></div>
        <section className="entry-structure-light">
            <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-2">
                    <h2>{attributes.ApplicationsARTitle ? attributes.ApplicationsARTitle : ''}</h2>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-6 spacer-xl-8"></div>
            {attributes.HealthcareApplicationsARList && attributes.HealthcareApplicationsARList.map((item,index) => {
                return (
                    <React.Fragment key={index}>
                    <div className="row gx-6 block-card block-card-sm">
                        <div  className={(index % 2) == 0 ? "col-lg-6 order-lg-1 order-2" : "col-lg-6 order-lg-2 order-1"}>
                            <div className="entry-display-3">
                                <h3>{item.Title}</h3>
                                <div className="spacer-xs-1"></div>
                                <p>{item.Description}</p>
                            </div>
                        </div>
                        <div className={(index % 2) == 0 ? "col-lg-6  mb-4 mb-xl-0  order-lg-2 order-1" : "col-lg-6  mb-4 mb-xl-0  order-lg-1 order-2"}>
                            <div className="entry-thumb">
                            <Image src={item && item.Image ? item.Image.data.attributes.url : ''} className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div className="spacer-xs-8 spacer-xl-12"></div>
                    </React.Fragment>
                )
            })}
        </section>
        <div className="spacer-xs-8 spacer-xl-12"></div>
        <section className="entry-structure">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-2">
                            <h2>{attributes.IndustryTitle ? attributes.IndustryTitle : ''}</h2>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes.IndustryDescription ? attributes.IndustryDescription : ''}</p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
        <section className="entry-structure-light">
            <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-3">
                    <div className="entry-thumb">
                    <Image src={attributes && attributes.VRExplainedImage ? attributes.VRExplainedImage.data.attributes.url : ''} className="img-fluid" />
                    </div>
                    <div className="spacer-xs-6 spacer-xl-8"></div>
                    <h3>{attributes.VRExplainedTitle ? attributes.VRExplainedTitle : ''}</h3>
                    <div className="spacer-xs-1"></div>
                    <ReactMarkdown children={attributes.VRExplainedDescription ? attributes.VRExplainedDescription : ''}/>
                    <div className="spacer-xs-2"></div>  
                    <div className="accordion entry-accordian" id={'accordion0'}>
                        {attributes.HealthcareRealityList && attributes.HealthcareRealityList.map((item,index) => {
                             return (
                                <div className="accordion-item" key={index}>
                                <h2 className="accordion-header" id={'heading' + index}>
                                <button
                                className={"accordion-button " + (index == 0 ? "" : "collapsed")}
                                    type="button"
                                    data-bs-toggle="collapse"
                                    data-bs-target={'#collapse' + index}
                                    aria-expanded={true}
                                    aria-controls={'collapse' + index}
                                >
                                    {item.Title}
                                </button>
                                </h2>
                                <div id={'collapse' + index}  className={"accordion-collapse collapse " + (index == 0 ? "show" : "")} aria-labelledby={'heading' + index} data-bs-parent={'#accordion0'}>
                                <div className="accordion-body">{item.Description}</div>
                                </div>
                            </div>
                            )
                        })}
                     </div>                               
                    </div>
                </div>
            </div>
            <div className="spacer-xs-8 spacer-xl-10"></div>
        </section>
        <div className="spacer-xs-8 spacer-xl-12"></div>
        <section className="entry-structure">
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-2">
                    <h2>{attributes.ApplicationsVRTitle ? attributes.ApplicationsVRTitle : ''}</h2>
                    <div className="spacer-xs-1"></div>
                    <p>{attributes.ApplicationsVRDescription ? attributes.ApplicationsVRDescription : ''}</p>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-6 spacer-xl-8"></div>
            {attributes.HealthcareApplicationsVRList && attributes.HealthcareApplicationsVRList.map((item,index) => {
                return (
                    <React.Fragment key={index}>
                    <div className="row gx-6 block-card block-card-sm">
                        <div className={(index % 2) == 0 ? "col-lg-6 order-lg-1 order-2" : "col-lg-6 order-lg-2 order-1"}>
                            <div className="entry-display-3">
                                <h3>{item.Title}</h3>
                                <div className="spacer-xs-1"></div>
                                <p>{item.Description}</p>
                            </div>
                        </div>
                        <div className={(index % 2) == 0 ? "col-lg-6  mb-4 mb-lg-0  order-lg-2 order-1" : "col-lg-6  mb-4 mb-lg-0  order-lg-1 order-2"}>
                            <div className="entry-thumb">
                            <Image src={item && item.Image ? item.Image.data.attributes.url : ''} className="img-fluid" />
                            </div>
                        </div>
                    </div>
                    <div className="spacer-xs-8 spacer-xl-12"></div>
                    </React.Fragment>
                )
            })}
            <div className="spacer-xs-6 spacer-xl-8"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3">
                            <h3>{attributes.TakeawayTitle ? attributes.TakeawayTitle : ''}</h3>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes.TakeawayDescription ? attributes.TakeawayDescription : ''}</p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
    </IndustryLayout>)
}