import React from "react";
import { Link } from 'react-router-dom';
import Layout from "../../Layout";
import LearnMore from '../../common/LearnMore';
import useFetch from '../../../hooks/useFetch';
import Loader from '../../common/Loader';
import { useParams } from "react-router-dom";
import RelatedArticles from "./RelatedArticles"
import ReactMarkdown from "react-markdown";
import Image from "../../common/Image";
import { BlogInnerQuery } from "../../../Queries";
import SEO from './../../common/SEO';

export default function BlogInnerNew() {

    const { id } = useParams();

    function UlComponent(props) {
        return <ul className="list-group list-group-flush">
            {props.children.map((child, key) => {
                return child.props != undefined ? <li key={key} className="list-group-item">{child.props ? child.props.children[0] : ''}
                </li> : ''
            })}
        </ul>
    }

    // const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/blogs/?'+ BlogInnerQuery);

    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + `/api/blogs/?${BlogInnerQuery}&filters[slug][$eq]=${id}`);

    if (loading) {
        return <Loader />;
    }
    const { data } = response ? response : [];
    const { attributes } = data ? data[0] : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null

    const addClass = (header) => {
        switch (header) {
            case "Current Challenges":
                return "challanges";
            case "Features":
                return "features";
            case "Benefits":
                return "benefit";
            default:
                return;
        }
    }

    return (
        <Layout>
            <SEO meta={seoMeta}></SEO>
            <main className="page-wrapper">
                <section className="banner banner-display-1">
                    <div className="container">
                        <div className="spacer-xs-5 spacer-xl-13"></div>
                        <div className="row">
                            <div className="entry-display-1 col-sm-12">
                                <div className="entry-display-1">
                                    <Link reloadDocument={true} to="/blog" className="post-back fs-xs-20"><i className="icon-comman icon-right"></i> Back</Link>
                                    <div className="spacer-xs-3"></div>
                                    <h1>{attributes.Title ? attributes.Title : ''}</h1>
                                    <div className="spacer-xs-1"></div>
                                    <p>{attributes.SubTitle ? attributes.SubTitle : ''}</p>
                                </div>
                            </div>
                            <div className="spacer-xs-6"></div>
                            <div className="post-image img-overlay">
                                <Image src={attributes && attributes.Image ? attributes.Image.data.attributes.url : ''} className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </section>
                <div className="spacer-xs-5 spacer-xl-8"></div>
                <section className="post-content">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-sm-10">
                                <div className="entry-display-2">
                                    <h2>{attributes.HybridWorkTitle ? attributes.HybridWorkTitle : ''}</h2>
                                    {attributes.Quote && <> <div className="spacer-xs-2"></div>
                                        <div className="card-box">
                                            <p className="fs-xs-20 fw-medium">{attributes.Quote ? attributes.Quote : ''}</p>
                                        </div>
                                        <div className="spacer-xs-4"></div> </>}
                                    <ReactMarkdown
                                        className="react-markdown"
                                        children={attributes.HybridWorkDescription ? attributes.HybridWorkDescription : ''} />
                                    {attributes.VideoLink && <><div className="spacer-xs-7"></div>
                                        <iframe width="100%" height="500" src="https://www.youtube.com/embed/bBuVphyrfuM" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                                        <div className="spacer-xs-7"></div></>}
                                    <ReactMarkdown
                                        className="react-markdown"
                                        children={attributes.HybridWorkSubDescription ? attributes.HybridWorkSubDescription : ''} />
                                </div>
                                <div className="spacer-xs-4"></div>
                                {attributes.ChallengesFeaturesBenefitsList && <div className="row">
                                    {attributes.ChallengesFeaturesBenefitsList && attributes.ChallengesFeaturesBenefitsList.map((item, index) => {
                                        return <div className="col-sm-4" key={index}>
                                            <div className="card h-100 rounded-0">
                                                <div className={"card-header fw-bold " + addClass(item.Header)}>
                                                    {item.Header}
                                                </div>
                                                <ReactMarkdown
                                                    className="blog-markdown"
                                                    components={{
                                                        ul: UlComponent,
                                                    }}>
                                                    {item.Description}
                                                </ReactMarkdown>
                                            </div>
                                        </div>
                                    })}
                                </div>}
                                <div className="spacer-xs-8"></div>
                                {attributes.ContentWebexList && attributes.ContentWebexList.map((contentWebex, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="entry-display-3">
                                                <h3>
                                                    {contentWebex.TitleUrl &&
                                                        <a href={contentWebex.TitleUrl}>{contentWebex.Title}</a>
                                                    }
                                                    {!contentWebex.TitleUrl &&
                                                        <>{contentWebex.Title}</>
                                                    }
                                                </h3>
                                                <div className="spacer-xs-1"></div>
                                                <ReactMarkdown
                                                    className="blog-markdown"
                                                    components={{
                                                        img: ({ node, ...props }) => <><div className="spacer-xs-6"></div><div className="post-image img-overlay"><img className="img-fluid" {...props} /></div><div className="spacer-xs-6"></div></>
                                                    }}>
                                                    {contentWebex.Content}
                                                </ReactMarkdown>
                                            </div>
                                            <div className="spacer-xs-8"></div>
                                        </React.Fragment>
                                    )
                                })}
                                {attributes.QuoteInBlue &&
                                    <div className="card-box bg-primary-lighten border-0">
                                        <ReactMarkdown className="reactMarkdown" children={attributes.QuoteInBlue} components={{
                                            p: ({ children }) => <p className="fs-xs-20 fw-medium">{children}</p>,
                                            a: ({ children, href }) => <a href={href} className='sub-link' target='_blank'>{children[0]}</a>
                                        }} />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="spacer-xs-10"></div>
                        {/* <div className="row">
                  <div className="col-sm-12 text-center">
                     <a href="#" className="btn btn-outline-primary btn-sm mx-1 icon-btn">
                     <i className="icon-comman icon-arrow-right me-3 fs-lg-18 rotate-90"></i>
                     Previous
                     </a>
                     <a href="#" className="btn btn-primary btn-sm icon-btn text-white mx-1">
                     Next 
                     <i className="icon-comman icon-arrow-right ms-3 fs-lg-18"></i>
                     </a>
                  </div>
               </div>
               <div className="spacer-xs-8 spacer-lg-10"></div> */}
                    </div>
                </section>
                {/* <div className="devider"></div> */}
                {/* <RelatedArticles type="blogs" id={id} /> */}
                <LearnMore />
            </main>
        </Layout>
    )
}