import { useEffect } from "react";
import $ from "jquery";
import useFetch from "../../../hooks/useFetch"
import Loader from '../../common/Loader';
import IndustryLayout from "./IndustryLayout"
import { SolutionByIndustryMedia } from "../../../Queries"
import Image from '../../common/Image';
import RealityCard from './RealityCard';
import ReactMarkdown from 'react-markdown';
import SEO from './../../common/SEO';

export default function IndustryMedia() {
    useEffect(() => {
        var windowWidth = $(window).width();
        var container = $('.container').width();

        var calcWidth = windowWidth - container;

        $('.art').css('right', - (calcWidth / 2));
    }, [])

    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/industries-media?' + SolutionByIndustryMedia);

    if (loading) {
        return <Loader />;
    }
    const { attributes } = response ? response.data : [];
    const { RealityList } = attributes ? attributes : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null
    return (
        <IndustryLayout>
            <SEO meta={seoMeta}></SEO>
            <section className="entry-section">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-1">
                            <p className="sub-heading text-primary fw-semibold">MEDIA & COMMUNICATION</p>
                            <h1>{attributes && attributes.Title ? attributes.Title : ''}</h1>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <div className="entry-thumb">
                                <Image src={attributes && attributes.Image ? attributes.Image.data.attributes.url : ''} alt="" className="img-fluid" />
                            </div>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <p>{attributes && attributes.Description ? attributes.Description : ''}</p>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-12"></div>
            </section>
            <section className="entry-structure-light">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3">
                            <h3>{attributes && attributes.TechnologyTitle ? attributes.TechnologyTitle : ''}</h3>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes && attributes.TechnologyDescription ? attributes.TechnologyDescription : ''}</p>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <div className="entry-thumb">
                                <Image src={attributes && attributes.TechnologyImage ? attributes.TechnologyImage.data.attributes.url : ''} alt="" className="img-fluid" />
                            </div>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <div className="accordion entry-accordian" id="mediaaccordion">
                                {RealityList.map((item, index) => {
                                    return <RealityCard itemIndex={index} item={item} />;
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-10"></div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3">
                            <div className="entry-thumb">
                                <Image src={attributes && attributes.UseApplicationImage ? attributes.UseApplicationImage.data.attributes.url : ''} alt="" className="img-fluid" />
                            </div>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <h3>{attributes && attributes.UseApplicationTitle ? attributes.UseApplicationTitle : ''}</h3>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes && attributes.UseApplicationDescription ? attributes.UseApplicationDescription : ''}</p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure-light">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3">
                            <h3>{attributes && attributes.VrEventTitle ? attributes.VrEventTitle : ''}</h3>
                            <div className="spacer-xs-1"></div>
                            <ReactMarkdown className="reactMarkdown" children={attributes.VrEventDescription} components={{
                                a: ({ children, href }) => <a href={href} className='sub-link' target='_blank'>{children[0]}</a>
                            }} />
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <div className="entry-thumb">
                                <Image src={attributes && attributes.VrEventImage ? attributes.VrEventImage.data.attributes.url : ''} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-10"></div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3">
                            <h3>{attributes && attributes.ArAdvertisingTitle ? attributes.ArAdvertisingTitle : ''}</h3>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes && attributes.ArAdvertisingDescription ? attributes.ArAdvertisingDescription : ''}</p>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <div className="entry-thumb">
                                <Image src={attributes && attributes.ArAdvertisingImage ? attributes.ArAdvertisingImage.data.attributes.url : ''} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure-light">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3">
                            <h3>{attributes && attributes.VrFilmTitle ? attributes.VrFilmTitle : ''}</h3>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes && attributes.VrFilmDescription ? attributes.VrFilmDescription : ''}</p>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <div className="entry-thumb">
                                <Image src={attributes && attributes.VrFilmImage ? attributes.VrFilmImage.data.attributes.url : ''} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-10"></div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3">
                            <h3>{attributes && attributes.MetaverseTitle ? attributes.MetaverseTitle : ''}</h3>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes && attributes.MetaverseDescription ? attributes.MetaverseDescription : ''}</p>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <div className="entry-thumb">
                                <Image src={attributes && attributes.MetaverseImage ? attributes.MetaverseImage.data.attributes.url : ''} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure-light report-card">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3">
                            <h3>{attributes && attributes.TakeAwayTitle ? attributes.TakeAwayTitle : ''}</h3>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes && attributes.TakeAwayDescription ? attributes.TakeAwayDescription : ''}</p>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-10"></div>
            </section>
        </IndustryLayout>
    )
}