import Image from '../../common/Image';
import { Link } from 'react-router-dom';
import React from 'react';

export default function ServiceFeatureCard(props) {
  const { itemIndex, lastItem } = props;
  const direction = (itemIndex + 1) % 2 === 0 ? 'left' : 'right';
  const imageClass = direction === 'left' ? 'order-lg-1 order-1' : 'order-lg-2 order-1';
  const descriptionClass = direction === 'left' ? 'order-lg-2 order-2' : 'order-lg-1 order-2';
  return (
    <React.Fragment key={itemIndex}>
      <div  className="row gx-6 block-card block-card-md">
        <div className={'col-lg-6 ' + descriptionClass}>
          <div className="entry-display-3">
            <h3>{props && props.item.Title ? props.item.Title : ''}</h3>
            <div className="spacer-xs-1"></div>
            <p>{props && props.item.Description ? props.item.Description : ''}</p>
            {props.item.LinkList && LinkList(props.item.LinkList, props.linkFor)}
          </div>
        </div>
        <div className={'col-lg-6 mb-4 mb-xl-0 ' + imageClass}>
          <div className={props.for && props.for == "product" ? "entry-thumb entry-product" : "entry-thumb img-overlay"}>
            <Image src={props && props.item.Image.data.attributes.url ? props.item.Image.data.attributes.url : ''} className="img-fluid" alt="" />
          </div>
        </div>
      </div>

      {lastItem ? <div className="spacer-xs-8 spacer-xl-12"></div> : <div className="spacer-xs-8 spacer-xl-12"></div>}
    </React.Fragment>
  );
}
function buttonAndLink(linkFor, link) {
  if (linkFor != "xr-customization") {
    return (
      <>
        <div className="spacer-xs-3"></div>
        <Link reloadDocument={true} to={link} className="btn btn-primary btn-sm text-white px-5">Start From the Basics</Link>
        <div className="spacer-xs-2"></div>
        <Link reloadDocument={true} to="/contact-us" className="btn btn-helper">Contact us for the metaverse of the future.<i className="icon-comman icon-arrow-right ms-4 ms-auto"></i></Link>
      </>
    )
  }

}

function helperLink(itemList, indexList) {
  return (
    <>
      <div key={indexList} className="spacer-xs-3"></div>
      <Link reloadDocument={true} to={itemList.Link ? itemList.Link : "#"} className="btn btn-helper">{itemList.Label}<i className="icon-comman icon-arrow-right ms-4 ms-auto"></i></Link>
    </>
  )
}
function LinkList(list, linkFor) {
  return (
    <>
      {list && list.map((itemList, indexList) => {
        return (
          <React.Fragment key={indexList}>
            {linkFor == "metaverse" && buttonAndLink(linkFor, itemList.Link)}
            {linkFor != "metaverse" && helperLink(itemList, indexList)}

          </React.Fragment>
        )
      })}
    </>
  )

}
