import Layout from '../Layout';
import LearnMore from '../common/LearnMore';
import SideMenu from "../common/SideMenu";

export default function BenefitLayout(props) {
    const menuItems = [
        {
            name: 'Faster Time to Market',
            link: '/benefits/faster-time-to-market',
        },
        {
            name: 'Effective Training',
            link: '/benefits/effective-training',
        },
        {
            name: 'Improve Remote Sales',
            link: '/benefits/improve-remote-sales',
        },
        {
            name: 'Augmented Shopping Experience',
            link: '/benefits/augmented-shopping-experience',
        },
        {
            name: 'Increase Conversion Rates',
            link: '/benefits/increase-conversion-rate',
        }
    ];
    return (
        <Layout>
            <main className="page-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="page-content">
                                <SideMenu menuHeading="Benefits" menuItems={menuItems} />
                                <div className="main-content">
                                    {props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LearnMore />
            </main>
        </Layout>
    )
}