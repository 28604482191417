import { useEffect } from "react";
import $ from "jquery";
import useFetch from '../../hooks/useFetch';
import Loader from '../common/Loader';
import { CompanyOurStoryQuery } from '../../Queries';
import TimelineRow from "./TimelineRow";
import CompanyLayout from "./CompanyLayout";
import ReactMarkdown from 'react-markdown';
import SEO from './../common/SEO';
import Image from '../common/Image';

export default function OurStory() {
   useEffect(() => {
      var windowWidth = $(window).width();
      var container = $('.container').width();

      var calcWidth = windowWidth - container;

      $('.art').css('right', - (calcWidth / 2));
   })
   const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/company-story?' + CompanyOurStoryQuery);

   if (loading) {
      return <Loader />;
   }
   const { attributes } = response ? response.data : [];
   const seoMeta = (attributes.seo) ? attributes.seo : null
   return (<CompanyLayout>
      <SEO meta={seoMeta}></SEO>
      <section className="banner banner-display-2">
         <div className="row">
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <div className="entry-display-1 width-xxl-50 width-xxxl-68 order-2 order-xl-1 text-xl-start text-center">
               <p className="sub-heading text-primary fw-semibold text-uppercase">Our Story</p>
               <h1>{attributes.Title}</h1>
               <div className="spacer-xs-1"></div>
               <p className="fw-medium">{attributes.Description}</p>
               <div className="spacer-xs-4"></div>
            </div>
            <div className="entry-thumb d-flex flex-column flex-xl-row justify-content-center justify-content-xl-end align-items-center position-absolute order-1 order-xl-2">
               <div className="circle-bg d-none d-xl-block"></div>
               <div className="spacer-xs-5 order-1 d-flex d-xl-none"></div>
               <div className="entry-image img-overlay">
                  <Image src={attributes.Image.data.attributes.url} alt="" className="img-fluid" />
               </div>
            </div>
         </div>
      </section>
      <div className="spacer-xs-8 spacer-lg-10"></div>
      <section className="entry-structure-light timeline">
         <div className="spacer-xs-8 spacer-lg-10"></div>
         <div className="row">
            <div className="col-sm-12">
               <div className="entry-display-2">
                  <h2>{attributes.TimelineTitle}</h2>
                  <div className="spacer-lg-1"></div>
                  <ReactMarkdown className="reactMarkdownDiv text-black" children={attributes.TimelineDescription} />
               </div>
            </div>
         </div>
         <div className="spacer-xs-12"></div>
         <div className="row">
            <div className="col-sm-12 position-relative">
               <TimelineRow timelineList={attributes.TimelineList} />
               <div className="timeline-path"></div>
            </div>
         </div>
         <div className="spacer-xs-8 spacer-lg-12"></div>
      </section>
   </CompanyLayout>)
}