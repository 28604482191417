import React, { useEffect, useRef, useState } from 'react';
import $ from 'jquery';
import Layout from '../Layout';
import useFetch from '../../hooks/useFetch';
import Loader from '../common/Loader';
import { form } from '../../services/form';
import ReCAPTCHA from 'react-google-recaptcha';
import SEO from './../common/SEO';

export default function OpenPositions() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    message: ''
  });
  const [errors, setErrors] = useState(false);
  const [reqSending, setReqSending] = useState(false);
  const [success, setSuccess] = useState(false);
  const recaptchaRef = useRef(null);

  useEffect(() => {
    var windowWidth = $(window).width();
    var container = $('.container').width();
    var calcWidth = windowWidth - container;
    $('.art').css('right', -(calcWidth / 2));
  }, []);

  const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/company-position?populate=*');
  if (loading) {
    return <Loader />;
  }
  const { attributes } = response ? response.data : [];
  const seoMeta = (attributes.seo) ? attributes.seo : null

  const handleFormSubmit = e => {
    e.preventDefault();
    setReqSending(true);
    setSuccess(null);
    setErrors(null);
    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue) {
      form.openPositionsSubmit(
        { ...formData, 'g-recaptcha-response': recaptchaValue },
        success => {
          document.getElementById('open-positions-form').reset();
          setFormData({
            firstName: '',
            lastName: '',
            email: '',
            contactNumber: '',
            message: ''
          });
          recaptchaRef.current.reset();
          setSuccess('Submitted successfully.');
          setReqSending(false);
          setTimeout(() => {
            setSuccess(null);
          }, 5000);
        },
        error => {
          console.log('error:', error);
          setErrors(error);
          setReqSending(false);
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        }
      );
    }
  };

  return (
    <Layout>
      <SEO meta={seoMeta}></SEO>
      <main className="page-wrapper">
        <section className="open-position">
          <div className="spacer-xs-8 spacer-lg-12"></div>
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-6">
                <div className="entry-display-1">
                  <h1>{attributes.Title}</h1>
                  <div className="spacer-xs-1"></div>
                  <p style={{ whiteSpace: 'pre-wrap' }}>{attributes.Description}</p>
                </div>
                <div className="spacer-xs-8 spacer-lg-0"></div>
              </div>
              <div className="col-lg-6">
                <div className="flotable-from">
                  <form
                    id="open-positions-form"
                    method="post"
                    onSubmit={e => {
                      handleFormSubmit(e);
                    }}
                  >
                    <h3 className="mb-0">We'd love to hear from you and any positions you might be interested in.</h3>
                    <div className="spacer-xs-2"></div>
                    {errors &&
                      Object.keys(errors).map(index => {
                        return (
                          <React.Fragment key={'error-' + index}>
                            <span className="text-danger">{errors[index]}</span> <br />
                          </React.Fragment>
                        );
                      })}
                    <span className="text-success">{success}</span>
                    <div className="spacer-xs-2"></div>
                    <div className="form-floating form-icon mb-3">
                      <i className="icon-form icon-user"></i>
                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        id="firstName"
                        placeholder="First Name"
                        required
                        value={formData.firstName}
                        onChange={e => {
                          setFormData({ ...formData, firstName: e.target.value });
                        }}
                      />
                      <label htmlFor="Name">First Name</label>
                    </div>
                    <div className="form-floating form-icon mb-3">
                      <i className="icon-form icon-user"></i>
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        id="lastName"
                        placeholder="Last Name"
                        required
                        value={formData.lastName}
                        onChange={e => {
                          setFormData({ ...formData, lastName: e.target.value });
                        }}
                      />
                      <label htmlFor="Email">Last Name</label>
                    </div>
                    <div className="form-floating form-icon mb-3">
                      <i className="icon-form icon-envelope"></i>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Email Address"
                        required
                        value={formData.email}
                        onChange={e => {
                          setFormData({ ...formData, email: e.target.value });
                        }}
                      />
                      <label htmlFor="bname">Email Address</label>
                    </div>
                    <div className="form-floating form-icon mb-3">
                      <i className="icon-form icon-phone"></i>
                      <input
                        type="text"
                        className="form-control"
                        name="contactNumber"
                        id="number"
                        placeholder="Phone Number"
                        required
                        value={formData.contactNumber}
                        onChange={e => {
                          setFormData({ ...formData, contactNumber: e.target.value });
                        }}
                      />
                      <label htmlFor="bname">Phone Number</label>
                    </div>
                    <div className="mb-3">
                      <textarea
                        cols="100%"
                        rows="3"
                        type="text"
                        className="form-control"
                        placeholder="Message"
                        name="message"
                        id="message"
                        value={formData.message}
                        onChange={e => {
                          setFormData({ ...formData, message: e.target.value });
                        }}
                      ></textarea>
                    </div>
                    <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_TOKEN} ref={recaptchaRef} required />
                    <div className="spacer-xs-2"></div>
                    <button type="submit" className={'btn btn-primary d-flex justify-content-center align-items-center text-white w-100 ' + (reqSending ? 'disabled' : '')}>
                      {reqSending ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : <></>}
                      &nbsp; Get In Touch
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="spacer-xs-8 spacer-lg-12"></div>
        </section>
      </main>
    </Layout>
  );
}
