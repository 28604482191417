import React from "react";

export default function TimelineRow({ timelineList }){
   
    const checkNumber = (number) => {
       if(number % 2 == 0){
        return "even"
       }else{
        return "odd";
       }
    }
    return(
    <ul className="list-unstyled">
        {timelineList.map((timeline , index) => {
            return (
                <React.Fragment key={index}>
                <li className={"d-flex justify-content-" + ((checkNumber(index + 1) == "odd" ? "start ": "end ") + (checkNumber(index + 1) == "odd" ? " odd" : " even")) } key={index} >
                        <div className={"card width-lg-40 " + timeline.Color}>
                            <div className="card-body d-flex flex-column">
                                <div className="entry-thumb  align-self-center mt-n5 ">
                                    <span>
                                        <img src={timeline.Image.data.attributes.url}  alt=""/>
                                    </span>
                                </div>
                                <div className="spacer-xs-1"></div>
                                <h3>{timeline.Year}</h3>
                                <div className="spacer-xs-1"></div>
                                <p>{timeline.Description}</p>
                            </div>
                        </div>
                </li>
                {timelineList[timelineList.length - 1] && <li className="spacer-xs-10 spacer-xl-0"></li> }
                </React.Fragment>
            )
        })}
    </ul>
    )
}