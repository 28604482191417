import React from 'react';

export default function FaqAccordian(props) {
  const { FaqTitle, FaqList } = props;

  const totalItems = FaqList.length % 2 === 0 ? FaqList.length : FaqList.length + 1;
  return (
    <section className="faq">
      <div className="container">
        <div className="spacer-xs-8 spacer-md-10 spacer-lg-12"></div>
        <div className="row">
          <div className="col-sm-12">
            <div className="entry-display-3">
              <h3 className="text-center">{FaqTitle}</h3>
              <div className="spacer-xs-4 spacer-lg-10"></div>
              <div className="accordion comman-accordion" id={'accordion0'}>
                <div className="row g-lg-5">
                  <div className="col-lg-6">
                    {FaqList.map((item, itemIndex) => {
                      // display left accordion
                      if ((itemIndex + 1) % 2 == 0) return true;
                      let index = itemIndex;
                      return (
                        <div className="accordion-item" key={itemIndex}>
                          <h2 className="accordion-header" id={'heading' + index}>
                            <button
                              className={"accordion-button " + (index == 0 ? "" : "collapsed")}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={'#collapse' + index}
                              aria-expanded={false}
                              aria-controls={'collapse' + index}
                            >
                              {item.Title}
                            </button>
                          </h2>
                          <div id={'collapse' + index} className={"accordion-collapse collapse " + (index == 0 ? "show" : "")} aria-labelledby={'heading' + index} data-bs-parent={'#accordion0'}>
                            <div className="accordion-body">{item.Description}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="col-lg-6">
                    {FaqList.map((item, itemIndex) => {
                      // display right accordion
                      if (itemIndex  % 2 == 0) return true;
                      let index = totalItems / 2 + itemIndex + 2;
                      return (
                        <div className="accordion-item" key={itemIndex}>
                          <h2 className="accordion-header" id={'heading' + index}>
                            <button
                              className={"accordion-button collapsed"}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={'#collapse' + index}
                              aria-expanded={false}
                              aria-controls={'collapse' + index}
                            >
                              {item.Title}
                            </button>
                          </h2>
                          <div id={'collapse' + index} className="accordion-collapse collapse" aria-labelledby={'heading' + index} data-bs-parent={'#accordion0'}>
                            <div className="accordion-body">{item.Description}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="spacer-xs-6 spacer-md-10 spacer-lg-12"></div>
      </div>
    </section>
  );
}
