import StaticHeader from './common/StaticHeader';
import './developer.scss';

export default function Layout(props) {
  return (
    <>
      <header>
        <StaticHeader />
      </header>
      {props.children}
    </>
  );
}
