import React from 'react';
import { Link } from 'react-router-dom';

export default function Industry(props) {
  const { IndustryTitle, IndustryDescription, IndustryList } = props;
  const dataCount = IndustryList.length;
  
  return (
    <section className="industries theme--dark">
      <div className="container">
        <div className="spacer-xs-8 spacer-md-10 spacer-lg-12"></div>
        <div className="row">
          <div className="col-sm-12">
            <div className="entry-display-1">
              <h1>{IndustryTitle}</h1>
              <p>{IndustryDescription}</p>
              <div className="spacer-xs-6 spacer-lg-10"></div>
            </div>
          </div>
        </div>
        <div className="row gx-6 justify-content-start">
          {IndustryList.map((item, index) => {
            return (
              <div className="col-lg-6 col-xl-4 mb-4" key={index}>
                <div className="card mb-3 bg-transparent border-0">
                  <div className="row g-0">
                    <div className="col-md-auto d-flex justify-content-center">
                      <div className={'entry-icon mb-4 ' + item.IconColor}>
                        <span><i className={"icon-industries " + item.IconSlug}></i></span> 
                      </div>
                    </div>
                    <div className="col-md-10">
                      <div className="entry-display-5 ps-0 ps-md-6 text-center text-md-start">
                        <h5>
                          {item.Title}
                          <i className="icon-comman icon-right text-white"></i>
                        </h5>
                        <p>{item.Description}</p>
                        <Link reloadDocument={true} to="/"    className="stretched-link"></Link>
                        <div className="spacer-lg-1"></div>
                        {index < dataCount-3 ? <div className="divider-bottom d-none d-lg-flex"></div> : ""}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="spacer-xs-4"></div>
        <div className="row">
            <div className="col-sm-12 text-center">
                <Link reloadDocument={true} to="/solutions/industries/overview" className="btn btn-primary btn-sm px-5 text-white">See All</Link>
            </div>
        </div>
        <div className="spacer-xs-8 spacer-md-10 spacer-lg-12"></div>
      </div>
    </section>
  );
}
