import Layout from '../Layout';
import LearnMore from '../common/LearnMore';
import SideMenu from "../common/SideMenu";

export default function CompanyLayout(props) {
    const menuItems = [
        {
            name: ' What is Vection Technologies',
            link: '/company/vection-technologies',
        },
        {
            name: 'Our Story',
            link: '/company/our-story',
        },
        {
            name: 'Brands that support our vision',
            link: '/company/our-vision',
        },
        {
            name: 'Global Offices',
            link: '/company/global-offices',
            subMenuLinks: [
                {
                    'link': '/company/global-offices/perth'
                },
                {
                    'link': '/company/global-offices/sydney'
                },
                {
                    'link': '/company/global-offices/ahmedabad'
                },
                {
                    'link': '/company/global-offices/milano'
                },
                {
                    'link': '/company/global-offices/bologna'
                },
                {
                    'link': '/company/global-offices/rome'
                },
                {
                    'link': '/company/global-offices/bari'
                },
                {
                    'link': '/company/global-offices/abu-dhabi'
                },
                {
                    'link': '/company/global-offices/san-francisco'
                }
            ]
        },
        {
            name: 'Open Positions',
            link: '/company/open-positions',
        }
    ];
    return (
        <Layout>
            <main className="page-wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="page-content">
                                <SideMenu menuHeading="Company" menuItems={menuItems} />
                                <div className="main-content">
                                    {props.children}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <LearnMore />
            </main>
        </Layout>
    )
}