import Layout from './Layout';

export default function NoPage() {
  return (
    <Layout>
      <main className="page-wrapper solution d-flex justify-content-center align-items-center " style={{ height: '200px' }}>
        <h1 className="text-center text-primary">No Page found.</h1>
      </main>
    </Layout>
  );
}
