import React from 'react';

export default function FaqAccordian(props) {
  const { faq } = props;

  const totalItems = faq.length % 2 === 0 ? faq.length : faq.length + 1;
  const chunkSize = totalItems / 2;

  const chunckedItems = [];
  chunckedItems.push(faq.slice(0, 0 + chunkSize));
  chunckedItems.push(faq.slice(chunkSize, totalItems));
  let itemIndex = 0;
  return (
    <div className="accordion comman-accordion" id="byNeedAccordion">
      <div className="row g-lg-5">
        {chunckedItems.map((items, sectionIndex) => {
          return (
            <div className="col-lg-6" key={'section-faqs-item-' + sectionIndex}>
              {items.map((item, i) => {
                itemIndex++;
                return (
                  <React.Fragment key={'faqs-item-' + itemIndex}>
                    <div className="accordion-item">
                      <h2 className="accordion-header" id={'heading-' + itemIndex}>
                        <button
                          className={'accordion-button ' + (itemIndex !== 1 ? 'collapsed' : '')}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={'#collapse-' + itemIndex}
                          aria-expanded={itemIndex === 1 ? 'true' : 'false'}
                          aria-controls={'#collapse-' + itemIndex}
                        >
                          {item.Title}
                        </button>
                      </h2>
                      <div
                        id={'collapse-' + itemIndex}
                        className={'accordion-collapse collapse ' + (itemIndex === 1 ? 'show' : '')}
                        aria-labelledby={'heading-' + itemIndex}
                        data-bs-parent="#byNeedAccordion"
                      >
                        <div className="accordion-body">{item.Description}</div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
          );
        })}
      </div>
    </div>
  );
}
