import { Link } from 'react-router-dom';
import CustomLink from './CustomLink';

export default function Breadcrumb(props) {
  const { breadcrumbLinks } = props;
  if (!breadcrumbLinks) return <></>;

  return (
    <ol className="breadcrumb d-flex justify-content-center justify-content-lg-start mb-0">
      {breadcrumbLinks.map((item, index) => {
        if (item.displayName === null) return <></>;
        let activeLink = breadcrumbLinks.length === index + 1;
        let link = <Link reloadDocument={true} to={item.link}>{item.displayName}</Link>
        if (activeLink) {
          link = item.displayName
        }
        return (
          <li className={'breadcrumb-item ' + (activeLink && 'active')} key={'breadcrumb-' + index} aria-current={activeLink && 'page'}>
            {/* <Link reloadDocument={true} to={item.link}>{item.displayName}</Link> */}
            {link}
          </li>
        );
      })}
    </ol>
  );
}
