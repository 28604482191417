import { useEffect, useState } from 'react';
import noImageIcon from '../../images/no-image-icon-15.png';

export default function Image({src,alt, ...rest}) {
  const [url, setUrl] = useState(src);

  useEffect(() => {
    setUrl(src ? src : '');
  });

  return (
    <img
      {...rest}
      src={url}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = noImageIcon;
      }}
      alt={alt}
    />
  );
}
