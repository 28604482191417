import useFetch from '../../hooks/useFetch';
import LearnMore from '../common/LearnMore';
import Layout from '../Layout';
import Loader from '../common/Loader';
import Breadcrumb from '../common/Breadcrumb';
import Links from './Links';
import icBgImg from '../../images/ic-banner.jpg';
import locationIcon from '../../images/company/location-icon.svg';
import SEO from './../common/SEO';
import { InvestorCenterADR } from '../../Queries';
import Image from '../common/Image';
import ReactMarkdown from 'react-markdown';

export default function AdrProgramPage() {

  const { loading, response } = useFetch(process.env.REACT_APP_CMS_URL + `/api/investor-adr-program?${InvestorCenterADR}`);
  if (loading) {
    return <Loader />;
  }
  const { attributes } = response ? response.data : [];
  const seoMeta = (attributes.seo) ? attributes.seo : null

  let breadcrumbLinks = [
    { 'link': '/', 'displayName': 'Home' },
    { 'link': '/investor-center/overview', 'displayName': 'Investment Center' },
    { 'link': '/investor-center/media', 'displayName': 'ADR Program' }
  ];
 
  const UlComponent = (props) => {
    const cnt = props.children.length;
    return <><ul className="list-arrow">
        {props.children.map((child, key) => {
            if (key % 2 != 0 && key < cnt - 1) {
                return <li className="mb-2" key={key}>
                    {child.props ? child.props.children[0] : ''}
                </li>
            }
        })}
    </ul>
    <div className="spacer-xs-3"></div>
    </>
}
  return (
    <Layout>
      <SEO meta={seoMeta}></SEO>
      <main className="page-wrapper">
         <section className="banner-display-4 text-center text-lg-start img-overlay" style={{ backgroundImage: `url(${icBgImg})` }}>
            <div className="container">
               <div className="row">
                  <div className="col-sm-12">
                     <div className="entry-display-1">
                        <h1>ADR Program Overview</h1>
                        <div className="spacer-xs-1"></div>
                        <div aria-label="breadcrumb">
                        <Breadcrumb breadcrumbLinks={breadcrumbLinks} />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <aside className="drawer-mobile sidebar-content d-flex d-xl-none">
            <div className="offcanvas offcanvas-start" tabIndex="-1" id="openCanvas" aria-labelledby="offcanvasExampleLabel">
               <div className="offcanvas-body handler-menu">
                     <div className="spacer-xs-3"></div>
                     <div className="sidebartitle">
                        <h2>Investor Center</h2>
                     </div>
                     <Links />
               </div>
            </div>
         </aside>
         <section className="handler-menu d-none d-xl-block">
            <div className="spacer-xs-3"></div>
            <Links />
            <div className="spacer-xs-3"></div>
         </section>
         <section className="entry-structure">
            <div className="container">
               <div className="spacer-xs-8 spacer-lg-12"></div>
               <div className="row gx-6 block-card block-card-lg">
                  <div className="col-sm-6 order-lg-1 order-2">
                     <div className="entry-display-1">
                        <h1>{attributes.Title ? attributes.Title : ''}</h1>
                        <div className="spacer-xs-1"></div>
                        <p>{attributes.Description ? attributes.Description : ''}</p>
                     </div>
                  </div>
                  <div className="col-sm-6 order-lg-2 order-1">
                     <div className="entry-thumb mb-4 mb-lg-0 img-overlay">
                     <Image src={attributes.Image.data.attributes.url} className="img-fluid" />
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <div className="spacer-xs-8 spacer-lg-12"></div>
         <section className="about-vection element-background">
            <div className="spacer-xs-12 spacer-lg-20"></div>
            <div className="container">
               <div className="row">
                  <div className="width-lg-100">
                     <div className="entry-display-3">
                        <h3 className="text-white text-center fw-semibold">{attributes.LevelDescription ? attributes.LevelDescription : ''}</h3>
                     </div>
                  </div>
               </div>
            </div>
            <div className="spacer-xs-12 spacer-lg-20"></div>
         </section>
         <div className="spacer-xs-8 spacer-lg-12"></div>
         <section className="investor-glance theme-lite">
            <div className="container">
               <div className="row">
                  <div className="col-sm-12">
                     <div className="row align-items-start justify-content-center">
                         {attributes.InvestorGlanceList && attributes.InvestorGlanceList.map((item , index) => {
                        return (
                        <div className="col-lg-2 col-md-3 mb-4 mb-lg-0" key={index}>
                        <div className={"entry-icon " + item.IconColor  + " d-flex justify-content-center"}>
                            <span><i className={"icon-investor-new " + item.IconSlug}></i></span> 
                        </div>
                        <div className="spacer-xs-3"></div>
                        <div className="entry-display-5 text-center">
                            <p className="fw-bold">{item.Title}</p>
                            <h5 className="fw-regular">{item.Description}</h5>
                        </div>
                        </div>
                        )
                         })}
                     </div>
                  </div>
               </div>
            </div>
         </section>
         <div className="spacer-xs-8 spacer-lg-8"></div>
         <section className="entry-structure map-wrapper">
            <div className="container">
               <div className="row justify-content-center">
                    <div className="width-lg-50">
                        <div className="card entry-card p-0 shadow-none">
                        <div className="card-header py-3 bg-transparent border-0">
                              <h4 className="fw-regular fs-lg-18 mb-0">{attributes.BrokersDetailsTitle ? attributes.BrokersDetailsTitle : ''}</h4>
                           </div>
                           <div className="card-body">
                            <div className="row gx-0">
                                <div className="width-lg-10 d-flex justify-content-center align-items-start">
                                    <img src={locationIcon} className="img-fluid"/>
                                </div>
                                <div className="width-lg-90">
                                    <div className="entry-content">
                                    <p className="fw-semibold fs-lg-20 text-black">{attributes.BrokerServicesTitle ? attributes.BrokerServicesTitle : ''}</p>
                                       <div className="d-flex fs-lg-20">
                                          <i className="icon-form icon-phone me-2 mt-1"></i>
                                          <p>
                                             <span className="fw-semibold">{attributes.Country1 ? attributes.Country1 : ''}</span><br/> 
                                             <a href={`tel:${attributes.Phone1}`}className="inherit text-dark">{attributes.Phone1 ? attributes.Phone1 : ''}</a>
                                          </p>
                                       </div>
                                       <div className="d-flex fs-lg-20">
                                          <i className="icon-form icon-phone me-2 mt-1"></i>
                                          <p>
                                             <span className="fw-semibold">{attributes.Country2 ? attributes.Country2 : ''}</span><br/> 
                                             <a href={`tel:${attributes.Phone2}`} className="inherit text-dark">{attributes.Phone2 ? attributes.Phone2 : ''}</a>
                                          </p>
                                       </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                    <div className="width-lg-50 mt-4 mt-lg-0">
                        <div className="card entry-card p-0 shadow-none">
                           <div className="card-header py-3 bg-transparent border-0">
                              <h4 className="fw-regular fs-lg-18 mb-0">{attributes.RegisteredDetailsTitle ? attributes.RegisteredDetailsTitle : ''}</h4>
                           </div>
                           <div className="card-body">
                            <div className="row gx-0">
                                <div className="width-lg-10 d-flex justify-content-center align-items-start">
                                    <img src={locationIcon} className="img-fluid"/>
                                </div>
                                <div className="width-lg-90">
                                    <div className="entry-content">
                                    <p className="fw-semibold fs-lg-20 text-black">{attributes.ShareholderServicesTitle ? attributes.ShareholderServicesTitle : ''} </p>
                                    <address className="fs-lg-20 text-dark">
                                        <ReactMarkdown children={attributes.Address ? attributes.Address : ''}/>
                                       {/* <p>
                                          American Stock Transfer & Trust Company, <br/>
                                          6201, 15th Avenue, <br/>Brooklyn, NY 11219
                                       </p> */}
                                    </address>
                                    <div className="d-flex fs-lg-20">
                                       <i className="icon-form icon-envelope me-2 mt-1"></i>
                                       <p><span className="fw-semibold">{attributes.EmailTitle ? attributes.EmailTitle : ''}</span> <br/><a href= {`mailto:${attributes.Email}`}className="inherit text-dark">{attributes.Email ? attributes.Email : ''}</a></p>
                                    </div>
                                    <div className="d-flex fs-lg-20">
                                       <i className="icon-form icon-phone me-2 mt-1"></i>
                                        <p><span className="fw-semibold">{attributes.TollFreeNoTitle ? attributes.TollFreeNoTitle : ''}</span> <br/> <a href={`tel:${attributes.TollFreeNo}`} className="inherit text-dark">{attributes.TollFreeNo ? attributes.TollFreeNo : ''}</a> <small> ({attributes.TollFreeCallingText ? attributes.TollFreeCallingText : ''})</small></p>
                                    </div>
                                    <div className="d-flex fs-lg-20">
                                       <i className="icon-form icon-phone me-2 mt-1"></i>
                                        <p><span className="fw-semibold">{attributes.DirectCallTitle ? attributes.DirectCallTitle : ''}</span> <br/> <a href={`tel:${attributes.DirectCallNo}`} className="inherit text-dark"> {attributes.DirectCallNo ? attributes.DirectCallNo : ''}</a><small> ({attributes.DirectCallCallingText ? attributes.DirectCallCallingText : ''})</small></p>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                  </div>
               </div>
            <div className="spacer-xs-8 spacer-lg-10"></div>
         </section>
        <section className="bg-lighten-grey">
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3">
                            <h3>{attributes.RolesForADRholdersTitle ? attributes.RolesForADRholdersTitle : ''}</h3>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-4"></div>
                <div className="row">
                    <div className="col-sm-12">
                    <div className="entry-display-3">
                        <ReactMarkdown className="reactMarkdown" children={attributes.RolesForADRholdersDescription} 
                        components={{
                            ul : UlComponent,
                        }} 
                        />
                    </div>
                    </div>
                </div>
            </div>
            <div className="spacer-xs-8 spacer-xl-12"></div>
        </section>
        <section className="faq">
            <div className="container">
               <div className="spacer-xs-8 spacer-md-10 spacer-lg-12"></div>
               <div className="row">
                  <div className="col-sm-12">
                     <div className="entry-display-3">
                        <h3 className="text-center">
                        {attributes.FaqTitle ? attributes.FaqTitle : ''}</h3>
                        <div className="spacer-xs-4 spacer-lg-10"></div>
                        <div className="accordion comman-accordion" id="accordionOne">
                           <div className="row g-lg-5 justify-content-center">
                              <div className="col-lg-10">
                                  {attributes.AdrFaqList && attributes.AdrFaqList.map((item,index) => {
                                      return (
                                        <div className="accordion-item" key={index}>
                                        <h2 className="accordion-header" id={'heading' + index}>
                                        <button
                                        className={"accordion-button " + (index == 0 ? "" : "collapsed")}
                                            type="button"
                                            data-bs-toggle="collapse"
                                            data-bs-target={'#collapse' + index}
                                            aria-expanded={true}
                                            aria-controls={'collapse' + index}
                                        >
                                            {item.Title}
                                        </button>
                                        </h2>
                                        <div id={'collapse' + index}  className={"accordion-collapse collapse " + (index == 0 ? "show" : "")} aria-labelledby={'heading' + index} data-bs-parent={'#accordionOne'}>
                                        <div className="accordion-body">
                                        <ReactMarkdown children={item.Description}
                                        components={{
                                            p : ({children}) => {
                                                return children
                                            },
                                            ul: ({ children }) => 
                                            <><div className="spacer-xs-2"></div><ul className="list-unstyled">
                                                {children.map((child, key) => {
                                                    if (key % 2 != 0 && key < children.length - 1) {
                                                        return <li key = {key}>
                                                        {child.props != undefined && <strong>{child.props.children[0].props.children[0]}</strong>} {child.props != undefined && child.props.children[1]}
                                                        </li>
                                                    }
                                                })}
                                            </ul>
                                            </>
                                           }}
                                         />    
                                        </div>
                                        </div>
                                    </div>
                                      )
                                  })}
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="spacer-xs-6 spacer-md-10 spacer-lg-12"></div>
            </div>
         </section>
        <LearnMore/>    
        </main>    
    </Layout>
  );
}
