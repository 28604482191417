import React, { useRef, useState } from 'react';
import Layout from './Layout';
import { form } from '../services/form';
import ReCAPTCHA from 'react-google-recaptcha';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import useFetch from '../hooks/useFetch';
import { ContactUsQuery } from '../Queries';
import Business from './common/Business';
import Loader from './common/Loader';
import SEO from './common/SEO';

export default function ContactUsPage() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    region: '',
    services: '',
    message: ''
  });
  const [errors, setErrors] = useState(false);
  const [success, setSuccess] = useState(false);
  const recaptchaRef = useRef(null);

  const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/contact-us?' + ContactUsQuery);
  const { data } = response ? response : [];
  const { attributes } = data ? data : [];

  if (loading) {
    return <Loader />;
  }
  const seoMeta = (attributes.seo) ? attributes.seo : null

  const handleFormSubmit = e => {
    e.preventDefault();
    setSuccess(null);
    setErrors(null);
    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue) {
      form.contactUs(
        { ...formData, 'g-recaptcha-response': recaptchaValue },
        success => {
          document.getElementById('contact-form').reset();
          setFormData({
            firstName: '',
            lastName: '',
            email: '',
            contactNumber: '',
            region: '',
            services: '',
            message: ''
          });
          recaptchaRef.current.reset();
          setSuccess('Submitted successfully.');
          console.log('success', success);
          setTimeout(() => {
            setSuccess(null);
          }, 5000);
        },
        error => {
          setErrors(error);
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        }
      );
    }
  };

  return (
    <Layout>
      <SEO meta={seoMeta}></SEO>
      <main className="page-wrapper">
        <section className="open-position">
          <div className="spacer-xs-8 spacer-lg-12"></div>
          <div className="container">
            <div className="row gx-5">
              <div className="col-lg-6">
                <div className="entry-display-1 text-center text-lg-start">
                  <h1>Contact Us</h1>
                  <div className="spacer-xs-1"></div>
                  <p></p>
                </div>
                <div className="spacer-xs-6"></div>
                <div className="row">
                  <div className="col-sm-12">
                    <Business {...attributes} titleClass="text-center text-lg-start" spaceBetween={50} />
                  </div>
                </div>
                <div className="spacer-xs-8 spacer-lg-0"></div>
              </div>
              <div className="col-lg-6">
                <div className="flotable-from">
                  <form
                    method="post"
                    onSubmit={e => {
                      handleFormSubmit(e);
                    }}
                    id="contact-form"
                  >
                    <h3 className="mb-0">Please fill out the form below to get in touch with us.</h3>
                    <div className="spacer-xs-2"></div>
                    {errors &&
                      Object.keys(errors).map(index => {
                        return (
                          <React.Fragment key={'error-' + index}>
                            <span className="text-danger">{errors[index]}</span> <br />
                          </React.Fragment>
                        );
                      })}
                    <span className="text-success">{success}</span>
                    <div className="spacer-xs-3"></div>
                    <div className="form-floating mb-4 form-icon">
                      <i className="icon-form icon-user"></i>
                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        id="firstName"
                        placeholder="First Name"
                        required
                        value={formData.firstName}
                        onChange={e => {
                          setFormData({ ...formData, firstName: e.target.value });
                        }}
                      />
                      <label htmlFor="firstName">First Name</label>
                      <div className="form-text">
                        This field is compulsory<span className="text-danger">*</span>
                      </div>
                    </div>
                    <div className="form-floating mb-4 form-icon">
                      <i className="icon-form icon-user"></i>
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        id="lastName"
                        placeholder="Last Name"
                        required
                        value={formData.lastName}
                        onChange={e => {
                          setFormData({ ...formData, lastName: e.target.value });
                        }}
                      />
                      <label htmlFor="lastName">Last Name</label>
                      <div className="form-text">
                        This field is compulsory<span className="text-danger">*</span>
                      </div>
                    </div>
                    <div className="form-floating mb-4 form-icon">
                      <i className="icon-form icon-envelope"></i>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Work Email"
                        required
                        value={formData.email}
                        onChange={e => {
                          setFormData({ ...formData, email: e.target.value });
                        }}
                      />
                      <label htmlFor="email">Work Email</label>
                      <div className="form-text">
                        This field is compulsory<span className="text-danger">*</span>
                      </div>
                    </div>
                    <div className="form-floating mb-4 form-icon">
                      <i className="icon-form icon-phone"></i>
                      <input
                        type="text"
                        className="form-control"
                        name="contactNumber"
                        id="number"
                        placeholder="Contact Number"
                        required
                        value={formData.contactNumber}
                        onChange={e => {
                          setFormData({ ...formData, contactNumber: e.target.value });
                        }}
                      />
                      <label htmlFor="number">Contact Number</label>
                      <div className="form-text">
                        This field is compulsory<span className="text-danger">*</span>
                      </div>
                    </div>
                    <div className="form-floating mb-4">
                      <select
                        className="form-select mb-1"
                        name="region"
                        id="region"
                        aria-label="Floating label select example"
                        required
                        onChange={e => {
                          setFormData({ ...formData, region: e.target.value });
                        }}
                      >
                        <option value=""></option>
                        <option value="Europe">Europe</option>
                        <option value="Middle East and Africa (EMEA)">Middle East and Africa (EMEA)</option>
                        <option value="Asia Pacific">Asia Pacific</option>
                        <option value="Americas">Americas</option>
                      </select>
                      <label htmlFor="floatingSelect">Region</label>
                      <div className="form-text">
                        This field is compulsory<span className="text-danger">*</span>
                      </div>
                    </div>
                    <div className="form-floating mb-4">
                      <select
                        className="form-select mb-1"
                        name="services"
                        id="Services"
                        aria-label="Floating label select example"
                        required
                        onChange={e => {
                          setFormData({ ...formData, services: e.target.value });
                        }}
                      >
                        <option value=""></option>
                        <option value="EnWorks">EnWorks</option>
                        <option value="3DFrame">3DFrame</option>
                        <option value="Mindesk">Mindesk</option>
                        <option value="3D Configurator">3D Configurator</option>
                        <option value="XRKiosk">XRKiosk</option>
                        <option value="3D Modeling & Rendering">3D Modeling & Rendering</option>
                        <option value="Metaverse & Web 3.0">Metaverse & Web 3.0</option>
                        <option value="XR Customizations & White Labels">XR Customizations & White Labels</option>
                        <option value="ICT Infrastructure">ICT Infrastructure</option>
                        <option value="Kiosks">Kiosks</option>
                      </select>
                      <label htmlFor="floatingSelect">Product and Services</label>
                      <div className="form-text">
                        This field is compulsory<span className="text-danger">*</span>
                      </div>
                    </div>
                    <div className="mb-3">
                      <textarea
                        cols="100%"
                        rows="3"
                        type="text"
                        className="form-control"
                        name="message"
                        id="message"
                        placeholder="Message"
                        value={formData.message}
                        onChange={e => {
                          setFormData({ ...formData, message: e.target.value });
                        }}
                      ></textarea>
                    </div>
                    <div className="form-floating mb-3">
                    <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_TOKEN} ref={recaptchaRef} required />
                    </div>
                    <button type="submit" className="btn btn-primary d-flex justify-content-center align-items-center text-white w-100">
                      Send Enquiry
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="spacer-xs-8 spacer-lg-12"></div>
        </section>
      </main>
    </Layout>
  );
}
