import LearnMore from '../../common/LearnMore';
import { SolutionByProductMindeskPricing } from '../../../Queries';
import ByProductSidebar from '../ByProductSidebar';
import useFetch from '../../../hooks/useFetch';
import Layout from '../../Layout';
import Loader from '../../common/Loader';

export default function MindeskPricing() {
    // const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/solution-by-product-mindesk-pricing?' + SolutionByProductMindeskPricing);
    // //SolutionByProductEnworkOverview
    // if (loading) {
    //     return <Loader />;
    // }
    // const { data } = response ? response : [];
    // const { attributes } = data ? data : [];
    // const { Challenges } = attributes ? attributes : [];

    return (
        <Layout activeTab="solution">
            <main className="page-wrapper">
                <section className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="page-content">
                                <aside className="sidebar sidebar-content sticky-top d-none d-xl-flex">
                                    <ByProductSidebar activeMenu="products-mindesk-pricing" />
                                </aside>
                                <aside className="drawer-mobile sidebar-content d-flex d-xl-none">
                                    <div className="offcanvas offcanvas-start" tabIndex="-1" id="openCanvas" aria-labelledby="offcanvasExampleLabel">
                                        <div className="offcanvas-body">
                                            <div className="spacer-xs-3"></div>
                                            <ByProductSidebar activeMenu="products-mindesk-pricing" />
                                        </div>
                                    </div>
                                </aside>
                                <section className="main-content">
                                    <section className="banner-display-3">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="entry-display-1">
                                                    <div className="hero-thumb img-overlay">
                                                        <img src="images/mindesk-logo.png" className="img-fluid" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="art position-absolute d-none d-xl-flex">
                                            <img src="images/products/3dframe/banner-bg.png" alt="" className="img-fluid" />
                                        </div>
                                    </section>
                                    <section className="entry-structure pricing-section">
                                        <div className="spacer-xs-8 spacer-xl-12"></div>
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="entry-display-1 text-center">
                                                    <h1>Simple pricing, powerful tool.</h1>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="spacer-xs-4 spacer-xl-6"></div>
                                        <div className="row justify-content-center">
                                            <div className="width-lg-37">
                                                <div className="pricing-switcher">
                                                    <ul className="nav nav-pills mb-3 d-flex" id="pills-tab" role="tablist">
                                                        <li className="nav-item flex-grow-1" role="presentation">
                                                            <button className="nav-link active w-100" id="pills-monthly-tab" data-bs-toggle="pill" data-bs-target="#pills-monthly" type="button" role="tab" aria-controls="pills-monthly" aria-selected="true">Monthly</button>
                                                        </li>
                                                        <li className="nav-item flex-grow-1" role="presentation">
                                                            <button className="nav-link w-100" id="pills-annualy-tab" data-bs-toggle="pill" data-bs-target="#pills-annualy" type="button" role="tab" aria-controls="pills-annualy" aria-selected="false">Annualy</button>
                                                        </li>
                                                    </ul>
                                                    <div className="save"><span>Save 20%</span></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="spacer-xs-6 spacer-lg-9"></div>
                                        <div className="col-sm-12">
                                            <div className="tab-content" id="pills-tabContent">
                                                <div className="tab-pane fade show active" id="pills-monthly" role="tabpanel" aria-labelledby="pills-monthly-tab">
                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-4 mb-5 mb-xl-0">
                                                            <div className="card pricing">
                                                                <h4>Free</h4>
                                                                <div className="plan-price">
                                                                    <p>
                                                                        <span className="amount">$0</span>
                                                                    </p>
                                                                    <div className="days">
                                                                        14 days free trial
                                                                </div>
                                                                </div>
                                                                <a href="#" className="btn btn-primary-light">Start Free Trial</a>
                                                            </div>
                                                            <div className="spacer-xs-4 spacer-xl-3"></div>
                                                            <div className="plan-included">
                                                                <h5>What’s Include</h5>
                                                                <ul>
                                                                    <li>Full feature set </li>
                                                                    <li>Support via ticket</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-md-4 mb-5 mb-xl-0">
                                                            <div className="card pricing">
                                                                <h4>Freelancer</h4>
                                                                <div className="plan-price">
                                                                    <p>
                                                                        <span className="amount">$49</span>
                                                                        <span className="mo">/mo</span>
                                                                    </p>
                                                                    <div className="days">
                                                                        Start free trial
                                                                </div>
                                                                </div>
                                                                <a href="#" className="btn btn-primary-light">Get Started</a>
                                                            </div>
                                                            <div className="spacer-xs-4 spacer-xl-3"></div>
                                                            <div className="plan-included">
                                                                <h5>What’s Include</h5>
                                                                <ul>
                                                                    <li>Fixed license </li>
                                                                    <li>Unreal, Solidworks and Rhinoceros Live Link</li>
                                                                    <li>Real-time rendering in Unreal </li>
                                                                    <li>VR visualization</li>
                                                                    <li>Support via ticket </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-md-4 mb-5 mb-xl-0">
                                                            <div className="card pricing professional">
                                                                <h4>Professional</h4>
                                                                <div className="plan-price">
                                                                    <p>
                                                                        <span className="amount">$199</span>
                                                                        <span className="mo">/mo</span>
                                                                    </p>
                                                                    <div className="days">
                                                                        Billed monthly
                                                                </div>
                                                                </div>
                                                                <a href="#" className="btn btn-primary btn-sm text-white d-flex justify-content-center">Get Started</a>
                                                            </div>
                                                            <div className="spacer-xs-4 spacer-xl-3"></div>
                                                            <div className="plan-included">
                                                                <h5>What’s Include</h5>
                                                                <ul>
                                                                    <li>Floating license </li>
                                                                    <li>Unreal, Solidworks, Rhinoceros, and Grasshopper Live Link </li>
                                                                    <li>Real-time rendering in Unreal </li>
                                                                    <li>VR visualization</li>
                                                                    <li>Desktop / VR collaboration </li>
                                                                    <li>LAN Multiuser </li>
                                                                    <li>VR NURBS modeling (Rhino only) </li>
                                                                    <li>Point cloud (Rhino only) </li>
                                                                    <li>Support via ticket</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="pills-annualy" role="tabpanel" aria-labelledby="pills-annualy-tab">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div className="start-trial">
                                                                <div className="row align-items-center justify-content-between">
                                                                    <div className="width-xl-11">
                                                                        <h5>Free</h5>
                                                                    </div>
                                                                    <div className="width-xl-52 mb-5 mb-xl-0">
                                                                        <div className="trial-feature">
                                                                            <div className="price">
                                                                                <h4>$0</h4>
                                                                                <p>15 days free trail</p>
                                                                            </div>
                                                                            <ul>
                                                                                <li>Full feature set </li>
                                                                                <div className="spacer-xs-1"></div>
                                                                                <li>Support via ticket</li>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                    <div className="width-xl-31">
                                                                        <a href="#" className="btn btn-primary py-3 pl-xs-6 pr-xs-6 btn-sm text-white">Start Free Trial</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="spacer-xs-6 spacer-xl-8"></div>
                                                    <div className="row">
                                                        <div className="col-sm-12 col-md-4 mb-5 mb-xl-0">
                                                            <div className="card pricing">
                                                                <h4>Freelancer</h4>
                                                                <div className="plan-price">
                                                                    <p>
                                                                        <span className="amount">$49</span>
                                                                        <span className="mo">/mo</span>
                                                                    </p>
                                                                    <div className="days">
                                                                        Billed annualy
                                                                </div>
                                                                </div>
                                                                <a href="#" className="btn btn-primary-light">Get Started</a>
                                                            </div>
                                                            <div className="spacer-xs-4 spacer-xl-3"></div>
                                                            <div className="plan-included">
                                                                <h5>What’s Include</h5>
                                                                <ul>
                                                                    <li>Fixed license   </li>
                                                                    <li>Unreal, Solidworks and Rhinoceros Live Link</li>
                                                                    <li>Real-time rendering in Unreal </li>
                                                                    <li>VR visualization</li>
                                                                    <li>Support via ticket </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-md-4 mb-5 mb-xl-0">
                                                            <div className="card pricing professional">
                                                                <h4>Professional</h4>
                                                                <div className="plan-price">
                                                                    <p>
                                                                        <span className="amount">$199</span>
                                                                        <span className="mo">/mo</span>
                                                                    </p>
                                                                    <div className="days">
                                                                        Billed annualy
                                                                </div>
                                                                </div>
                                                                <a href="#" className="btn btn-primary btn-sm text-white d-flex justify-content-center ">Get Started</a>
                                                            </div>
                                                            <div className="spacer-xs-4 spacer-xl-3"></div>
                                                            <div className="plan-included">
                                                                <h5>What’s Include</h5>
                                                                <ul>
                                                                    <li>Floating license </li>
                                                                    <li>Unreal, Solidworks,Rhinoceros,and Grasshopper Live Link </li>
                                                                    <li>Real-time rendering in Unreal  </li>
                                                                    <li>VR visualization</li>
                                                                    <li>Desktop / VR collaboration  </li>
                                                                    <li>LAN Multiuser </li>
                                                                    <li>VR NURBS modeling (Rhino only) </li>
                                                                    <li>Point cloud (Rhino only) </li>
                                                                    <li>Support via ticket</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-12 col-md-4 mb-5 mb-xl-0">
                                                            <div className="card pricing">
                                                                <h4>Enterprise</h4>
                                                                <div className="plan-price">
                                                                    <p>
                                                                        <span className="amount">$4k</span>
                                                                        <span className="mo">/mo</span>
                                                                    </p>
                                                                    <div className="days">
                                                                        Billed monthly
                                                                </div>
                                                                </div>
                                                                <a href="contact-us.php" className="btn btn-primary-light">Contact Us</a>
                                                            </div>
                                                            <div className="spacer-xs-4 spacer-xl-3"></div>
                                                            <div className="plan-included">
                                                                <h5>What’s Include</h5>
                                                                <ul>
                                                                    <li>Network / On-prem license  </li>
                                                                    <li>Unreal, Solidworks,Rhinoceros,and Grasshopper Live Link </li>
                                                                    <li>Real-time rendering in Unreal </li>
                                                                    <li>VR visualization</li>
                                                                    <li>Desktop / VR collaboration </li>
                                                                    <li>LAN / Cloud Multiuser  </li>
                                                                    <li>VR NURBS modeling (Rhino only)  </li>
                                                                    <li>Point cloud (Rhino only) </li>
                                                                    <li>Varjo VR-2 / XR-1, Hololens 2 AR integration </li>
                                                                    <li>Features co-development </li>
                                                                    <li>Support via ticket, video conference and remote desktop</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="spacer-lg-12"></div>
                                    </section>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
                <LearnMore />
            </main>
        </Layout>
    );
}
