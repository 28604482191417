import { useEffect } from "react";
import $ from "jquery";
import useFetch from "../../../hooks/useFetch"
import Loader from '../../common/Loader';
import Image from "../../common/Image";
import IndustryLayout from "./IndustryLayout"
import { IndustriesAutomotiveQuery } from "../../../Queries";
import ReactMarkdown from "react-markdown";
import SEO from './../../common/SEO';

export default function IndustryAutomotive() {
    useEffect(() => {
        var windowWidth = $(window).width();
        var container = $('.container').width();

        var calcWidth = windowWidth - container;

        $('.art').css('right', - (calcWidth / 2));
    },[])
    
    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/industry-automotive?' + IndustriesAutomotiveQuery);
    
    if (loading) {
        return <Loader />;
    }
    const { attributes } = response ? response.data : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null
    return (<IndustryLayout>
            <SEO meta={seoMeta}></SEO>
            <section className="entry-section">
            <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
            <div className="col-sm-12">
                <div className="entry-display-1">
                <p className="sub-heading text-primary fw-semibold">AUTOMOTIVE</p>
                <h1>{attributes.Title ? attributes.Title : ''}</h1>
                <div className="spacer-xs-4 spacer-xl-6"></div>
                <div className="entry-thumb img-overlay">
                <Image src={attributes && attributes.Image ? attributes.Image.data.attributes.url : ''} className="img-fluid" />
                </div>
                <div className="spacer-xs-4 spacer-xl-6"></div>
                <p>{attributes.Description ? attributes.Description : ''}
                </p>
                </div>
            </div>
        </div>
        <div className="spacer-xs-8 spacer-xl-12"></div>
    </section>
    <section className="entry-structure-light">
        <div className="spacer-xs-8 spacer-xl-10"></div>
        <div className="row">
            <div className="col-sm-12">
                <div className="entry-display-3">
                <h3>{attributes.ImmersiveTechTitle ? attributes.ImmersiveTechTitle : ''}</h3>
                <div className="spacer-xs-1"></div>
                <p>{attributes.ImmersiveTechDescription ? attributes.ImmersiveTechDescription : ''}</p>
                <div className="spacer-xs-4 spacer-xl-6"></div>
                <div className="entry-thumb img-overlay">
                <Image src={attributes && attributes.ImmersiveTechImage ? attributes.ImmersiveTechImage.data.attributes.url : ''} className="img-fluid" />
                </div>
                <div className="spacer-xs-4 spacer-xl-6"></div>
                <div className="accordion entry-accordian" id={'accordion0'}>
                    {attributes.RealityList && attributes.RealityList.map((item , index) => {
                        return (
                            <div className="accordion-item" key={index}>
                            <h2 className="accordion-header" id={'heading' + index}>
                              <button
                               className={"accordion-button " + (index == 0 ? "" : "collapsed")}
                                type="button"
                                data-bs-toggle="collapse"
                                data-bs-target={'#collapse' + index}
                                aria-expanded={true}
                                aria-controls={'collapse' + index}
                              >
                                {item.Title}
                              </button>
                            </h2>
                            <div id={'collapse' + index}  className={"accordion-collapse collapse " + (index == 0 ? "show" : "")} aria-labelledby={'heading' + index} data-bs-parent={'#accordion0'}>
                              <div className="accordion-body">{item.Description}</div>
                            </div>
                          </div>
                        )
                    })}
                 </div>
                </div>
            </div>
        </div>
        <div className="spacer-xs-8 spacer-xl-10"></div>
    </section>
    <section className="entry-structure">
        <div className="spacer-xs-8 spacer-xl-12"></div>
        <div className="row">
            <div className="col-sm-12"> 
                <div className="entry-display-3">
                <div className="entry-thumb img-overlay">
                <Image src={attributes && attributes.BenefitsOfImmersiveImage ? attributes.BenefitsOfImmersiveImage.data.attributes.url : ''} className="img-fluid" />
                </div>
                <div className="spacer-xs-4 spacer-xl-6"></div>
                <h3>{attributes.BenefitsOfImmersiveTitle ? attributes.BenefitsOfImmersiveTitle : ''}</h3>
                    <div className="spacer-xs-1"></div>
                    <p>{attributes.BenefitsOfImmersiveDescription ? attributes.BenefitsOfImmersiveDescription : ''}</p>  
                </div>                                   
            </div>
        </div>
        <div className="spacer-xs-8 spacer-xl-12"></div>
    </section>
    <section className="entry-structure-light">
        <div className="spacer-xs-8 spacer-xl-10"></div>
        <div className="row">
            <div className="col-sm-12">
                <div className="entry-display-3">
                <h3>{attributes.VrTitle ? attributes.VrTitle : ''}</h3>
                <div className="spacer-xs-1"></div>
                <p>{attributes.VrDescription ? attributes.VrDescription : ''}</p>
                <div className="spacer-xs-4 spacer-xl-6"></div>
                <div className="entry-thumb img-overlay">
                <Image src={attributes && attributes.VrImage ? attributes.VrImage.data.attributes.url : ''} className="img-fluid" />
                </div>
                </div>
            </div>
        </div>
        <div className="spacer-xs-8 spacer-xl-10"></div>
    </section>
    <div className="spacer-xs-8 spacer-xl-12"></div>
    <section className="entry-structure">
        <div className="row">
            <div className="col-sm-12">
                <div className="entry-display-3">
                <h3>{attributes.DesignTitle ? attributes.DesignTitle : ''}</h3>
                <div className="spacer-xs-1"></div>
                <p>{attributes.DesignDescription ? attributes.DesignDescription : ''}</p>
                <div className="spacer-xs-4 spacer-xl-6"></div>
                <div className="entry-thumb img-overlay">
                <Image src={attributes && attributes.DesignImage ? attributes.DesignImage.data.attributes.url : ''} className="img-fluid" />
                </div>
                </div>
            </div>
        </div>
    </section>
    <div className="spacer-xs-8 spacer-xl-12"></div>
    <section className="entry-structure-light">
        <div className="spacer-xs-8 spacer-xl-10"></div>
        <div className="row">
            <div className="col-sm-12">
                <div className="entry-display-3">
                <h3>{attributes.TrainingTitle ? attributes.TrainingTitle : ''}</h3>
                <div className="spacer-xs-1"></div>
                <p>{attributes.TrainingDescription ? attributes.TrainingDescription : ''}</p>
                <div className="spacer-xs-4 spacer-xl-6"></div>
                <div className="entry-thumb img-overlay">
                <Image src={attributes && attributes.TrainingImage ? attributes.TrainingImage.data.attributes.url : ''} className="img-fluid" />
                </div>
                </div>
            </div>
        </div>
        <div className="spacer-xs-8 spacer-xl-10"></div>
    </section>
    <section className="entry-structure report-card">
        <div className="spacer-xs-8 spacer-xl-12"></div>
            <div className="row">
                <div className="col-sm-12">
                    <div className="entry-display-3">
                        <h3>{attributes.TakeawayTitle ? attributes.TakeawayTitle : ''}</h3>
                        <div className="spacer-xs-1"></div>
                        <ReactMarkdown children = {attributes.TakeawayDescription ? attributes.TakeawayDescription : ''}/>
                    </div>
                </div>
            </div>
        <div className="spacer-xs-8 spacer-xl-12"></div>
    </section>
    </IndustryLayout>)
}