import useFetch from "../../../hooks/useFetch";
import Loader from '../../common/Loader';
import TechnologyLayout from "./TechnologyLayout";
import Image from '../../common/Image';
import { TechnologyVirtualRealityQuery } from '../../../Queries';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import FeatureCard from './FeatureCard';
import SEO from '../../common/SEO';

export default function IntegratedXrPage() {
    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/technology-virtual-reality?' + TechnologyVirtualRealityQuery);

    if (loading) {
        return <Loader />;
    }
    const { data } = response ? response : [];
    const { attributes } = data ? data : [];
    const { VRTypeList } = attributes ? attributes : [];
    const { VRUsecaseList } = attributes ? attributes : [];
    const { HealthcareList } = attributes ? attributes : [];
    const { HealthcareTypesList } = attributes ? attributes : [];
    const { RealityFAQList } = attributes ? attributes : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null

    return (
        <TechnologyLayout>
            <SEO meta={seoMeta}></SEO>
            <section className="banner banner-display-2">
                <div className="row">
                    <div className="spacer-xs-8 spacer-xl-12"></div>
                    <div className="entry-display-1 width-xxl-50 width-xxxl-68 order-2 order-xl-1 text-xl-start text-center">
                        <h1>{attributes && attributes.Title ? attributes.Title : ''}</h1>
                        <div className="spacer-xs-1"></div>
                        <ReactMarkdown className="reactMarkdownDiv" children={attributes && attributes.Description ? attributes.Description : ''} />
                    </div>
                    <div className="entry-thumb d-flex flex-column flex-xl-row justify-content-center justify-content-xl-end align-items-center position-absolute order-1 order-xl-2">
                        <div className="circle-bg d-none d-xl-block"></div>
                        <div className="spacer-xs-5 order-1 d-flex d-xl-none"></div>
                        <div className="entry-image img-overlay">
                            <Image src={attributes && attributes.CircleImage.data.attributes.url ? attributes.CircleImage.data.attributes.url : ''} alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-1">
                            <div className="entry-thumb img-overlay">
                                <Image src={attributes && attributes.BannerImage.data.attributes.url ? attributes.BannerImage.data.attributes.url : ''} alt="" className="img-fluid" />
                            </div>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <p>{attributes && attributes.SubDescription ? attributes.SubDescription : ''}</p>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-3"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3">
                            <h3>{attributes && attributes.SensoryTitle ? attributes.SensoryTitle : ''}</h3>
                            <p className="fw-regular">{attributes && attributes.SensoryDescription ? attributes.SensoryDescription : ''}</p>
                        </div>
                        <div className="spacer-xs-4"></div>
                        <div className="entry-display-3">
                            <h3>{attributes && attributes.InteractionTitle ? attributes.InteractionTitle : ''}</h3>
                            <p className="fw-regular">{attributes && attributes.InteractionDescription ? attributes.InteractionDescription : ''}</p>
                        </div>
                        <div className="spacer-xs-4"></div>
                        <div className="entry-display-3">
                            <h3>{attributes && attributes.PresenceTitle ? attributes.PresenceTitle : ''}</h3>
                            <p className="fw-regular">{attributes && attributes.PresenceDescription ? attributes.PresenceDescription : ''}</p>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-12"></div>
            </section>
            <section className="entry-structure-light">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-2">
                            <h2>{attributes && attributes.VRTitle ? attributes.VRTitle : ''}</h2>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes && attributes.VRDescription ? attributes.VRDescription : ''}</p>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-6 spacer-xl-8"></div>
                {VRTypeList &&
                    VRTypeList.map((itemVR, indexVR) => {
                        return <FeatureCard key={'feature-card-2-' + indexVR} lastItem={indexVR === 2} itemIndex={indexVR} item={itemVR}  page="virtual-reality" />;
                    })}
            </section>
            <section className="entry-structure">
                <div className="spacer-xs-8 spacer-xl-12"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-2">
                            <h2>{attributes && attributes.VRChallengeTitle ? attributes.VRChallengeTitle : ''}</h2>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes && attributes.VRChallengeDescription1 ? attributes.VRChallengeDescription1 : ''}</p>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <div className="entry-display-4 bg-primary px-4 py-3 rounded-2 d-flex align-items-center flex-lg-row flex-column">
                                <h4 className="text-white fw-regular fs-lg-22 flex-1 pe-lg-4 mb-3 mb-lg-0 text-center text-lg-start">
                                    {attributes && attributes.LearnProductTitle ? attributes.LearnProductTitle : ''}
                                </h4>
                                <Link reloadDocument={true} to={attributes && attributes.LearnProductLink ? attributes.LearnProductLink : '#'} className="btn btn-white btn-sm text-primary px-5 text-nowrap">Learn More</Link>
                            </div>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <p>{attributes && attributes.VRChallengeDescription2 ? attributes.VRChallengeDescription2 : ''}</p>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <div className="entry-display-4 bg-primary px-4 py-3 rounded-2 d-flex align-items-center flex-lg-row flex-column">
                                <h4 className="text-white fw-regular fs-lg-22 flex-1 pe-lg-4 mb-3 mb-lg-0 text-center text-lg-start">
                                    {attributes && attributes.LearnServiceTitle ? attributes.LearnServiceTitle : ''}
                                </h4>
                                <Link reloadDocument={true} to={attributes && attributes.LearnServiceLink ? attributes.LearnServiceLink : '#'} className="btn btn-white btn-sm text-primary px-5 text-nowrap">Learn More</Link>
                            </div>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <p>{attributes && attributes.VRChallengeDescription3 ? attributes.VRChallengeDescription3 : ''} </p>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-12"></div>
            </section>
            <section className="entry-structure-light">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-2">
                            <h2>{attributes && attributes.VRUsecaseTitle ? attributes.VRUsecaseTitle : ''}</h2>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes && attributes.VRUsecaseDescription ? attributes.VRUsecaseDescription : ''}</p>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-6 spacer-xl-8"></div>
                {VRUsecaseList &&
                    VRUsecaseList.map((itemUC, indexUC) => {
                        return <FeatureCard key={'feature-card-2-' + indexUC} lastItem={indexUC === 0} itemIndex={indexUC} item={itemUC} page="virtual-reality" />;
                    })}
            </section>
            <section className="entry-structure">
                <div className="spacer-xs-8 spacer-xl-12"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-2">
                            <h2>{attributes && attributes.HealthcareTitle ? attributes.HealthcareTitle : ''}</h2>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes && attributes.HealthcareDescription ? attributes.HealthcareDescription : ''}</p>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <div className="entry-thumb img-overlay">
                                <Image src={attributes && attributes.HealthcareImage.data.attributes.url ? attributes.HealthcareImage.data.attributes.url : ''} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-6 spacer-xl-8"></div>
                <div className="row">
                    <div className="col-sm-12">
                        {HealthcareList &&
                            HealthcareList.map((itemHC, indexHC) => {
                                return (
                                    <>
                                        <div key={indexHC} className="entry-display-4">
                                            <h4>{itemHC && itemHC.Title ? itemHC.Title : ''}</h4>
                                            <div className="spacer-xs-1"></div>
                                            <p>{itemHC && itemHC.Description ? itemHC.Description : ''}</p>
                                        </div>
                                        <div className="spacer-xs-2"></div>
                                    </>
                                )
                            })}
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-10"></div>
            </section>
            <section className="entry-structure-light">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                {HealthcareTypesList &&
                    HealthcareTypesList.map((itemUC, indexUC) => {
                        return <FeatureCard key={'feature-card-2-' + indexUC} lastItem={indexUC === 2} itemIndex={indexUC} item={itemUC} page="virtual-reality" />;
                    })}
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-2">
                            <h2>{attributes && attributes.RealityTitle ? attributes.RealityTitle : ''}</h2>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <div className="entry-thumb img-overlay">
                                <Image src={attributes && attributes.RealityImage.data.attributes.url ? attributes.RealityImage.data.attributes.url : ''} alt="" className="img-fluid" />
                            </div>
                            <div className="spacer-xs-4"></div>
                            <div className="accordion entry-accordian" id="vraccordionOne">
                                {RealityFAQList &&
                                    RealityFAQList.map((itemFAQ, indexFAQ) => {
                                        return (
                                            <div key={indexFAQ} className="accordion-item">
                                                <h2 className="accordion-header" id={"heading-" + indexFAQ}>
                                                    <button className={'accordion-button ' + (indexFAQ !== 0 ? 'collapsed' : '')} type="button" data-bs-toggle="collapse" data-bs-target={"#collapse-" + indexFAQ} aria-expanded="true" aria-controls={"collapse-" + indexFAQ}>
                                                        {itemFAQ && itemFAQ.Title ? itemFAQ.Title : ''}
                                                    </button>
                                                </h2>
                                                <div id={"collapse-" + indexFAQ} className={'accordion-collapse collapse ' + (indexFAQ === 0 ? 'show' : '')} aria-labelledby={"heading-" + indexFAQ} data-bs-parent="#vraccordionOne">
                                                    <div className="accordion-body">{itemFAQ && itemFAQ.Description ? itemFAQ.Description : ''}</div>
                                                </div>
                                            </div>
                                        )
                                    })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
        </TechnologyLayout>
    )
}