import React from 'react';
import { Link } from 'react-router-dom';
import Image from '../common/Image';
export default function ByNeedCardSolution(props) {
  const { item, iconFor, index } = props;
  if (iconFor != 'icon-integration') {
    return (
      <>
        <div key={index} className="col-lg-6 col-xl-4 mb-4">
          <div className="card mb-3 bg-transparent border-0">
            <div className="row g-0">
              <div className="col-md-auto d-flex justify-content-center">
                <div className={item && item.IconColor ? 'entry-icon mb-4 ' + item.IconColor : 'entry-icon color-1 mb-4'}>
                  {iconFor == 'icon-need' && (
                    <span>
                      <i className={item && item.IconSlug ? iconFor + ' ' + item.IconSlug : 'icon-need icon-design-review'}></i>
                    </span>
                  )}
                  {iconFor == 'icon-industries' && (
                    <span>
                      <i className={item && item.IconSlug ? iconFor + ' ' + item.IconSlug : 'icon-need icon-design-review'}></i>
                    </span>
                  )}
                  {iconFor == 'icon-product' && (
                    <span>
                      <Image src={item && item.IconSlug ? 'images/home/solutions/logo/' + item.IconSlug : ''} alt="" />
                    </span>
                  )}
                </div>
              </div>
              <div className="col-md-10">
                <div className="entry-display-5 ps-0 ps-md-6 text-center text-md-start">
                  <h5>
                    {item && item.Title ? item.Title : ''}
                    <i className="icon-comman icon-right"></i>
                  </h5>
                  <p>{item && item.Description ? item.Description : ''}</p>
                  <Link reloadDocument={true} to={item && item.Link ? item.Link : '#'} className="stretched-link"></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <div className="col-lg-6 col-xl-4 mb-4">
        <div className="card entry-card">
          <div className="card-body d-flex flex-column flex-md-row">
            <div className="col-md-auto d-flex justify-content-center">
              <div className="entry-icon mb-4">
                <span>
                  <i className={item && item.IconSlug ? iconFor + ' ' + item.IconSlug : 'icon-integration icon-rhino'}></i>
                </span>
              </div>
            </div>
            <div className="col-md-10">
              <div className="entry-display-5 ps-0 ps-md-6 text-center text-md-start">
                <h5>
                  {item && item.Title ? item.Title : ''}
                  <i className="icon-comman icon-right"></i>
                </h5>
                <p>{item && item.Description ? item.Description : ''}</p>
                <Link reloadDocument={true} to={item && item.Link ? item.Link : '#'} className="read-more stretched-link"></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
