import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import ImageTag from '../../common/Image';

export default function ChallengeCard(props) {
  const { itemIndex, Image, Description, Title, Link: propLink } = props;
  const direction = (itemIndex + 1) % 2 === 0 ? 'left' : 'right';
  const imageClass = direction === 'left' ? 'order-lg-1 order-2' : 'order-lg-2 order-1';
  const descriptionClass = direction === 'left' ? 'order-lg-2 order-2' : 'order-lg-1 order-2';

  const hrefComp = (props) => {
    return (
        <a href={props.href} target="_blank" rel="noreferrer">
          {props.children}
        </a>
      );
  }
  return (
    <>
      <div className="row gx-6 block-card block-card-lg">
        <div className={'col-lg-6 ' + imageClass}>
          <div className="entry-thumb mb-4 mb-lg-0 img-overlay">
            <ImageTag src={Image.data.attributes.url} className="img-fluid" alt="" />
          </div>
        </div>
        <div className={'col-lg-6 ' + descriptionClass}>
          <div className="entry-display-3">
            <h3>{Title}</h3>
            <div className="spacer-xs-1"></div>
            <ReactMarkdown  className="test" 
            components={{
                a : hrefComp,
            }}
            children={Description}/>
            {/* <p>{Description}</p> */}
            <div className="spacer-xs-3"></div>
            <Link reloadDocument={true} to={propLink} className="btn btn-primary btn-sm text-white icon-btn">
              <i className="icon-comman icon-unlock me-2"></i>Discover the Solutions
            </Link>
          </div>
        </div>
      </div>
      <div className="spacer-xs-8 spacer-lg-12"></div>
    </>
  );
}
