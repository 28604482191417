import { useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import { SEOContext } from '../../contexts';

export default function SEO(props) {
  const { title } = useContext(SEOContext);
  if (props.meta) {
    const { metaTitle, keywords, metaDescription, metaImage, metaRobots } = props.meta;
    return props.meta ? (
      <Helmet>
        <title>{metaTitle ? metaTitle : title}</title>
        {keywords && <meta name="keywords" content={keywords} />}
        {metaDescription && <meta name="description" content={metaDescription} />}
        {metaImage && <meta name="image" content={metaImage.data.attributes.url} />}
        {metaRobots && <meta name="robots" content={metaRobots} />}
      </Helmet>
    ) : (
      <Helmet>
        <title>{title}</title>
      </Helmet>
    );
  } else {
    return (
      <Helmet>
        <title>{title}</title>
      </Helmet>
    );
  }
}
