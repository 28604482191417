import LearnMore from '../common/LearnMore';
import Banner from './Banner';
import FeatureList from './FeatureList';
import { SolutionQuery } from '../../Queries';
import useFetch from '../../hooks/useFetch';
import Layout from '../Layout';
import Loader from '../common/Loader';
import Image from '../common/Image';
import ByNeedCardSolution from './ByNeedCardSolution';
import SEO from './../common/SEO';

export default function Solutions() {
  const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/solutions?' + SolutionQuery);
  if (loading) {
    return <Loader />;
  }

  const { data } = response ? response : [];
  const { attributes } = data ? data : [];
  const { ByNeed } = attributes ? attributes : [];
  const { ByProducts } = attributes ? attributes : [];
  const { ByIndustry } = attributes ? attributes : [];
  const { ByIntegration } = attributes ? attributes : [];
  const seoMeta = (attributes.seo) ? attributes.seo : null

  let breadcrumbLinks = [
    { 'link': '/', 'displayName': 'Home' },
    { 'link': '/solutions', 'displayName': 'Solutions' }
  ];

  return (
    <Layout activeTab="solution">
            <SEO meta={seoMeta}></SEO>
      <main className="page-wrapper">
        <section className="banner banner-display-1">
          <div className="container">
            <div className="spacer-xs-5 spacer-xl-13"></div>
            <div className="row align-items-center">
              <div className="entry-display-1 width-xl-55 order-xl-1 order-2 text-center text-xl-start">
                <h1>{attributes && attributes.Title ? attributes.Title : ''}</h1>
                <div className="spacer-xs-1"></div>
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb d-flex justify-content-center justify-content-xl-start">
                    <li className="breadcrumb-item">
                      <a href="/">Home</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#">Solutions</a>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Needs
                    </li>
                  </ol>
                </nav>
                <p>{attributes && attributes.Description ? attributes.Description : ''}</p>
              </div>
              <div className="entry-thumb width-xl-45 d-flex flex-column flex-xl-row justify-content-center justify-content-xl-end align-items-center order-xl-2 order-1">
                <div className="circle-bg d-none d-xl-block"></div>
                <div className="spacer-xs-5 order-1 d-flex d-xl-none"></div>
                <div className="entry-image img-overlay">
                  <Image src={attributes && attributes.CircleImage.data.attributes.url ? attributes.CircleImage.data.attributes.url : ''} alt="" className="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <div className="spacer-xs-2 spacer-lg-4 spacer-xl-13"></div>
        </section>
        <section className="element-background">
          <div className="container">
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <div className="row justify-content-center">
              <div className="col-lg-11">
                <div className="entry-display-1 text-center">
                  <h1 className="text-white">{attributes && attributes.XRTechnologiesTitle ? attributes.XRTechnologiesTitle : ''}</h1>
                  <div className="spacer-xs-1"></div>
                  <p className="text-ultra-light">{attributes && attributes.XRTechnologiesDescription ? attributes.XRTechnologiesDescription : ''}</p>
                </div>
              </div>
            </div>
            <div className="spacer-xs-8 spacer-xl-12"></div>
          </div>
        </section>
        <section className="solution theme-lite">
          <div className="spacer-xs-8 spacer-xl-12"></div>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="entry-display-3 text-center text-md-start">
                  <h3>By Need</h3>
                  <div className="spacer-xs-4 spacer-xl-6"></div>
                </div>
              </div>
            </div>
            <div className="row gx-6">
              {ByNeed.map((itemNeed, indexNeed) => {
                return <ByNeedCardSolution index={'byneed-' + indexNeed} item={itemNeed} iconFor="icon-need" />;
              })}
            </div>
            <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
              <div className="col-sm-12">
                <div className="entry-display-3 text-center text-md-start">
                  <h3>By Product</h3>
                  <div className="spacer-xs-4 spacer-xl-6"></div>
                </div>
              </div>
            </div>
            <div className="row gx-6">
              {ByProducts.map((itemProduct, indexProduct) => {
                return <ByNeedCardSolution index={'byproduct-' + indexProduct} item={itemProduct} iconFor="icon-product" />;
              })}
            </div>
            <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
              <div className="col-sm-12">
                <div className="entry-display-3 text-center text-md-start">
                  <h3>By Industry</h3>
                  <div className="spacer-xs-4 spacer-xl-6"></div>
                </div>
              </div>
            </div>
            <div className="row gx-6 justify-content-start">
              {ByIndustry.map((itemIndustry, indexIndustry) => {
                return <ByNeedCardSolution index={'byindustry-' + indexIndustry} item={itemIndustry} iconFor="icon-industries" />;
              })}
            </div>
            <div className="spacer-xs-8 spacer-xl-10"></div>
          </div>
        </section>
        <section className="entry-structure-light theme--block">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="entry-display-3 text-center text-md-start">
                  <h3>By Integration </h3>
                  <div className="spacer-xs-4 spacer-xl-6"></div>
                </div>
              </div>
            </div>
            <div className="row gx-4">
              {ByIntegration.map((itemIntegration, indexIntegration) => {
                return <ByNeedCardSolution index={'byintigration-' + indexIntegration} item={itemIntegration} iconFor="icon-integration" />;
              })}
            </div>
          </div>
          <div className="spacer-xs-8 spacer-xl-10"></div>
        </section>
        <LearnMore />
      </main>
    </Layout>
  );
}
