import Layout from '../Layout';

export default function Loader() {
  return (
    <Layout activeTab="solution">
      <main className="page-wrapper solution d-flex justify-content-md-center align-items-center vh-100">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </main>
    </Layout>
  );
}
