import { useState, useEffect } from 'react';
import LearnMore from '../common/LearnMore';
import Layout from '../Layout';
import Loader from '../common/Loader';
import Breadcrumb from '../common/Breadcrumb';
import Links from './Links';
import icBgImg from '../../images/ic-bg.png';
import SEOPage from '../common/SEOPage';

export default function MediaPage() {
  const [activePage, setActivePage] = useState(1);
  const [totalPages, setTotalPages] = useState(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const perPage = 12;

  const getData = async url => {
    try {
      const res = await fetch(url, { headers: { Authorization: `Bearer ${process.env.REACT_APP_API_TOKEN}` } });
      const json = await res.json();
      setData(json.data);
      setTotalPages(json.meta.pagination.pageCount);
      setActivePage(json.meta.pagination.page);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    const url = process.env.REACT_APP_CMS_URL + `/api/investor-medias?populate=*&sort=Date:DESC&pagination[page]=1&pagination[pageSize]=${perPage}`;
    getData(url);
  }, []);

  if (loading) {
    return <Loader />;
  }

  const monthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const getDateFormat = dates => {
    let date = new Date(`${dates}T18:16:43.000Z`);
    return date.getDate() + ' ' + monthNames[date.getMonth()] + ', ' + date.getFullYear('YYYY');
  };

  let breadcrumbLinks = [
    { 'link': '/', 'displayName': 'Home' },
    { 'link': '/investor-center/overview', 'displayName': 'Investment Center' },
    { 'link': '/investor-center/media', 'displayName': 'Media' }
  ];
  const handlePage = page => {
    const url = process.env.REACT_APP_CMS_URL + `/api/investor-medias?populate=*&pagination[page]=${page}&pagination[pageSize]=${perPage}`;
    getData(url);
  };

  const url = process.env.REACT_APP_CMS_URL + '/api/investor-media-overview?populate=*';

  return (
    <Layout>
      <SEOPage url={url}></SEOPage>
      <main className="page-wrapper">
        <section className="banner-display-4 text-center text-lg-start" style={{ backgroundImage: `url(${icBgImg})` }}>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="entry-display-1">
                  <h1>News &amp; Media</h1>
                  <div className="spacer-xs-1"></div>
                  <div aria-label="breadcrumb">
                    <Breadcrumb breadcrumbLinks={breadcrumbLinks} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <aside className="drawer-mobile sidebar-content d-flex d-xl-none">
          <div className="offcanvas offcanvas-start" tabIndex="-1" id="openCanvas" aria-labelledby="offcanvasExampleLabel">
            <div className="offcanvas-body handler-menu">
              <div className="spacer-xs-3"></div>
              <div className="sidebartitle">
                <h2>Investor Center</h2>
              </div>
              <Links />
            </div>
          </div>
        </aside>
        <section className="handler-menu d-none d-xl-block">
          <div className="spacer-xs-3"></div>
          <Links />
          <div className="spacer-xs-3"></div>
        </section>
        <section className="post">
          <div className="container">
            <div className="spacer-xs-8 spacer-lg-10"></div>
            <div className="row">
              {data.map((media, index) => {
                return (
                  <article className="col-md-6 col-lg-4 col-xl-3 mb-4 mb-lg-5" key={index}>
                    <div className="card">
                      <div className="post-thumb img-overlay">
                        <img src={media.attributes.Image.data.attributes.url} className="img-fluid" alt="" />
                      </div>
                      <div className="post-content">
                        <h3>{media.attributes.Title}</h3>
                        <p className="date">{getDateFormat(media.attributes.Date)}</p>
                        <a href={media.attributes.SourceUrl} target="_blank" className="read-more mt-auto text-primary stretched-link d-flex align-items-center">
                          Read More<i className="icon-comman icon-right ms-2"></i>
                        </a>
                      </div>
                    </div>
                  </article>
                );
              })}
            </div>
            <div className="spacer-lg-5 spacer-xs-3"></div>
            {totalPages > 1 && (
              <div className="row">
                <div className="col-sm-12">
                  <div className="pagination">
                    <ul className="d-flex justify-content-center list-unstyled">
                      {activePage > 1 && (
                        <li className="page-item">
                          <span className="page-link cursor-pointer" onClick={e => handlePage(activePage - 1)}>
                            Prev
                          </span>
                        </li>
                      )}
                      {[...Array(totalPages)].map((value, index) => {
                        return (
                          <li className={'page-item ' + (activePage === index + 1 ? 'active' : '')} key={'pagination-' + index}>
                            <span
                              className="page-link cursor-pointer"
                              onClick={e => {
                                handlePage(index + 1);
                              }}
                            >
                              {index + 1}
                            </span>
                          </li>
                        );
                      })}
                      {activePage < totalPages && (
                        <li className="page-item">
                          <span className="page-link cursor-pointer" onClick={e => handlePage(activePage + 1)}>
                            Next
                          </span>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            )}
            {totalPages > 1 && <div className="spacer-xs-10"></div>}
          </div>
        </section>
        <LearnMore />
      </main>
    </Layout>
  );
}
