import React, { useLayoutEffect, useRef } from 'react';
import Image from '../../common/Image';
import { Autoplay, Controller, Navigation, Pagination, Thumbs } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/effect-fade';
import 'swiper/css/navigation';

export default function TrustedByBusinessCard(props) {
    // const swiper1Ref = useRef();
    // const swiper2Ref = useRef();
    // useLayoutEffect(() => {
    //   swiper1Ref.current.controller.control = swiper2Ref.current;
    // }, []);
    return (
        <div className="row">
            <div className="col-sm-12">
                <div className="company-logo overflow-hidden">
                    <h2>{props && props.title ? props.title : ''}</h2>
                    <div className="spacer-xs-4"></div>
                    <Swiper
                        className="swiper swiper-company-logo d-flex justify-content-center swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events"
                        spaceBetween={0}
                        slidesPerView="auto"
                        loop={true}
                        autoplay={{
                            delay: 1,
                            disableOnInteraction: false,
                        }}
                        speed={5000}
                        modules={[Autoplay]}
                        direction="horizontal"
                        onSwiper={(swiper) => console.log(swiper)}
                        breakpoints={{
                            340: {
                                slidesPerView: 3,
                                spaceBetween: 80,
                                allowTouchMove: false,
                            },
                            640: {
                                slidesPerView: 3,
                                spaceBetween: 0,
                                allowTouchMove: false,
                            },
                            768: {
                                slidesPerView: 4,
                                spaceBetween: 0,
                                allowTouchMove: false,
                            },
                            1024: {
                                slidesPerView: 5,
                                spaceBetween: 0,
                                allowTouchMove: false,
                            },
                        }}
                    >
                        <div className="swiper swiper-company-logo d-flex justify-content-center">
                            <div className="swiper-wrapper row flex-nowrap">

                                {props.logos.data && props.logos.data.map((item, index) => {
                                    return (
                                        <SwiperSlide>
                                            <div className="swiper-slide width-xs-20 d-flex justify-content-center align-items-center">
                                                <Image src={item.attributes && item.attributes.url ? item.attributes.url : ''} className="img-fluid" />
                                            </div>
                                        </SwiperSlide>
                                    )
                                })}
                            </div>
                        </div>
                    </Swiper>

                    {/* <div className="swiper swiper-company-logo d-flex justify-content-center">
                        <div className="swiper-wrapper row flex-nowrap">
                            {props.logos.data && props.logos.data.map((item, index) => {
                                return (
                                    <div className="swiper-slide width-xs-20 d-flex justify-content-center align-items-center">
                                        <Image src={item.attributes && item.attributes.url ? item.attributes.url : ''} className="img-fluid" />
                                    </div>
                                )
                            })}

                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    );
}
