import React,{ useEffect } from "react";
import $ from "jquery";
import useFetch from "../../../hooks/useFetch"
import Loader from '../../common/Loader';
import Image from "../../common/Image";
import IndustryLayout from "./IndustryLayout"
import { IndustriesArchitectureQuery } from "../../../Queries";
import ReactMarkdown from "react-markdown";
import SEO from './../../common/SEO';

export default function IndustryArchitecture() {
    function UlComponent(props){
        return <ul className="list-arrow">
          {props.children.map(( child , key ) => {
                 return <li index = {key}>
                 {child.props != undefined && <strong className="text-dark fw-bold">{child.props.children[0].props.children[0]}</strong>} {child.props != undefined && child.props.children[1]}
                 </li>
          })}
        </ul>
    }
    useEffect(() => {
        var windowWidth = $(window).width();
        var container = $('.container').width();

        var calcWidth = windowWidth - container;

        $('.art').css('right', - (calcWidth / 2));
    },[])
    
    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/industries-architecture?' + IndustriesArchitectureQuery);
    
    if (loading) {
        return <Loader />;
    }
    const { attributes } = response ? response.data : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null
    return (<IndustryLayout>
            <SEO meta={seoMeta}></SEO>
            <section className="entry-section">
            <div className="spacer-xs-8 spacer-xl-10"></div>
            <div className="row">
            <div className="col-sm-12">
            <div className="entry-display-1">
            <p className="sub-heading text-primary fw-semibold">ARCHITECTURE, ENGINEERING AND CONSTRUCTION (AEC)</p>
            <h1>{attributes.Title ? attributes.Title : ''}</h1>
            <p>{attributes.Description ? attributes.Description : ''}
            </p>
            <div className="spacer-xs-4 spacer-xl-6"></div>
            <div className="entry-thumb img-overlay">
            <Image src={attributes && attributes.Image ? attributes.Image.data.attributes.url : ''} className="img-fluid" />
            </div>
        <div className="spacer-xs-4 spacer-xl-6"></div>
            <ReactMarkdown className="reactMarkdown" children={attributes.SubDescription} components={{
                a: ({ children, href }) => <a href={href} className='sub-link' target='_blank'>{children[0]}</a>
            }} />   
            </div>
        </div>
    </div>
    <div className="spacer-xs-8 spacer-xl-12"></div>
</section>
<section className="entry-structure-light">
    <div className="spacer-xs-8 spacer-xl-10"></div>
    <div className="row">
        <div className="col-sm-12">
            <div className="entry-display-3">
                <ReactMarkdown classname = "markdown"
                components={{
                    img: ({ node, ...props }) => <><div className="spacer-xs-4 spacer-xl-6"></div><div className="entry-thumb img-overlay"><img className="img-fluid" {...props} /></div><div className="spacer-xs-4 spacer-xl-6"></div></>
                }}
                children = {attributes.DifferenceBetweenContent ? attributes.DifferenceBetweenContent : ''}/>
                <div className="accordion entry-accordian" id={'accordion0'}>
                {attributes.RealityListArchitecture && attributes.RealityListArchitecture.map((item , index) => {
                    return (
                        <div className="accordion-item" key={index}>
                        <h2 className="accordion-header" id={'heading' + index}>
                          <button
                           className={"accordion-button " + (index == 0 ? "" : "collapsed")}
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={'#collapse' + index}
                            aria-expanded={true}
                            aria-controls={'collapse' + index}
                          >
                            {item.Title}
                          </button>
                        </h2>
                        <div id={'collapse' + index}  className={"accordion-collapse collapse " + (index == 0 ? "show" : "")} aria-labelledby={'heading' + index} data-bs-parent={'#accordion0'}>
                        <div className="accordion-body">{item.Description}</div>
                        </div>
                      </div>
                    )
                })}
            </div>
            </div>
        </div>
    </div>
    <div className="spacer-xs-8 spacer-xl-10"></div>
</section>
<section className="entry-structure">
    <div className="spacer-xs-8 spacer-xl-12"></div>
    <div className="row">
        <div className="col-sm-12">
            <div className="card-box">
                <h4>{attributes.QuoteTitle ? attributes.QuoteTitle : ''}</h4>
                <div className="spacer-xs-1"></div>
                <p>{attributes.QuoteDescription ? attributes.QuoteDescription : ''}</p>
            </div>
        </div>
    </div>
    <div className="spacer-xs-8 spacer-xl-12"></div>
    <div className="row">
        <div className="col-sm-12">
            <div className="entry-display-2">
            <h2>{attributes.VisualizationTitle ? attributes.VisualizationTitle : ''}</h2>
            <div className="spacer-xs-1"></div>
            <p>{attributes.VisualizationDescription ? attributes.VisualizationDescription : ''}</p>
            </div>
        </div>
    </div>
    <div className="spacer-xs-8 spacer-xl-12"></div>
     {attributes.VisualizationList && attributes.VisualizationList.map((visualization , index) => {
         return (
             <React.Fragment key={index}>
             <div className="row gx-6 block-card block-card-md">
             <div className={(index % 2) == 0 ? "col-lg-6 order-lg-1 order-2" : "col-lg-6 order-lg-2 order-1"}>
            <div className="entry-display-3">
                <h3>{visualization.Title}</h3>
                <div className="spacer-xs-1"></div>
                 <ReactMarkdown children={visualization.Description ? visualization.Description : ''} />
            </div>
        </div>
        <div className={(index % 2) == 0 ? "col-lg-6 order-lg-2 order-1" : "col-lg-6 order-lg-1 order-2"}>
            <div className="entry-thumb mb-4 mb-xl-0 img-overlay">
            <Image src={visualization && visualization.Image ? visualization.Image.data.attributes.url : ''} className="img-fluid" />
            </div>
            </div>
            </div>
           <div className="spacer-xs-8 spacer-xl-12"></div>
        </React.Fragment>
        )})}
</section>
<section className="entry-structure-light">
    <div className="spacer-xs-8 spacer-xl-10"></div>
    {attributes.ToolsList && attributes.ToolsList.map((tool , index) => {
        return <React.Fragment key={index}>
  <div className="row">
        <div className="col-sm-12">
            <div className="entry-display-3">
            <h3>{tool.Title ? tool.Title : ''}</h3>
            <div className="spacer-xs-1"></div>
            <ReactMarkdown children={tool.Description ? tool.Description : ''}/>
            <div className="spacer-xs-4 spacer-xl-6"></div>
            <div className="entry-thumb img-overlay">
            <Image src={tool && tool.Image ? tool.Image.data.attributes.url : ''} className="img-fluid" />
            </div>
            </div>
        </div>
    </div>
    <div className="spacer-xs-8 spacer-xl-12"></div>
    </React.Fragment>
    })}
</section>
<section className="entry-structure">
    <div className="spacer-xs-8 spacer-xl-12"></div>
        <div className="row">
            <div className="col-sm-12">
                <div className="entry-display-2">
                    <h2>{attributes.RevolutionaryTitle ? attributes.RevolutionaryTitle : ''}</h2>
                </div>
            </div>
        </div>
        <div className="spacer-xs-4"></div>
        <div className="row">
            <div className="col-sm-12">
                <div className="entry-display-3">
                 <ReactMarkdown 
                    className="blog-markdown"
                    components={{
                        ul : UlComponent,
                    }}>
                    {attributes.RevolutionaryDescription}
                  </ReactMarkdown>
                </div>
            </div>
        </div>
        {/* <div className="spacer-xs-8 spacer-xl-12"></div> */}
     </section>
    </IndustryLayout>)
}