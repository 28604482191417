import React from "react";
import useFetch from "../../../hooks/useFetch";
import Loader from '../../common/Loader';
import TechnologyLayout from "./TechnologyLayout";
import ReactMarkdown from 'react-markdown';
import { TechnologyMetaverseQuery } from "../../../Queries";
import SEO from '../../common/SEO';

export default function Metaverse() {
    function UlComponent(props) {
        const cnt = props.children.length;
        console.log(props.children)
        return <ul className="list-arrow">
            {props.children.map((child, key) => {
                if (key % 2 != 0 && key < cnt - 1) {
                    console.log(key)
                    return <li className="mb-3" index={key}>
                        {child.props ? child.props.children[0] : ''}
                    </li>
                }
            })}
        </ul>
    }
    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/technology-metaverse?' + TechnologyMetaverseQuery);

    if (loading) {
        return <Loader />;
    }
    const { attributes } = response ? response.data : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null
    return (
        <TechnologyLayout>
            <SEO meta={seoMeta}></SEO>
            <section className="banner banner-display-2">
                <div className="row align-items-center">
                    <div className="spacer-xs-8 spacer-xl-12"></div>
                    <div className="entry-display-1 width-xxl-50 width-xxxl-68 order-2 order-xl-1 text-xl-start text-center">
                        <h1>{attributes.Title}</h1>
                        <div className="spacer-xs-1"></div>
                        <ReactMarkdown className="reactMarkdown" children={attributes.Description} components={{
                            a: ({ children, href }) => <a href={href} className='sub-link' target='_blank'>{children[0]}</a>
                        }} />
                    </div>
                    <div className="entry-thumb d-flex flex-column flex-xl-row justify-content-center justify-content-xl-end align-items-center position-absolute order-1 order-xl-2">
                        <div className="circle-bg d-none d-xl-block"></div>
                        <div className="spacer-xs-5 order-1 d-flex d-xl-none"></div>
                        <div className="entry-image img-overlay">
                            <img src={attributes.Image.data.attributes.url} alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure-light">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-2">
                            <h2>{attributes.OverviewTitle}</h2>
                            <div className="spacer-xs-1"></div>
                            <ReactMarkdown className="reactMarkdown" children={attributes.OverviewDescription} />
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <div className="entry-thumb img-overlay">
                                <img src={attributes.OverviewImage.data.attributes.url} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-10"></div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-2">
                            <h2>{attributes.RoleTitle}</h2>
                            <div className="spacer-xs-1"></div>
                            <ReactMarkdown className="reactMarkdown" children={attributes.RoleDescription} />
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-6 spacer-xl-8"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3">
                            <h3>{attributes.ExperienceTitle}</h3>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes.ExperienceDescription}</p>
                        </div>
                    </div>
                </div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure-light">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-2">
                            <h2>{attributes.ActivitiesTitle}</h2>
                            <div className="spacer-xs-1"></div>
                            <ReactMarkdown className="reactMarkdown" children={attributes.ActivitiesDescription} />
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-6 spacer-xl-8"></div>
                {attributes.ActivitiesList.map((activities, index) => {
                    return (
                        <React.Fragment key={index}>
                            <div className="row gx-6 block-card block-card-sm">
                                <div className={"col-lg-6 order-2 " + ((index % 2) == 0 ? "order-lg-1" : "order-lg-2")}>
                                    <div className="entry-display-3">
                                        <h3>{activities.Title}</h3>
                                        <div className="spacer-xs-1"></div>
                                        <p>{activities.Description}</p>
                                    </div>
                                </div>
                                <div className={"col-lg-6 order-1 mb-4 mb-xl-0 " + + ((index % 2) == 0 ? "order-lg-2" : "order-lg-2")}>
                                    <div className="entry-thumb img-overlay">
                                        <img src={activities.Image.data.attributes.url} className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div className="spacer-xs-8 spacer-xl-12"></div>
                        </React.Fragment>
                    )
                })}
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-2">
                            <h2>{attributes.AreasOfInterestTitle}</h2>
                            <div className="spacer-xs-4"></div>
                            <div className="entry-thumb img-overlay">
                                <img src={attributes.AreasOfInterestImage.data.attributes.url} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-6 spacer-xl-8"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3">
                            <ReactMarkdown
                                className="blog-markdown"
                                components={{
                                    ul: UlComponent,
                                }}>
                                {attributes.AreasOfInterestDescription}
                            </ReactMarkdown>
                        </div>
                    </div>
                </div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure-light report-card">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3">
                            <h3>{attributes.TakeawaysTitle}</h3>
                            <div className="spacer-xs-1"></div>
                            <ReactMarkdown className="reactMarkdown" children={attributes.TakeawaysDescription} />
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-10"></div>
            </section>
        </TechnologyLayout>
    )
}