import { isElement } from "react-dom/test-utils";
import { Link, useLocation } from "react-router-dom";

export default function SideMenu(props) {
  const { menuHeading } = props;
  return (
    <>
      <aside className="sidebar sidebar-content sticky-top d-none d-xl-flex">
        {menuHeading != "Benefits" && <Menu {...props} />}
        {menuHeading == "Benefits" && <MenuBenefit {...props} />}
      </aside>
      <aside className="drawer-mobile sidebar-content d-flex d-xl-none">
        <div
          className="offcanvas offcanvas-start"
          tabIndex="-1"
          id="openCanvas"
          aria-labelledby="offcanvasExampleLabel"
        >
          <div className="offcanvas-body">
            <div className="spacer-xs-3"></div>
            {menuHeading != "Benefits" && <Menu {...props} />}
            {menuHeading == "Benefits" && <MenuBenefit {...props} />}
          </div>
        </div>
      </aside>
    </>
  );
}
function contactBtn() {
  return (
    <Link
      reloadDocument={true}
      to="/contact-us"
      className="btn btn-primary btn-sm text-white icon-btn ml-xs-4 mr-xs-4 mt-5 d-flex justify-content-center"
    >
      <i className="icon-comman icon-envelope-fill me-2"></i>
      Contact Us
    </Link>
  );
}
function Menu(props) {
  const { menuHeading, menuItems } = props;
  const { pathname } = useLocation();
  return (
    <>
      <div className="sidebartitle">
        <h2>{menuHeading}</h2>
      </div>
      <div className="sidebarmenu custom-scroller">
        <div className="accordion" id={"menuAccordion"}>
          {menuItems &&
            menuItems.map((item, index) => {
              let allLinks = [];
              allLinks.push(item.link);
              if (item.subMenu) {
                item.subMenu.forEach((data) => {
                  allLinks.push(data.link);
                  return true;
                });
              }
              if (item.subMenuLinks) {
                item.subMenuLinks.forEach((dataLink) => {
                  allLinks.push(dataLink.link);
                  return true;
                });
              }
              return (
                <div
                  className="accordion-item"
                  key={"side-menu-accordion-" + index}
                >
                  {item.subMenu ? (
                    <h2 className="accordion-header" id={"heading-" + index}>
                      <button
                        className={
                          "accordion-button collapsed " +
                          (allLinks.includes(pathname) ? "active" : "")
                        }
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target={"#collapse-" + index}
                        aria-expanded="false"
                        aria-controls={"collapse-" + index}
                      >
                        {item.name}
                      </button>
                    </h2>
                  ) : (
                    <Link
                      reloadDocument={true}
                      to={item.link}
                      className={
                        "accordion-header " +
                        (allLinks.includes(pathname) ? "active" : "")
                      }
                    >
                      {item.name}
                    </Link>
                  )}

                  {item.subMenu ? (
                    <div
                      id={"collapse-" + index}
                      className={
                        "accordion-collapse collapse " +
                        (allLinks.includes(pathname) ? "show" : "")
                      }
                      aria-labelledby={"heading-" + index}
                      data-bs-parent={"#menuAccordion"}
                    >
                      <div className="accordion-body">
                        <ul>
                          {item.subMenu.map((subMenuItem, subMenuIndex) => {
                            if (
                              subMenuItem.link ==
                                "https://mindeskvr.com/store/" ||
                              subMenuItem.link ==
                                "https://mindeskvr.com/download/" ||
                              subMenuItem.link ==
                                "https://app.3dframe.vection-technologies.com/signup" ||
                              subMenuItem.link ==
                                "https://www.youtube.com/playlist?list=PLP1eKeWNTKK-8ZZ4YGaOTPO92dxyKznes"
                            ) {
                              return (
                                <li key={"side-sub-menu-" + subMenuIndex}>
                                  <a
                                    href={subMenuItem.link}
                                    target="_blank"
                                    className={
                                      pathname === subMenuItem.link
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    <i className="icon-comman icon-right-fill"></i>
                                    <h3>{subMenuItem.name}</h3>
                                  </a>
                                </li>
                              );
                            } else {
                              return (
                                <li key={"side-sub-menu-" + subMenuIndex}>
                                  <Link
                                    reloadDocument={true}
                                    to={subMenuItem.link}
                                    className={
                                      pathname === subMenuItem.link
                                        ? "active"
                                        : ""
                                    }
                                  >
                                    <i className="icon-comman icon-right-fill"></i>
                                    <h3>{subMenuItem.name}</h3>
                                  </Link>
                                </li>
                              );
                            }
                          })}
                        </ul>
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
        </div>

        {menuHeading != "Technologies" && contactBtn()}
      </div>
    </>
  );
}

function MenuBenefit(props) {
  const { menuHeading, menuItems } = props;
  const { pathname } = useLocation();
  return (
    <>
      <div className="sidebartitle">
        <h2>{menuHeading}</h2>
      </div>
      <div className="sidebarmenu custom-scroller">
        <ul className="nav flex-column">
          {menuItems &&
            menuItems.map((item, index) => {
              return (
                <li key={index} className="nav-item">
                  <Link
                    class={
                      "nav-link " + (pathname === item.link ? "active" : "")
                    }
                    aria-current="page"
                    to={item.link}
                  >
                    {item.name}
                  </Link>
                </li>
              );
            })}
        </ul>
        {menuHeading != "Technologies" && contactBtn()}
      </div>
    </>
  );
}
