import { useEffect } from "react";
import $ from "jquery";
import useFetch from "../../../hooks/useFetch";
import Loader from '../../common/Loader';
import TechnologyLayout from "./TechnologyLayout";
import { TechnologyExtendedRealityQuery } from '../../../Queries';
import FeatureCard from './FeatureCard';
import Image from '../../common/Image';
import ReactMarkdown from 'react-markdown';
import SEO from '../../common/SEO';

export default function ExtendedReality() {
    useEffect(() => {
        var windowWidth = $(window).width();
        var container = $('.container').width();

        var calcWidth = windowWidth - container;

        $('.art').css('right', - (calcWidth / 2));
    },[])
    
    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/technology-extended-reality?' + TechnologyExtendedRealityQuery);
    
    if (loading) {
        return <Loader />;
    }
    const { attributes } = response ? response.data : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null

    return(
        <TechnologyLayout>
            <SEO meta={seoMeta}></SEO>
            <section className="banner banner-display-2">
                <div className="row align-items-center">
                    <div className="spacer-xs-8 spacer-xl-12"></div>
                    <div className="entry-display-1 width-xxl-50 width-xxxl-68 order-2 order-xl-1 text-xl-start text-center">
                        <h1>{attributes.Title}</h1>
                        <div className="spacer-xs-1"></div>
                        <p>{attributes.Description}</p>
                    </div>
                    <div className="entry-thumb d-flex flex-column flex-xl-row justify-content-center justify-content-xl-end align-items-center position-absolute order-1 order-xl-2">
                        <div className="circle-bg d-none d-xl-block"></div>
                        <div className="spacer-xs-5 order-1 d-flex d-xl-none"></div>
                        <div className="entry-image img-overlay">
                            <Image src={attributes.BannerImage.data.attributes.url} alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure"> 
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-1">
                            <div className="entry-thumb img-overlay">
                                <Image src={attributes.SubImage.data.attributes.url} alt="" className="img-fluid" />
                            </div>
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <ReactMarkdown className="reactMarkdown" children={attributes.SubDescription} />
                        </div>
                    </div>
                </div>  
                <div className="spacer-xs-8 spacer-xl-10"></div>                              
            </section>
            <section className="entry-structure-light">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-2">
                            <h2>{attributes.TypeTitle}</h2>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes.TypeDescription}</p>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-6 spacer-xl-8"></div>
                {attributes.TypesOfExtendedReality &&
                    attributes.TypesOfExtendedReality.map((types, index) => {
                        return <FeatureCard key={'feature-card-2-' + index} lastItem={index === 3} itemIndex={index} item={types} />;
                    })}
            </section>
            <section className="entry-structure">
                <div className="spacer-xs-8 spacer-xl-12"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-2">
                            <h2>{attributes.PopularTitle}</h2>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes.PopularDescription}</p>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-6 spacer-xl-8"></div>
                {attributes.PopularUseCases &&
                    attributes.PopularUseCases.map((cases, index) => {
                        return <FeatureCard key={'feature-card-2-' + index} lastItem={index === 4} itemIndex={index} item={cases} page="extended-reality" />;
                    })}
            </section>
            <section className="entry-structure-light report-card">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3">
                            <h3>{attributes.TakeawaysTitle}</h3>
                            <div className="spacer-xs-1"></div>
                            <p>{attributes.TakeawaysDescription}</p>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-10"></div>
            </section>
            
        </TechnologyLayout>
    )
}