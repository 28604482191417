import StaticFooterMenu from './StaticFooterMenu';
import Iubenda from 'react-iubenda-policy';
import { Link } from 'react-router-dom';

export default function Footer() {
  const iubendaPolicyId = 19591611;
  return (
    <footer>
      <div className="container">
        <StaticFooterMenu />
        <div className="spacer-xs-2"></div>
        <div className="footer-bottom">
          <div className="copyright order-lg-1 order-2 text-center d-flex align-items-center justify-content-between">
            <p>
              Copyright@{new Date().getFullYear()} <a href="#">Vection Technologies</a> All rights reserved.
            </p>
          </div>
          <div className="d-flex align-items-center justify-content-between  order-lg-2 order-1 flex-column flex-lg-row">
            <div className="policy order-lg-1 order-2 mb-4 mb-lg-0">
              <Iubenda id={iubendaPolicyId} type="privacy" styling="nostyle">
                Privacy Policy
              </Iubenda>
              {/* <span></span>
            <Iubenda id={iubendaPolicyId} type="cookie" styling="nostyle">
              Cookie Policy
            </Iubenda>{' '} */}
              <span></span>
              <Iubenda id={iubendaPolicyId} type="terms-and-conditions" styling="nostyle">
                Terms and conditions
              </Iubenda>
            </div>
            <ul className="social-media order-1 order-lg-2 mb-4 mb-lg-0">
              <li>
                <Link reloadDocument={true} to={'/'}>
                  <i className="icon-social-media icon-facebook"></i>
                </Link>
              </li>
              <li>
                <Link reloadDocument={true} to={'/'}>
                  <i className="icon-social-media icon-twitter"></i>
                </Link>
              </li>
              <li>
                <Link reloadDocument={true} to={'/'}>
                  <i className="icon-social-media icon-linkedin"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  );
}
