import { useEffect } from "react";
import $ from "jquery";
import companyBannerImg from "../../../images/company/company-banner-bg.svg";
import CompanyLayout from "../CompanyLayout";
import { ComppanyLocationQuery } from '../../../Queries';
import iconLocation from "../../../images/location-icon.svg"
import { useParams } from "react-router-dom";
import useFetch from '../../../hooks/useFetch';
import Loader from '../../common/Loader';
import Image from '../../common/Image';
import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import SEO from './../../common/SEO';

export default function CityLayoutPage() {
    const { city } = useParams();
    // const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/company-locations/' + city + '?' + ComppanyLocationQuery);
    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/company-locations/?filters[slug][$eq]=' + city + "&" + ComppanyLocationQuery);
    useEffect(() => {
        var windowWidth = $(window).width();
        var container = $('.container').width();

        var calcWidth = windowWidth - container;

        $('.art').css('right', - (calcWidth / 2));
    })

    if (loading) {
        return <Loader />;
    }
    const { data } = response ? response : [];
    const { attributes } = data[0] ? data[0] : [];
    const sateText = attributes && attributes.State ? ", " + attributes.State : '';
    if (!attributes) {
        return <Loader />;
    }
    const seoMeta = (attributes.seo) ? attributes.seo : null
    return (<CompanyLayout>
            <SEO meta={seoMeta}></SEO>
        <div className="spacer-xs-8 spacer-lg-10"></div>
        <section className="text-center text-lg-start justify-content-center justify-content-lg-start">
            <div className="row">
                <div className="width-lg-100">
                    <div className="entry-display-2">
                        <p className="sub-heading text-primary fw-semibold text-uppercase mb-2">Global Offices</p>
                        <h2 className="fw-bold text-black">{attributes && attributes.City ? attributes.City + sateText  : ''}</h2>
                        <div className="spacer-xs-1"></div>
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb d-flex justify-content-center justify-content-lg-start mb-0">
                                <li className="breadcrumb-item"><a href="/">Home</a></li>
                                <li className="breadcrumb-item"><a href="/company/vection-technologies">Company</a></li>
                                <li className="breadcrumb-item"><a href="/company/global-offices">Global Offices</a></li>
                                <li className="breadcrumb-item active" aria-current="page">{attributes.City ? attributes.City : ''}</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
        </section>
        <div className="spacer-xs-8"></div>
        <section className="map-wrapper position-relative">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3311.9815413951205!2d151.20888581578146!3d-33.89012922759885!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b12b1e01b1ba38d%3A0x3f2c00ba580421a4!2sMarlborough%20house%2C%2056%20l5%2F61%20Marlborough%20St%2C%20Surry%20Hills%20NSW%202010%2C%20Australia!5e0!3m2!1sen!2sin!4v1652358769964!5m2!1sen!2sin" width="100%" height="100%" className="position-absolute" style={{ border: '0' }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            <div className="row justify-content-end align-items-end min-h-inherit">
                <div className="width-md-70 width-lg-53 card-cover">
                    <div className="card p-4 border-0">
                        <div className="row gx-0">
                            <div className="width-lg-10 d-flex justify-content-center align-items-start mb-4 mb-lg-0">
                                <Image src={iconLocation} className="img-fluid" />
                            </div>
                            <div className="width-lg-90">
                                <div className="entry-content ps-md-3 text-center text-md-start">
                                    <h4>Vection Technologies Office</h4>
                                    <h3>{attributes && attributes.City ? attributes.City : ''}</h3>
                                    {attributes && attributes.OperationsText ? <p>{attributes.OperationsText}</p> : ''}
                                    <ReactMarkdown className="reactMarkdownDiv text-black" children={attributes && attributes.Address ? attributes.Address : ''} />
                                    {attributes && attributes.Phone ? <p className="d-flex align-items-center justify-content-center justify-content-md-start text-black"><i className="icon-form icon-phone me-2 fs-xs-22"></i>{attributes.Phone}</p> : ''}
                                    <Link reloadDocument={true} to="#" className="btn btn-primary text-white py-2 px-4 mt-2">Get Direction<i className="icon-comman icon-arrow-right fs-xs-15 ms-2 me-0"></i></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <div className="spacer-xs-8 spacer-lg-10"></div>
    </CompanyLayout>)
}