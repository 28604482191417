
import React from 'react';

export default function RealityCard(props) {
    const { itemIndex, item } = props;
    return (
        <>
         <React.Fragment key={'faqs-item-' + itemIndex}>
         <div className="accordion-item">
                      <h2 className="accordion-header" id={'heading-' + itemIndex}>
                        <button
                          className={'accordion-button ' + (itemIndex !== 0 ? 'collapsed' : '')}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={'#collapse-' + itemIndex}
                          aria-expanded={itemIndex === 0 ? 'true' : 'false'}
                          aria-controls={'#collapse-' + itemIndex}
                        >
                          {item.Title}
                        </button>
                      </h2>
                      <div
                        id={'collapse-' + itemIndex}
                        className={'accordion-collapse collapse ' + (itemIndex === 0 ? 'show' : '')}
                        aria-labelledby={'heading-' + itemIndex}
                        data-bs-parent="#byNeedAccordion"
                      >
                        <div className="accordion-body">{item.Description}</div>
                      </div>
                    </div>
            </React.Fragment>
        </>
    );
}
