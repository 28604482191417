import React from "react";
import useFetch from "../../../hooks/useFetch";
import Loader from '../../common/Loader';
import TechnologyLayout from "./TechnologyLayout";
import ReactMarkdown from 'react-markdown';
import { AugmentedRealityQuery } from "../../../Queries";
import SEO from '../../common/SEO';

export default function AugmentedReality() {
    const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/technology-augmented-reality?' + AugmentedRealityQuery);

    if (loading) {
        return <Loader />;
    }
    const { attributes } = response ? response.data : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null
    return (
        <TechnologyLayout>
            <SEO meta={seoMeta}></SEO>
            <section className="banner banner-display-2">
                <div className="row align-items-center">
                    <div className="spacer-xs-8 spacer-xl-12"></div>
                    <div className="entry-display-1 width-xxl-50 width-xxxl-68 order-2 order-xl-1 text-xl-start text-center">
                        <h1>{attributes.Title}</h1>
                        <div className="spacer-xs-1"></div>
                        <p>{attributes.Description}</p>
                    </div>
                    <div className="entry-thumb d-flex flex-column flex-xl-row justify-content-center justify-content-xl-end align-items-center position-absolute order-1 order-xl-2">
                        <div className="circle-bg d-none d-xl-block"></div>
                        <div className="spacer-xs-5 order-1 d-flex d-xl-none"></div>
                        <div className="entry-image img-overlay">
                            <img src={attributes.Image.data.attributes.url} alt="" className="img-fluid" />
                        </div>
                    </div>
                </div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure-light">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3">
                            <h3>{attributes.RealityTitle}</h3>
                            <div className="spacer-xs-1"></div>
                            <ReactMarkdown className="reactMarkdown" children={attributes.RealityDescription} />
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <div className="entry-thumb img-overlay">
                                <img src={attributes.RealityImage.data.attributes.url} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-10"></div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-3">
                            <h3>{attributes.AugmentedRealityTitle}</h3>
                            <div className="spacer-xs-1"></div>
                            <ReactMarkdown className="reactMarkdown" children={attributes.AugmentedRealityDescription} components={{
                                a: ({ children, href }) => <a href={href} className='sub-link' target='_blank'>{children[0]}</a>
                            }} />
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <div className="entry-thumb img-overlay">
                                <img src={attributes.AugmentedRealityImage.data.attributes.url} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure-light">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-2">
                            <h2>{attributes.RealityWorkTitle}</h2>
                            <div className="spacer-xs-1"></div>
                            <ReactMarkdown className="reactMarkdown" children={attributes.RealityWorkDescription} />
                            <div className="spacer-xs-4 spacer-xl-6"></div>
                            <div className="entry-thumb img-overlay">
                                <img src={attributes.RealityWorkImage.data.attributes.url} alt="" className="img-fluid" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-10"></div>
            </section>
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <section className="entry-structure">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-2 text-center">
                            <h2>{attributes.ToolsTitle}</h2>
                            <p>{attributes.ToolsDescription}</p>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-4 spacer-xl-6"></div>
                <div className="row gx-4 justify-content-center">
                    {attributes.DevicesList.map((device, index) => {
                        return (
                            <div className="col-lg-3 col-md-4 mb-4" key={index}>
                                <div className="card entry-card text-center pt-4">
                                    <div className="entry-icon">
                                        <img src={device.Image.data.attributes.url} className="img-fluid" />
                                    </div>
                                    <div className="spacer-xs-3"></div>
                                    <div className="entry-display-5">
                                        <h5 className="fw-medium fs-lg-18">{device.Title}</h5>
                                    </div>
                                </div>
                            </div>)
                    })}
                </div>
                <div className="spacer-xs-8 spacer-xl-10"></div>
            </section>
            <section className="entry-structure-light">
                <div className="spacer-xs-8 spacer-xl-10"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-2">
                            <h2>{attributes.UseCasesTitle}</h2>
                            <div className="spacer-xs-1"></div>
                            <ReactMarkdown className="reactMarkdown" children={attributes.UseCasesDescription} />
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-6 spacer-xl-8"></div>
                {attributes.UseCasesList.map((usecase, index) => {
                    return (
                        <React.Fragment key={index}>
                            <div className="row gx-6 block-card block-card-sm">
                                <div className={"col-lg-6 order-2 " + ((index % 2) == 0 ? "order-lg-1" : "order-lg-2")}>
                                    <div className="entry-display-3">
                                        <h3>{usecase.Title}</h3>
                                        <div className="spacer-xs-1"></div>
                                        <p>{usecase.Description}</p>
                                    </div>
                                </div>
                                <div className={"col-lg-6 order-1 mb-4 mb-xl-0 " + + ((index % 2) == 0 ? "order-lg-2" : "order-lg-2")}>
                                    <div className="entry-thumb img-overlay">
                                        <img src={usecase.Image.data.attributes.url} className="img-fluid" />
                                    </div>
                                </div>
                            </div>
                            <div className="spacer-xs-8 spacer-xl-12"></div>
                        </React.Fragment>)
                })}
            </section>
        </TechnologyLayout>
    )
}