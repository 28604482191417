import { SolutionByProductConfiguratorGetStarted } from '../../../Queries';
import useFetch from '../../../hooks/useFetch';
import Loader from '../../common/Loader';
import bgImage from '../../../images/products/3dframe/banner-bg.png';
import ByProductLayout from './ByProductLayout';
import TrustedByBusinessCard from './TrustedByBusinessCard';
import { Link } from 'react-router-dom';
import React, { useRef, useState } from 'react';
import { form } from '../../../services/form';
import ReCAPTCHA from 'react-google-recaptcha';
import SEO from '../../common/SEO';

export default function ConfiguratorGetStartedPage() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    companyName: '',
    region: '',
    product: 'Configurator',
    message: ''
  });
  const [errors, setErrors] = useState(false);
  const [success, setSuccess] = useState(false);
  const recaptchaRef = useRef(null);
  const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/product-3dconfigurator-get-started?' + SolutionByProductConfiguratorGetStarted);
  if (loading) {
    return <Loader />;
  }
  const { data } = response ? response : [];
  const { attributes } = data ? data : [];
  const seoMeta = (attributes.seo) ? attributes.seo : null

  const handleFormSubmit = e => {
    e.preventDefault();
    setSuccess(null);
    setErrors(null);
    const recaptchaValue = recaptchaRef.current.getValue();
    if (recaptchaValue) {
      form.enquirySubmit(
        { ...formData, 'g-recaptcha-response': recaptchaValue },
        success => {
          document.getElementById('enquiry-form').reset();
          setFormData({
            firstName: '',
            lastName: '',
            email: '',
            contactNumber: '',
            companyName: '',
            region: '',
            product: 'Configurator',
            message: ''
          });
          recaptchaRef.current.reset();
          setSuccess('Submitted successfully.');
          console.log('success', success);
          setTimeout(() => {
            setSuccess(null);
          }, 5000);
        },
        error => {
          setErrors(error);
          setTimeout(() => {
            setErrors(null);
          }, 5000);
        }
      );
    }
  };

  return (
    <ByProductLayout menuHeading="Products" backgroundImage={bgImage} logo={attributes.Logo}>
            <SEO meta={seoMeta}></SEO>
      <section className="entry-structure">
        <div className="spacer-xs-8 spacer-xl-12"></div>
        <div className="row">
          <div className="col-sm-12">
            <div className="entry-display-3">
              <h3>{attributes && attributes.Title ? attributes.Title : ''}</h3>
            </div>
          </div>
        </div>

        <div className="spacer-xs-2"></div>
        {errors &&
          Object.keys(errors).map(index => {
            return (
              <React.Fragment key={'error-' + index}>
                <span className="text-danger">{errors[index]}</span> <br />
              </React.Fragment>
            );
          })}
        <span className="text-success">{success}</span>
        <div className="spacer-xs-4"></div>
        <div className="row">
          <div className="col-sm-12">
            <div className="entry-form">
              <form
                id="enquiry-form"
                method="post"
                onSubmit={e => {
                  handleFormSubmit(e);
                }}
              >
                <div className="row">
                  <div className="col-xl">
                    <div className="form-floating mb-xs-4">
                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        id="firstName"
                        placeholder="First Name"
                        required
                        value={formData.firstName}
                        onChange={e => {
                          setFormData({ ...formData, firstName: e.target.value });
                        }}
                      />
                      <label htmlFor="Fname">First Name</label>
                      <div className="form-text">
                        This field is compulsory<span className="text-danger">*</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl">
                    <div className="form-floating mb-xs-4">
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        id="lastName"
                        placeholder="Last Name"
                        required
                        value={formData.lastName}
                        onChange={e => {
                          setFormData({ ...formData, lastName: e.target.value });
                        }}
                      />
                      <label htmlFor="Lname">Last Name</label>
                      <div className="form-text">
                        This field is compulsory<span className="text-danger">*</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl">
                    <div className="form-floating mb-xs-4">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Work Email"
                        required
                        value={formData.email}
                        onChange={e => {
                          setFormData({ ...formData, email: e.target.value });
                        }}
                      />
                      <label htmlFor="Email">Work Email</label>
                      <div className="form-text">
                        This field is compulsory<span className="text-danger">*</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl">
                    <div className="form-floating mb-xs-4">
                      <input
                        type="text"
                        className="form-control"
                        name="contactNumber"
                        id="number"
                        placeholder="Contact Number"
                        required
                        value={formData.contactNumber}
                        onChange={e => {
                          setFormData({ ...formData, contactNumber: e.target.value });
                        }}
                      />
                      <label htmlFor="Contact">Contact Number</label>
                      <div className="form-text">
                        This field is compulsory<span className="text-danger">*</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl">
                    <div className="form-floating mb-xs-4">
                      <input
                        type="text"
                        className="form-control"
                        name="companyName"
                        id="number"
                        placeholder="Company Name"
                        required
                        value={formData.companyName}
                        onChange={e => {
                          setFormData({ ...formData, companyName: e.target.value });
                        }}
                      />
                      <label htmlFor="Cname">Company Name</label>
                      <div className="form-text">
                        This field is compulsory<span className="text-danger">*</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl">
                    <div className="form-floating mb-xs-4">
                      <select
                        className="form-select mb-1"
                        name="region"
                        id="region"
                        aria-label="Floating label select example"
                        required
                        onChange={e => {
                          setFormData({ ...formData, region: e.target.value });
                        }}
                      >
                        <option value=""></option>
                        <option value="Europe">Europe</option>
                        <option value="Middle East and Africa (EMEA)">Middle East and Africa (EMEA)</option>
                        <option value="Asia Pacific">Asia Pacific</option>
                        <option value="Americas">Americas</option>
                      </select>
                      <label htmlFor="floatingSelect">Region</label>
                      <div className="form-text">
                        This field is compulsory<span className="text-danger">*</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl">
                    <div className="form-floating mb-xs-4">
                      <input
                        type="text"
                        className="form-control"
                        name="product"
                        id="product"
                        placeholder="Product"
                        required
                        value="Configurator"
                        disabled
                        // onChange={e => {
                        //   setFormData({ ...formData, product: "Configurator" });
                        // }}
                      />
                      <label htmlFor="Product">Product</label>
                      <div className="form-text">
                        This field is compulsory<span className="text-danger">*</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-xl mb-xs-3">
                    <textarea
                      cols="100%"
                      rows="3"
                      type="text"
                      className="form-control"
                      placeholder="Message"
                      name="message"
                      id="message"
                      value={formData.message}
                      onChange={e => {
                        setFormData({ ...formData, message: e.target.value });
                      }}
                    ></textarea>
                  </div>
                </div>
                <ReCAPTCHA sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_TOKEN} ref={recaptchaRef} required />
                <div className="spacer-xs-2"></div>
                <button type="submit" className="btn btn-primary d-flex justify-content-center text-white w-100">
                  Send an Enquiry
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="spacer-xs-8 spacer-lg-12"></div>
        <TrustedByBusinessCard logos={attributes.BusinessIcons} title={attributes.BusinessTitle} />
        <div className="spacer-xs-6 spacer-xl-12"></div>
      </section>
    </ByProductLayout>
  );
}
