import { useEffect } from "react";
import $ from "jquery";
import useFetch from '../../hooks/useFetch';
import Loader from '../common/Loader';
import Breadcrumb from '../common/Breadcrumb';
import companyBannerImg from "../../images/company/company-banner-bg.svg";
import trainingBanner from "../../images/benefits/training-banner.jpg"
import infographyLogoImg from "../../images/infography-logo.svg";
import infographyImg from "../../images/infography.svg";
import infographyMobileImg from "../../images/infography-mobile.svg";
import CompanyLayout from "./CompanyLayout";
import SEO from './../common/SEO';
import Image from '../common/Image';

export default function OurVision() {
   useEffect(() => {
      var windowWidth = $(window).width();
      var container = $('.container').width();

      var calcWidth = windowWidth - container;

      $('.art').css('right', - (calcWidth / 2));
   })
   const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/company-brand?populate=*');

   if (loading) {
      return <Loader />;
   }
   const { attributes } = response ? response.data : [];
   const seoMeta = (attributes.seo) ? attributes.seo : null

   let breadcrumbLinks = [
      { 'link': '/', 'displayName': 'Home' },
      { 'link': '/company/vection-technologies', 'displayName': 'Company' },
      { 'link': '/company/our-vision', 'displayName': 'Brands that Support Our Vision' }
   ];

   return (<CompanyLayout>
      <SEO meta={seoMeta}></SEO>
      <section className="banner banner-display-2">
         <div className="row">
            <div className="spacer-xs-8 spacer-xl-12"></div>
            <div className="entry-display-1 width-xxl-50 width-xxxl-68 order-2 order-xl-1 text-xl-start text-center">
               <p className="sub-heading text-primary fw-semibold text-uppercase">Brands that Support our Vision</p>
               <h1>{attributes.Title}</h1>
               <div className="spacer-xs-1"></div>
               <p className="fw-medium">{attributes.Description}</p>
               <div className="spacer-xs-4"></div>
            </div>
            <div className="entry-thumb d-flex flex-column flex-xl-row justify-content-center justify-content-xl-end align-items-center position-absolute order-1 order-xl-2">
               <div className="circle-bg d-none d-xl-block"></div>
               <div className="spacer-xs-5 order-1 d-flex d-xl-none"></div>
               <div className="entry-image img-overlay">
                  <Image src={attributes.Image.data.attributes.url} alt="" className="img-fluid" />
               </div>
            </div>
         </div>
      </section>
      <section className="entry-section">
         <div className="spacer-xs-8 spacer-lg-12"></div>
         <div className="infographic-block list">
            <div className="row gx-0">
               <div className="width-lg-33">
                  <ul className="card list-unstyled h-100 mb-0">
                     {attributes.BridgeListLeft.map((list, index) => {
                        return <li key={index}>{list.Text}</li>
                     })}
                  </ul>
               </div>
               <div className="width-lg-34">
                  <div className="entry-image h-100 align-items-center d-flex justify-content-center">
                     <img src={infographyImg} className="img-fluid d-none d-lg-flex" alt="" />
                     <img src={infographyMobileImg} className="img-fluid d-flex d-lg-none" alt="" />
                  </div>
               </div>
               <div className="width-lg-33">
                  <ul className="card list-unstyled h-100 mb-0">
                     {attributes.BridgeListRight.map((list, index) => {
                        return <li key={index}>{list.Text}</li>
                     })}
                  </ul>
               </div>
            </div>
         </div>
         <div className="spacer-xs-8 spacer-lg-14"></div>
      </section>
      <section className="entry-section">
         <div className="row">
            <div className="col-sm-12">
               <div className="entry-display-2">
                  <h2>{attributes.BrandTitle}</h2>
                  <div className="spacer-xs-1"></div>
                  <p>{attributes.BrandDescription}</p>
               </div>
            </div>
         </div>
         <div className="spacer-xs-4 spacer-lg-6"></div>
         <div className="infographic-block list">
            <div className="row gx-0">
               <div className="width-lg-33">
                  <ul className="card list-unstyled h-100 mb-0">
                     {attributes.BrandIconsLeft.data.map((icon, index) => {
                        return <li key={index}>
                           <img src={icon.attributes.url} className="img-fluid" alt="" />
                        </li>
                     })}
                  </ul>
               </div>
               <div className="width-lg-34">
                  <div className="entry-image h-100 align-items-center d-flex justify-content-center">
                     <img src={infographyLogoImg} className="img-fluid d-none d-lg-flex" alt="" />
                     <img src={infographyMobileImg} className="img-fluid d-flex d-lg-none" alt="" />
                  </div>
               </div>
               <div className="width-lg-33">
                  <ul className="card list-unstyled h-100 mb-0">
                     {attributes.BrandIconsRight.data.map((icon, index) => {
                        return <li key={index}>
                           <img src={icon.attributes.url} className="img-fluid" alt="" />
                        </li>
                     })}
                  </ul>
               </div>
            </div>
         </div>
         <div className="spacer-xs-8 spacer-lg-12"></div>
      </section>
   </CompanyLayout>)
}