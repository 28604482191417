import React from 'react';
import Image from '../../common/Image';
import { Link, useLocation } from 'react-router-dom';
export default function BannerVideoUrl(props) {

    const { pathname } = useLocation();
    return (
        <>
            <section className="entry-structure">
                <div className="spacer-xs-8 spacer-xl-12"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-1">
                            <h1>{props && props.title ? props.title : '' }</h1>
                            <div className="spacer-xs-1"></div>
                            <p>{props && props.description ? props.description : ''}</p>
                            <div className="spacer-xs-4"></div>
                            {props.productName && props.productName == "mindesk" ? <a href={props.link} target="_blank" className="btn btn-primary btn-sm text-white icon-btn px-5 py-3">Get Started<i className="icon-comman icon-arrow-right ms-3 fs-lg-18"></i></a> : <Link reloadDocument={true} to={props.link} className="btn btn-primary btn-sm text-white icon-btn px-5 py-3">Get Started<i className="icon-comman icon-arrow-right ms-3 fs-lg-18"></i></Link> }
                            
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-6 spacer-xl-12"></div>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="entry-display-1">
                            <div className="entry-video-1">
                            <iframe width="100%" height="100%" src="https://www.youtube.com/embed/Z7vwbyk7riQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                            {/* <iframe width="100%" height="415" src="https://www.youtube.com/embed/Z7vwbyk7riQ?controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                                {/* <Image src={props && props.video.data.attributes.url ? props.video.data.attributes.url : ''} className="img-fluid h-100 w-100" /> */}
                                {pathname != "/solutions/products/configurator/overview"}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="spacer-xs-8 spacer-xl-12"></div>
            </section>
        </>
    );
}
