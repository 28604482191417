import React from 'react';
import { Link } from 'react-router-dom';
import Image from '../common/Image';

export default function Solutions(props) {
  const { SolutionTitle, SolutionDescription, SolutionList } = props;

  return (
    <section className="element-background product product--theme-dark">
      <div className="container">
        <div className="spacer-xs-8 spacer-md-10 spacer-lg-12"></div>
        <div className="row">
          <div className="col-sm-12">
            <div className="entry-display-1">
              <h1>{SolutionTitle}</h1>
               <p>{SolutionDescription}</p>
            </div>
          </div>
        </div>
        <div className="spacer-xs-4 spacer-lg-8"></div>
        <div className="row">
          {SolutionList.map((item, index) => {
            return (
              <div className="width-sm-50 width-lg-33 width-xl-20 d-flex" key={index}>
                <div className={'card border-0 bg-transparent py-4 '}>
                  <div className={'entry-icon ' + item.IconColor}>
                    <span>
                    <img src={`/images/home/solutions/logo/${item.IconSlug}`}/>
                    </span>
                  </div>
                  <div className="spacer-xs-3 spacer-lg-4"></div>
                  <div className="entry-display-4 text-center flex-grow-1 d-flex flex-column">
                    <h4>{item.Title}</h4>
                    <p>{item.Description}</p>
                    </div>
                    <Link reloadDocument={true} to={item.Link ? item.Link : "#"} className="read-more-arrow d-flex justify-content-center mt-auto stretched-link text-white"><i className="icon-comman icon-arrow-right text-ultra-light fs-xs-18"></i></Link>
                  </div>
                </div>
            );
          })}
        </div>
        <div className="spacer-xs-6"></div>
        <div className="row">
          <div className="col-sm-12 text-center">
            <Link reloadDocument={true} to="/contact-us" className="btn btn-primary btn-sm text-white icon-btn">
              <i className="icon-comman icon-envelope-fill me-2"></i>Contact Us
            </Link>
          </div>
        </div>
        <div className="spacer-xs-8 spacer-md-10 spacer-lg-12"></div>
      </div>
    </section>
  );
}
