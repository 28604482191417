import Layout from '../Layout';
import { SolutionByProductOverview } from '../../Queries';
import LearnMore from '../common/LearnMore';
import useFetch from '../../hooks/useFetch';
import Loader from '../common/Loader';
import Image from '../common/Image';
import { Link } from 'react-router-dom';
import bgImage from '../../images/circle-bg.png';
import SEO from './../common/SEO';

export default function ProductsOverview(props) {
  const { loading, error, response } = useFetch(process.env.REACT_APP_CMS_URL + '/api/product-overview?' + SolutionByProductOverview);
  if (loading) {
    return <Loader />;
  }
  const { data } = response ? response : [];
  const { attributes } = data ? data : [];
  const { ProductList } = attributes ? attributes : [];
    const seoMeta = (attributes.seo) ? attributes.seo : null
    return (
    <Layout>
            <SEO meta={seoMeta}></SEO>
      <main className="page-wrapper">
        <section className="banner banner-display-1 bg-grey-dark">
          <div className="container">
            <div className="spacer-xs-5 spacer-xl-13"></div>
            <div className="row align-items-center">
              <div className="entry-display-1 width-xl-55 order-2 order-xl-1 text-center text-xl-start">
                <span className="sub-heading text-primary fw-semibold">PRODUCTS</span>
                <div className="spacer-xs-2"></div>
                <h1 className="text-white">{attributes && attributes.Title ? attributes.Title : ''}</h1>
                <div className="spacer-lg-1"></div>
                <p className="text-white">{attributes && attributes.Description ? attributes.Description : ''}</p>
              </div>
              <div className="entry-thumb width-xl-45 d-flex flex-column flex-xl-row justify-content-center justify-content-xl-end align-items-center order-xl-2 order-1">
                <div className="circle-bg d-none d-xl-block" style={{ backgroundImage: `url(${bgImage})` }}></div>
                <div className="spacer-xs-5 order-1 d-flex d-xl-none"></div>
                <div className="entry-image dark img-overlay">
                  <Image src={attributes && attributes.Image.data.attributes.url ? attributes.Image.data.attributes.url : ''} />
                </div>
              </div>
            </div>
            <div className="spacer-xs-6 spacer-xl-13"></div>
          </div>
        </section>
        <section className="product product--theme-lite">
          <div className="spacer-xs-8 spacer-xl-12"></div>
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="entry-display-2 text-center">
                  <h2>Products</h2>
                  <div className="spacer-xs-4 spacer-xl-6"></div>
                </div>
              </div>
            </div>
            <div className="row">
              {ProductList.map((item, index) => {
                return (
                  <div key={index} className="width-sm-50 width-lg-33 width-xl-20 d-flex">
                    <div className="card border-0 bg-transparent py-4">
                      <div className={"entry-icon "+item.IconColor}>
                        <span>
                          <Image src={'images/home/solutions/logo/' + item.IconSlug} />
                        </span>
                      </div>
                      <div className="spacer-xs-3 spacer-lg-4"></div>
                      <div className="entry-display-4 text-center flex-grow-1 d-flex flex-column">
                        <h4>{item && item.Title ? item.Title : ''}</h4>
                        <p>{item && item.Description ? item.Description : ''}</p>
                      </div>
                      <Link reloadDocument={true} to={item && item.Link ? item.Link : '#'} className="read-more-arrow d-flex justify-content-center mt-auto stretched-link">
                        <i className="icon-comman icon-arrow-right text-ultra-light fs-xs-18"></i>
                      </Link>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
        <div className="spacer-xs-8 spacer-xl-12"></div>
        <LearnMore />
      </main>
    </Layout>
  );
}
